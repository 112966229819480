<!--Post result-->
<div class="container">
    <!-- Card -->
    <div class="card mb-5">
        <!-- Card content -->
        <div class="card-body bg-theme">
            <div class="row">
                <div class="col-12 pt-3">
                    <h3><i class="fas fa-file-import" style="font-size: 25px;"></i> {{ 'pg-post-result.post-result' |
                        translate }}</h3>
                    <hr />
                </div>
                <!-- Loading (desapears when is loaded) -->
                <div *ngIf="!isLoaded" id="spinner-loading" class="justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <!-- post result layout -->
                <div class="col-12" *ngIf="isLoaded">
                    <div class="col-12">

                        <mat-form-field class="code-field mb-3">
                            <mat-label>{{ 'pg-post-result.choose-round' | translate }}</mat-label>
                            <mat-select>
                                <mat-option (click)="loadGames(round.id, round.rankRound)" *ngFor="let round of info"
                                    [value]="round.id">
                                    <!--{{ 'pg-post-result.round' | translate }}: ID {{round.id}}-->
                                    {{round.name}} - {{round.number}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div class="col-12" *ngIf="!isRankRound">
                        <table class="table table-striped white-text" mdbTable mdbTableScroll scrollX="true"
                            maxWidth="100%" bordered="true">
                            <tr>
                                <th>{{ 'pg-post-result.date' | translate }}</th>
                                <th>{{ 'pg-post-result.home-team' | translate }}</th>
                                <th>{{ 'pg-post-result.score' | translate }}</th>
                                <th>VS</th>
                                <th>{{ 'pg-post-result.score' | translate }}</th>
                                <th>{{ 'pg-post-result.away-team' | translate }}</th>
                                <th>{{ 'pg-post-result.cancel' | translate }}</th>
                                <th>{{ 'pg-post-result.result' | translate }}</th>
                            </tr>
                            <tr id="spinner-loading2">
                                <td colspan="7" *ngIf="isLoadingGames">
                                    <!-- Loading (desapears when is loaded) -->
                                    <div class="justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngFor="let game of games; let i = index">
                                <td>{{game.dateTime | date: 'dd/MM'}}</td>
                                <td style="text-align: right;">
                                    {{game.homeName}}
                                </td>
                                <td class="center-content">
                                    <input #homeScore (input)="onChange('a', i, homeScore.value)"
                                        class="center-content field-size" type="text" value="{{game.homeTeamScore}}">
                                </td>
                                <td class="center-content">-</td>
                                <td class="center-content">
                                    <input #awayScore (input)="onChange('b', i, awayScore.value)"
                                        class="center-content field-size" type="text" value="{{game.awayTeamScore}}">
                                </td>
                                <td>{{game.awayName}}</td>
                                <td class="center-content">
                                    <mat-slide-toggle checked (click)="cancelGame(game.id)"></mat-slide-toggle>
                                </td>
                                <td class="center-content">{{setResult(game.homeTeamScore, game.awayTeamScore)}}</td>
                            </tr>
                        </table>
                        <button type="button" mdbBtn class="button-bg-theme button-font" mdbWavesEffect
                            (click)="modalPost.show()">
                            {{ 'pg-post-result.publish-result' | translate }}
                        </button>
                    </div>
                    <div class="col-12" *ngIf="isRankRound">
                        <table class="table table-striped white-text" mdbTable mdbTableScroll scrollX="true"
                            maxWidth="100%" bordered="true">
                            <tr>
                                <th>{{ 'pg-post-result.date' | translate }}</th>
                                <th>{{ 'pg-home.player-name' | translate }}</th>
                                <th>{{ 'pg-post-result.result' | translate }}</th>
                                <th>{{ 'pg-post-result.cancel' | translate }}</th>
                            </tr>
                            <tr id="spinner-loading2">
                                <td colspan="7" *ngIf="isLoadingGames">
                                    <!-- Loading (desapears when is loaded) -->
                                    <div class="justify-content-center">
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading....</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngFor="let game of games; let i = index">
                                <td>{{game.dateTime | date: 'dd/MM'}}</td>
                                <td style="text-align: center;">
                                    {{game.homeName}}
                                </td>
                                <td class="center-content">
                                    <input #homeScore max="{{games.length}}" min="1"
                                        (input)="onChange('a', i, homeScore.value)" class="center-content" type="number"
                                        value="{{game.homeTeamScore}}">
                                </td>
                                <td class="center-content">
                                    <mat-slide-toggle checked (click)="cancelGame(game.id)"></mat-slide-toggle>
                                </td>
                            </tr>
                        </table>
                        <button type="button" mdbBtn class="button-bg-theme button-font" mdbWavesEffect
                            (click)="modalPost.show()">
                            {{ 'pg-post-result.publish-result' | translate }}
                        </button>
                    </div>
                </div>
                <!-- post result layout -->
            </div>
        </div>
    </div>
    <!-- Card -->
</div>
<!--Post result-->

<!--Modal Confirm Post-->
<div mdbModal #frame="mdbModal" class="modal fade" scrollY="true" maxHeight="200" id="frameModalTop" tabindex="-1"
    role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
    [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog moda-notify" role="document">
        <div class="modal-content bg-theme" style="height: 411px;
    max-height: 433px;">
            <div class="modal-header text-center">
                <h4 style="color: white;" class="modal-title w-100 font-weight-bold">{{ 'pg-post-result.confirm-result'
                    | translate }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body mx-3">
                <div class="row">
                    <div class="col-9 center-content">
                        <h4 style="color: white;">{{ 'pg-post-result.match' | translate }}</h4>
                    </div>
                    <div class="col-3 center-content">
                        <h4 style="color: white;">{{ 'pg-post-result.result' | translate }}</h4>
                    </div>
                </div>
                <div class="row" *ngFor="let game of games; let i = index">
                    <div *ngIf="!isRankRound" class="col-9">
                        {{game.homeName}} {{game.homeTeamScore}} - {{game.awayTeamScore}} {{game.awayName}}
                    </div>
                    <div *ngIf="isRankRound" class="col-9">
                        {{game.homeName}}
                    </div>
                    <div *ngIf="!isRankRound" class="col-3 center-content">
                        {{setResult(game.homeTeamScore, game.awayTeamScore)}}
                    </div>
                    <div *ngIf="isRankRound" class="col-3 center-content">
                        {{scoreArray[i]}}°
                    </div>
                </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
                <button mdbBtn style="background-color:#ff8e38;color: white" class="waves-light button-font"
                    (click)="modalPost.hide()" mdbWavesEffect>{{ 'pg-post-result.back' | translate }}</button><br />
                <button mdbBtn style="background-color:#ff8e38;color: white" class="waves-light button-font"
                    (click)="postResult()" mdbWavesEffect>{{ 'pg-post-result.confirm' | translate }}</button><br />
            </div>

        </div>
    </div>
</div>
<!--Modal Confirm Post-->