<div class="msg-modal modal" [ngClass]="{'show': isVisible}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
  <div class="modal-dialog modal-detail" role="document">
    <div class="modal-content bg-theme">
      <div class="modal-header text-center" [ngClass]="{'error': isError, 'notification': !isError}">
        <h5 class="modal-title w-100 font-weight-bold" style="color: white; font-size: 30px !important;" [innerHtml]="title"></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onBackgroundClick($event)">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body adjustModel scrollDeg text-center">
        <p style="color: white" [innerHtml]='message'></p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <a type="button" style="padding: 0.54rem 2.14rem" class="btn" [ngClass]="{'error': isError, 'notification': !isError}" (click)="onBackgroundClick($event);" data-dismiss="modal">OK</a>
      </div>
    </div>
  </div>
</div>
