import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from '../../services/cookie/cookie.service';
import { AppComponent } from '../../app.component';
import { ConfigService } from '../../config.service';
import { InteractionService } from '../../interaction.service';
import { Flags, StreamGroup } from '../../round-group.interface';
import { ModalDirective } from 'angular-bootstrap-md';
import { SignalrService } from '../../signalr.service';
import { ModalsService } from '../../services/modals/modals.service';
import { UserCookieService } from '../../services/User/UserCookie.service';


@Component({
  selector: 'app-streamdata',
  templateUrl: './streamdata.component.html',
  styleUrls: ['./streamdata.component.scss']
})


export class StreamdataComponent implements OnInit {
  @ViewChild('frame3', { static: true }) modalRegister: ModalDirective;
  @ViewChild('frameMessage', { static: true }) modalMessages: ModalDirective;
  treeControl = new NestedTreeControl<StreamGroup>(node => node.children);
  dataSource = new MatTreeNestedDataSource<StreamGroup>();
  isLogged: boolean = false;
  countryFlags: Flags;
  currentUser: any;
  public activeLang;
  matchNode: any;
  matIcons: any = [
    { name: "Soccer", icon: "sports_soccer" },
    { name: "Basketball", icon: "sports_basketball" },
    { name: "Baseball", icon: "sports_baseball" },
    { name: "Ice Hockey", icon: "sports_hockey" },
    { name: "Tennis", icon: "sports_tennis" },
    { name: "Handball", icon: "sports_handball" },
    { name: "Floorball", icon: "sports_handball" },
    { name: "Boxing", icon: "sports_mma" },
    { name: "Rugby", icon: "sports_rugby" },
    { name: "Bandy", icon: "sports_football" },
    { name: "American Football", icon: "sports_football" },
    { name: "Cycling", icon: "directions_bike" },
    { name: "Table Tennis", icon: "sports_tennis" },
    { name: "Cricket", icon: "sports_cricket" },
    { name: "Darts", icon: "south_east" },
    { name: "Volleyball", icon: "sports_volleyball" },
    { name: "Curling", icon: "sports_hockey" },
    { name: "Futsal", icon: "sports_football" },
    { name: "Badminton", icon: "sports_tennis" },
  ]
  windows: number = 0;
  curUserId: number = 0;
  loggedInUserId: number;
  titleToAdd: string = '';
  msgToAdd: string = '';
  msgType: string[] = ['', 'modal-primary', 'modal-warning', 'modal-success', 'modal-danger'];

  constructor(private interactionService: InteractionService, private configService: ConfigService,
    private appComponent: AppComponent, private router: Router,
    private cookieService: CookieService,
    private translate: TranslateService,
    private signalr: SignalrService,
    private modalsService: ModalsService,
    private userCookieService: UserCookieService) {


    this.translate.setDefaultLang(this.appComponent.activeLang);
    this.configService.getFlags().subscribe(data => {
      this.countryFlags = data;
    });
  }

  ngOnInit() {
    //this.dataSource.data = JSON.parse('[{"id":1,"name":"Soccer","children":[{"id":3147,"name":"Spain","children":[{"id":42053,"name":"LaLiga","children":[]}]},{"id":3139,"name":"Poland","children":[{"id":41072,"name":"I Liga","children":[]}]}]},{"id":23,"name":"Baseball","children":[{"id":-10,"name":"Other Countries","children":[{"id":-2,"name":"Other Tournaments","children":[]}]}]}]');
    // this.dataSource.data = [
    //   {"id":1,"name":"Soccer","sportId":0, "style": "", "children":[{"id":1,"name":"Soccer","sportId":0, "style": "", "children":[{"id":1,"name":"Soccer","sportId":0, "style": "", "children":[]}]}]},
    //   {"id":2,"name":"Basketball","sportId":0, "style": "", "children":[{"id":1,"name":"Soccer","sportId":0, "style": "", "children":[{"id":1,"name":"Soccer","sportId":0, "style": "", "children":[]}]}]},
    //   {"id":3,"name":"Baseball","sportId":0, "style": "", "children":[]},
    //   {"id":4,"name":"Ice Hockey","sportId":0, "style": "", "children":[]},
    //   {"id":5,"name":"Tennis","sportId":0, "style": "", "children":[]},
    //   {"id":6,"name":"Handball","sportId":0, "style": "", "children":[]},
    //   {"id":7,"name":"Floorball","sportId":0, "style": "", "children":[]},
    //   {"id":9,"name":"Golf","sportId":0, "style": "", "children":[]},
    //   {"id":11,"name":"Motorsport","sportId":0, "style": "", "children":[]},
    //   {"id":12,"name":"Rugby","sportId":0, "style": "", "children":[]},
    //   {"id":13,"name":"Aussie rules","sportId":0, "style": "", "children":[]},
    //   {"id":14,"name":"Winter Sports","sportId":0, "style": "", "children":[]},
    //   {"id":15,"name":"Bandy","sportId":0, "style": "", "children":[]},
    //   {"id":16,"name":"American Football","sportId":0, "style": "", "children":[]},
    //   {"id":17,"name":"Cycling","sportId":0, "style": "", "children":[]},
    //   {"id":19,"name":"Snooker","sportId":0, "style": "", "children":[]},
    //   {"id":20,"name":"Table tennis","sportId":0, "style": "", "children":[]},
    //   {"id":21,"name":"Cricket","sportId":0, "style": "", "children":[]},
    //   {"id":22,"name":"Darts","sportId":0, "style": "", "children":[]},
    //   {"id":23,"name":"Volleyball","sportId":0, "style": "", "children":[]},
    //   {"id":24,"name":"Field hockey","sportId":0, "style": "", "children":[]},
    //   {"id":26,"name":"Waterpolo","sportId":0, "style": "", "children":[]},
    //   {"id":28,"name":"Curling","sportId":0, "style": "", "children":[]},
    //   {"id":29,"name":"Futsal","sportId":0, "style": "", "children":[]},
    //   {"id":32,"name":"Bowls","sportId":0, "style": "", "children":[]},
    //   {"id":60,"name":"BeachSoccer","sportId":0, "style": "", "children":[]},
    //   {"id":61,"name":"Pesapallo","sportId":0, "style": "", "children":[]},
    //   {"id":62,"name":"sporttest","sportId":0, "style": "", "children":[]},
    //   {"id":63,"name":"sporttest223","sportId":0, "style": "", "children":[]},
    //   {"id":64,"name":"sporttest3","sportId":0, "style": "", "children":[]},
    //   {"id":65,"name":"sporttest31","sportId":0, "style": "", "children":[]},
    //   {"id":66,"name":"sporttestprova","sportId":0, "style": "", "children":[]}];
    localStorage.setItem('windows', this.windows.toString());
    this.loggedInUserId = this.userCookieService.getUserId();
    if (this.loggedInUserId <= 0) {
      this.curUserId = 1;
    }
    else {
      this.curUserId = this.loggedInUserId;
    }
    if (this.loggedInUserId > 0) {
      this.configService.getUser(this.loggedInUserId)
        .subscribe(data => {
          if (data.credit >= 10) {
            this.isLogged = true;
          }
        });
    }
    this.setDataSource();
  }

  setDataSource() {
    // setInterval(() => {
    //   this.configService.getStreamingData().subscribe(data => {
    //     this.dataSource.data = data;
    //   }, error => {
    //     console.log(error);
    //   });
    // }, 60000);
  }

  getFlagByName(name: string) {
    name = name == "England" ? "United Kingdom" : name;
    if (this.countryFlags.data.length > 0) {
      var result = this.countryFlags.data.filter(obj => {
        return obj.name === name;
      });
      if (result.length > 0) {
        return result[0].flag;
      }
      else {
        return "/assets/svgs/flag.svg";
      }
    }

  }
  getGameIcon(name: string) {
    var result = this.matIcons.filter(obj => {
      return obj.name === name;
    });
    if (result.length > 0)
      return result[0].icon;
  }

  public isGroup = (_: number, node: StreamGroup) => !!node.children;

  flagName(groupName: string): string {
    let indexResult: number;
    let flags: string[] = ['pt', 'it', 'en', 'ar', 'es', 'wo'];

    switch (groupName) {
      case 'Brasil': {
        indexResult = 0;
        break;
      }
      case 'Italia': {
        indexResult = 1;
        break;
      }
      case 'England': {
        indexResult = 2;
        break;
      }
      case 'Argentina': {
        indexResult = 3;
        break;
      }
      case 'Espanha': {
        indexResult = 4;
        break;
      }
      default: {
        indexResult = 5;
        break;
      }
    }

    return flags[indexResult];
  }

  customTranslate(country: string): string {
    let currentLanguage = this.appComponent.activeLang;
    let result: string = '';
    switch (country) {
      case 'Brasil': {
        if (currentLanguage == 'it') {
          result = 'Brasile';
        }
        if (currentLanguage == 'en') {
          result = 'Brazil';
        }
        if (currentLanguage == 'pt') {
          result = 'Brasil';
        }
        if (currentLanguage == 'es') {
          result = 'Brasil';
        }
        break;
      }
      case 'Italia': {
        if (currentLanguage == 'it') {
          result = 'Italia';
        }
        if (currentLanguage == 'en') {
          result = 'Italy';
        }
        if (currentLanguage == 'pt') {
          result = 'Itália';
        }
        if (currentLanguage == 'es') {
          result = 'Italia';
        }
        break;
      }
      case 'Misto': {
        if (currentLanguage == 'it') {
          result = 'Misto';
        }
        if (currentLanguage == 'en') {
          result = 'Mixed';
        }
        if (currentLanguage == 'pt') {
          result = 'Misto';
        }
        if (currentLanguage == 'es') {
          result = 'Misto';
        }
        break;
      }
      case 'Espanha': {
        if (currentLanguage == 'it') {
          result = 'Spagna';
        }
        if (currentLanguage == 'en') {
          result = 'Spain';
        }
        if (currentLanguage == 'pt') {
          result = 'Espanha';
        }
        if (currentLanguage == 'es') {
          result = 'España';
        }
        break;
      }
      case 'England': {
        if (currentLanguage == 'it') {
          result = "Inghilterra";
        }
        if (currentLanguage == 'en') {
          result = "England";
        }
        if (currentLanguage == 'pt') {
          result = "Inglaterra";
        }
        if (currentLanguage == 'es') {
          result = "Inglaterra";
        }
        break;
      }
      default: {
        result = country;
      }
    }
    return result;
  }

  // NEW SHOWMATCHES
  /*showMatches(node: StreamGroup) {
    this.matchNode = node;
    this.signalr.hubReady.then(() => {
      setInterval(() => {
        this.signalr.getAsyncStreaming();
        let updatedSource = this.dataSource.data;
        let data = this.signalr.updatedSource;
        updatedSource.forEach(element => {
          element.children.forEach(element2 => {
            element2.children.forEach(element3 => {
              if (this.matchNode.id == element3.id) {
                element3.children = [{ "id": parseInt(data[0].code), "name": data[0].name, "sportId": data[0].sportId, "style": "", "children": [] }]
                this.matchNode = element3;
              }
            });
          });
        });
        this.dataSource.data = null;
        this.dataSource.data = updatedSource;
      }, 10000)
    });
  }*/

  showMatches(node: StreamGroup) {
    this.matchNode = node;
    //this.router.navigate(['/live-stream']);
    // this.router.navigate(['/live-stream/' + node.id+'/'+node.sportId]);
    this.configService.getLiveMatches(node.id, node.sportId).subscribe(data => {
      var updatedSource = this.dataSource.data;
      updatedSource.forEach(element => {
        element.children.forEach(element2 => {
          element2.children.forEach(element3 => {
            if (this.matchNode.id == element3.id) {
              element3.children = [{ "id": parseInt(data[0].code), "name": data[0].name, "sportId": data[0].sportId, "style": "", "children": [] }]
              this.matchNode = element3;
            }
          });
        });
      });
      this.dataSource.data = null;
      this.dataSource.data = updatedSource;
    }, error => {
    });

  }
  loadMatch(id: string, sportId: number, type: string) {
    if (type == "mob") {
      this.router.navigate(['/live-stream/' + id + '/' + sportId + '/' + type]);
    }
    else {
      if (this.loggedInUserId == 0) {
        this.loggedInUserId = this.userCookieService.getUserId();
      }
      this.configService.getMatchLink(id, this.loggedInUserId).subscribe(data => {
        if (data.isSuccess) {
          if (type == "desk") {
            window.open(data.matchUrl, "_blank", "scrollbars=0,resizable=0,width=100,height=0,top=0,left=0");
          }
        }
        else {
          this.modalsService.openNotificationModal(this.translate.instant('comp-streaming.fail-title'), this.translate.instant('comp-streaming.fail-info'), true);
        }
      });
    }
  }

  sportLevelFormat(sportId: number) {
    let ret: boolean = false;

    if (sportId >= 1 && sportId <= 117 || sportId == 1000) {
      ret = true;
    }

    return ret;
  }
}
