import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-ticket-mobile',
  templateUrl: './search-ticket-mobile.component.html',
  styleUrls: ['./search-ticket-mobile.component.scss']
})
export class SearchTicketMobileComponent implements OnInit {

  searchCode: number;

  constructor(private appComponent: AppComponent, private translate: TranslateService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {
  }

  public searchTicket(code?: string) {

    //let ticketCode: number = +(<HTMLInputElement>document.getElementById("code-search")).value;
    let ticketCode: string;
    if (typeof (code) == 'undefined') {
      ticketCode = (<HTMLInputElement>document.getElementById("code-search2")).value;
    }
    else {
      ticketCode = code;
    }
    this.appComponent.detailCoupon(ticketCode);
  }

}
