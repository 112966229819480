<div class="sideMenuTittle">
  <div class="side-menu">
    {{ 'pg-slot.filter-by-games' | translate }}
  </div>

</div>
<div *ngFor="let sport of sports" class="text-left ">
  <div class="sportBox hoverBbox sport{{sport.id}} font-weight-bold sportsTitle__box" style="width: 100%;">
    <div (click)="toggleCountries(sport.id)" class="pl-2">
      <img src='{{setIcon(sport.name)}}' width="20px" class="mr-1">
      {{ sport.name | translate }}
    </div>
  </div>

  <div class="text-left listCountry{{sport.id}}" style="width: 100%;" id="">
    <div *ngFor="let country of sport.countries" class="sportBox">
      <div class="hoverBbox country{{country.id}}{{sport.id}}" style="width: 100%;" #countryOption
        (click)="setSport(sport.id, country.id)">
        <div class="pl-4 div-adjust_filter_by_country" style="height: 48px;">
          <img src="{{getFlagByName(country.name)}}" width="18px" style="margin-right: 8px;"/>
          {{country.name | translate}}
          <span class="closeBtn mr-2" *ngIf="countryOption.classList.contains('bgGreen')" id="closeBtn">X</span>
        </div>
      </div>
    </div>
  </div>
</div>