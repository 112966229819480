import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare global {
  interface Window { isRoundLoaded: boolean; }
}

@Injectable({
  providedIn: 'root'
})
export class RoundService {
  roundList = new BehaviorSubject<any>(null);
  constructor() { }

  setRoundList(_roundList) {
    if (_roundList) {
      window.isRoundLoaded = true;
      this.roundList.next(_roundList);
    }
  }

}
