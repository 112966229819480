import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-pix',
  templateUrl: './pix.component.html',
  styleUrls: ['./pix.component.scss']
})
export class PixComponent implements OnInit {

  credit: number;

  constructor(private appComponent: AppComponent, private route: ActivatedRoute, private translate: TranslateService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {

    this.credit = +this.route.snapshot.paramMap.get("value");
  }

}
