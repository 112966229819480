import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { InteractionService } from '../../interaction.service'
import { ModalsService } from 'src/app/services/modals/modals.service';
import { EUserActionsModalType } from 'src/app/Interfaces/Enumerators/EUserActionsModalType';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() actualUrl: string
  isLogged: boolean = false;

  constructor(private appComponent: AppComponent,
    private router: Router, private interactionService: InteractionService,
  private modalsService: ModalsService) { }

  ngOnInit(): void {
    this.interactionService.isLogged.subscribe(x => {
      this.isLogged = x;
    });
  }


  goTo(route: string) {
    if (!this.isLogged) {
      this.appComponent.nextUrl = route;
      this.modalsService.openUserActionsModal(EUserActionsModalType.Login);
    } else {
      this.router.navigate([route]);
    }
  }

}
