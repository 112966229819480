import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../app.component';
import { ConfigService } from '../config.service';
import { ModalsService } from '../services/modals/modals.service';

@Component({
  selector: 'app-work-together',
  templateUrl: './work-together.component.html',
  styleUrls: ['./work-together.component.scss']
})
export class WorkTogetherComponent implements OnInit {

  countries;
  roleList = [
    "other",
    "operator",
    "assistant",
    "programmer",
    "director"
  ]

  contactForm = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(3)]],
    birthdate: [''],
    email: ['', [Validators.required, Validators.email]],
    whatsapp: [''],
    country: [''],
    city: [''],
    role: ['', [Validators.required]],
    message: ['', [Validators.required, Validators.minLength(20)]]
  });

  constructor(private configService: ConfigService, private translate: TranslateService,
    private appComponent: AppComponent, private fb: FormBuilder, private router: Router,
    private modalsService: ModalsService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit(): void {
    this.configService.GetCountry()
      .subscribe(data => {
        this.countries = data;
      }, error => {
        console.log(error);
      });

  }

  get name() { return this.contactForm.get('name'); }
  get birthdate() { return this.contactForm.get('birthdate'); }
  get email() { return this.contactForm.get('email'); }
  get whatsapp() { return this.contactForm.get('whatsapp'); }
  get country() { return this.contactForm.get('country'); }
  get city() { return this.contactForm.get('city'); }
  get role() { return this.contactForm.get('role'); }
  get message() { return this.contactForm.get('message'); }

  sendMessage() {
    let contact = {
      name: this.name.value,
      birthdate: this.birthdate.value,
      email: this.email.value,
      whatsapp: this.whatsapp.value,
      country: this.country.value,
      city: this.city.value,
      role: this.role.value,
      message: this.message.value,
    }

    this.configService.sendWorkMessage(contact)
      .subscribe(data => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-work-together.success-title"), this.translate.instant("pg-work-together.success-info"), false);
        this.router.navigate(['/home']);
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-work-together.fail-title"), this.translate.instant("pg-work-together.fail-info"), true);
        console.log(error);
      });

  }

}
