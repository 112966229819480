<div class="container">
    <h1 style="color: white !important">Loading...</h1>
    <div class="rectangle-animated">

    </div>
</div>

<!--Modal change password-->
<div mdbModal #frame2="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-warning modal-dialog-scrollable" role="document">
        <!--Content-->
        <div class="modal-content bg-theme">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">{{ 'pg-logged.change-password' | translate}}</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame2.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div>
                    <mat-form-field class="w-100">
                        <mat-label>{{ 'pg-logged.new-password' | translate}}</mat-label>
                        <input type="password" matInput [(ngModel)]="newPassword" newPassword ngModel
                            name="newPassword">
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <mat-label>{{ 'pg-logged.confirm-password' | translate}}</mat-label>
                        <input type="password" matInput [(ngModel)]="confirmPassword" confirmPassword ngModel
                            name="confirmPassword">
                    </mat-form-field>
                </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">

                <a type="button" mdbBtn class="waves-light button-bg-theme button-font" (click)="changePassword()">
                    {{ 'pg-logged.confirm-password' | translate}}
                </a>
                <a type="button" mdbBtn style="background-color: #ff8e38;" class="btn" mdbWavesEffect
                    (click)="frame2.hide()" data-dismiss="modal">{{ 'pg-logged.close' | translate}}</a>


            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal change password-->