import { Component, OnInit, Input } from '@angular/core';
import { ModalsService } from '../../../services/modals/modals.service';


@Component({
  selector: 'app-message-standard',
  templateUrl: './message-standard.component.html',
  styleUrls: ['./message-standard.component.scss']
})
export class MessageStandardComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  isVisible: boolean = false;
  isError: boolean = false;
  redirectTo: string | null = null;
  constructor(private modalsService: ModalsService) { this.modalsService.registerNotificationModal(this) }

  ngOnInit(): void {}

  open(title: string, message: string, isError: boolean, redirectTo: string | null = null) {
    this.title = title;
    this.message = message;
    this.isError = isError
    this.isVisible = true;
    this.redirectTo = redirectTo;
  }

  close() {
    this.isVisible = false;
  }

  onBackgroundClick(event: Event) {
    this.close();
    if (this.redirectTo != null)
      window.location.href = this.redirectTo;
  }
}
