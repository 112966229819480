import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class CookieService {

    set(name: string, value: string, days: number) {
        const maxAge = days * 8640000;
        document.cookie = `${name}=${value}; path=/; max-age=${maxAge}`
    }

    get(name: string): string {
        const cookiesBase = `; ${document.cookie}`;
        const cookies = cookiesBase.split(`; ${name}=`);

        if (cookies.length === 2)
            return cookies.pop()?.split(';').shift() || '';

        return '';
    }

    delete(name: string) {
        this.set(name, '', -1);
    }

    deleteAll() {
        const cookies = document.cookie.split(";");

        cookies.forEach(
            cookie => {
                const finalPositionOfCookieName = cookie.indexOf("=");
                const name = finalPositionOfCookieName > -1 ? cookie.substring(0 , finalPositionOfCookieName) : cookie;

                this.delete(name);
            }
        )
    }
}