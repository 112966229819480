<div class="container">
    <div class="row">
        <div class="card mb-5">

            <div class="card-body bg-theme">

                <h3><i class="fas fa-clipboard-list" style="font-size: 25px;"></i>
                    {{ 'pg-rule.rules-site' | translate }}
                </h3>

                <hr />

                <p class="title">{{ 'pg-rule.I' | translate }}</p>

                <p>{{ 'pg-rule.Ia' | translate }}</p>

                <p>{{ 'pg-rule.Iai' | translate }}</p>

                <p>{{ 'pg-rule.Iaii' | translate }}</p>

                <p>{{ 'pg-rule.I.a.info' | translate }}</p>

                <p>{{ 'pg-rule.I.a.iii' | translate }}</p>

                <p class="title">{{ 'pg-rule.II' | translate }}</p>

                <p>{{ 'pg-rule.II.a' | translate }}</p>

                <p>{{ 'pg-rule.II.a.i' | translate }}</p>

                <p>{{ 'pg-rule.II.a.ii' | translate }}</p>

                <p>{{ 'pg-rule.II.b' | translate }}</p>

                <p>{{ 'pg-rule.II.c' | translate }}</p>

                <p>{{ 'pg-rule.II.d' | translate }}</p>

                <p class="title">{{ 'pg-rule.III' | translate }}</p>

                <p>{{ 'pg-rule.IIIa' | translate }}</p>


                <p class="title">{{ 'pg-rule.IV' | translate }}</p>

                <p>{{ 'pg-rule.IVa' | translate }}</p>

                <p class="title">{{ 'pg-rule.V' | translate }}</p>

                <p>{{ 'pg-rule.Va' | translate }}</p>

                <p class="title">{{ 'pg-rule.VI' | translate }}</p>

                <p>{{ 'pg-rule.VIa' | translate }}</p>

                <p>{{ 'pg-rule.VIai' | translate }}</p>

                <p>{{ 'pg-rule.VIaii' | translate }}</p>

                <p>{{ 'pg-rule.VIaiii' | translate }}</p>

                <p class="title">{{ 'pg-rule.VII' | translate }}</p>

                <p>{{ 'pg-rule.VIIa' | translate }}</p>

                <p class="title">{{ 'pg-rule.VIII' | translate }}</p>

                <p>{{ 'pg-rule.VIIIa' | translate }}</p>

                <!--POKER RULES-->
                <h3>
                    <img class="pb-1" src="../../assets/image/chip-32.png">
                    {{ 'pg-rule.poker-rules' | translate }}
                </h3>
                <hr />

                <p class="title">{{ 'pg-rule.I' | translate }}</p>

                <p>{{ 'pg-rule.poker-Ia' | translate }}</p>

                <p>{{ 'pg-rule.poker-Ib' | translate }}</p>

                <p>{{ 'pg-rule.poker-Ibi' | translate }}</p>

                <p>{{ 'pg-rule.poker-Ibii' | translate }}</p>

                <p class="title">{{ 'pg-rule.II' | translate }}</p>

                <p>{{ 'pg-rule.poker-II.a' | translate }}</p>

                <p>{{ 'pg-rule.poker-II.b' | translate }}</p>

                <p>{{ 'pg-rule.poker-II.c' | translate }}</p>

                <p>{{ 'pg-rule.poker-II.d' | translate }}</p>

                <p class="title">{{ 'pg-rule.III' | translate }}</p>

                <p>{{ 'pg-rule.poker-III.a' | translate }}</p>

                <p class="title">{{ 'pg-rule.IV' | translate }}</p>

                <p>{{ 'pg-rule.IVa' | translate }}</p>

                <p class="title">{{ 'pg-rule.V' | translate }}</p>

                <p>{{ 'pg-rule.poker-V.a' | translate }}</p>

                <p class="title">{{ 'pg-rule.poker-V.b' | translate }}</p>

                <p>{{ 'pg-rule.poker-V.c' | translate }}</p>

                <p>{{ 'pg-rule.poker-V.d' | translate }}</p>

                <p class="title">{{ 'pg-rule.poker-VI' | translate }}</p>

                <p class="title">{{ 'pg-rule.poker-VI.a' | translate }}</p>

                <p><b>{{ 'pg-rule.poker-VI.a.i' | translate }}</b></p>

                <p><b>{{ 'pg-rule.poker-VI.a.ii' | translate }}</b></p>

                <p><b>{{ 'pg-rule.poker-VI.a.iii' | translate }}</b></p>

                <p>{{ 'pg-rule.poker-VI.b' | translate }}</p>

                <p>{{ 'pg-rule.poker-VI.c' | translate }}</p>

                <p>{{ 'pg-rule.poker-VI.c.i' | translate }}</p>

                <p class="title">{{ 'pg-rule.poker-VII' | translate }}</p>

                <p>{{ 'pg-rule.poker-VII.a' | translate }}</p>

                <!-- MMA RULES -->
                <h3>
                    <img class="pb-1" src="../../assets/image/cinturao-de-campeao.png">
                    {{ 'pg-rule.rulemma' | translate }}
                </h3>
                <hr />

                <p class="title">{{ 'pg-rule.mma1' | translate }}</p>

                <p>{{ 'pg-rule.mma1a' | translate }}</p>

                <p>{{ 'pg-rule.mma1b' | translate }}</p>

                <p>{{ 'pg-rule.mma1c' | translate }}</p>

                <p>{{ 'pg-rule.mma1d' | translate }}</p>

                <p>{{ 'pg-rule.mma1di' | translate }}</p>

                <p>{{ 'pg-rule.mma1e' | translate }}</p>

                <p class="title">{{ 'pg-rule.mma2' | translate }}</p>

                <p>{{ 'pg-rule.mma2a' | translate }}</p>

                <p>{{ 'pg-rule.mma2b' | translate }}</p>

                <p>{{ 'pg-rule.mma2c' | translate }}</p>

                <p>{{ 'pg-rule.mma2d' | translate }}</p>

                <p class="title">{{ 'pg-rule.mma3' | translate }}</p>

                <p>{{ 'pg-rule.mma3a' | translate }}</p>

                <p class="title">{{ 'pg-rule.mma4' | translate }}</p>

                <p>{{ 'pg-rule.mma4a' | translate }}</p>

                <p>{{ 'pg-rule.mma4b' | translate }}</p>

                <p>{{ 'pg-rule.mma4c' | translate }}</p>

                <p>{{ 'pg-rule.mma4d' | translate }}</p>

                <p class="title">{{ 'pg-rule.mma5' | translate }}</p>

                <p>{{ 'pg-rule.mma5a' | translate }}</p>

                <p>{{ 'pg-rule.mma5ai' | translate }}</p>

                <p>{{ 'pg-rule.mma5aii' | translate }}</p>

                <p>{{ 'pg-rule.mma5aiii' | translate }}</p>

                <p>{{ 'pg-rule.mma5b' | translate }}</p>

                <p>{{ 'pg-rule.mma5c' | translate }}</p>

                <p class="title">{{ 'pg-rule.mma6' | translate }}</p>

                <p>{{ 'pg-rule.mma6a' | translate }}</p>
            </div>
        </div>
    </div>
</div>