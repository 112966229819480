<div class="cart-container desktop-hide" *ngIf="count>0">
    <div class="badge-container">
        <span class="badge badge-pill badge-warning">{{endRound(count)}}</span>
    </div>

    <button class="btn-cart" (click)="openModal();SetFinalResultName(ticketState)">
        <img src="../assets/image/NavBarMobile/apostas.png" style="width: 40px;" alt="">
    </button>

    <div class="little-ball"
        [@ballMoving]="{value: ballMoving, params: {topStart: topStartPosition, rightStart: rightSartPosition } }">
    </div>
</div>

<!--Modal confirm ticket-->
<div mdbModal #frame="mdbModal" class="modal fade desktop-hide" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-warning modal-dialog-scrollable" role="document">
        <!--Content-->
        <div class="modal-content" style="background-color: #FFF;
   color: black;">
            <!--Header-->
            <div class="modal-header" style="background: #03a84e">
                <p class="heading lead">{{ 'pg-home.confirm-ticket' | translate }}</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body bg-theme">
                <div>
                    <p style="color: #fff;" *ngIf="(ticketState != null)">
                        <strong>{{ 'pg-home.total-value' | translate }}: {{ Currency.currencySymbol }}
                            {{roundingFunction(ticketState.finalValue) | currency:'':''}}
                        </strong><br />
                        {{ 'pg-home.prize' | translate }}: {{ Currency.currencySymbol }}
                        {{ roundingFunction(prizeAmount) | currency:'':''}} <br />
                        {{ 'pg-home.jackpot' | translate }}: {{ Currency.currencySymbol }}
                        {{ roundingFunction(jackpotAmount) | currency:'':''}}
                    </p>
                    <p style="color: white" id="final-msg" [innerHtml]="htmlToAdd"></p>
                </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center"
                style=" display: flex; align-items: center; justify-content: flex-end; padding: 0.5rem; background: #1f1f1f; border-top: 1px solid #dee2e6; border-bottom-right-radius: 0.3rem; border-bottom-left-radius: 0.3rem;">
                <a type="button" mdbBtn class="waves-light button-font" (click)="Confirm()" *ngIf="isChecked"
                    style="text-transform: capitalize;width: 115px;background-color: #37b13f;  border: 1px solid transparent; color: #ffffff;font-weight: 600;padding: 10px; border-radius: 6px;">{{
                    'pg-home.confirm' | translate }}
                </a>

                <a type="button" mdbBtn
                    style="text-transform: capitalize;width: 115px;background-color: #dc3545;border: 1px solid transparent;border-color: #dc3545;color: #fff;font-weight: 600;padding: 10px;border-radius: 6px;"
                    class="btn" mdbWavesEffect (click)="frame.hide();" data-dismiss="modal">{{ 'pg-home.back' |
                    translate
                    }}</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal confirm ticket-->

<!--Modal finish ticket-->
<div mdbModal #frame2="mdbModal" class="modal fade desktop-hide" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document">
        <!--Content-->
        <div class="modal-content bg-theme">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">{{ 'pg-home.bet-done' | translate }}</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="closeFinishModal()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div class="text-center" style="color: white">
                    <p>{{ 'pg-home.bet-successfull-created' | translate }}</p>
                </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn class="waves-light button-font" style="background-color:#01d36b;"
                    (click)="showModalWhatsapp()" mdbWavesEffect><i class="fab fa-whatsapp"></i>
                </a>
                <a type="button" mdbBtn class="waves-light button-font" style="background-color:#01d36b;"
                    (click)="printTicket()" mdbWavesEffect><i class="fas fa-print"></i>
                </a>
                <a type="button" color="waves-light button-font" style="background-color:#01d36b;" class="btn"
                    mdbWavesEffect (click)="closeFinishModal(); sendBetConfirmedSignal()" data-dismiss="modal">OK</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal finish ticket-->

<!--Modal Whatsapp-->
<div mdbModal #frame3="mdbModal" class="modal fade desktop-hide" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document">
        <!--Content-->
        <div class="modal-content bg-theme">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">{{ 'pg-home.phone-number' | translate }}</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeWhatsapp()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div class="text-center" style="color: white">
                    <p>
                        {{ 'pg-home.phone-number-info' | translate }}<br />
                        ({{ 'pg-home.example' | translate }}: 5521987654321)
                    </p>
                    <input #celphone class="center-content field-size" type="text" />
                </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn class="waves-light button-bg-theme button-font sendBtn"
                    (click)="whatsappTicket(celphone.value)" mdbWavesEffect>
                    {{ 'pg-home.send' | translate }}
                </a>
                <a type="button" mdbBtn color="warning" outline="true" class="btn" mdbWavesEffect
                    (click)="closeWhatsapp()" data-dismiss="modal">{{ 'pg-home.close' | translate }}</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal Whatsapp-->

<div mdbModal #frame5="mdbModal" class="modal fade desktop-hide" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document">
        <!--Content-->
        <div class="modal-content bg-theme">
            <!--Header-->
            <div class="modal-header" style="background-color: #dc3545;">
                <p class="heading lead" style="font-weight: bold; margin-left: 8%; ">
                    {{ 'pg-home.operation-failed' | translate}}
                </p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="modalWithoutCredit.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div class="text-center" style="color: white">
                    <div>
                        <p>{{ noEnoughCredit[0] }}!</p>
                        <p>{{ noEnoughCredit[1] }}</p>
                    </div>
                </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn class="btn" mdbWavesEffect
                    (click)="modalWithoutCredit.hide(); RedirectToPayment()" data-dismiss="modal"
                    style="text-transform: capitalize;width: 300px;background-color: #dc3545;border: 1px solid transparent;border-color: #dc3545;color: #fff;font-weight: bold; font-size: 1.3rem; padding: 10px;">
                    {{ 'pg-payment.to-deposit' | translate}}
                </a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>

<!--Modal confirm ticket-->
<div mdbModal #frame4="mdbModal" class="modal fade desktop-hide" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify  modal-warning modal-dialog-scrollable" role="document">
        <!--Content-->
        <div class="modal-content bg-theme">
            <!--Header-->
            <div class="modal-header" style="padding: 5px; background-color: #00c851 !important;">
                <p class="heading lead">{{ 'pg-home.confirm-ticket' | translate }} </p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clearBet()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="row mt-2 rowlajust overflow-auto">
                <div class="col-12 p-0">
                    <div class="ticket-content" *ngIf="(ticketState == null)">
                        <p class="pattern-space">{{ 'pg-home.bet-selected' | translate }}</p>
                    </div>

                    <div class="ticket-content" *ngIf="(ticketState != null && ticketState.round != null)">
                        <div class="gameHeightAdjust">
                            <div class="games-area" *ngFor="let matches of ticketState.matches; let i = index">
                                <div *ngIf="round.rankRound && !round.singleMatchRound; else other_content">
                                    <div *ngIf="matches.matchResult != ''">
                                        <p class="pattern-space mb-0">{{matches.homeName}}</p>
                                        <div
                                            class="result-line mt-0 d-flex justify-content-between align-items-center pl-3">
                                            <span class="item-result">{{matches.matchResult}}°</span>
                                            <a class="btn-remove pattern-space d-flex mr-3"
                                                (click)="removeResult(i, matches.matchResult)">
                                                <i class="material-icons"
                                                    style="font-size: 17px; color: red; padding: 14%; background-color: #afafaf;">close</i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #other_content>
                                    <div *ngIf="matches.matchResult != ''">
                                        <p class="pattern-space mb-0"
                                            *ngIf="!round.singleMatchRound && !round.jiuJitsuRound">
                                            <!-- {{matches.dateTime | date: 'short' }} -->
                                            {{matches.homeName}} - {{matches.awayName}}
                                        </p>
                                        <p class="pattern-space mb-0" *ngIf="round.singleMatchRound">
                                            <!-- {{matches.dateTime | date: 'short' }} -->
                                            <!-- {{matches.homeName}} -->
                                            {{ matches.homeName | translate }}
                                        </p>

                                        <div class="result-line mt-0 d-flex align-items-center flex-nowrap pl-3"
                                            *ngFor="let result of splitResults(matches.matchResult)">

                                            <a class="d-flex ml-2" (click)="removeResult(i, result)">
                                                <i class="material-icons"
                                                    style="font-size: 17px; color: red; padding: 14%; background-color: #afafaf;">close</i>
                                            </a>

                                            <span class="item-resul" style="margin-left: 10%;"
                                                *ngIf="!round.singleMatchRound && !round.jiuJitsuRound">
                                                <ng-container *ngIf="result == 'X';else homeTeamWin">{{ 'Teamdraw' |
                                                    translate }}</ng-container>
                                                <ng-template #homeTeamWin>
                                                    <div *ngIf="result == '1';else awayTeamWin">
                                                        {{ matches.homeName | translate }}
                                                    </div>
                                                </ng-template>
                                                <ng-template #awayTeamWin>
                                                    <div *ngIf="result == '2';else RestResult">
                                                        {{ matches.awayName | translate }}
                                                    </div>
                                                </ng-template>
                                                <ng-template #RestResult>{{result}}</ng-template>
                                            </span>

                                            <span class="item-result mr-3" *ngIf="round.singleMatchRound">
                                                <span
                                                    *ngIf="(matches.type == 0 || matches.type == 1 || matches.type == 5 || matches. type == 6) && result == 1">
                                                    {{teamHome}}
                                                </span>

                                                <span
                                                    *ngIf="(matches.type == 0 || matches. type == 6) && result != 1 && result != 2 ">
                                                    {{'Teamdraw' | translate}}
                                                </span>

                                                <span
                                                    *ngIf="(matches.type == 0 || matches.type == 1 || matches.type == 5 || matches. type == 6) && result == 2">
                                                    {{teamAway}}
                                                </span>

                                                <span *ngIf="matches.type == 3 && result == 1">
                                                    {{ 'Yes' | translate }}
                                                </span>

                                                <span *ngIf="matches.type == 3 && result == 2">
                                                    {{ 'No' | translate }}
                                                </span>

                                                <span *ngIf="matches.type == 2  && result == 1">
                                                    {{ 'Coupon-Over' | translate }}
                                                </span>

                                                <span *ngIf="matches.type == 2 && result == 2">
                                                    {{ 'Coupon-Under' | translate }}
                                                </span>

                                                <span *ngIf="matches.type == 4 && result == 1">
                                                    {{ 'Even' | translate }}
                                                </span>

                                                <span *ngIf="matches.type == 4 && result == 2">
                                                    {{ 'Odd' | translate }}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="final-area mb-2">
                            <p *ngIf="ticketState.singleBet" class="text-center item-final mb-3 mt-1">{{
                                'pg-home.simple' | translate }}</p>
                            <p *ngIf="!ticketState.singleBet" class="text-center item-final mb-3 mt-1">{{
                                'pg-home.multiple' | translate }}
                            </p>
                            <p class="item-final pattern-space" style="margin-right: 20px;">{{ 'round-header.jackpot' |
                                translate }}: <span class="to-right"> {{ticketState.currencySymbol}}
                                    {{roundingFunction(jackpotAmount) | currency:'':''}}</span>
                            </p>
                            <p class="item-final pattern-space" style="margin-right: 20px;">{{ 'round-header.prize' |
                                translate }}: <span class="to-right"> {{ticketState.currencySymbol}}
                                    {{roundingFunction(prizeAmount) | currency:'':''}}</span>
                            </p>
                            <p class="item-final pattern-space" style="margin-right: 20px;">{{ 'pg-home.round-value' |
                                translate }}: <span class="to-right"> {{ticketState.currencySymbol}}
                                    {{roundingFunction(ticketState.round.value) | currency:'':''}}</span>
                            </p>
                            <p class="item-final pattern-space" style="margin-right: 20px;">{{ 'pg-home.total-value' |
                                translate }}: <span class="to-right"> {{ticketState.currencySymbol}}
                                    {{roundingFunction(ticketState.finalValue) | currency:'':''}}</span>
                            </p>
                            <p for="ticket-quantity" class="item-final pattern-space" style="margin-right: 15px;">{{
                                'pg-home.quantity-tickets' | translate }}:
                                <input type="number" id="quantity" name="ticket-quantity" min="1" max="999999"
                                    #ticketquantity class="quantity-field"
                                    (input)="onChangeQuantity(ticketquantity.value)" value="{{ticketState.quantity}}" />
                            </p>
                            <p class="item-final pattern-space text-center">
                                {{ 'pg-home.player-name' | translate }}:
                                <!-- <span style="margin-left: 4.7em;-webkit-text-fill-color: rgb(255, 255, 255);" *ngIf="(appComponent.userAdmin != null && appComponent.userAdmin.jurisdictionId < 7)">
                                    <input placeholder="{{ 'pg-home.friend-name' | translate }}..." type="text" #playername [(ngModel)]="playerName" name="playername" class="friendCouponField" />
                                </span> -->
                                <span style="-webkit-text-fill-color: rgb(255, 255, 255);margin-top: 0px;"
                                    *ngIf="(appComponent.userAdmin != null)">
                                    <input placeholder="{{ 'pg-home.friend-name' | translate }}..." type="text"
                                        #playername [(ngModel)]="playerName" name="playername"
                                        class="friendCouponField" />
                                </span>
                                <!--<span
                                    *ngIf="(appComponent.userAdmin != null && appComponent.userAdmin.jurisdictionId == 7)">{{appComponent.userAdmin.login}}</span>-->
                            </p>
                        </div>
                        <ng-container *ngIf="!processingBet; else processingBetContent">
                            <div class="d-flex flex-column align-items-center pb-2 pt-2">
                                <a (click)="verifyBet()" class="btn btn-color-green1 newBtnSucces btn-block mb-2">
                                    <div class="play-coupon-text">{{ 'pg-home.bet' | translate }}</div>
                                </a>
                                <a (click)="frame4.hide()" class="btn btn-color-red1 newBtnCancel btn-block">
                                    <div> {{ 'pg-home.back' | translate }} </div>
                                </a>
                            </div>
                        </ng-container>
                        <ng-template #processingBetContent>
                            <div *ngIf="processingBet" class="spinner-border mt-2 align-self-center" role="status"
                              style="width: 1.5rem !important; height: 1.5rem !important;">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>

<!-- STORAGE QRCODE FOR PRINTING-->
<div hidden>
    <div id="bet-qrcode">
        <ngx-qrcode *ngIf="ticketState != null" [value]="ticketState.uuid8" elementType="url" cssClass="bet-qrcode">
        </ngx-qrcode>
    </div>
</div>
