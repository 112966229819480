import { Component, OnInit, SimpleChanges } from '@angular/core';
import { AppComponent } from '../app.component';
import { ToastService } from '../toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-sportbook',
  templateUrl: './sportbook.component.html',
  styleUrls: ['./sportbook.component.scss']
})
export class SportbookComponent implements OnInit {
  iframeUrl: any;

  constructor(private appComponent: AppComponent, private toastService: ToastService, private translate: TranslateService, private configService: ConfigService) { }

  ngOnInit(): void {
    if(this.appComponent.userAdmin) {
      this.getSportbookUrl();
    } else {
      return this.toastService.showNotification({ message: this.translate.instant("pg-home.must-be-logged"), type: 'danger' });
    }
  }

  getSportbookUrl() {
    if (!this.iframeUrl) {
      this.configService.getSportbookUrl(this.appComponent.userAdmin.id)
        .subscribe((data: any) => {
          this.configSportbookIframe(data.url);
        });
    } else {
      this.configSportbookIframe(this.iframeUrl);
    };
  }

  configSportbookIframe(url) {
    let iframe = document.getElementById('iframe-sportbook');
    iframe.setAttribute('src', url);
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', 'true');
    iframe.setAttribute('sandbox', 'allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation');
  }

}
