<!-- <div class="container">
    <div class="row">
        <div class="col-12 mb-5">-->
<div class="card-group rule-spaces">
    <!-- Card -->
    <mdb-card class="bg-theme">
        <!-- Card image -->
        <!--<img class="card-img-top" src="../../assets/image/yellow-card.jpg" alt="Regras">-->
        <!--Card content-->
        <mdb-card-body>
            <!--Title-->
            <mdb-card-title>
                <h4>
                    <i class="fas fa-clipboard-list" style="font-size: 25px;"></i> {{ 'pg-global-terms.title' |
                    translate }}
                </h4>
            </mdb-card-title>

            <hr>

            <mdb-card-text>

                <p>{{ 'pg-global-terms.intro' | translate }}</p>
                <p class="title">{{ 'pg-global-terms.1' | translate }}</p>
                <p>{{ 'pg-global-terms.1.1' | translate }}</p>
                <p>{{ 'pg-global-terms.1.2' | translate }}</p>
                <p>{{ 'pg-global-terms.1.3' | translate }}</p>
                <p>{{ 'pg-global-terms.1.4' | translate }}</p>
                <p>{{ 'pg-global-terms.1.5' | translate }}</p>
                <p>{{ 'pg-global-terms.1.6' | translate }}</p>
                <p>{{ 'pg-global-terms.1.7' | translate }}</p>
                <p>{{ 'pg-global-terms.1.8' | translate }}</p>
                <p>{{ 'pg-global-terms.1.9' | translate }}</p>
                <p>{{ 'pg-global-terms.1.10' | translate }}</p>
                <p>{{ 'pg-global-terms.1.11' | translate }}</p>
                <p>{{ 'pg-global-terms.1.12' | translate }}</p>
                <p>{{ 'pg-global-terms.1.13' | translate }}</p>
                <p>{{ 'pg-global-terms.1.14' | translate }}</p>
                <p>{{ 'pg-global-terms.1.15' | translate }}</p>
                <p>{{ 'pg-global-terms.1.16' | translate }}</p>
                <p>{{ 'pg-global-terms.1.17' | translate }}</p>
                <p>{{ 'pg-global-terms.1.18' | translate }}</p>
                <p>{{ 'pg-global-terms.1.19' | translate }}</p>
                <p>{{ 'pg-global-terms.1.20' | translate }}</p>
                <p>{{ 'pg-global-terms.1.21' | translate }}</p>
                <p>{{ 'pg-global-terms.1.22' | translate }}</p>
                <p>{{ 'pg-global-terms.1.23' | translate }}</p>
                <p>{{ 'pg-global-terms.1.24' | translate }}</p>
                <p>{{ 'pg-global-terms.1.25' | translate }}</p>
                <p>{{ 'pg-global-terms.1.26' | translate }}</p>
                <p>{{ 'pg-global-terms.1.27' | translate }}</p>
                <p>{{ 'pg-global-terms.1.28' | translate }}</p>
                <p>{{ 'pg-global-terms.1.29' | translate }}</p>
                <p class="title">{{ 'pg-global-terms.2' | translate }}</p>
                <p>{{ 'pg-global-terms.2.1' | translate }}</p>
                <p>{{ 'pg-global-terms.2.2' | translate }}</p>
                <p>{{ 'pg-global-terms.2.3' | translate }}</p>
                <p>{{ 'pg-global-terms.2.4' | translate }}</p>
                <p>{{ 'pg-global-terms.2.5' | translate }}</p>
                <p class="title">{{ 'pg-global-terms.3' | translate }}</p>
                <p>{{ 'pg-global-terms.3.1' | translate }}</p>
                <p>{{ 'pg-global-terms.3.2' | translate }}</p>
                <p>{{ 'pg-global-terms.3.3' | translate }}</p>
                <p>{{ 'pg-global-terms.3.4' | translate }}</p>
                <p>{{ 'pg-global-terms.3.5' | translate }}</p>
                <p class="title">{{ 'pg-global-terms.4' | translate }}</p>
                <p>{{ 'pg-global-terms.4.1' | translate }}</p>
                <p class="title">{{ 'pg-global-terms.5' | translate }}</p>
                <p>{{ 'pg-global-terms.5.1' | translate }}</p>
                <p>{{ 'pg-global-terms.5.2' | translate }}</p>
                <p>{{ 'pg-global-terms.5.3' | translate }}</p>
                <p>{{ 'pg-global-terms.5.4' | translate }}</p>
                <p class="title">{{ 'pg-global-terms.6' | translate }}</p>
                <p>{{ 'pg-global-terms.6.1' | translate }}</p>
                <p class="title">{{ 'pg-global-terms.7' | translate }}</p>
                <p>{{ 'pg-global-terms.7.1' | translate }}</p>
                <p>{{ 'pg-global-terms.7.2' | translate }}</p>
                <p>{{ 'pg-global-terms.7.2a' | translate }}</p>
                <p>{{ 'pg-global-terms.7.2b' | translate }}</p>
                <p>{{ 'pg-global-terms.7.2c' | translate }}</p>
                <p>{{ 'pg-global-terms.7.3' | translate }}</p>
                <p class="title">{{ 'pg-global-terms.8' | translate }}</p>
                <p>{{ 'pg-global-terms.8.1' | translate }}</p>
                <p>{{ 'pg-global-terms.8.2' | translate }}</p>
                <p>{{ 'pg-global-terms.8.3' | translate }}</p>
                <p>{{ 'pg-global-terms.8.3a' | translate }}</p>
                <p>{{ 'pg-global-terms.8.3b' | translate }}</p>
                <p>{{ 'pg-global-terms.8.3c' | translate }}</p>
                <p>{{ 'pg-global-terms.8.4' | translate }}</p>
                <p>{{ 'pg-global-terms.8.5' | translate }}</p>
                <p>{{ 'pg-global-terms.8.6' | translate }}</p>
                <p>{{ 'pg-global-terms.8.7' | translate }}</p>
                <p class="title">{{ 'pg-global-terms.final-title' | translate }}</p>
                <p>{{ 'pg-global-terms.final-info' | translate }}</p>

            </mdb-card-text>
        </mdb-card-body>
    </mdb-card>
</div>
<!--</div>
                             </div>
                        </div> -->
