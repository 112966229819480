import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Round } from './round.interface';
import { Login } from './login.interface';
import { Ticket } from './ticket.interface';
import { Currency } from './currency.interface';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ISkinConfig } from '../app/Interfaces/SkinConfig.interface'

@Injectable({
  providedIn: 'root'
})
export class InteractionService {
  sportsbar = new BehaviorSubject<string>('football');
  username = this.sportsbar.asObservable();
  football: any;
  selectedRound: any;

  isResponsiveMain = new BehaviorSubject<boolean>(false);

  setSportbar(username: string) {
    this.sportsbar.next(username);
  }

  onGettingData: BehaviorSubject<any>;

  constructor(private router: Router) {
    this.onGettingData = new BehaviorSubject(null);
    this.observeRouteChanges();
  }

  homeVisibleRound = new BehaviorSubject<Round>(null);

  setHomeVisibleRound(round: Round) {
    this.homeVisibleRound.next(round);
  }

  public activatedRound(): Observable<Round> {
    return this.homeVisibleRound;
  }

  matchRankArray = new BehaviorSubject<number[]>([0]);
  setMatchRank(rankArray: number[]) {
    this.matchRankArray.next(rankArray);
  }

  clearMatchRank(rankArray: number[]) {
    for (let i = rankArray.length; i > 1; i--) rankArray.pop();
    this.matchRankArray.next(rankArray);
  }

  loginData = new BehaviorSubject<Login>(
    {
      user: {},
      token: '',
      message: ''
    }
);

  setLoginData(login: Login) {
    this.loginData.next(login);
  }

  ticketPanelState = new BehaviorSubject<Ticket>(null);
  mTicketPanelState = new Subject<Ticket>();

  setTicketPanelState(ticketState: Ticket) {
    this.ticketPanelState.next(ticketState);
    this.mTicketPanelState.next(ticketState);
  }

  globalCurrency = new BehaviorSubject<Currency>(null);

  setGlobalCountry(globalCurrency: Currency) {
    this.globalCurrency.next(globalCurrency);
  }

  clickX = new BehaviorSubject<number>(null);
  setClickX(clickX: number) {
    this.clickX.next(clickX);
  }

  clickY = new BehaviorSubject<number>(null);
  setClickY(clickY: number) {
    this.clickY.next(clickY);
  }

  playBallAnimation = new BehaviorSubject<boolean>(null);
  setPlayBallAnimation(playBallAnimation: boolean) {
    this.playBallAnimation.next(playBallAnimation);
  }

  betConfirmedSignal = new EventEmitter<any>();
  sendBetConfirmedSignal() {
    this.betConfirmedSignal.emit();
  }


  private observeRouteChanges() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const isManageTicket = ['/manage-ticket', '/settings'].includes(event.urlAfterRedirects);
      this.isResponsiveMain.next(isManageTicket);
    });
  }

  public getResponsiveMainApp(): Observable<boolean> {
    return this.isResponsiveMain.asObservable();
  }
  public creatorId = new BehaviorSubject<number>(0);

  public setCreatorId(creatorId: number): void {
    this.creatorId.next(creatorId);
  }

  isLogged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public setIsLogged(isLogged: boolean): void {
    this.isLogged.next(isLogged);
  }

  skinConfig = new BehaviorSubject<ISkinConfig>(null);

  public setSkinConfig(skinConfig: ISkinConfig): void {
    this.skinConfig.next(skinConfig);
  }

  userAdmin: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public setUserAdmin(userAdmin: any): void {
    this.userAdmin.next(userAdmin);
  }

  refCode: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public setRefCode(refCode: string): void {
    this.refCode.next(refCode);
  }
}
