import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BetradarSport } from '../../../betradar-sport.interface';
import { InteractionService } from '../../../interaction.service';
import { ISkinConfig } from '../../../Interfaces/SkinConfig.interface';
import { Flags } from '../../../round-group.interface';
import { ConfigService } from './../../../config.service';
import { SportService } from '../../../services/sport/sport.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  creatorId: number;
  @Output() callParent: EventEmitter<any> = new EventEmitter<any>();
  @Output() close = new EventEmitter<void>(); // fecha ao clicar em um filtro (mobile)

  hubID: number;
  constructor(private configService: ConfigService,
    private translate: TranslateService, private sportService: SportService,
    private interactionService: InteractionService, private http: HttpClient
  ) {
    this.configService.getFlags().subscribe(data => {
      this.countryFlags = data;
      this.countryFlags.data.push({
        flag: "https://upload.wikimedia.org/wikipedia/commons/6/68/APAC_Icon.png", iso2: "", iso3: "", name: "Internacional"
      });
    });
  }


  sports: BetradarSport[] = [];
  countryFlags: Flags;
  ids: number[] = [];

  ngOnInit(): void {
    // this.interactionService.skinConfig
    //   .subscribe((data: ISkinConfig) => {
    //     this.configService.getSportsActive(data.hubID).subscribe(data => {
    //       this.sports = data;
    //     })
    //   })

    this.sportService.sportList
      .subscribe(sports => {
        this.sports = sports
      })

  }

  setSport(idSport: number, idCountry: number) {
    if (idSport.toString() == localStorage.getItem('Sport') && idCountry.toString() == localStorage.getItem('SportCountry')) {
      localStorage.removeItem('Sport');
      localStorage.removeItem('SportCountry');

      var actives = document.querySelectorAll('.bgGreen');
      actives.forEach(element => {
        element.classList.toggle('bgGreen');
        element.classList.toggle('hoverBbox');
      });

      return;
    }
    localStorage.setItem('Sport', idSport.toString());
    localStorage.setItem('SportCountry', idCountry.toString());
    this.selectSportCountry(idSport, idCountry);
  }

  setIcon(name: string) {
    name = name.toUpperCase();
    name = name.replace(' ', '');

    return `../../../../assets/image/SideMenuIcons/${name}.png`;
  }

  toggleCountries(id) {
    var listCountry = document.querySelectorAll(`.listCountry${id}`);
    listCountry.forEach(element => {
      element.classList.toggle('sdHidden');
    });
  }

  selectSportCountry(idSport: number, idCountry: number) {
    var actives = document.querySelectorAll('.bgGreen');
    actives.forEach(element => {
      element.classList.toggle('bgGreen');
      element.classList.toggle('hoverBbox');
    });

    var sports = document.querySelectorAll(`.sport${idSport}`);
    sports.forEach(element => {
      element.classList.toggle('bgGreen');
      element.classList.toggle('hoverBbox');
    });

    var countries = document.querySelectorAll(`.country${idCountry}${idSport}`);
    countries.forEach(element => {
      element.classList.toggle('bgGreen');
      element.classList.toggle('hoverBbox');
    });

    if (window.innerWidth <= 989) {
      this.closeMenu();
    }
  }

  getFlagByName(name: string) {
    switch (name) {
      case "England":
        name = "United Kingdom"
        break;
      case "United States of America":
        name = "United States"
        break;
    }

    if (this.countryFlags?.data.length > 0) {
      var result = this.countryFlags.data.filter(obj => {
        return obj.name === name;
      });
      if (result.length > 0) {
        return result[0].flag;
      }
      else {
        return "/assets/svgs/flag.svg";
      }
    }
  }

  closeMenu() {
    this.close.emit(); // Emitir evento quando quiser fechar o modal
  }
}
