import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { ConfigService } from 'src/app/config.service';

interface CurrencyToQuoteComponent {
  "code": string;
  "code-in": string;
  "name": string;
  "high": number;
  "low": number;
  "varBid": number;
  "pctChange": number;
  "bid": number;
  "ask": number;
  "timestamp": number;
  "create_date": Date;
}

@Component({
  selector: 'app-currency-quote',
  templateUrl: './currency-quote.component.html',
  styleUrls: ['./currency-quote.component.scss']
})

export class CurrencyQuoteComponent implements OnInit {

  constructor(private configService: ConfigService, private appComponent: AppComponent,
    private router: Router, private translate: TranslateService) {
      this.translate.setDefaultLang(this.appComponent.activeLang);
    }
  
  allQuotes: CurrencyToQuoteComponent[] = [];
  mainQuotes: CurrencyToQuoteComponent[] = [];
  quotesToShow: string = "";

  ngOnInit() {
    this.configService.getCurrencyQuote()
    .subscribe((data: CurrencyToQuoteComponent[]) => {
      this.allQuotes = data;
      //console.log(data["USD"]);
      this.loadMainQuotes();
    });
  }

  loadMainQuotes(){
    let importantQuotes = [ "USD", "EUR", "GBP", "ARS" ];
    
    importantQuotes.forEach(element => {
      this.quotesToShow += " | 1 " + this.allQuotes[element].code + " = R$ " + this.allQuotes[element].high.substring(0, 4).replace(".", ",");
    });
  }

}
