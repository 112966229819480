<!-- Card -->
<div class="card">
    <!-- Card content -->
    <div class="card-body bg-theme">
        <div class="col-12 pt-0">
            <h3><i class="fas fa-donate" style="font-size: 25px;"></i> <span style="font-size: 25px;">
                {{'pg-transactions.transactions' | translate }}</span>
            </h3>
            <hr />
        </div>
        <!-- <br> -->
        <div class="mat-elevation-z8 container-table" style="overflow: auto;">
            <div class="justify-content-center mb-2 p-2 filter"
                style="display: flex;align-items: center;flex-wrap: nowrap;justify-content: space-around !important;">
                <!--DESKTOP FILTER-->
                <!-- <div class="d-flex pb-2 filterContent mobile-hide"> -->
                <div class="row d-flex mobile-hide" style="align-items: center !important;">
                    <div class="col-7">
                        <!-- <label class="m-0 p-0" for="dateStart">{{ 'pg-event-result.date' | translate }}:</label>
                    <br />
                    <input [(ngModel)]="dateStart" type="date"
                        class="mr-2 dateStart" name="dateStart">
                    <input [(ngModel)]="dateEnd" type="date" class="mr-1 dateEnd"
                        name="dateEnd"> -->
                        <div class="row">
                            <div class="col-2 pr-0">
                                <label for="dateStart">{{ 'pg-event-result.date' | translate }}:</label>
                            </div>
                            <div class="col-5 pl-3 pr-0">
                                <input [(ngModel)]="dateStart" type="date"
                                    style="width: 90% !important;font-size: 20px;" name="dateStart">
                            </div>
                            <div class="col-5 pl-1">
                                <input [(ngModel)]="dateEnd" type="date" style="width: 90% !important;font-size: 20px;"
                                    name="dateEnd">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="mr-2 periodBox">
                    {{ 'pg-event-result.period' | translate }} <br />
                    <select [(ngModel)]="period" class="period" type="text" name="selectDateFilter"
                        id="selectDateFilter-mobile" style="height: 36px;">
                        <option value="allTime">{{ 'pg-event-result.all' | translate }}</option>
                        <option value="lastWeek">1 {{ 'pg-event-result.week' | translate }}</option>
                        <option value="lastMonth">1 {{ 'pg-event-result.month' | translate }}</option>
                        <option value="threeMonth">3 {{ 'pg-event-result.months' | translate }}</option>
                    </select>
                    </div> -->
                    <div class="col-3 pl-0">
                        <!-- {{ 'pg-event-result.type' | translate }} <br />
                    <select class="type" [(ngModel)]="type" type="text" name="typefilter" id="typefilter-mobile"
                        style="height: 36px;">
                        <option value="all">Todos</option>
                        <option value="{{ 'pg-transactions.bets' | translate }}">{{ 'pg-transactions.bets' |
                            translate }}</option>
                        <option value="{{ 'pg-credit-transfer.withdraw' | translate }}">{{
                            'pg-transactions.withdraw' | translate }}</option>
                        <option value="{{ 'pg-transactions.deposit' | translate }}">{{ 'pg-transactions.deposit' |
                            translate }}</option>
                    </select> -->
                        <div class="row d-flex" style="align-items: center;">
                            <div class="col-4">
                                <label>{{ 'pg-event-result.type' | translate }}:</label>
                            </div>
                            <div class="col-8">
                                <select class="type" [(ngModel)]="type" type="text" name="typefilter"
                                    id="typefilter-mobile" style="width: 90% !important;font-size: 20px;height: 36px;">
                                    <option value="0">{{ 'All' | translate }}</option>
                                    <option value="1">{{ 'pg-transactions.deposit' | translate }} / {{
                                        'pg-transactions.out'
                                        |
                                        translate }}</option>
                                    <option value="2">{{ 'transaction-filter.refund-cancelBet' | translate }}</option>
                                    <option value="3">{{ 'transaction-filter.bets' | translate }}</option>
                                    <option value="4">{{ 'transaction-filter.slot' | translate }}</option>
                                    <option value="5">{{ 'transaction-filter.live-slot' | translate }}</option>
                                    <option value="6">Aviator</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-2" style="padding-left: 0px;">
                        <!-- <label for="searchTransaction"></label>{{ 'pg-event-result.name-event' | translate }}: <br />
                                    <input class="mr-2 transactionName" name="searchTransaction" (keyup)="searchTransaction($event)"
                                        #input hidden> -->
                        <div class="row d-flex">
                            <div class="col pr-5 pl-1">
                                <button (click)="getFilterTransaction(dateStart, dateEnd, period, type)" type="submit"
                                    class="btnSearch-mobile btnSearch" value="Submit">{{ 'pg-app-component.search' |
                                    translate
                                    }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--MOBILE FILTER-->
                <div class="d-flex pb-2 flex-column filterContent desktop-hide">
                    <div class="d-flex">
                        <div class="dateBox">
                            <label class="m-0 p-0" for="dateStart">{{ 'pg-event-result.date' | translate }}:</label>
                            <br />
                            <input [(ngModel)]="dateStart" type="date" class="mr-2 dateStart" name="dateStart">
                            <input [(ngModel)]="dateEnd" type="date" class="dateEnd" name="dateEnd">
                        </div>
                        <div class="periodBox mobile-hide">
                            {{ 'pg-event-result.period' | translate }} <br />
                            <select [(ngModel)]="period" class="period" type="text" name="selectDateFilter"
                                id="selectDateFilter-mobile" style="height: 36px;">
                                <option value="allTime">{{ 'pg-event-result.all' | translate }}</option>
                                <option value="lastWeek">1 {{ 'pg-event-result.week' | translate }}</option>
                                <option value="lastMonth">1 {{ 'pg-event-result.month' | translate }}</option>
                                <option value="threeMonth">3 {{ 'pg-event-result.months' | translate }}</option>
                            </select>
                        </div>
                        <div class="typeBox">
                            {{ 'pg-event-result.type' | translate }} <br />
                            <select class="type" [(ngModel)]="type" type="text" name="typefilter" id="typefilter-mobile"
                                style="height: 36px;">
                                <option value="0">{{ 'All' | translate }}</option>
                                <option value="1">{{ 'pg-transactions.deposit' | translate }} / {{ 'pg-transactions.out'
                                    |
                                    translate }}</option>
                                <option value="2">{{ 'transaction-filter.refund-cancelBet' | translate }}</option>
                                <option value="3">{{ 'transaction-filter.bets' | translate }}</option>
                                <option value="4">{{ 'transaction-filter.slot' | translate }}</option>
                                <option value="5">{{ 'transaction-filter.live-slot' | translate }}</option>
                                <option value="6">Aviator</option>
                            </select>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;padding: 15px;justify-content: space-around;">
                        <div class="mobile-hide">
                            <label for="searchTransaction"></label>{{ 'pg-event-result.name-event' | translate }}:
                            <br />
                            <input class="mr-2 transactionName" name="searchTransaction"
                                (keyup)="searchTransaction($event)" #input>
                        </div>
                        <button (click)="getFilterTransaction(dateStart, dateEnd, period, type)" type="submit"
                            class="btnSearch-mobile btnSearch" value="Submit">{{ 'pg-app-component.search' | translate
                            }}</button>
                    </div>
                </div>
            </div>

            <table style="overflow: hidden;" mat-table matSort [dataSource]="dataSource">
                <ng-container matColumnDef="data" style="width: 50px;">
                    <th class="mobile-hide" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'pg-transactions.date'
                        |
                        translate }} </th>
                    <td class="mobile-hide"
                        style="color: black; text-align: start; padding-left: 10px!important; padding-right: 5px; width: 18%;"
                        mat-cell *matCellDef="let element">
                        <span class="mobile-label">ID:</span>
                        <span class="idStyle">
                            ID: {{element.id}}
                        </span>
                        <br />
                        {{element.date | date:'mediumDate'}} {{element.date | date: 'shortTime'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="icon">
                    <th style="color: transparent !important;" mat-header-cell *matHeaderCellDef>-
                    </th>
                    <td style="padding-right: 0px !important; width: 6%;" mat-cell *matCellDef="let element">
                        <!-- Bet win or credit add icon -->
                        <div class="ml-1 mr-1">
                            <svg [style.fill]="'#000000cc'"
                                *ngIf="element.type == 1 || element.type == 2 || element.type == 3 || element.type == 14 || element.type == 15 "
                                [style.background-color]="element.color" class="cifraUp" id="Camada_1" version="1.1"
                                viewBox="0 0 50 50" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
                                <switch>
                                    <foreignObject width="1" height="1"
                                        requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/">

                                    </foreignObject>
                                    <g>

                                        <path
                                            d="m31.1 16.4c-0.1-1.4-0.8-2.6-1.8-3.4-1.1-0.8-2.6-1.2-4.4-1.2-1.3 0-2.3 0.2-3.2 0.5-0.9 0.4-1.5 0.8-2 1.5-0.5 0.6-0.7 1.3-0.7 2.1 0 0.7 0.1 1.2 0.4 1.7s0.7 0.9 1.3 1.3 1.2 0.7 1.9 0.9 1.5 0.5 2.3 0.7l3.3 0.8c1.6 0.4 3.1 0.8 4.4 1.4s2.5 1.3 3.5 2.2 1.7 1.9 2.3 3.1c0.5 1.2 0.8 2.5 0.8 4.1 0 2.2-0.6 4.2-1.7 5.8s-2.7 2.9-4.9 3.8-4.7 1.3-7.6 1.3c-3 0-5.5-0.5-7.7-1.4s-3.9-2.2-5.1-4-1.9-4-1.9-6.6h7.5c0.1 1.2 0.4 2.2 1.1 3.1 0.6 0.8 1.5 1.4 2.5 1.8 1.1 0.4 2.2 0.6 3.6 0.6 1.3 0 2.4-0.2 3.4-0.6s1.7-0.9 2.3-1.6c0.5-0.7 0.8-1.5 0.8-2.3s-0.2-1.5-0.7-2.1-1.2-1-2.1-1.4-2.1-0.8-3.4-1.1l-4-1c-3.1-0.8-5.5-1.9-7.3-3.5s-2.7-3.7-2.7-6.4c0-2.2 0.6-4.1 1.8-5.8s2.8-2.9 4.9-3.9c2.1-0.9 4.5-1.4 7.1-1.4 2.7 0 5.1 0.5 7.1 1.4s3.6 2.2 4.7 3.9 1.7 3.6 1.7 5.7h-7.5zm-8.6 30.6 0.5-45.7h4.2l-0.5 45.7h-4.2z" />

                                    </g>
                                </switch>
                            </svg>
                            <!-- Pix Icon -->
                            <svg class="pix" *ngIf="element.type === 12"
                                [style.background-color]="element.betUuid8 && (element.type == 1 || element.type == 5) ? '#00BDAE' : element.color"
                                version="1.1" id="Camada_1" xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                y="0px" viewBox="0 0 124 124.1" xml:space="preserve">
                                <g id="Camada_x0020_1">
                                    <path class="st0" d="M0,61.2c0,8.4,2.5,11.2,7.5,16.2c3.7,3.7,7.3,7.2,11,11c1.8,1.9,0.6,1.2,3.7,1.2c12.1,0,11.5,0.3,19.6-7.8
                    		l10.8-10.8c1.7-1.7,3.6-4.1,5.7-5.2c2.5-1.4,6-1.5,8.6-0.1c2.4,1.3,14.2,13.7,16.7,16.2c8.1,8.1,8,7.8,20.1,7.8
                    		c0.9,0,11.3-10.9,12.1-11.7c4.5-4.5,8-7.8,8.1-15.8c0.1-9.9-6.7-14.8-13.7-21.8c-7-7-3.8-5.8-13.2-5.8c-4.8,0-7.7,2-10.1,4.5
                    		c-2.5,2.5-4.9,4.9-7.4,7.4c-2.5,2.5-4.9,4.9-7.4,7.4c-3.5,3.5-7.7,8.4-14.5,3.9c-1.9-1.3-15.5-15.2-18.1-17.9
                    		c-2.6-2.6-4.5-4.8-9-5.4c-1.8-0.2-5-0.1-7-0.1c-0.6,0-3.2-0.1-3.4-0.1c-0.7,0.1-15.2,14.8-16.1,16C1.9,53.1,0,56.7,0,61.2L0,61.2z
                    		 M25,94.9l24.2,24.3c3.2,3.1,7.6,4.7,12,4.9h1.6c4.3-0.2,8.5-1.7,11.5-4.6l24.4-24.6c-10.9,0-13-3.2-19.9-10.1L67.6,73.4
                    		c-2.4-2.4-2.5-3.3-5.3-3.3c-1.9,0-6.8,5.6-8.3,7.1c-3,3-13,13.6-15.9,15.1C33.5,94.9,31.9,94.9,25,94.9L25,94.9z M25.3,29.4
                    		c8.1,0,11.2,0.1,17,5.9l15.5,15.5c2,2,2.5,3.3,5.3,3.3c1.9,0,6.6-5.5,8.2-7c2.6-2.6,5-5,7.6-7.6c7.5-7.5,9.2-10.2,20-10.2
                    		c-0.3-1-3-3.4-3.8-4.2c-1.5-1.5-2.7-2.7-4.2-4.2L78.4,8.6C73.8,3.9,69.9,0.2,62.1,0c-2-0.1-4.9,0.6-6.7,1.3c-1.7,0.6-4,1.9-5.3,3
                    		c-3.5,3.1-12.6,12.6-16.6,16.6C32.5,21.8,25.6,28.3,25.3,29.4L25.3,29.4z" />
                                </g>
                            </svg>
                            <!-- Refound Icon -->
                            <svg class="refound" *ngIf="element.type === 4 || element.type === 18" version="1.1"
                                [style.background-color]="element.color" id="Camada_1"
                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                y="0px" viewBox="0 0 19 21" style="enable-background:new 0 0 19 21;"
                                xml:space="preserve">
                                <g>
                                    <path d="M10.2,4.2c1.8,0.1,4,1.2,5.1,2.5c1.4,1.6,2.1,3.4,1.9,5.7c-0.2,2.3-1.4,4-2.6,5c-1.3,1.1-3.3,2.1-5.8,1.9
                        		c-4.1-0.3-7.5-3.7-7.2-8.1c0.1-2.1,1.1-3.8,2.5-5.1C4.4,6,4.5,5.8,4.5,5.5c0-0.5-0.5-0.9-1-0.8C2.9,4.8,1.6,6.5,1.2,7.2
                        		c-2.8,4.9-0.6,10.5,3.9,12.7c4.8,2.4,10.6,0.5,13-4.1c2.9-5.7-0.9-12.7-7.7-13.3c0-0.2,1.2-1,1.3-1.5c0.1-0.5-0.2-0.8-0.5-0.9
                        		c-0.5-0.2-0.8,0.1-1,0.3L7.9,2.5C7.7,2.8,7.3,3,7.3,3.5c0,0.4,0.4,0.7,0.6,0.9l2.2,2.2c0.7,0.7,1.8,0,1.5-0.8
                        		C11.5,5.3,10.3,4.4,10.2,4.2L10.2,4.2z" />
                                </g>
                            </svg>
                            <!-- Refound Icon -->
                            <svg class="refound" *ngIf="(element.type === 5 || element.type === 18)&& !verifyGame(element.type)" version="1.1"
                                [style.background-color]="element.color" id="Camada_1"
                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                y="0px" viewBox="0 0 19 21" style="enable-background:new 0 0 19 21;"
                                xml:space="preserve">
                                <g>
                                    <path d="M10.2,4.2c1.8,0.1,4,1.2,5.1,2.5c1.4,1.6,2.1,3.4,1.9,5.7c-0.2,2.3-1.4,4-2.6,5c-1.3,1.1-3.3,2.1-5.8,1.9
                        		c-4.1-0.3-7.5-3.7-7.2-8.1c0.1-2.1,1.1-3.8,2.5-5.1C4.4,6,4.5,5.8,4.5,5.5c0-0.5-0.5-0.9-1-0.8C2.9,4.8,1.6,6.5,1.2,7.2
                        		c-2.8,4.9-0.6,10.5,3.9,12.7c4.8,2.4,10.6,0.5,13-4.1c2.9-5.7-0.9-12.7-7.7-13.3c0-0.2,1.2-1,1.3-1.5c0.1-0.5-0.2-0.8-0.5-0.9
                        		c-0.5-0.2-0.8,0.1-1,0.3L7.9,2.5C7.7,2.8,7.3,3,7.3,3.5c0,0.4,0.4,0.7,0.6,0.9l2.2,2.2c0.7,0.7,1.8,0,1.5-0.8
                        		C11.5,5.3,10.3,4.4,10.2,4.2L10.2,4.2z" />
                                </g>
                            </svg>
                            <!-- Warning Icon-->
                            <svg [style.background-color]="element.color"
                                *ngIf="element.typeDesc == rejectedTransaction(element.typeDesc)"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" fill="none">
                                <path
                                    d="M13.134 1.5C13.5189 0.833333 14.4811 0.833333 14.866 1.5L25.2583 19.5C25.6432 20.1667 25.1621 21 24.3923 21H3.60769C2.83789 21 2.35677 20.1667 2.74167 19.5L13.134 1.5Z"
                                    fill="#E8C300" />
                                <path
                                    d="M13.35 14.52L12.99 7.5H15.21L14.835 14.52H13.35ZM14.1 18.105C13.75 18.105 13.46 17.99 13.23 17.76C13 17.53 12.885 17.255 12.885 16.935C12.885 16.615 13 16.345 13.23 16.125C13.46 15.895 13.75 15.78 14.1 15.78C14.45 15.78 14.735 15.895 14.955 16.125C15.175 16.345 15.285 16.615 15.285 16.935C15.285 17.255 15.175 17.53 14.955 17.76C14.735 17.99 14.45 18.105 14.1 18.105Z"
                                    fill="black" />
                            </svg>
                            <!-- Slot Icon -->
                            <span
                                *ngIf="element.type == 6 || element.type == 7 || element.type == 8 || element.type == 9 || element.type == 10">
                                <img class="ml-1 IconSlotStyle" src="../../assets/image/slot-machine.png">
                            </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> &nbsp;&nbsp;&nbsp; {{ 'pg-transactions.description' |
                        translate }} </th>
                    <td style="color: black; text-align: left; display: flex; flex-direction: column; justify-content: center;"
                        mat-cell *matCellDef="let element">
                        <div class="mobile-hide"
                            style="display: grid;justify-content: start;align-items: center;justify-items: start;align-content: space-evenly;">
                            <div *ngIf="element.betUuid8 != null && element.rankMatch == 0 || element.betUuid8 != null && element.rankMatch == 1 && element.singleMatch == 0"
                                class="d-flex align-items-center">
                                <img *ngIf="element.singleMatch == 0" width="24px" class="mr-1"
                                    src="{{getChampionshipLogos(element.name)}}"
                                    onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">
                                <span title="{{element.name}}" class="descriptionStyle normalRankNameLimit"
                                    *ngIf="element.singleMatch == 0">
                                    {{element.name}}
                                </span>
                            </div>
                            <!--IF IS SINGLEMATCH ROUND-->
                            <span *ngIf="element.betUuid8 != null && element.singleMatch == 1">
                                <img *ngIf="element.singleMatch == 1" width="20px" class="mr-1"
                                    src="{{getSingleMatchShield(element.name,0)}}"
                                    onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">
                                <span class="descriptionStyle">
                                    {{element.description | translate}}
                                </span>
                                <img *ngIf="element.singleMatch == 1" width="20px" class="ml-1"
                                    src="{{getSingleMatchShield(element.name,1)}}"
                                    onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">
                            </span>

                            <span
                                *ngIf="isbet(element.betUuid8) && !checkWithdraw(element.typeDesc) && element.type != 14 && element.type != 15"
                                class="descriptionStyle">
                                {{element.typeDesc | translate}}
                            </span>
                            <span *ngIf="element.type == 14 || element.type == 15" class="descriptionStyle">
                                {{element.typeDesc | translate}}
                            </span>
                            <span class="mobile-label">
                                {{ 'pg-transactions.description' | translate }}:
                            </span>
                            <ng-container *ngIf="element.color != '#00BDAE'; else buyByPix">
                                <span
                                    *ngIf="element.betUuid8 == null || element.betUuid8 == '' && element.color != '#A7FFF8'"
                                    [hidden]="element.color == '#A7FFF8'">
                                    {{element.activeUserName}}
                                    <span
                                        [style.color]="element.betUuid8 && (element.type == 1 || element.type == 5 || element.type == 9 || element.type == 13 || element.type == 18) ? '#00ffba' : element.color">
                                        <span
                                            *ngIf="element.color == 'lightgreen' && element.withdraw == null && element.color != '#00BDAE'"
                                            [hidden]="element.typeDesc == this.translate.instant('pg-transactions.Accepted')"
                                            style="display: inline-flex; transform: rotate(180deg);">➜</span>
                                        <span
                                            *ngIf="element.color != 'lightgreen' || element.typeDesc == this.translate.instant('pg-transactions.Accepted')">➜</span>
                                    </span>
                                    {{element.passiveUserName}}
                                </span>
                            </ng-container>
                            <ng-template #buyByPix>
                                <span>{{ 'credit.BuyPix' | translate }} </span>
                            </ng-template>
                            <span *ngIf="element.type == 6 || element.type == 7 || element.type == 8 || element.type == 9 || element.type == 10">
                                <span class="descriptionStyle normalRankNameLimit">{{element.assUsername}} </span>
                            </span>
                        </div>
                        <!-- DeskTop End -->
                        <div class="ml-1 desktop-hide"
                            style="display: grid;justify-items: start;justify-content: start;font-size: 13px;">
                            <span style="font-size: 15px;font-weight: 500;">{{element.typeDesc | translate}}</span>
                            <div *ngIf="element.betUuid8 != null && element.rankMatch == 0 || element.betUuid8 != null && element.rankMatch == 1"
                                class="d-flex align-items-center">
                                <img *ngIf="element.singleMatch == 0" width="24px" class="mr-1 mobile-hide"
                                    src="{{getChampionshipLogos(element.name)}}"
                                    onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">
                                <span title="{{element.name}}" class="descriptionStyle normalRankNameLimit"
                                    *ngIf="element.singleMatch == 0">
                                    {{element.name}}
                                </span>
                            </div>
                            <span *ngIf="element.betUuid8 != null && element.singleMatch == 1">
                                <img *ngIf="element.singleMatch == 1" width="20px" class="mr-1 mobile-hide"
                                    src="{{getSingleMatchShield(element.name,0)}}"
                                    onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">
                                <span class="descriptionStyle" *ngIf="element.singleMatch == 1">
                                    {{element.description | translate}}
                                </span>
                                <img *ngIf="element.singleMatch == 1" width="20px" class="ml-1 mobile-hide"
                                    src="{{getSingleMatchShield(element.name,1)}}"
                                    onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">
                            </span>
                            <!-- <span *ngIf="isbet(element.betUuid8) && !checkWithdraw(element.typeDesc) && element.type != 14 && element.type != 15"
                            class="descriptionStyle">
                            {{element.typeDesc | translate}}
                        </span>
                        <span *ngIf="element.type == 14 || element.type == 15" class="descriptionStyle">
                            {{element.typeDesc | translate}}
                        </span> -->
                            <span class="mobile-label">
                                {{ 'pg-transactions.description' | translate }}:
                            </span>
                            <ng-container *ngIf="element.color != '#00BDAE'; else buyByPix">
                                <span
                                    *ngIf="element.betUuid8 == null || element.betUuid8 == '' && element.color != '#A7FFF8'"
                                    [hidden]="element.color == '#A7FFF8'">
                                    {{element.activeUserName}}
                                    <span
                                        [style.color]="element.betUuid8 && (element.type == 1 || element.type == 5 || element.type == 9 || element.type == 13) ? '#00ffba' : element.color">
                                        <span
                                            *ngIf="element.color == 'lightgreen' && element.withdraw == null && element.color != '#00BDAE'"
                                            [hidden]="element.typeDesc == this.translate.instant('pg-transactions.Accepted')"
                                            style="display: inline-flex; transform: rotate(180deg);">➜</span>
                                        <span
                                            *ngIf="element.color != 'lightgreen' || element.typeDesc == this.translate.instant('pg-transactions.Accepted')">➜</span>
                                    </span>
                                    {{element.passiveUserName}}
                                </span>
                            </ng-container>
                            <ng-template #buyByPix>
                                <span>{{ 'credit.BuyPix' | translate }} </span>
                            </ng-template>
                            <span
                                *ngIf="element.type == 6 || element.type == 7 || element.type == 8 || element.type == 9 || element.type == 10">
                                <span class="descriptionStyle normalRankNameLimit">{{element.assUsername}} </span>
                            </span>
                            <span style="font-size: 11px;" *ngIf="element.type != 3">
                                ID: {{element.id}}
                            </span>
                            <span style="font-size: 11px;" *ngIf="element.type == 3">
                                <span style="color: #03b9f3; cursor: pointer; text-decoration: underline;"
                                    (click)="detailCoupon(element.betUuid8)">{{element.betUuid8}}</span>
                            </span>
                            <span style="font-size: 9px;">
                                {{element.date | date:'mediumDate'}} {{element.date | date: 'shortTime'}}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th class="mobile-hide" mat-header-cell *matHeaderCellDef> {{ 'pg-transactions.type' | translate }}
                    </th>
                    <td class="Transaction_TypeStyle mobile-hide" mat-cell *matCellDef="let element">
                        <span class="mobile-label">{{ 'pg-transactions.moviment' | translate }}:</span>
                        <span *ngIf="element.color != '#00BDAE'" [style.color]="element.color"
                            class="typeStyle">{{element.typeDesc | translate}}</span>
                        <br />
                        <ng-container
                            *ngIf="element.type == 18 || element.type == 5 || element.type == 4 || element.type == 3 || element.type == 1 && element.betUuid8 != null; else RiseUp">
                            <span style="color: #03b9f3; cursor: pointer; text-decoration: underline;"
                                (click)="detailCoupon(element.betUuid8)">{{element.betUuid8}}</span>
                        </ng-container>
                        <ng-template #RiseUp>
                            <span *ngIf="element.type == 6||element.type == 7||element.type == 8||element.type == 10"
                                style="color: #03b9f3;">{{element.description | translate}}</span>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'pg-jurisdiction.amount' | translate }}
                    </th>
                    <td style="color: black; text-align: center; padding-right: 0px !important;" mat-cell
                        *matCellDef="let element">
                        <div class="mobile-hide">
                            <span class="mobile-label">{{ 'pg-transactions.in' | translate }}:</span>
                            <span class="priceText" *ngIf="element.creditIn != 0">{{Currency.currencySymbol}}
                                {{element.creditIn * Currency.quote | currency:'':''}}</span>
                            <span class="priceText" *ngIf="element.creditOut != 0">
                                {{Currency.currencySymbol}} <span *ngIf="element.typeDesc != 'Saque rejeitado' && element.type != 18">-</span>
                                {{element.creditOut * Currency.quote | currency:'':''}}</span>
                        </div>
                        <div class="desktop-hide">
                            <span class="mobile-label">{{ 'pg-transactions.in' | translate }}:</span>
                            <span style="font-size: 12px !important"
                                *ngIf="element.creditIn != 0">{{Currency.currencySymbol}}
                                {{element.creditIn * Currency.quote | currency:'':''}}</span>
                            <span style="font-size: 12px !important" *ngIf="element.creditOut != 0">
                                {{Currency.currencySymbol}} <span *ngIf="element.typeDesc != 'Saque rejeitado' && element.type != 18">-</span>
                                {{element.creditOut * Currency.quote | currency:'':''}}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="balance">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'pg-jackpot.jackpot-total' | translate }}
                    </th>
                    <td style="color: black; text-align: center; padding-right: 0px !important;" mat-cell
                        *matCellDef="let element">
                        <div class="mobile-hide">
                            <span class="mobile-label">{{ 'pg-transactions.in' | translate }}:</span>
                            <span class="priceText">{{Currency.currencySymbol}}
                                {{element.balance * Currency.quote | currency:'':''}}</span>
                        </div>
                        <div class="desktop-hide">
                            <span class="mobile-label">{{ 'pg-transactions.in' | translate }}:</span>
                            <span style="font-size: 12px !important">{{Currency.currencySymbol}}
                                {{element.balance * Currency.quote | currency:'':''}}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="color">
                    <th class="mobile-hide" style="color: transparent !important;" mat-header-cell *matHeaderCellDef>-
                    </th>
                    <td class="mobile-hide"
                        [style.background-color]=" element.betUuid8 == null && (element.type == 1) ? '#90ee90' : element.color"
                        style="color: black; text-align: center; padding-right: 0px !important; width: 0%;" mat-cell
                        *matCellDef="let element">
                        <span style="color: transparent;">.</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="creditIn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'pg-transactions.in' | translate }} </th>
                    <td style="color: black; text-align: center;" mat-cell *matCellDef="let element"
                        [style.background-color]="element.betUuid8 && (element.type == 1 || element.type == 5 || element.type == 9 || element.type == 13) ? '#00ffba' : element.color">
                        <div class="mobile-hide">
                            <span class="mobile-label">{{ 'pg-transactions.in' | translate }}:</span>
                            {{Currency.currencySymbol}}{{element.creditIn * Currency.quote | currency:'':''}}
                        </div>
                        <div class="desktop-hide">
                            <span style="font-size: 12px !important;">
                                <span class="mobile-label">{{ 'pg-transactions.in' | translate }}:</span>
                                {{Currency.currencySymbol}}{{element.creditIn * Currency.quote | currency:'':''}}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="creditOut">
                    <th mat-header-cell *matHeaderCellDef> {{ 'pg-transactions.out' | translate }} </th>
                    <td style="color: black; text-align: center;" mat-cell *matCellDef="let element"
                        [style.background-color]="element.betUuid8 && (element.type == 1 || element.type == 5 || element.type == 9 || element.type == 13) ? '#00ffba' : element.color">
                        <div class="mobile-hide">
                            <span class="mobile-label">{{ 'pg-transactions.out' | translate }}:</span>
                            {{Currency.currencySymbol}}{{element.creditOut * Currency.quote | currency:'':''}}
                        </div>
                        <div class="desktop-hide">
                            <span style="font-size: 12px !important;">
                                <span class="mobile-label">{{ 'pg-transactions.out' | translate }}:</span>
                                {{Currency.currencySymbol}}{{element.creditOut * Currency.quote | currency:'':''}}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="credit">
                    <th mat-header-cell *matHeaderCellDef> {{ 'pg-transactions.balance' | translate }} </th>
                    <td style="color: black; text-align: center;" mat-cell *matCellDef="let element"
                        [style.background-color]="element.betUuid8 && (element.type == 1 || element.type == 5 || element.type == 9 || element.type == 13) ? '#00ffba' : element.color">
                        <div class="mobile-hide">
                            <span class="mobile-label"> {{ 'pg-transactions.balance' | translate }}:</span>
                            {{Currency.currencySymbol}}{{element.balance * Currency.quote | currency:'':''}}
                        </div>
                        <div class="desktop-hide">
                            <span style="font-size: 12px !important;">
                                <span class="mobile-label"> {{ 'pg-transactions.balance' | translate }}:</span>
                                {{Currency.currencySymbol}}{{element.balance * Currency.quote | currency:'':''}}
                            </span>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

        </div>
        <mat-paginator class="bg-theme" [pageSizeOptions]="[10, 50, 100, 500]" showFirstLastButtons>
        </mat-paginator>

    </div>
</div>
