import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { ConfigService } from '../../../config.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { Round } from '../../../round.interface';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'ticket-modal',
  templateUrl: './ticket-modal.component.html',
  styleUrls: ['./ticket-modal.component.scss'],
  providers: [DatePipe]
})
export class TicketModalComponent {

  @ViewChild('ticketModal', { static: true }) ticketModal: ModalDirective;

  round = {} as Round;
  ticketBody: string = '';
  ticketTitle: string = '';
  msgType: string[] = ['', 'modal-primary', 'modal-warning', 'modal-success', 'modal-danger'];

  constructor(private configService: ConfigService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private modalsService: ModalsService) {
  }

  msgStandard(title: string, msg: string, type?: number) {
    this.ticketTitle = title;
    this.ticketBody = msg;

    for (let i = 1; i < this.msgType.length; i++) {
      document.getElementById('msgModalStandard').classList.remove(this.msgType[i]);
    }
    if (type != 0) {
      document.getElementById('msgModalStandard').classList.add(this.msgType[type]);
    }

    this.ticketModal.show();
  }

  public showTicket(ticketCode) {
    this.configService.getBet(ticketCode)
      .subscribe(bet => {
        if (bet == null) {
          this.modalsService.openNotificationModal(this.translate.instant("pg-tickets.ticket-not-found"), this.translate.instant("comp-search-ticket.fail-info1") + ticketCode + this.translate.instant("comp-search-ticket.fail-info2"), true);
        }
        else {
          //this.configService.getUser(bet.userAdminId)
          let rankResult = [];
          this.configService.getRoundById(bet.roundId)
            .subscribe(round => {
              if (round && round.rankRound && round.rankResult)
                rankResult = round.rankResult.split('|');
            });
          this.configService.getBasicUser(bet.userAdminId)
            .subscribe(user => {
              /*let stringTable = "<table border='1'><tr><td>" + this.translate.instant("pg-result.ticket") + "</td><td>" + this.translate.instant("pg-tickets.operator") + "</td><td>" + this.translate.instant("pg-tickets.player") + "</td><td>" + this.translate.instant("pg-tickets.results") + "</td></tr>" +
                "<tr><td>" + ticketCode + "</td><td>" + user.login + "</td><td>" + bet.playerName + "</td>" +
                "<td>" + bet.results + "</td></tr>" +  "<td>" + user.country + "</td></tr>" +
                "</table>";*/
              this.configService.getGames(bet.roundId)
                .subscribe(matchs => {
                  let results = bet.results.split("|");
                  let total_hit = 0;
                  let total_cancel = 0;
                  if (bet.rankRound && results.length) {
                    rankResult.forEach((element, i) => {
                      if (results[i] == element) {
                        total_hit++;
                      }
                    });
                  }
                  matchs.forEach((element, i) => {
                    if (element.status == 0) {
                      total_cancel++;
                    } else {
                      if (results[i].includes(element.matchResult) && !bet.rankRound) {
                        total_hit++;
                      }
                    }
                  });
                  let legend: string = "<div class='col-12'>";
                  legend += "<div class='row legend'><div class='col-3'><div class='running'></div>&nbsp;" + this.translate.instant("pg-tickets.running") + "</div>";
                  legend += "<div class='col-3'><div class='hit'></div>&nbsp;" + this.translate.instant("pg-tickets.hit") + "</div>";
                  legend += "<div class='col-3'><div class='wrong'></div>&nbsp;" + this.translate.instant("pg-tickets.wrong") + "</div>";
                  legend += "<div class='col-3'><div class='cancel'></div>&nbsp;" + this.translate.instant("pg-tickets.cancelled") + "</div>";
                  legend += "</div></div>";


                  let resultMats: string = "<div class='col-12 total-show'>" + /*legend + */
                    "<div class='col-12'><strong style='font-weight: bold;'>" + this.translate.instant("pg-round.matches") + "</strong>: " + matchs.length +
                    "</div><div class='col-12 total-hit'><strong style='font-weight: bold;'>" + this.translate.instant("pg-result.number-hits") + "</strong>: " + total_hit +
                    "</div><div class='col-12 total-cancel'><strong style='font-weight: bold;'>" + this.translate.instant("pg-manage-ticket.canceled") + "</strong>: " + total_cancel + "</div></div>";
                  let count: number = 0;
                  if (bet.rankRound)
                    results.forEach((element, i) => {
                      if (i < rankResult.length && rankResult[i] == element) {
                        resultMats += "<div class='col-6 search-game-list correct'>" + element + "</div><div class='col-6 search-game-list correct'> " + rankResult[i] + "</div>";
                      } else if (i < rankResult.length && rankResult[i] != element) {
                        resultMats += "<div class='col-6 search-game-list no-hit'>" + element + "</div><div class='col-6 search-game-list no-hit'> " + rankResult[i] + "</div>";
                      }
                      else {
                        resultMats += "<div class='col-9 search-game-list'>" + element + "</div><div class='col-3 search-game-list'> " + (i + 1) + "°</div>";
                      }
                    });
                  else
                    matchs.forEach(element => {
                      if (element.status == 0) {
                        resultMats += "<div class='col-9 search-game-list'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list'> " + results[count] + "</div>";
                      } else {
                        if (element.matchResult == "0") {
                          resultMats += "<div class='col-9 search-game-list'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list'> " + results[count] + "</div>";
                        } else {
                          if (results[count].includes(element.matchResult)) {
                            resultMats += "<div class='col-9 search-game-list correct'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list correct'> " + results[count] + "</div>";
                          } else {
                            resultMats += "<div class='col-9 search-game-list no-hit'>" + element.homeName + " - " + element.awayName + "</div><div class='col-3 search-game-list no-hit'> " + results[count] + "</div>";
                          }
                        }
                      }
                      count++;
                    });

                  let betDate = (new Date(bet.betDate)).getTime() - (new Date()).getTimezoneOffset() * 60000;
                  let stringTable = "<div class='row'>" +
                    "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-tickets.operator") + "</div>" +
                    "<div class='col-6 search-under-border'>" + user.login + "</div>" +
                    "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-tickets.player") + "</div>" +
                    "<div class='col-6 search-under-border'>" + bet.playerName + "</div>" +
                    "<div class='col-12 text-center search-head'>" + this.translate.instant("pg-round.matches") + "</div>" +
                    resultMats +
                    "<div class='col-6 mt-3 search-head search-under-border'>" + this.translate.instant("pg-result.ticket") + "</div>" +
                    "<div class='col-6 mt-3 search-under-border'>" + ticketCode + "</div>" +
                    "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-credit-transfer.value") + "</div>" +
                    "<div class='col-6 search-under-border'>" + this.configService.formatCurrencyValue(bet.value) + "</div>" +
                    "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-post-result.date") + "</div>" +
                    "<div class='col-6 search-under-border'>" + this.datePipe.transform(betDate, 'dd/MM/y HH:mm') + "</div>" +
                    "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-jurisdiction.country") + "</div>" +
                    "<div class='col-6 search-under-border'>" + user.country + "</div>" +
                    "<div class='col-6 search-head search-under-border'>" + this.translate.instant("pg-home.quantity-tickets") + "</div>" +
                    "<div class='col-6 search-under-border'>" + bet.quantity + "</div>" +
                    "</div>";
                    this.modalsService.openNotificationModal(this.translate.instant("pg-tickets.resume-ticket") + ticketCode, stringTable, false);
                  (<HTMLInputElement>document.getElementById("code-search")).value = "";
                }, error => {
                  this.modalsService.openNotificationModal(this.translate.instant("pg-tickets.ticket-not-found"), this.translate.instant("comp-search-ticket.fail-info1") + ticketCode + this.translate.instant("comp-search-ticket.fail-info2"), true);
                });
            }, error => {
              this.modalsService.openNotificationModal(this.translate.instant("pg-tickets.ticket-not-found"), this.translate.instant("comp-search-ticket.fail-info1") + ticketCode + this.translate.instant("comp-search-ticket.fail-info2"), true);
            });
        }
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-tickets.ticket-not-found"), this.translate.instant("comp-search-ticket.fail-info1") + ticketCode + this.translate.instant("comp-search-ticket.fail-info2"), true);
      });
  }
}
