import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, MatTableDataSource } from '@angular/material';

import { AppComponent } from '../app.component';
import { ConfigService } from '../config.service';

import { Round } from '../round.interface';
import { Report } from '../report.interface';
import { ReportFilter } from '../report-filter.interface';
import { Jurisdiction } from '../jurisdiction.interface';
import { TranslateService } from '@ngx-translate/core';
import { Currency } from '../currency.interface';
import { InteractionService } from '../interaction.service';
import axios from "axios";
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns: string[] = ['operator', 'amountSold', 'prizeValue', 'playerWinnings', 'percentage', 'amountToGive', 'amountToReceive', 'wasPaid'];
  dataSource = new MatTableDataSource<Report>();
  jurisdictions: Jurisdiction[];
  rounds: Round[];
  info: any = [];
  roundName: string;
  Currency: Currency = <Currency>{};

  reportForm = this.fb.group({
    jurisdictionId: [0],
    dateStart: [''],
    dateEnd: [''],
    roundId: [0]
  });

  constructor(private interactionService: InteractionService, private configService: ConfigService, private appComponent: AppComponent,
    private fb: FormBuilder, private translate: TranslateService) {
    this.dataSource = new MatTableDataSource<Report>();
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {
    this.listJurisdiction();
    this.listRounds();
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
   
  }

  listJurisdiction(){
    this.configService.getJurisdictionsById(this.appComponent.userAdmin.jurisdictionId)
    .subscribe(data => {
      this.jurisdictions = data;
    }, error => {
      console.log(error);
    });
  }

  listRounds(){
    this.configService.getRounds().subscribe(data => {
      this.rounds = data;
      for (let index = 0; index < this.rounds.length; index++) {
        switch (this.appComponent.activeLang) {
          case 'pt':
            this.roundName = this.rounds[index].name;
            break;
          case 'en':
            this.roundName = this.rounds[index].nameEng;
            break;
          case 'it':
            this.roundName = this.rounds[index].nameItaly;
            break;
          case 'es':
            this.roundName = this.rounds[index].nameSpan;
            break;
          default:
            break;
        }
        this.info.push({
          id: this.rounds[index].id,
          startDateTime: this.rounds[index].startDateTime,
          endDateTime: this.rounds[index].endDateTime,
          name: this.roundName,
          number: this.rounds[index].number
        });
      }
    }, error => {
      console.log(error);
    });
  }

  getReport() {
    let reportFilter = {} as ReportFilter;
    reportFilter.userId = this.appComponent.userAdmin.id;
    reportFilter.roundId = this.reportForm.get('roundId').value;
    reportFilter.jurisdictionId = this.reportForm.get('jurisdictionId').value;

    if(this.reportForm.get('dateStart').value != "")
      reportFilter.dateStart = this.reportForm.get('dateStart').value;
    if(this.reportForm.get('dateEnd').value != "")
      reportFilter.dateEnd = this.reportForm.get('dateEnd').value;

    this.configService.getReport(reportFilter).subscribe(data => {
      this.dataSource = new MatTableDataSource<Report>(data);
      this.dataSource.paginator = this.paginator;
    }, error => {
      console.log(error);
    });
  }

}
