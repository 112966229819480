<div id="bannerswidth">
  <div class="my-body">
    <div class="row text-center bannerAdjust" *ngIf="!showMatch">

      <mdb-carousel [isControls]="false" class="carousel slide carousel-fade" [animation]="'fade'">
        <mdb-carousel-item *ngFor="let slides of topSlideList; let i = index;">
          <div class="view w-100">
            <img class="d-block w-100 banner-limits" src="{{ slidesPathPrefix + slides.fileName}}">
            <div class="waves-light" mdbWavesEffect></div>
          </div>
          <div class="carousel-caption">
            <h3 class="h3-responsive">{{ slides.title }}</h3>
            <p>{{slides.subtitle}}</p>
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
      <!-- <button mdbBtn color="default" *ngIf="!isLogin"
        style="white-space: nowrap; padding: 1px 5px;z-index: 1; color: black;background-image: linear-gradient(#fff,#888686) !important;position: absolute;bottom: 1%;"
        class="waves-light button-bg-theme button-font desktop-hide" (click)="showStreamingMsg()" mdbWavesEffect>
        <span class="button-bg-theme" style="color: red">
          <i class="blink_me fa fa-circle blk-style" aria-hidden="true"></i>
          {{ 'comp-roundgroups.live' | translate }}</span>
      </button> -->
      <!-- <button mdbBtn color="default" *ngIf="!isLogin"
        style="white-space: nowrap; padding: 1px 5px;z-index: 1; color: black;background-image: linear-gradient(#fff,#888686) !important;position: absolute;bottom: 1%;"
        class="waves-light button-bg-theme button-font desktop-hide" (click)="showTicketList()" mdbWavesEffect>
        <span class="button-bg-theme">
          {{ 'comp-roundgroups.live' | translate }}</span>
      </button> -->


    </div>
    <div *ngIf="showMatch" class="mobile-hide container">
      <iframe class="responsive-iframe" scrolling="no" frameborder="0" allowfullscreen="true"
        webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true"
        [src]="url"></iframe>

      <!-- <iframe class="responsive-iframe"
      allow="fullscreen; gyroscope; accelerometer; magnetometer; vr; xr"
      allowfullscreen webkitallowfullscreen mozallowfullscreen
      allowvr="true" allowfullscreen="true" [src]="url"></iframe> -->
    </div>
    <div *ngIf="showMatch" class="desktop-hide container">
      <iframe class="responsive-iframe" frameborder="0" allowfullscreen="" webkitallowfullscreen="true"
        mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true" [src]="url"></iframe>
    </div>
  </div>

  <!-- Slide Carousel NEW-->

</div>
<!-- Code added for bet grid by rehmat  -->


<!-- <app-bet-grid></app-bet-grid> -->
<app-newbetgrid></app-newbetgrid>



<!-- Code comment by rehmat for matches -->
<!-- <div class="col-12 text-center font-weight-bold" style="color: white;font-size: 15px;background-color: #3d3d3d;">
  Live Matches
</div>
<div class="col-12 round-header" style="padding: 0px;min-height: 550px; overflow: auto;overflow-x: hidden;">

  <ng-container *ngFor="let item of liveMatches">
    <div class="row headerRow mobile-hide">
      <div class="col-md-12" style="color: white;">
        {{item.matchTime | date: 'dd/MM/yyyy'}}
      </div>


    </div>
    <div class="row contentRow mobile-hide">
      <div class="col-md-2">
        {{item.matchTime | date: 'shortTime'}}
      </div>
      <div class="col-md-6">
        {{item.homeTeam}} ~ {{item.awayTeam}}
      </div>
      <div class="col-md-4">
        <div class="text-center">
          <a (click)="loadMatch(item.code,loggedInUserId,'mob')" class="">
            <mat-icon class="fore-green">tablet_android</mat-icon>
          </a>
          <b> & </b>
          <a (click)="loadMatch(item.code,loggedInUserId,'desk')" class="">
            <mat-icon class="fore-green">tv</mat-icon>
          </a>
        </div>

      </div>
    </div>

    <div class="col-12 headerRow desktop-hide">
      {{item.matchTime | date: 'dd/MM/yyyy'}}
    </div>
    <div class="desktop-hide w-100 col-12 game-group mbContentRow">
      <div class="teams-date">
        <div class="col-8">
          {{item.homeTeam}} ~ {{item.awayTeam}}
        </div>
        <div class="col-4 date">
          {{item.matchTime | date: 'shortTime'}}
        </div>
      </div>
      <div class="row" style="margin-left: 15px;margin-top: 20px;font-size: 20px;">
        <div class="col-12 bt-mobile">
          <div class="text-center">
            <a (click)="loadMatch(item.code,loggedInUserId,'mob')" class="">
              <mat-icon class="fore-green">tablet_android</mat-icon>
            </a>
            <b> & </b>
            <a (click)="loadMatch(item.code,loggedInUserId,'desk')" class="">
              <mat-icon class="fore-green">tv</mat-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>


</div> -->

<!-- <window *ngIf="showPortal">
  <iframe width="100%" height="300" [src]="url"></iframe>
</window> -->
<!--Modal Create Account -->
<div mdbModal #frame3="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog" role="document">
    <div class="modal-content bg-theme">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold" style="color:  white !important;">{{ 'pg-app-component.register'
          | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame3.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Loading (appears when is loading register) -->
      <div class="modal-body mx-3" *ngIf="registerLoading" id="spinner-loading">{{ 'pg-app-component.registering' |
        translate }}...
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div class="modal-body mx-3" *ngIf="!registerLoading">
        <!-- <div class="md-form mb-4">
          <i class="fas fa-user prefix grey-text"></i>
          <input type="text" id="orangeForm-login-home" name="orangeForm-login" [formControl]="userFormLogin"
            class="form-control code-field" mdbInput mdbValidate>
          <label for="orangeForm-login">{{ 'pg-app-component.username' | translate }} *</label>
        </div>
        <div class="md-form mb-4 " hidden>
          <i class="fas fa-user prefix grey-text"></i>
          <input type="text" id="orangeForm-name-home" name="orangeForm-name" class="form-control code-field"
            [formControl]="userFormName" mdbInput mdbValidate>
          <label data-error="wrong" data-success="right" for="orangeForm-name-home">{{ 'pg-app-component.name' |
            translate }}
            *</label>
        </div>
        <div class="md-form mb-4 " hidden>
          <i class="fas fa-user prefix grey-text"></i>
          <input type="text" id="orangeForm-lastname-home" name="orangeForm-lastname"
            class="form-control validate code-field" [formControl]="userFormLastName" mdbInput mdbValidate>
          <label data-error="wrong" data-success="right" for="orangeForm-lastname-home">{{ 'pg-app-component.last-name'
            | translate }}</label>
        </div>
        <div class="md-form mb-4">
          <i class="fas fa-envelope prefix grey-text"></i>
          <input type="email" id="orangeForm-email-home" name="orangeForm-email" class="form-control code-field"
            [formControl]="userFormEmail" mdbInput mdbValidate>
          <label data-error="wrong" data-success="right" for="orangeForm-email-home">E-mail *</label>
        </div>
        <div class="md-form mb-4">
          <i class="fas fa-envelope prefix grey-text"></i>
          <input type="email" id="orangeForm-email2-home" name="orangeForm-email2" class="form-control code-field"
            [formControl]="userFormConfirmEmail" mdbInput mdbValidate>
          <label data-error="wrong" data-success="right" for="orangeForm-email2-home">{{ 'pg-app-component.confirm' |
            translate }} e-mail *</label>
        </div>
        <div class="md-form mb-4">
          <mdb-icon fas icon="ticket-alt" class="prefix grey-text"></mdb-icon>
          <input type="email" id="jurisdiction-coupon-home" name="jurisdiction-coupon" class="form-control code-field"
            [formControl]="userJurisdictionCoupon" mdbInput mdbValidate>
          <label data-error="wrong" data-success="right" for="orangeForm-email2-home">{{
            'pg-app-component.register-coupon' | translate }}</label>
        </div>
        <div class="md-form mb-4 " hidden>
          <i class="fas fa-location-arrow prefix grey-text"></i>
          <input type="text" id="orangeForm-country-home" name="orangeForm-country" class="form-control code-field"
            [formControl]="userFormCountry" mdbInput mdbValidate value="Brasil">
          <label data-error="wrong" data-success="right" for="orangeForm-country-home">{{ 'pg-app-component.country' |
            translate }}</label>
        </div>
        <div class="md-form mb-4 " hidden>
          <i class="fas fa-location-arrow prefix grey-text"></i>
          <input type="text" id="orangeForm-city-home" name="orangeForm-city" class="form-control code-field"
            [formControl]="userFormCity" mdbInput mdbValidate>
          <label data-error="wrong" data-success="right" for="orangeForm-city-home">{{ 'pg-app-component.city' |
            translate }}</label>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button mdbBtn class="waves-light button-bg-theme button-font" mdbWavesEffect (click)="register()">{{
          'pg-app-component.register' | translate }}</button>

      </div> -->
      </div>
    </div>
  </div>
</div>

<div mdbModal #frameMessageDanger="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandardDanger">
    <!--<div class="modal-content  bg-theme" style="height: 378px;
    max-height: 400px;">-->
    <div class="modal-content  bg-theme">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="frameMessageDanger.hide();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3 adjustModel scrollDeg">
        <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>
      </div>
      <div class="modal-footer d-flex justify-content-center">

        <a type="button" color="waves-light button-font" style="background-color:#ff4b4b;" class="btn" mdbWavesEffect
          (click)="frameMessageDanger.hide();" data-dismiss="modal">OK</a>
      </div>

    </div>
  </div>
</div>
