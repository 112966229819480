export class ResponseGame {

  idGame: number;
  idHome: number;
  idRound:number;
  idVisit: number;
  teamHome: string;
  teamVisit: string;
  imgLogoTeamHome: string;
  imgLogoTeamVisit: string;
  homeTeamScore: number;
  awayTeamScore: number;
  dateBegin: Date;
  hourBegin: Date;
  homeAbbreviation: string;
  visitAbbreviation: string;
  dateEnd: Date;
  hourEnd: Date;
  result: string;
  two_options: number;
  betRadarID:number;
  gameStatus : number;


  totalGoals: string;
  totalCorners: string;
  yellowCards: string;
  cornerHomeTeam: string;
  cornerAwayTeam: string;
  totalFaults: string;
  shootsOnTarget: string;
  redCards: string;
  penaltyOnFavor: string;
  goalIn1stHalf: string;
  goalIn2ndHalf: string;
  moreBallPossession: string;
  finalResult: string;

  shieldTeamHome: string;
  shieldTeamAway: string;
}