import { Injectable } from '@angular/core';
import * as signalr from '@microsoft/signalr';
import { BetradarSport } from './betradar-sport.interface';
import { Prize } from './prize.interface';
import { RoundService } from './round.service';
import { SkinConfigService } from './skin-config.service';
import { SportService } from './services/sport/sport.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  prizeList: Prize[] = [];
  betRadarSportList: BetradarSport[] = [];
  activatedTicketList: any[] = [];
  private _hubConnection: signalr.HubConnection;
  public hubReady: Promise<void>;
  hubID: number;
  // apiUrl = 'http://dev-backend.poolbet365.com'; // dev online
  apiUrl = 'https://backend.poolbet365.com'; // production
  // apiUrl = 'https://localhost:44341'; // local test
  // apiUrl =  'http://217.79.251.10:89';

  constructor(private roundService: RoundService, private skinConfigService: SkinConfigService,
              private sportService: SportService
  ) {
    this.startConnection();
    this.skinConfigService.loadConstants()
      .subscribe(data => {
        this.hubID = data.hubID;
      })
    this._hubConnection.onclose(error => {
      this.retryConnection();
    });
  }

  startConnection = () => {
    this._hubConnection = new signalr.HubConnectionBuilder()
      .withUrl(`${this.apiUrl}/poolbetHub`, {
        skipNegotiation: true,
        transport: signalr.HttpTransportType.WebSockets
      })
      .configureLogging(signalr.LogLevel.Information)
      .build();

    this._hubConnection
      .start()
      .then(() => {
        this._hubConnection.invoke("JoinGroup", new String(this.hubID))
          .then(() => {
            this.KeepAlive();
            this.getCompleteActivatedRoundsOnInit();
            this.updateActiveRounds();
          })

      })
      .catch(err => console.log('Error while starting connection: ' + err));
  }

  retryConnection() {
    let attempts = 0;

    const tryReconnect = async () => {
      try {
        this.startConnection();
      } catch (reconnectError) {
        console.log("Reconnect Failed: ", reconnectError);
        const retryInMs = Math.pow(2, attempts) * 1000;

        setTimeout(() => {
          tryReconnect();
        }, retryInMs);
      }
    }
    tryReconnect();
  }

  KeepAlive = () => {
    this._hubConnection.on('SendPing', () => {
      this.sendPong();
    });
  }

  sendPong = () => {
    this._hubConnection.invoke('ReceivePong')
      .catch(err => console.error('Error while sending pong: ' + err));
  }

  getCompleteActivatedRoundsOnInit() {
    this._hubConnection.invoke('GetCompleteActivatedRounds', this.hubID)
      .then(activeItens => {
        this.roundService.setRoundList(activeItens.rounds);
        this.sportService.setSportList(activeItens.sports);
      });
  }

  updateActiveRounds() {
    this._hubConnection.on("GetCompleteActivatedRounds", (activeItens) => {
      this.roundService.setRoundList(activeItens.rounds);
      this.sportService.setSportList(activeItens.sports);
    });
  }

}
