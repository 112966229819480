import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'angular-bootstrap-md';
//import { currentId } from 'async_hooks';
import async_hooks from 'async_hooks';
import { debug } from 'console';
import { parse } from 'querystring';
import { AppComponent } from '../app.component';
import { ConfigService } from '../config.service';
import { Currency } from '../currency.interface';
import { InteractionService } from '../interaction.service';
import { LiveMatchModel } from '../round-group.interface';
import { Slides } from '../slide.interface';
import { User } from '../user.interface';
import { ModalsService } from '../services/modals/modals.service';
import { ISkinConfig } from '../Interfaces/SkinConfig.interface';
import { UserCookieService } from '../services/User/UserCookie.service';



@Component({
  selector: 'app-live-matches',
  templateUrl: './live-matches.component.html',
  styleUrls: ['./live-matches.component.scss']
})



export class LiveMatchesComponent implements OnInit {

  @ViewChild('frame3', { static: true }) modalRegister: ModalDirective;
  @ViewChild('frameMessageDanger', { static: true }) modalMessagesDanger: ModalDirective;
  @ViewChild('frameMessage', { static: true }) modalMessages: ModalDirective;
  isLogged: boolean = false;
  liveMatches: LiveMatchModel[] = [];
  topSlideList: Slides[] = [];
  slidesPathPrefix = "http://poolbet365.com/assets/banners/";
  registerLoading = false;
  userValidatingForm: FormGroup;
  forgotValidatingForm: FormGroup;
  validatingForm: FormGroup;
  Currency: Currency = <Currency>{};
  msgToAdd: string = '';
  sportId: number;
  titleToAdd: string = '';
  curUserId: number = 0;
  loggedInUserId: number;
  msgType: string[] = ['', 'modal-primary', 'modal-warning', 'modal-success', 'modal-danger'];
  url: SafeResourceUrl;
  showMatch: boolean = false;
  showPortal: boolean = false;
  spId: number;
  windows: number = 0;
  type: string = "";
  title: string = "";



  constructor(private interactionService: InteractionService, private configService: ConfigService,
    private appComponent: AppComponent, private router: Router,
    private translate: TranslateService, private elRef: ElementRef,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private modalsService: ModalsService,
    private userCookieService: UserCookieService) {
    this.interactionService.isLogged.subscribe(logged => {
      this.isLogged = logged;
    })
    this.translate.setDefaultLang(this.appComponent.activeLang);
    interactionService.skinConfig
      .subscribe(skinConfig => {
        this.title = skinConfig.pageTitle;
      })
  }

  ngOnInit() {
    localStorage.setItem('windows', this.windows.toString());

    this.route.params.subscribe((params: Params): void => {
      this.sportId = params['id'];
      this.spId = params['spId'];
      this.configService.getLiveMatches(this.sportId, this.spId).subscribe(data => {
        this.liveMatches = data;
      }, error => {
        console.log(error);
      });

    });

    this.loggedInUserId = this.userCookieService.getUserId();
    if (this.loggedInUserId <= 0) {
      this.curUserId = 1;
    }
    else {
      this.curUserId = this.loggedInUserId;
    }
    this.route.params.subscribe((params: Params): void => {
      this.sportId = params['id'];
      this.spId = params['spId'];
      this.type = params['type']
      // this.configService.getLiveMatches(this.sportId,this.spId).subscribe(data => {
      //   this.liveMatches = data;
      // }, error => {
      //   console.log(error);
      // });
      this.loadMatch(this.sportId.toString(), this.loggedInUserId, this.type)

    });

    this.getOwnTopSlides(this.curUserId, 1, "top");
    // this.liveMatches.push({
    //   id: '1',
    //   datetime: '',
    //   sport: 'Soccer',
    //   time: '12:00',
    //   teams: 'Real Madrid - Barcelona'
    // });

    this.validatingForm = new FormGroup({
      loginFormModalName: new FormControl('', Validators.required),
      loginFormModalPassword: new FormControl('', Validators.required)
    });

    this.userValidatingForm = new FormGroup({
      userFormLogin: new FormControl('', Validators.required),
      userFormName: new FormControl('', Validators.required),
      userFormLastName: new FormControl(''),
      userFormEmail: new FormControl('', Validators.required),
      userFormConfirmEmail: new FormControl('', Validators.required),
      userJurisdictionCoupon: new FormControl(''),
      userFormCountry: new FormControl(''),
      userFormCity: new FormControl('')
    });

    this.forgotValidatingForm = new FormGroup({
      forgotFormEmail: new FormControl('', [Validators.required, Validators.email])
    });

    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  getOwnTopSlides(masterParentId: number, languageId: number, slideType: string) {
    this.configService.getOwnSlides(masterParentId, languageId, slideType)
      .subscribe(data => {
        this.topSlideList = data;
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant('pg-banners.operation-fail'), this.translate.instant('pg-banners.load-fail-info'), true);
        console.log(error);
      });
  }

  loadMatch(code: string, userId: number, type: string) {
    this.configService.getMatchLink(code, userId).subscribe(data => {
      if (data.isSuccess) {
        if (type == "desk") {
          //this.showMatch=false;
          window.open(data.matchUrl, "_blank", "scrollbars=0,resizable=0,width=100,height=0,top=0,left=0");
          // this.windows = localStorage.getItem('windows') == null ? 0 : parseInt(localStorage.getItem('windows'));
          // if(this.windows < 3)
          // {
          //   window.open(data.matchUrl, "_blank", "scrollbars=0,resizable=0,width=100,height=0,top=0,left=0");
          //   this.windows = this.windows + 1;
          //   localStorage.setItem('windows', this.windows.toString());
          // }

        }
        else {
          this.showMatch = true;
          this.showPortal = false;
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.matchUrl);
        }
      }
      else {
        this.modalsService.openNotificationModal(this.translate.instant('comp-streaming.fail-title'), this.translate.instant('comp-streaming.fail-info'), true);
      }
    });
  }

  generateRandomPassword() {
    let length = 8,
      charset = 'abcde0123456789',
      newPassword = '';

    for (var i = 0, n = charset.length; i < length; ++i) {
      newPassword += charset.charAt(Math.floor(Math.random() * n));
    }

    return newPassword;
  }

  register() {
    let newLogin = (<HTMLInputElement>document.getElementById('orangeForm-login')).value;
    let newName = (<HTMLInputElement>document.getElementById('orangeForm-name')).value;
    let newLastName = (<HTMLInputElement>document.getElementById('orangeForm-lastname')).value;
    let newEmail = (<HTMLInputElement>document.getElementById('orangeForm-email')).value;
    let newConfirmEmail = (<HTMLInputElement>document.getElementById('orangeForm-email2')).value;
    let newJurisdictionCoupon = (<HTMLInputElement>document.getElementById('jurisdiction-coupon')).value;
    let newCountry = (<HTMLInputElement>document.getElementById('orangeForm-country')).value;
    let newCity = (<HTMLInputElement>document.getElementById('orangeForm-city')).value;

    let newPassword = this.generateRandomPassword();

    if (newLogin == '' || /*newName == "" ||*/ newEmail == '' || newConfirmEmail == '') {
      this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.field-required'), this.translate.instant('pg-app-component.field-required-info'), true);
    } else if (newLogin.includes(' ')) {
      this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.field-required'), this.translate.instant('pg-app-component.invalid-character-info'), true);
    } else if (newEmail != newConfirmEmail) {
      this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.email-not-match'), this.translate.instant('pg-app-component.email-not-match-info'), true);
    } else {
      this.registerLoading = true;
      let newUser: User = {
        login: newLogin,
        name: newName,
        lastName: newLastName,
        email: newEmail,
        jurisdictionId: 7,
        parentId: 8,
        password: newPassword,
        country: newCountry,
        city: newCity,
        deleted: false,
        commission: 0,
        jurisdiction: null,
        userPermission: null,
        credit: 0,
        id: 0,
        children: null,
        bank: '',
        agency: '',
        account: '',
        currencyCode: "BRL"
      };

      this.configService.createUser(newUser, newJurisdictionCoupon)
        .subscribe(data => {
          this.configService.sendPasswordToEmail(newUser.name, newUser.login, newUser.email, newUser.password)
            .subscribe(data => {
              if (data) {
                this.modalsService.openNotificationModal(
                  this.translate.instant('pg-app-component.successfull-register'),
                  this.translate.instant('pg-app-component.successfull-register-info') + this.title,
                  false
                );
                this.modalRegister.hide();
                this.registerLoading = false;
              }
            }, error => {
              this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.erro-on-register'), this.translate.instant('pg-app-component.erro-on-register-info'), true);
              this.modalRegister.hide();
              this.registerLoading = false;
              console.log(error);
            });
        }, error => {
          this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.erro-on-register'), this.translate.instant('pg-app-component.erro-on-register-info'), true);
          this.modalRegister.hide();
          this.registerLoading = false;
        });
    }
  }

  showTicketList() {
    this.appComponent.ticketListMobileOpen();
  }
}
