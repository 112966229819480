import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentResponseDto } from '../document.interface';
import { ConfigService } from '../config.service';
import { Withdraw } from '../withdraw.interface';
import { User } from '../user.interface';

export interface DialogData {
  userId: number;
  name: string;
  customer: User;
}

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  imageShowFlag: boolean;
  imageSrc: string;
  customerInfo: User;
  docList: DocumentResponseDto[];
  withdrawList: Withdraw[];
  descList: string[] = [
    'upload-doc.doc_type_0',
    'upload-doc.doc_type_1',
    'upload-doc.doc_type_2',
    'upload-doc.doc_type_3',
    'upload-doc.doc_type_4'
  ];
  statusList: string[] = [
    'upload-doc.PENDING',
    'upload-doc.FAILED',
    'upload-doc.SUCCESS',
  ];

  constructor(
    public dialogRef: MatDialogRef<UserDetailComponent>,
    private configService: ConfigService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.imageSrc = '';
    this.imageShowFlag = false;
    this.docList = [];
    this.withdrawList = [];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getDocuments();
    this.getWithdraws();
    this.customerInfo = this.data.customer;
  }

  async getDocuments(): Promise<void> {
    if (this.data.userId) {
      this.docList = await this.configService.getDocument(this.data.userId).toPromise();
    }
  }

  async getWithdraws(): Promise<void> {
    if (this.data.userId) {
      this.withdrawList = await this.configService.getWithdraw(this.data.userId).toPromise();
    }
  }

  async onAgreeClicked(docId: number) {
    this.docList = await this.configService.updateDocument( this.data.userId, docId, 2, '').toPromise();
    this.getDocuments();
  }

  async onDeclineClicked(docId: number) {
    this.docList = await this.configService.updateDocument( this.data.userId, docId, 1, '').toPromise();
    this.getDocuments();
  }

  onImageOpen(fileName: string) {
    let src = `../../assets/documents/${fileName}`;
    this.imageSrc = src;
    this.imageShowFlag = true;
  }
  onImageClose() {
    this.imageShowFlag = false;
  }

}
