<div class="container">

  <div class="row">

    <!-- Olda Card -->
    <div class="card">

      <!-- Card content -->
      <div class="card-body bg-theme">
        <div class="row">
          <div class="col-12 pt-3">
            <h3><i class="fas fa-users" style="font-size: 25px;"></i> {{ 'pg-jurisdiction.users' | translate}}</h3>
          </div>
        </div>
        <hr>
        <div class="row">

          <div class="col-md-6 col-sm-12">
            <button class="p-2" type="button" mdbBtn color="success" mdbWavesEffect routerLink="/new-user">
              <i class="fas fa-user-plus"></i> {{ 'pg-jurisdiction.create-user' | translate}}</button>
          </div>
          <div class="col-md-6 col-sm-12 text-right search-area">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event)">
              <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-12">
            <div *ngIf="!isLoaded" id="spinner-loading" class="justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>

        <!-- table users -->
        <div class="row">
          <div class="col-12 user-table" style="overflow: auto;">
            <div class="mat-elevation-z8">

              <table mat-table [dataSource]="dataSource2">

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef> ID </th>
                  <td mat-cell *matCellDef="let element; let i = index" [class.bg-grey]="formatingGreyCheck(i)"
                    class="table-text">
                    {{element.id}} <img src="../../assets/image/{{element.jurisdictionId}}.png" width="20px"
                      height="20px" />
                  </td>
                </ng-container>

                <!-- Login Column -->
                <ng-container matColumnDef="login">
                  <th mat-header-cell *matHeaderCellDef> {{ 'pg-jurisdiction.username' | translate}} </th>
                  <td mat-cell *matCellDef="let element; let i = index" [class.bg-grey]="formatingGreyCheck(i)"
                    [class.blocked-user]="formatingRedCheck(element.deleted)" class="table-text"> {{element.login}}
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> {{ 'pg-jurisdiction.name' | translate}} </th>
                  <td mat-cell *matCellDef="let element; let i = index" [class.bg-grey]="formatingGreyCheck(i)"
                    [class.blocked-user]="formatingRedCheck(element.deleted)" class="table-text"> {{element.name}} </td>
                </ng-container>

                <!-- E-mail Column -->
                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef> E-mail </th>
                  <td mat-cell *matCellDef="let element; let i = index" [class.bg-grey]="formatingGreyCheck(i)"
                    [class.blocked-user]="formatingRedCheck(element.deleted)" class="table-text">
                    {{element.email}}
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="text-capitalize"> {{ 'pg-manage-ticket.actions' |
                    translate
                    }} </th>
                  <td mat-cell *matCellDef="let element; let i = index" [class.bg-grey]="formatingGreyCheck(i)">
                    <div class="d-flex">
                      <!--<a class="actions-buttons edit-button" *ngIf="appComponent.userAdmin.userPermissions[1].value"
                        (click)="openEditUser(element.id)"><i class="fas fa-pencil-alt"></i></a>-->
                      <a class="actions-buttons edit-button" (click)="openEditUser(element.id)"><i
                          class="fas fa-pencil-alt"></i></a>
                      <!--<a class="actions-buttons credit-button" *ngIf="appComponent.userAdmin.userPermissions[1].value"
                        (click)="openCreditTransferModal(element.id)"><i class="fas fa-dollar-sign"></i></a>-->
                      <a class="actions-buttons credit-button"
                        *ngIf="((appComponent.userAdmin.jurisdictionId == 1 || appComponent.userAdmin.jurisdictionId == 6) || element.jurisdictionId != 7)"
                        (click)="openCreditTransferModal(element.id)"><i class="fas fa-dollar-sign"></i></a>
                      <!--<a class="actions-buttons bank-button" *ngIf="appComponent.userAdmin.userPermissions[3].value" (click)="onEditClicked(element, frame1)"><i class="fas fa-file-alt"></i></a>-->
                      <a class="actions-buttons delete-button" *ngIf="appComponent.userAdmin.userPermissions[3].value"
                        (click)="openDeleteModal(element.id)"><i class="fas fa-ban"></i></a>
                      <a class="actions-buttons" *ngIf="appComponent.userAdmin.userPermissions[3].value"
                        (click)="autoLogin(element.id)"><i class="fas fa-arrow-alt-circle-right"></i></a>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

              <mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100, 500]" showFirstLastButtons>
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- Card -->

  </div>

</div>

<!--Modal create jurisdiction-->
<div mdbModal #frame="mdbModal" class="modal fade" scrollY="true" maxHeight="200" id="frameModalTop" tabindex="-1"
  role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-success" role="document">
    <!--Content-->
    <div class="modal-content bg-theme">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ 'pg-jurisdiction.create-jurisdiction' | translate}}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="col-12">
          <!-- Form -->
          <form style="color:  white;" [formGroup]="jurisdictionForm">

            <!-- <label>{{ 'pg-jurisdiction.type' | translate}} *</label>
            <div *ngFor="let jurisdiction of jurisdictions" style="margin-left: 15px">
              <input type="radio" [value]="jurisdiction.id" formControlName="jurisdictionId" required
                (click)="updateListUsers(jurisdiction.id)">
              <label for="jurType">{{jurisdiction.name}}</label>
            </div> -->

            <mat-form-field class="code-field mt-2 w-100">
              <mat-label>{{ 'pg-jurisdiction.name' | translate}}</mat-label>
              <input matInput formControlName="name" required>
            </mat-form-field>

            <mat-form-field class="code-field mt-2 w-100">
              <mat-label>{{ 'pg-jurisdiction.username' | translate}}</mat-label>
              <input matInput formControlName="login" required>
            </mat-form-field>

            <mat-form-field *ngIf="(appComponent.userAdmin.jurisdictionId < 6 && !isPlayer)"
              class="code-field mt-2 w-100">
              <mat-label>{{ 'pg-jurisdiction.commission' | translate}} (%)</mat-label>
              <!--<input matInput formControlName="commission" value="90" required>-->
              <input matInput formControlName="commission" value="0">
            </mat-form-field>

            <mat-form-field class="code-field mt-2 w-100">
              <mat-label>{{ 'pg-jurisdiction.password' | translate}}</mat-label>
              <input matInput type="password" formControlName="password" required>
            </mat-form-field>

            <mat-form-field class="code-field mt-2 w-100">
              <mat-label>{{ 'pg-jurisdiction.confirm-password' | translate}}</mat-label>
              <input matInput type="password" formControlName="confirmPassword" (keyup)="comparePassword()" required>
              <mat-error *ngIf="!senhaOk">{{ 'pg-jurisdiction.password-not-match' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="code-field mt-2 w-100">
              <mat-label>E-mail</mat-label>
              <input matInput type="email" formControlName="email" (keyup)="wrongEmail()" required>
              <mat-error *ngIf="!emailOk">{{ 'pg-jurisdiction.invalid-email' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="code-field mt-2 w-100">
              <mat-label>{{ 'pg-jurisdiction.jurisdiction-father' | translate}}</mat-label>
              <mat-select formControlName="parentId" [(value)]="userSelected" required disabled>
                <mat-option *ngFor="let user of treeList" [value]="user.id">
                  {{user.login}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="code-field mt-2 w-100">
              <mat-label>{{ 'pg-jurisdiction.obs' | translate}}</mat-label>
              <textarea matInput rows="3" formControlName="obs"></textarea>
            </mat-form-field>

          </form>
          <!-- Form -->
        </div>
      </div>
      <!--Body-->

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <button type="submit" mdbBtn class="waves-light button-font" style="background-color:#01d36b;" mdbWavesEffect
          (click)="addNewUserAdmin(frame)" [disabled]="!jurisdictionForm.valid && !senhaOk">{{ 'pg-jurisdiction.create'
          | translate}}</button>
        <button type="submit" mdbBtn class="waves-light button-font" style="background-color:#01d36b;" mdbWavesEffect
          (click)="frame.hide()" data-dismiss="modal">{{ 'pg-jurisdiction.cancel' | translate}}</button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Modal create jurisdiction-->

<!--Modal create jurisdiction-->
<div mdbModal #frame1="mdbModal" class="modal fade" scrollY="true" maxHeight="200" id="frameModalTop" tabindex="-1"
  role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-success" role="document">
    <!--Content-->
    <div class="modal-content bg-theme">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ editUserInfo && editUserInfo.login }}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame1.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body text-white user-detail">
        <div class="col-12">
          <!-- Form -->
          <div class="row">
            <div class="col col-8">
              <div class="mb-2 row">
                <label class="col col-4">{{ 'withdraw.bank' | translate }}</label>
                <input matInput class="center-content code-field col col-8" disabled
                  [ngModel]="editUserInfo && editUserInfo.bank">
              </div>
              <div class="mb-2 row">
                <label class="col col-4">{{ 'withdraw.agency' | translate }}</label>
                <input matInput class="center-content code-field col col-8" disabled
                  [ngModel]="editUserInfo && editUserInfo.agency">
              </div>
              <div class="mb-2 row">
                <label class="col col-4">{{ 'withdraw.account' | translate }}</label>
                <input matInput class="center-content code-field col col-8" disabled
                  [ngModel]="editUserInfo && editUserInfo.account">
              </div>
            </div>
            <div class="col col-4"></div>
          </div>
          <div class="row">
            <p class="px-4 py-0">{{ 'upload-doc.table_heaader' | translate }}</p>
            <table class="table text-white" style="font-size: small">
              <thead>
                <th scope="col">{{ 'upload-doc.Id' | translate }}</th>
                <th scope="col">{{ 'upload-doc.CreatedAt' | translate }}</th>
                <th scope="col">{{ 'upload-doc.Type' | translate }}</th>
                <th scope="col">{{ 'upload-doc.Status' | translate }}</th>
                <th scope="col">{{ 'upload-doc.ResponseAt' | translate }}</th>
                <th scope="col">{{ 'upload-doc.REASON' | translate }}</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of docList">
                  <td (click)="onImageOpen(item.fileName)">{{item.id}}</td>
                  <td (click)="onImageOpen(item.fileName)">{{item.createdDate.toString().substring(0,10)}}</td>
                  <td (click)="onImageOpen(item.fileName)">{{ descList[item.type] | translate }}</td>
                  <td (click)="onImageOpen(item.fileName)">{{ statusList[item.status] | translate }}</td>
                  <td *ngIf="item.status == 0">
                    <a (click)="onAgreeClicked(item.id)">{{ 'upload-doc.Agree' | translate }}</a>
                    <span>/</span>
                    <a (click)="onDeclineClicked(item.id, frame4)">{{ 'upload-doc.Decline' | translate }}</a>
                  </td>
                  <td *ngIf="item.status != 0" (click)="onImageOpen(item.fileName)">
                    {{item.responseDate.toString().substring(0,10)}}
                  </td>
                  <td (click)="onDescriptionClicked(0, item.status, item.id, item.description, frame4)">{{
                    item.description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top: 20px;">
            <p class="px-4 py-0">{{ 'upload-doc.withdraw_heaader' | translate }}</p>
            <table class="table text-white" style="font-size: small">
              <thead>
                <th scope="col">{{ 'upload-doc.Id' | translate }}</th>
                <th scope="col">{{ 'upload-doc.CreatedAt' | translate }}</th>
                <th scope="col">{{ 'upload-doc.Status' | translate }}</th>
                <th scope="col">{{ 'withdraw.amount' | translate }}</th>
                <th scope="col">{{ 'upload-doc.ResponseAt' | translate }}</th>
                <th scope="col">{{ 'upload-doc.REASON' | translate }}</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of withdrawList">
                  <td>{{item.id}}</td>
                  <td>{{item.createdDate.toString().substring(0,10)}}</td>
                  <td>{{ statusList[item.status] | translate }}</td>
                  <td>
                    {{item.amount}}
                  </td>
                  <td>
                    <ng-container *ngIf="item.status != 0">
                      {{item.responseDate.toString().substring(0,10)}}
                    </ng-container>
                    <ng-container *ngIf="item.status == 0">
                      <a (click)="onAgreeWithdrawClicked(item.id)">{{ 'upload-doc.Agree' | translate }}</a>
                      <span>/</span>
                      <a (click)="onDeclineWithdrawClicked(item.id, frame4)">{{ 'upload-doc.Decline' | translate }}</a>
                    </ng-container>
                  </td>
                  <td (click)="onDescriptionClicked(1, item.status, item.id, item.description, frame4)">{{
                    item.description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Form -->
        </div>
      </div>
      <!--Body-->

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <button type="submit" mdbBtn class="waves-light button-font" style="background-color:#01d36b;" mdbWavesEffect
          (click)="frame1.hide()" data-dismiss="modal">{{ 'pg-jurisdiction.cancel' | translate}}</button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Modal create jurisdiction-->

<!--Modal edit jurisdiction-->
<div mdbModal #frame3="mdbModal" class="modal fade" scrollY="true" maxHeight="200" id="frameModalTop" tabindex="-1"
  role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-primary" role="document">
    <!--Content-->
    <div class="modal-content bg-theme">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ 'pg-jurisdiction.edit-jurisdiction' | translate}}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame3.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="text-center">
          <form style="color:  white;" #f="ngForm">
            <!-- User Data -->
            <mat-form-field class="w-100">
              <mat-label>
                {{ 'pg-jurisdiction.username' | translate}}
                <img src="../../assets/image/{{userEditable.jurisdictionId}}.png" width="20px" height="20px" />
              </mat-label>
              <input type="text" matInput [(ngModel)]="userEditable.login" userLogin name="userLogin">
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>{{ 'pg-jurisdiction.credit' | translate}}</mat-label>
              <div class="row">
                <div class="col-md-1">
                  <mat-label>{{ Currency.currencySymbol }}</mat-label>
                </div>
                <div class="col-md-6" style="padding-left: 0.1%;">
                  <input type="text" matInput [(ngModel)]="userCredit" userCredit name="userCredit" disabled>
                </div>
              </div>
            </mat-form-field>
            <span *ngIf="!showPassword" style="text-align: left; text-decoration: underline; color: #4285f4"><a
                (click)="showPasswordField()">{{ 'pg-jurisdiction.change-password' | translate}}</a></span>
            <mat-form-field class="w-100" *ngIf="showPassword">
              <mat-label>{{ 'pg-jurisdiction.new-password' | translate}}</mat-label>
              <input type="password" matInput [(ngModel)]="userEditable.password" userPassword name="userPassword">
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>{{ 'pg-jurisdiction.jurisdiction-father' | translate }}</mat-label>
              <input type="text" matInput [(ngModel)]="parentUser.login" userName name="userName" disabled>
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>{{ 'pg-jurisdiction.name' | translate}} *</mat-label>
              <input type="text" matInput [(ngModel)]="userEditable.name" userName name="userName">
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>{{ 'pg-jurisdiction.last-name' | translate}}</mat-label>
              <input type="text" matInput [(ngModel)]="userEditable.lastName" lastName name="lastName">
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>E-mail *</mat-label>
              <input type="text" matInput [(ngModel)]="userEditable.email" userEmail ngModel name="userEmail">
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>{{ 'pg-jurisdiction.commission' | translate}} (%)</mat-label>
              <input type="text" matInput [(ngModel)]="userEditable.commission" name="commission">
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>{{ 'pg-jurisdiction.country' | translate}}</mat-label>
              <mat-select [(value)]="userEditable.country" country ngModel name="country">
                <mat-option *ngFor="let p of paises" [value]="p.name">
                  {{p.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="w-100">
              <mat-label>{{ 'pg-jurisdiction.city' | translate}}</mat-label>
              <input type="text" matInput [(ngModel)]="userEditable.city" city ngModel name="city">
            </mat-form-field>
            <div class="w-100" *ngIf="(userEditable.jurisdictionId <= 6)">
              {{ 'pg-jurisdiction.coupon' | translate}}: <strong>{{ coupon }}</strong> <a (click)="generateCoupon()"
                style="margin-left: 3px; text-decoration: underline; color: #4285f4">{{ 'pg-jurisdiction.generate-new' |
                translate}}</a>
            </div>
            <mat-form-field class="w-100" *ngIf="(coupon != '')">
              <mat-label>{{ 'pg-jurisdiction.promotional-value' | translate}}</mat-label>
              <input type="text" matInput [(ngModel)]="availableValue" city ngModel name="available-value">
            </mat-form-field>

          </form>

        </div>
      </div>
      <!--Body-->

      <!--Footer -->
      <div class="modal-footer justify-content-center">
        <div class="row">
          <div class="col-6">
            <button mat-raised-button style="background-color:#4285f4; color: white;" class="button-font"
              (click)="editUser(f)">{{ 'pg-jurisdiction.save-changes' | translate}}</button>
          </div>
          <div class="col-6">
            <button mat-raised-button style="background-color:#4285f4; color: white;" class="button-font close-btn"
              outline="true" (click)="frame3.hide()">{{ 'pg-jurisdiction.dont-change' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Modal edit jurisdiction-->


<!--Modal create jurisdiction-->
<div mdbModal #frame4="mdbModal" class="modal fade" scrollY="true" maxHeight="100" id="frameModalTop" tabindex="-1"
  role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-success" role="document">
    <!--Content-->
    <div class="modal-content bg-theme">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ 'upload-doc.DESCRIPTION' | translate}}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame4.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body text-white user-detail">
        <div class="col-12">
          <!-- Form -->
          <!--          <textarea matInput rows="10" class="md-outline" [(ngModel)]="descItem.description"></textarea>-->
          <mat-form-field class="code-field mt-2 w-100">
            <mat-label>{{ 'upload-doc.DESCRIPTION' | translate}}</mat-label>
            <textarea matInput rows="6" [(ngModel)]="descItem.description"></textarea>
          </mat-form-field>
          <!-- Form -->
        </div>
      </div>
      <!--Body-->

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <div class="row">
          <div class="col-6">
            <button mat-raised-button style="background-color:#4285f4; color: white;" class="button-font"
              (click)="transferDecline(frame4)">{{ 'pg-jurisdiction.save-changes' | translate}}</button>
          </div>
          <div class="col-6">
            <button mat-raised-button style="background-color:#4285f4; color: white;" class="button-font close-btn"
              outline="true" (click)="frame4.hide()">{{ 'pg-jurisdiction.dont-change' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Modal create jurisdiction-->

<!--Modal give permission-->
<div mdbModal #frame5="mdbModal" class="modal fade" scrollY="true" maxHeight="200" id="frameModalTop" tabindex="-1"
  role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
  [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content bg-theme">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{selectedUser?.login}}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelPermission(frame5)">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body" style="height: 390px;">
        <div class="col-12">
          <div class="checkbox">
            <mat-checkbox [checked]="selectedUser?.userPermissions[0].value" (change)="permissionChange($event, 0)">
              Pages(Settings, Deposit, Withdaw, Manage Coupons, Transactions)</mat-checkbox>
          </div>
          <div class="checkbox">
            <mat-checkbox [checked]="selectedUser?.userPermissions[1].value" (change)="permissionChange($event, 1)">
              Pages(Reports, Credit Transfer)</mat-checkbox>
          </div>
          <div class="checkbox">
            <mat-checkbox [checked]="selectedUser?.userPermissions[2].value" (change)="permissionChange($event, 2)">Page
              User(Create User)</mat-checkbox>
          </div>
          <div class="checkbox">
            <mat-checkbox [checked]="selectedUser?.userPermissions[3].value" (change)="permissionChange($event, 3)">Page
              User(Delete/Edit User, Auto-Login)</mat-checkbox>
          </div>
          <div class="checkbox">
            <mat-checkbox [checked]="selectedUser?.userPermissions[4].value" (change)="permissionChange($event, 4)">Page
              Banners</mat-checkbox>
          </div>
          <div class="checkbox">
            <mat-checkbox [checked]="selectedUser?.userPermissions[5].value" (change)="permissionChange($event, 5)">
              Pages(Create Round, Post Result, Edit Jackpot)</mat-checkbox>
          </div>
        </div>
      </div>
      <!--Body-->

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <button style="background-color:#5394ff; color: white;" type="submit" mdbBtn mdbWavesEffect
          (click)="updatePermission(frame5)">Save</button>
        <button mdbBtn style="background-color:#5394ff; color: white;" mdbWavesEffect (click)="cancelPermission(frame5)"
          data-dismiss="modal">{{ 'pg-jurisdiction.cancel' | translate}}</button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Modal give permission-->

<!--Modal credit transfer-->
<div mdbModal #frame6="mdbModal" class="modal fade" scrollY="true" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-success" role="document">
    <!--Content-->
    <div class="modal-content bg-theme">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ 'pg-jurisdiction.credit-transfer' | translate}}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="closeCreditTransferModal()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body credit-transfer-body">
        <div class="row">
          <div class="col-6 credit-transfer-header">Username</div>
          <div class="col-6">{{userEditable.login}}</div>
          <div class="col-6 credit-transfer-header">{{ 'pg-jurisdiction.current-credit' | translate}}</div>
          <div class="col-6">{{Currency.currencySymbol}} {{userEditable.credit * Currency.quote | currency:'':''}}</div>
          <div class="col-6 credit-transfer-header d-flex align-items-center justify-content-end">{{
            'pg-jurisdiction.transfer-type' | translate}}</div>
          <div class="col-6">
            <mat-form-field class="filter-field">
              <mat-select [(value)]="crediTtransferSelected" class="credit-transfer-type">
                <mat-option value="deposit">{{ 'pg-jurisdiction.deposit' | translate}}</mat-option>
                <mat-option value="withdraw">{{ 'pg-jurisdiction.withdraw' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6 credit-transfer-header d-flex align-items-center justify-content-end">{{
            'pg-jurisdiction.amount' | translate}}</div>
          <div class="col-6">
            <mat-form-field>
              <mat-label></mat-label>
              <input matInput type="number" placeholder="{{Currency.currencySymbol}}" [(ngModel)]="transferValue" />
              <span matSuffix>,00</span>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!--Body-->

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <button type="submit" mdbBtn class="waves-light button-font" style="background-color:#01d36b;" mdbWavesEffect
          (click)="openConfirmCreditTransferModal()">{{ 'pg-jurisdiction.transfer' | translate}}</button>
        <button type="submit" mdbBtn class="waves-light button-font" color="danger" mdbWavesEffect
          (click)="closeCreditTransferModal()" data-dismiss="modal">{{ 'pg-jurisdiction.cancel' | translate}}</button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Modal credit transfer-->

<!--Modal confirmation credit transfer-->
<div mdbModal #frame7="mdbModal" class="modal fade" scrollY="true" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-success" role="document">
    <!--Content-->
    <div class="modal-content bg-theme">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ 'pg-jurisdiction.credit-transfer' | translate}}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame7.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body credit-transfer-body">
        <div class="row">
          <div class="col-6 credit-transfer-header">Username</div>
          <div class="col-6">{{userEditable.login}}</div>
          <div class="col-6 credit-transfer-header">{{ 'pg-jurisdiction.current-credit' | translate}}</div>
          <div class="col-6">{{Currency.currencySymbol}} {{userEditable.credit * Currency.quote | currency:'':''}}</div>
          <div class="col-6 credit-transfer-header">{{ 'pg-jurisdiction.transfer-type' | translate}}</div>
          <div class="col-6">{{ ('pg-jurisdiction.' + crediTtransferSelected) | translate}}</div>
          <div class="col-6 credit-transfer-header">{{ 'pg-jurisdiction.amount' | translate}}</div>
          <div class="col-6">{{Currency.currencySymbol}} {{transferValue * Currency.quote | currency:'':''}}</div>
          <div class="col-6 credit-transfer-header">{{ 'pg-jurisdiction.new-user-credit' | translate}}</div>
          <div class="col-6 credit-new-value">{{Currency.currencySymbol}} {{newValue * Currency.quote | currency:'':''}}
          </div>
        </div>
      </div>
      <!--Body-->

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <button type="submit" mdbBtn class="waves-light button-font" mdbWavesEffect style="background-color:#01d36b;"
          (click)="creditTransfer()">{{ 'pg-jurisdiction.confirm' | translate}}</button>
        <button type="submit" mdbBtn class="waves-light button-font" color="danger" mdbWavesEffect
          (click)="frame7.hide()" data-dismiss="modal">{{ 'pg-jurisdiction.cancel' | translate}}</button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Modal confirmation credit transfer-->

<!--Modal delete-jurisdiction-->
<div mdbModal #frame8="mdbModal" class="modal fade" scrollY="true" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content bg-theme">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">{{ 'pg-jurisdiction.delete-jurisdiction' | translate}}</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame8.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body credit-transfer-body">
        <div class="row">
          <div class="col-12">{{ 'pg-jurisdiction.delete-user-confirmation' |
            translate}}<strong>{{userEditable.login}}</strong>?</div>
          <div class="col-12 warning"><strong>{{ 'pg-jurisdiction.warning' | translate}}</strong>{{
            'pg-jurisdiction.warning-msg' | translate}}</div>
        </div>
      </div>
      <!--Body-->

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <button type="submit" mdbBtn class="waves-light button-font" color="danger" style="background-color:#01d36b;"
          mdbWavesEffect (click)="blockUser(userEditable.id)">{{ 'pg-jurisdiction.confirm' | translate}}</button>
        <button type="submit" mdbBtn class="waves-light button-font" mdbWavesEffect (click)="frame8.hide()"
          data-dismiss="modal" style="background-color:#01d36b;">{{ 'pg-jurisdiction.cancel' | translate}}</button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Modal delete-jurisdiction-->


<div id="myModal" class="modal" style="display: block" *ngIf="imageShowFlag">
  <span class="close" (click)="onImageClose()">&times;</span>
  <img class="modal-content" id="img01" [src]="imageSrc">
  <div id="caption"></div>
</div>