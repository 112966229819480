import { Component, OnInit, ViewChild, Inject, HostListener, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from '../config.service';
import { AppComponent } from '../app.component';
import { User } from '../user.interface';
import { NgForm } from '@angular/forms';
import { ModalDirective } from 'angular-bootstrap-md';
import { TranslateService } from '@ngx-translate/core';
import { JurisdictionCoupon } from '../jurisdiction-coupon.interface';
import { Currency } from '../currency.interface';
import axios from "axios";
import { InteractionService } from '../interaction.service';
import { MatTableDataSource } from '@angular/material/table';
import { ClipboardService } from 'ngx-clipboard';
import { ModalsService } from '../services/modals/modals.service';
import { UserCookieService } from '../services/User/UserCookie.service';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  @ViewChild('frame', { static: true }) modalUser: ModalDirective;
  @ViewChild('frame2', { static: true }) modalPassword: ModalDirective;

  user = {} as User;
  ownCredit: any;
  isLoaded: boolean = false;
  creditVisibility: boolean = false;
  coupon: JurisdictionCoupon;
  Currency: Currency = <Currency>{};
  paises: string[];
  width: number;

  currentPassword = '';
  newPassword = '';
  confirmPassword = '';

  docs: boolean = false;
  selectedCountry: string;
  selectedCurrency: string;
  CurrencySelect: string;
  countries: any[];
  linkRefCode: string = ""
  checkClub: boolean = false;

  apiurl: string = "https://www.poolbet365.com";
  //apiurl : string = "http://dev-bolao.poolbet365.com"
  QrCodeLink: string = "https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl="

  constructor(private interactionService: InteractionService, private configService: ConfigService, private appComponent: AppComponent,
    private translate: TranslateService, @Inject(DOCUMENT) private document: Document, private clipboardService: ClipboardService,
    private modalsSerice: ModalsService, private userCookieService: UserCookieService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  @HostListener('window:resize')
  handleResize() {
    this.width = Math.max(this.document.body.scrollWidth, this.document.body.offsetWidth,
      this.document.documentElement.clientWidth, this.document.documentElement.scrollWidth,
      this.document.documentElement.offsetWidth);
  }

  ngOnInit() {
    this.interactionService.getResponsiveMainApp().subscribe(isManageTicket => {
      if (isManageTicket) {
        const mainContainer = document.getElementById("colun13");
        mainContainer?.classList.add("principal-responsivity");
      }
    });

    this.getUser();
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
    this.handleResize();
    //this.checkDocuments();
  }

  getUser() {
    const userId = this.userCookieService.getUserId();
    this.configService.getUser(userId)
      .subscribe(data => {
        if (data.jurisdictionId == 6) {
          this.checkClub = true;
          this.configService.getCoupon(userId)
            .subscribe(coupon => {
              this.linkRefCode = this.apiurl + "/refcode/" + coupon.code;
              this.QrCodeLink = this.QrCodeLink + this.linkRefCode;
            }, error => {
              //...
            });
        }
        else this.checkClub = false;
        this.user = data;
        this.selectedCountry = data.country;
        // this.configService.getCoupon(Number(localStorage.getItem('currentUser')))
        //   .subscribe(coupon => {
        //     this.coupon = coupon;
        //   }, error => {
        //     //...
        //   });
        //this.isLoaded = true;
        //document.getElementById("spinner-loading").classList.add("hidden");
        //this.setOwnCredit();
        this.isLoaded = true;
        this.getCountries();
      }, error => {
        console.log(error);
      });
  }

  async downloadImage(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const anchor = document.createElement("a");
      anchor.href = URL.createObjectURL(blob);
      anchor.download = "Qr Code - poolbet365";
      anchor.click();
      URL.revokeObjectURL(anchor.href);
      this.modalsSerice.openNotificationModal(this.translate.instant("pg-new-user.success"), this.translate.instant("friendCode.DownloadSuccess"), false);
    } catch (error) {
      this.modalsSerice.openNotificationModal(this.translate.instant("pg-tickets.fail"), this.translate.instant("friendCode.DownloadError"), true);
    }
  }

  getCountries() {
    this.configService.getCountries()
      .subscribe(data => {
        let userCurrency;
        if (this.user.currencyCode == null) userCurrency = data.find(x => x.name == this.user.country);
        else if (this.user.currencyCode == "BRL") userCurrency = data.find(x => x.name == "Brazil");
        else userCurrency = data.find(x => x.name == this.user.currencyCode);
        this.selectedCurrency = userCurrency.currencyId + " - " + userCurrency.name;
        this.CurrencySelect = userCurrency.name
        this.countries = data.sort((a, b) => a.name.localeCompare(b.name));


        this.setOwnCredit();
      }, error => {
        console.log(error);
      })
  }

  setOwnCredit() {
    this.ownCredit = this.user.credit;
  }

  closeModalPassword() {
    this.modalPassword.hide();
  }

  closeModalUser() {
    this.modalUser.hide();
  }

  changePassword() {
    this.modalPassword.hide();
    this.isLoaded = false;

    this.configService.updatePassword(this.user.id, this.user.login, true, this.newPassword, this.currentPassword)
      .subscribe(data => {
        this.modalsSerice.openNotificationModal(this.translate.instant("pg-settings.change-made"), this.translate.instant("pg-settings.change-made-info"), false);
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.ngOnInit();
      }, error => {
        this.modalsSerice.openNotificationModal(this.translate.instant("pg-settings.change-error"), this.translate.instant("pg-settings.change-error-info"), true);
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        console.log(error);
        this.ngOnInit();
      });
    // this.appComponent.msgStandard(this.translate.instant("pg-settings.password-not-match"), this.translate.instant("pg-settings.password-not-match-info"), 4);
  }

  editUser() {
    //if (form.value.userName != "" && form.value.userEmail != "") {
    this.modalUser.hide();
    this.isLoaded = false;
    this.user.currencyCode = 'BRL';
    this.configService.updateUser(this.user.id, this.user)
      .subscribe(data => {
        this.configService.setGlobalCurrency();
        this.modalsSerice.openNotificationModal(this.translate.instant("pg-settings.change-made"), this.translate.instant("pg-settings.data-changed-info"), false);
        this.ngOnInit();
      }, error => {
        this.modalsSerice.openNotificationModal(this.translate.instant("pg-settings.change-error"), this.translate.instant("pg-settings.data-error-info"), true);
        console.log(error);
        this.ngOnInit();
      });
    //}
    // else {
    // this.modalsSerice.openNotificationModal(this.translate.instant("pg-settings.required-field-empty"), this.translate.instant("pg-settings.required-field-empty-info"), true);
    // }
  }

  checkDocuments() {
    const idUser = this.userCookieService.getUserId();
    this.configService.getDocumentCount(idUser)
      .subscribe(documentData => {
        if (documentData < 3) {
          // this.modalsSerice.openNotificationModal(this.translate.instant("pg-banners.operation-fail"), this.translate.instant("withdraw.allowed-docs"), true);
          this.docs = false
        }
        else {
          this.docs = true;
        }
      }, error => {
        console.log(error);
        // this.modalsSerice.openNotificationModal(this.translate.instant("pg-banners.operation-fail"), this.translate.instant("withdraw.allowed-docs"), true);
        this.docs = false
      });
  }

  toggleVisibility() {
    this.creditVisibility = !this.creditVisibility;
  }

  AddCountry() {
    const pais = 'https://free.currconv.com/api/v7/countries?&apiKey=085a5c4bbbd6e6750bfc'
    axios.get(pais)
      .then((response) => {
        var countries = response.data.results;
        for (const prop in countries) {
          this.configService.AddCountry(countries[prop].currencyId, countries[prop].name, countries[prop].currencySymbol)
            .subscribe(data => {


            }, error => {

            });
        }
      })
  }

  copyLinkRefCode(): void {
    this.clipboardService.copyFromContent(this.linkRefCode);
  }

  passwordsChekeds() {
    if (this.newPassword != this.confirmPassword || this.newPassword == '' || this.confirmPassword == '' || this.currentPassword == '')
      return true;

    return false;
  }

  ngOnDestroy() {
    const mainContainer = document.getElementById("colun13");
    mainContainer.classList.remove("principal-responsivity");
  }
}
