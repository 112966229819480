import {Component, OnInit, ViewChild} from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {ModalDirective} from 'angular-bootstrap-md';
import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from '../../app.component';
import {ConfigService} from '../../config.service';
import {InteractionService} from '../../interaction.service';
import {Round} from '../../round.interface';
import {RoundGroup} from '../../round-group.interface';
import {Router} from '@angular/router';

export class DataNode {
  name: string;
  roundId: number;
  childrens?: DataNode[];
}

@Component({
  selector: 'app-roundgroups',
  templateUrl: './roundgroups.component.html',
  styleUrls: ['./roundgroups.component.scss']
})
export class RoundgroupsComponent implements OnInit {
  @ViewChild('frame6', {static: true}) modalRounds: ModalDirective;
  treeControl = new NestedTreeControl<DataNode>(node => node.childrens);
  dataSource = new MatTreeNestedDataSource<DataNode>();
  roundGroups: RoundGroup[];
  rounds: Round[];
  isLogged: boolean = false;
  public activeLang;

  constructor(private interactionService: InteractionService, private configService: ConfigService,
              private appComponent: AppComponent, private router: Router,
              private translate: TranslateService) {
                this.interactionService.isLogged.subscribe(logged => {
                  this.isLogged = logged;
                })
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {
    this.listRoundGroups();
    this.listRounds();

  }

  hideModalRoundsF() {

    let lenghyellow = document.querySelectorAll('table .bg-yellow').length;
    while (lenghyellow != 0) {
      if (document.querySelectorAll('.bg-yellow').length != 0) {
        document.querySelector('table .bg-yellow').classList.remove('bg-yellow');
        document.getElementById('cupomhtml').innerHTML = ' <div id=\'txtcupomhtml\'>' + this.translate.instant('pg-home.bet-selected') + '</div> ';
      }
      lenghyellow = lenghyellow - 1;
    }
    this.modalRounds.hide();
  }

  hideModalRoundsF2() {
    this.modalRounds.hide();
  }

  public isGroup = (_: number, node: DataNode) => !!node.childrens;

  public loadRound(roundId: number) {
    this.configService.getRound(roundId)
      .subscribe(data => {
        this.interactionService.setHomeVisibleRound(data);
        this.router.navigate(['/home']);
      }, error => {
        console.log(error);
      });
  }

  private listRoundGroups() {
    this.configService.getActivatedRoundGroups().subscribe(data => {
      this.roundGroups = data;
      this.prepareDataSource();
    }, error => {
      console.log(error);
    });
  }

  private listRounds() {
    this.configService.getRounds().subscribe(data => {
      this.rounds = data;
      this.prepareDataSource();
    }, error => {
      console.log(error);
    });
  }

  private prepareDataSource() {
    this.dataSource.data = this.buildDataNodes(this.roundGroups);
  }

  private buildDataNodes(roundGroups: RoundGroup[]): DataNode[] {
    var dataNodes: DataNode[] = [];

    for (var i in roundGroups) {
      var rg = roundGroups[i];
      var dn = new DataNode();
      dn.name = rg.name;
      dn.childrens = [];

      // evaluate rounds of this group
      for (var ir in rg.rounds) {
        var rId = rg.rounds[ir];
        var r = this.findRound(rId);
        if (!!r) {
          var dnr = new DataNode();
          //dnr.name = "Rodada nº " + r.number.toString();
          dnr.name = r.name;
          dnr.roundId = r.id;
          dnr.childrens = null;
          dn.childrens.push(dnr);
        }
      }

      // evaluate childgroups of this group
      var subdns = this.buildDataNodes(rg.childrens);
      for (var subdnI in subdns) {
        dn.childrens.push(subdns[subdnI]);
      }

      dataNodes.push(dn);
    }

    return dataNodes;
  }

  private findRound(id: number): Round {
    for (var i in this.rounds) {
      if (this.rounds[i].id == id) {
        return this.rounds[i];
      }
    }
    return null;
  }

  flagName(groupName: string): string {
    let indexResult: number;
    let flags: string[] = ['pt', 'it', 'en', 'ar', 'es', 'wo'];

    switch (groupName) {
      case 'Brasil': {
        indexResult = 0;
        break;
      }
      case 'Italia': {
        indexResult = 1;
        break;
      }
      case 'England': {
        indexResult = 2;
        break;
      }
      case 'Argentina': {
        indexResult = 3;
        break;
      }
      case 'Espanha': {
        indexResult = 4;
        break;
      }
      default: {
        indexResult = 5;
        break;
      }
    }

    return flags[indexResult];
  }

  customTranslate(country: string): string {
    let currentLanguage = this.appComponent.activeLang;
    let result: string = '';
    switch (country) {
      case 'Brasil': {
        if (currentLanguage == 'it') {
          result = 'Brasile';
        }
        if (currentLanguage == 'en') {
          result = 'Brazil';
        }
        if (currentLanguage == 'pt') {
          result = 'Brasil';
        }
        if (currentLanguage == 'es') {
          result = 'Brasil';
        }
        break;
      }
      case 'Italia': {
        if (currentLanguage == 'it') {
          result = 'Italia';
        }
        if (currentLanguage == 'en') {
          result = 'Italy';
        }
        if (currentLanguage == 'pt') {
          result = 'Itália';
        }
        if (currentLanguage == 'es') {
          result = 'Italia';
        }
        break;
      }
      case 'Misto': {
        if (currentLanguage == 'it') {
          result = 'Misto';
        }
        if (currentLanguage == 'en') {
          result = 'Mixed';
        }
        if (currentLanguage == 'pt') {
          result = 'Misto';
        }
        if (currentLanguage == 'es') {
          result = 'Misto';
        }
        break;
      }
      case 'Espanha': {
        if (currentLanguage == 'it') {
          result = 'Spagna';
        }
        if (currentLanguage == 'en') {
          result = 'Spain';
        }
        if (currentLanguage == 'pt') {
          result = 'Espanha';
        }
        if (currentLanguage == 'es') {
          result = 'España';
        }
        break;
      }
      case 'England': {
        if (currentLanguage == 'it') {
          result = "Inghilterra";
        }
        if (currentLanguage == 'en') {
          result = "England";
        }
        if (currentLanguage == 'pt') {
          result = "Inglaterra";
        }
        if (currentLanguage == 'es') {
          result = "Inglaterra";
        }
        break;
      }
      default: {
        result = country;
      }
    }
    return result;
  }
}
