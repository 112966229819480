<!-- Card -->
<div class="card-body bg-theme">
    <!-- Card content -->
    <div>
        <h3><i class="fas fa-user-plus" style="font-size: 25px;"></i>
            {{ 'pg-app-component.register' | translate }}
        </h3>
        <hr />
    </div>

    <br>
    <div class="d-flex flex-column">
        <div class="mb-2">

            <div class="md-form register-form mb-4 d-flex justify-content-center">
                <i class="fas fa-user prefix grey-text"></i>
                <!--<input type="text" id="orangeForm-login" name="orangeForm-login" [formControl]="userFormLogin"
            class="form-control code-field" mdbInput mdbValidate>-->
                <input type="text" id="orangeForm-login" name="orangeForm-login"
                    [formControl]="userFormLogin" class="form-control code-field"
                    placeholder="{{ 'pg-app-component.username' | translate }} *">


                <!--<label for="orangeForm-login">{{ 'pg-app-component.username' | translate }} *</label>-->
            </div>

            <div class="md-form register-form mb-4" hidden>
                <i class="fas fa-user prefix grey-text"></i>
                <!--<input type="text" id="orangeForm-name" name="orangeForm-name" class="form-control code-field"
            [formControl]="userFormName" mdbInput mdbValidate>-->
                <input type="text" id="orangeForm-name" name="orangeForm-name"
                    class="form-control code-field" [formControl]="userFormName" mdbInput>
                <label data-error="wrong" data-success="right" for="orangeForm-name">{{
                    'pg-app-component.name' |
                    translate }} *</label>
            </div>

            <div class="md-form register-form mb-4 " hidden>
                <i class="fas fa-user prefix grey-text"></i>
                <!--<input type="text" id="orangeForm-lastname" name="orangeForm-lastname"
            class="form-control validate code-field" [formControl]="userFormLastName" mdbInput mdbValidate>-->
                <input type="text" id="orangeForm-lastname" name="orangeForm-lastname"
                    class="form-control code-field" [formControl]="userFormLastName" mdbInput>
                <label data-error="wrong" data-success="right" for="orangeForm-lastname">{{
                    'pg-app-component.last-name' | translate }}</label>
            </div>
            
            
            <div class="md-form register-form mb-4 d-flex justify-content-center">
                <i class='fas fa-key prefix grey-text'></i>
                <input type="password" id="orangeForm-password" name="orangeForm-password"
                    class="form-control code-field" [formControl]="userFormPassword"
                    placeholder="{{ 'pg-app-component.password'| translate }} *">
                <!--<label data-error="wrong" data-success="right" for="orangeForm-password">
            {{ 'pg-app-component.password'| translate }} *</label>-->
            </div>

            <div class="md-form register-form mb-4 d-flex justify-content-center">
                <i class='fas fa-key prefix grey-text'></i>
                <input type="password" id="orangeForm-confirm-password" name="orangeForm-confirm-password"
                    class="form-control code-field" [formControl]="userFormConfirmPassword"
                    placeholder="{{'pg-app-component.confirm-password' | translate }} *">
                <!--<label data-error="wrong" data-success="right" for="orangeForm-confirm-password">
            {{'pg-app-component.confirm-password' | translate }} *</label>-->
            </div>

            <div class="md-form register-form mb-4 d-flex justify-content-center">
                <i class="fas fa-envelope prefix grey-text"></i>
                <!--<input type="email" id="orangeForm-email" name="orangeForm-email" class="form-control code-field"
            [formControl]="userFormEmail" mdbInput mdbValidate>-->
                <input type="email" id="orangeForm-email" name="orangeForm-email"
                    class="form-control code-field" [formControl]="userFormEmail" placeholder="E-mail *">
                <!--<label data-error="wrong" data-success="right" for="orangeForm-email">E-mail *</label>-->
            </div>

            <div class="row mb-2">
                <div class="col-sm-12 text-center">
                    <mdb-icon fas icon="ticket-alt" class="prefix grey-text"></mdb-icon>
                    {{ 'pg-new-user.friend-code' | translate }}: <br>
                    {{ code }}
                </div>
            </div> 

            <div class="row mb-2">
                <div class="col-md-12 col-sm-12 pl-4 text-center">
                    <button mdbBtn class="waves-light button-bg-theme button-font sendBtn" mdbWavesEffect (click)="register()">
                        {{ 'pg-app-component.register' | translate }}
                    </button>
                </div>
            </div>

        </div>
    </div>


</div>

<!--MOBILE-->
<!-- Card -->