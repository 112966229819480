import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-search-ticket',
  templateUrl: './search-ticket.component.html',
  styleUrls: ['./search-ticket.component.scss']
})
export class SearchTicketComponent implements OnInit {

  searchCode: number;

  constructor(private appComponent: AppComponent, private translate: TranslateService, private modalService: ModalsService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {
  }

  public searchTicket(code?: string) {
    //let ticketCode: number = +(<HTMLInputElement>document.getElementById("code-search")).value;
    let ticketCode: string;
    if (typeof (code) == 'undefined') {
      ticketCode = (<HTMLInputElement>document.getElementById("code-search")).value;
    }
    else {
      ticketCode = code;
    }
    //this.appComponent.detailCoupon(ticketCode);
    this.modalService.openDetailTickeModal(0, ticketCode);
  }

}
