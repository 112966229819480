<!--Post result-->
<div class="container">
    <!-- Card -->
    <div class="card mb-5">
        <!-- Card content -->
        <div class="card-body bg-theme">
            <div class="row">
                <!---------------------->
                <div class="col-12">
                    <button mdbBtn color="default" class="waves-light" (click)="frame4.show()" mdbWavesEffect>{{
                        'pg-print.ticket' | translate }}</button>
                </div>

            </div>
        </div>
    </div>
    <!-- Card -->
</div>

<!--New Modal test print -->
<div mdbModal #frame4="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog-scrollable modal-print" role="document">

        <div class="modal-content bg-theme">
            <!-- <div class="modal-header text-center">
                <h3 class="modal-title w-100 font-weight-bold" style="text-align: left;">{{ 'pg-print.ticket' |
                    translate }}</h3>
                <button mdbBtn class="waves-light button-bg-theme button-font tertiary-button"
                    style="margin-right: 20px;" (click)="print2()" mdbWavesEffect>{{ 'pg-print.print' | translate
                    }}</button>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closePrintModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> -->

            <div class="modal-body mx-3 ml-1" id="print-section" style="font-size: 12px;">
                <div style="max-width: 300px !important;" *ngIf="(round != null && bet != null)">
                    <div class="row ml-1">
                        <div class="col-8">
                            <img src="/assets/image/logo-principal.png" width="150px" style="filter: drop-shadow(2px 4px 6px black);" />
                        </div>
                        <div class="col-4 text-right d-flex p-0 justify-content-around" style="margin-top: 10px;">
                            <img src="/assets/image/whats-icon.png" (click)="showModalWhatsapp()" style="cursor: pointer; font-size: 33px;max-width: 25px;max-height: 25px;margin-left: 27px;" />
                            <img src="/assets/image/print-icon.png" (click)="print2()" style="cursor: pointer; font-size: 33px; max-width: 25px;max-height: 25px;" />
                        </div>
                
                    </div>

                    <div class="row ml-1">
                        <div class="row m-2 pt-1 pb-1" style="border: solid black thin">
                            <div class="col-6">
                                <b>{{ 'pg-result.ticket' | translate }}</b>
                            </div>
                            <div class="col-6 text-right">
                                {{ betId }}
                            </div>

                            <div class="col-12 pt-0 pb-0">
                                <hr style="margin: 0px" />
                            </div>

                            <div class="col-6">
                                <b>{{ 'round-header.datetime' | translate }}</b>
                            </div>
                            <div class="col-6 text-right">
                                {{ getCurrentDateTime(displayDateTime) | date: 'dd/MM'}} {{
                                getCurrentDateTime(displayDateTime) | date: 'shortTime'}}
                            </div>

                            <div class="col-12 pt-0 pb-0">
                                <hr style="margin: 0px" />
                            </div>

                            <div class="col-6">
                                <b>{{ 'pg-home.operator' | translate }}</b>
                            </div>
                            <div class="col-6 text-right">
                                {{ operator }}
                            </div>

                            <div class="col-12 pt-0 pb-0">
                                <hr style="margin: 0px" />
                            </div>

                            <div class="col-6">
                                <b>{{ 'pg-home.player' | translate }}</b>
                            </div>
                            <div class="col-6 text-right">
                                {{ bet.playerName }}
                            </div>

                            <div class="col-12 pt-0 pb-0">
                                <hr style="margin: 0px" />
                            </div>

                            <div class="col-6">
                                <b>{{ 'pg-print.round-id' | translate }}</b>
                            </div>
                            <div class="col-6 text-right">
                                {{ round.uuid4 }}
                            </div>

                            <div class="col-12 pt-0 pb-0">
                                <hr style="margin: 0px" />
                            </div>

                            <div class="col-6">
                                <b>{{ 'pg-home.round-value' | translate }}</b>
                            </div>
                            <div class="col-6 text-right">
                                {{ Currency.currencySymbol }} {{ roundingFunction(+round.value) |
                                currency:'':''}}
                            </div>

                            <div class="col-12 pt-0 pb-0">
                                <hr style="margin: 0px" />
                            </div>

                            <div class="col-6">
                                <b>{{ 'round-header.prize' | translate }}</b>
                            </div>
                            <div class="col-6 text-right">
                                {{ Currency.currencySymbol }} {{ roundingFunction(displayPrize) |
                                    currency:'':''}}
                            </div>

                            <div class="col-12 pt-0 pb-0">
                                <hr class="ml-1" style="margin: 0px" />
                            </div>

                            <div class="col-6">
                                <b>{{ 'round-header.jackpot' | translate }}</b>
                            </div>
                            <div class="col-6  text-right">
                                {{ Currency.currencySymbol }} {{ roundingFunction(displayJackpot) |
                                    currency:'':''}}
                            </div>

                        </div>
                    </div>

                    <div class="row ml-1">
                        <div class="row w-100 mr-2 ml-2 text-center" style="border: solid black thin;">
                            <div class="col-12">
                                <b>{{ roundName }}</b>
                            </div>
                        </div>

                        <!-- NORMAL ROUND -->
                        <div class="row mr-2 mb-2 ml-2 pt-1 pb-1" style="border: solid black thin; border-top: 0px">
                            <div class="row col-12 pr-0" *ngFor="let event of displayEvents">
                                <!-- NORMAL ROUND -->
                                <div class="col-12" *ngIf="event.awayTeam != ''">
                                    <b>{{ event.homeTeam }} <span *ngIf="event.awayTeam != ''">-</span> {{
                                        event.awayTeam }}</b>
                                </div>
                                <div class="col-6" *ngIf="event.awayTeam != ''">
                                    {{ event.dateTime | date: 'dd/MM'}} {{ event.dateTime | date: 'shortTime'}}
                                </div>
                                <div class="col-6 pr-0 text-right" *ngIf="event.awayTeam != ''">
                                    <b>{{ event.result }}</b>
                                </div>
                                <!-- NORMAL ROUND -->

                                <!-- SINGLE MATCH OR RANK ROUND -->
                                <div class="col-9" *ngIf="round.singleMatchRound || round.rankRound">
                                    <b>{{ event.homeTeam | translate }}</b>
                                </div>
                                <div class="col-3 pr-0 text-right" *ngIf="round.singleMatchRound || round.rankRound">
                                    <b> {{ event.result | translate }}</b>
                                </div>
                                <!-- SINGLE MATCH OR RANK ROUND -->

                                <div class="col-12 pt-0 pb-0">
                                    <hr class="ml-1" style="margin: 0px" />
                                </div>

                            </div>

                        </div>
                        <!-- NORMAL ROUND -->

                        <!-- RANK ROUND 
                        <div *ngIf="round.rankRound == 1" class="row mr-2 mb-2 ml-2 pt-1 pb-1"
                            style="border: solid black thin; border-top: 0px">
                            <div class="row col-12 pr-0" *ngFor="let event of displayEvents">
                                <div class="col-12">
                                    <b>{{ event.homeTeam }}</b>
                                </div>
                                <div class="col-12 pr-0 text-right">
                                    <b>{{ event.result }}</b>
                                </div>

                                <div class="col-12 pt-0 pb-0">
                                    <hr class="ml-1" style="margin: 0px" />
                                </div>

                            </div>

                        </div>
                        
                        <div *ngIf="round.singleMatchRound == 1" class="row mr-2 mb-2 ml-2 pt-1 pb-1"
                            style="border: solid black thin; border-top: 0px">
                            <div class="row col-12 pr-0" *ngFor="let event of displayEvents">
                                <div class="col-12">
                                    <b>{{ event.homeTeam }}</b>
                                </div>
                                <div class="col-12 pr-0 text-right">
                                    <b>{{ event.result }}</b>
                                </div>

                                <div class="col-12 pt-0 pb-0">
                                    <hr class="ml-1" style="margin: 0px" />
                                </div>

                            </div>

                        </div>
                        SINGLE MATCH ROUND -->
                    </div>

                    <div class="row mt-2 ml-1">
                        <div class="row w-100 mr-2 ml-2" style="border: solid black thin; font-size: 14px;">
                            <div class="col-6">
                                <b>{{ 'pg-home.total-value' | translate }}</b>
                            </div>
                            <div class="col-6 text-right">
                                <b>{{ Currency.currencySymbol }} {{ roundingFunction(bet.value) |
                                    currency:'':''}}</b>
                            </div>

                            <div class="col-12 pt-0 pb-0">
                                <hr class="ml-1" style="margin: 0px" />
                            </div>

                            <div class="col-6">
                                <b>{{ 'pg-home.quantity-tickets-value' | translate }}</b>
                            </div>
                            <div class="col-6 text-right">
                                {{ bet.quantity }}
                            </div>

                        </div>
                    </div>

                    <div class="row mt-2 ml-1">
                        <div class="row w-100 mr-2 ml-2" style="border: solid black thin;">
                            <div class="col-6">
                                <b>{{ 'round-header.prize' | translate }}</b>
                            </div>
                            <div class="col-6 text-right">
                                <b>{{ Currency.currencySymbol }} {{ roundingFunction(displayPrize) |
                                    currency:'':''}}</b>
                            </div>

                            <div class="col-12 pt-0 pb-0">
                                <hr class="ml-1" style="margin: 0px" />
                            </div>

                            <div class="col-6">
                                <b>{{ 'round-header.jackpot' | translate }}</b>
                            </div>
                            <div class="col-6 text-right">
                                <b>{{ Currency.currencySymbol }} {{ roundingFunction(displayJackpot) |
                                    currency:'':''}}</b>
                            </div>

                        </div>
                    </div>

                    <div class="row mt-2 ml-1">
                        <div class="row m-2 pt-1 pb-1" style="border: solid black thin">
                            <div class="col-12 pt-0 pb-0 text-center">
                                <p>{{ 'pg-print.final-warnning' | translate }}</p>
                                <ngx-qrcode value="{{urlBuilder()}}" elementType="url" cssClass="bet-qrcode"></ngx-qrcode>
                                <p>{{ 'pg-result.ticket' | translate }}: {{betId}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!--Modal Whatsapp-->
<div mdbModal #frame3="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document">
        <!--Content-->
        <div class="modal-content bg-theme">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">{{ 'pg-home.phone-number' | translate }}</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeWhatsapp()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div class="text-center" style="color: white">
                    <p>
                        {{ 'pg-home.phone-number-info' | translate }}<br />
                        ({{ 'pg-home.example' | translate }}: 5521987654321)
                    </p>
                    <input #celphone class="center-content field-size" type="text" />
                </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn class="waves-light button-bg-theme button-font sendBtn"
                    (click)="whatsappTicket(celphone.value)" mdbWavesEffect>
                    {{ 'pg-home.send' | translate }}
                </a>
                <a type="button" mdbBtn color="warning" outline="true" class="btn" mdbWavesEffect
                    (click)="closeWhatsapp()" data-dismiss="modal">{{ 'pg-home.close' | translate }}</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal Whatsapp-->

<!-- New Modal test print-->

<!--Old Modal test print
<div mdbModal #frame4="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog-scrollable modal-print" role="document">
    
        <div class="modal-content bg-theme">
            <div class="modal-header text-center">
                <h3 class="modal-title w-100 font-weight-bold" style="text-align: left;">{{ 'pg-print.ticket' | translate }}</h3>
                <button mdbBtn class="waves-light button-bg-theme button-font" style="margin-right: 20px;" (click)="print2()" mdbWavesEffect>{{ 'pg-print.print' | translate }}</button>
                
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closePrintModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body mx-3">
                <div id="print-section">
                    <div style="text-align: center; margin-top: 6vh;">
                        <img src="/assets/image/logo.png" width="30%" />
                    </div>
                    <div id="final-msg" [innerHtml]="htmlToAdd"></div>
                    <ngx-qrcode [value]="betId" elementType="url" cssClass="bet-qrcode"></ngx-qrcode>
                </div>
            </div>
            
        </div>
    </div>
</div>
 Old Modal test print-->