<div class="container">
    <div class="row">
        <!-- Card -->
        <div class="card w-100 bg-theme">

            <div class="card-body bg-theme pb-1">
                <div class="row">
                    <div class="col-12 pt-3">
                        <h3><i class="fas fa-pencil-alt" style="font-size: 25px;"></i> {{
                            'pg-jurisdiction.edit-jurisdiction' | translate}}</h3>
                        <hr />
                    </div>
                </div>
            </div>

            <!-- Card content -->
            <div class="card-body bg-theme" *ngIf="!isLoaded" id="spinner-loading">
                <!-- Loading (desapears when is loaded) -->
                <div class="justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

            <div class="card-body bg-theme pt-1" *ngIf="isLoaded">

                <div class="row">
                    <div class="col-12">
                        <div class="row mb-2" *ngIf="(editableUser.id != appComponent.userAdmin.id)">

                            <div class="col-4 text-right">{{ 'pg-new-user.jurisdiction-father' | translate }}</div>
                            <div class="col-3">
                                {{fatherName}}
                            </div>
                        </div>
                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">Username</div>
                            <div class="col-5">
                                {{editableUser.login}}
                            </div>
                        </div>
                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">Credit</div>
                            <div class="col-5">
                                {{ Currency.currencySymbol }} {{editableUser.credit * Currency.quote | currency:'':''}}
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-4 text-right">{{ 'pg-settings.password' | translate }}</div>
                            <div class="col-3">
                                <span style="cursor: pointer; color: #5394ff"
                                    (click)="frame5.show()">{{'pg-jurisdiction.change-password' | translate}}</span>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">E-mail</div>
                            <div class="col-7">
                                {{editableUser.email}}
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">E-mail 2</div>
                            <div class="col-7">
                                <input class="bg-white text-dark pl-2" type="text" matInput email2 name="email2"
                                    [(ngModel)]="editableUser.email2">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">E-mail 3</div>
                            <div class="col-7">
                                <input class="bg-white text-dark pl-2" type="text" matInput email3 name="email3"
                                    [(ngModel)]="editableUser.email3">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">{{ 'pg-settings.name' | translate }} </div>
                            <div class="col-5">
                                <input class="bg-white text-dark pl-2" type="text" matInput name name="name"
                                    [(ngModel)]="editableUser.name">
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">{{ 'pg-settings.last-name' | translate }} </div>
                            <div class="col-5">
                                <input class="bg-white text-dark pl-2" type="text" matInput lastName name="lastName"
                                    [(ngModel)]="editableUser.lastName">
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">{{ 'pg-new-user.gender' | translate }} </div>
                            <div class="col-6">
                                <mat-radio-group [(ngModel)]="selectedGender">
                                    <mat-radio-button value="Male">{{ 'pg-new-user.male' | translate }}
                                    </mat-radio-button>
                                    <mat-radio-button value="Female">{{ 'pg-new-user.female' | translate }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">{{ 'pg-new-user.birth-date' | translate }} </div>
                            <div class="col-6">
                                <mat-form-field class="no-padding">
                                    <input matInput class="birthdate-field" [matDatepicker]="birthdate"
                                        [value]="newBirth" [disabled]="true" (dateInput)="changeBirth('input', $event)">
                                    <mat-datepicker-toggle matSuffix [for]="birthdate"></mat-datepicker-toggle>
                                    <mat-datepicker #birthdate startView="multi-year" disabled="false"></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">{{ 'pg-app-component.country' | translate }} </div>
                            <div class="col-6">
                                <select style="height: 30px;" class="form-control py-0 pr-0 pl-1" country name="country"
                                    id="country-field">
                                    <option value="{{selectedCountry}}">
                                        {{selectedCountry}}
                                    </option>
                                    <option [value]="item.name" *ngFor="let item of countries">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">{{ 'pg-app-component.city' | translate }} </div>
                            <div class="col-6">
                                <input class="bg-white text-dark pl-2" type="text" matInput city name="city"
                                    [(ngModel)]="editableUser.city">
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">{{ 'pg-new-user.address' | translate }} </div>
                            <div class="col-6">
                                <input class="bg-white text-dark pl-2" type="text" matInput city name="address"
                                    [(ngModel)]="editableUser.address">
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">{{ 'pg-new-user.zip-code' | translate }} </div>
                            <div class="col-4">
                                <input class="bg-white text-dark pl-2" type="text" matInput zipcode name="zipcode"
                                    [(ngModel)]="editableUser.zipCode">
                            </div>
                        </div>

                        <div class="row mb-2" *ngIf="(editableUser.jurisdictionId < 7)">
                            <!-- User Data -->
                            <div class="col-4 text-right">{{ 'pg-new-user.commission' | translate }} </div>
                            <div class="col-2">
                                <input class="bg-white text-dark pl-2 pr-0" type="number" matInput commission
                                    name="commission" value="0" [(ngModel)]="editableUser.commission">
                            </div>
                            <div class="col-1 pl-0">
                                %
                            </div>
                        </div>

                        <div class="row mb-2" *ngIf="(editableUser.jurisdictionId < 7)">
                            <div class="col-4 text-right">
                                {{ 'pg-edit-user.friend-code' | translate}}
                            </div>
                            <div class="col-7" *ngIf="(friendCode != '')">
                                {{ friendCode }}
                            </div>
                            <div class="col-4 text-right" *ngIf="(friendCode != '')"></div>
                            <div class="col-7" *ngIf="(friendCode != '')">
                                {{ Currency.currencySymbol }} <input class="bg-white text-dark pl-2" style="width: 90px"
                                    type="text" matInput phone name="phone" [(ngModel)]="availableValue">,00
                            </div>
                            <div class="col-4 text-right" *ngIf="(friendCode != '')"></div>
                            <div class="col-7">
                                <span style="cursor: pointer; color: #5394ff" (click)="generateFriendCode()">{{
                                    'pg-jurisdiction.generate-new' | translate}}</span>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">{{ 'pg-new-user.phone' | translate }} </div>
                            <div class="col-3">
                                <input class="bg-white text-dark pl-2" type="text" matInput phone name="phone"
                                    [(ngModel)]="editableUser.phone">
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">{{ 'pg-new-user.phone' | translate }} 2</div>
                            <div class="col-3">
                                <input class="bg-white text-dark pl-2" type="text" matInput phone2 name="phone2"
                                    [(ngModel)]="editableUser.phone2">
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">{{ 'pg-new-user.cell-phone' | translate }} </div>
                            <div class="col-3">
                                <input class="bg-white text-dark pl-2" type="text" matInput cellphone name="cellphone"
                                    [(ngModel)]="editableUser.cellPhone">
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">Whatsapp</div>
                            <div class="col-3">
                                <input class="bg-white text-dark pl-2" type="text" matInput whatsapp name="whatsapp"
                                    [(ngModel)]="editableUser.whatsapp">
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">{{ 'pg-new-user.notes' | translate }} </div>
                            <div class="col-7">
                                <textarea name="notes" rows="3" [(ngModel)]="editableUser.notes"></textarea>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <!-- User Data -->
                            <div class="col-4 text-right">{{ 'pg-new-user.active' | translate }} </div>
                            <div class="col-6">
                                <input type="checkbox" name="active" [checked]="selectedAccess"
                                    [(ngModel)]="selectedAccess">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-right">
                        <button class="p-2" style="margin-right: 0px;" type="button" routerLink='../../jurisdiction'
                            mdbBtn color="danger" mdbWavesEffect>
                            {{ 'pg-jurisdiction.cancel' | translate}}</button>
                        <button class="p-2" style="margin-right: 0px;" type="submit" mdbBtn color="success"
                            mdbWavesEffect (click)="editUser()">
                            <i class="fas fa-save"></i> {{ 'pg-settings.save-changes' | translate}}</button>
                    </div>
                </div>

            </div>

        </div>
        <!-- Card -->
    </div>
</div>

<!--Modal Password-->
<div mdbModal #frame5="mdbModal" class="modal fade" scrollY="true" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content bg-theme">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">{{ 'pg-logged.change-password' | translate }}</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame5.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div class="col-12" [formGroup]="passwordForm">
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>{{ 'pg-logged.new-password' | translate }}</mat-label>
                        <input type="password" matInput style="color: white;" formControlName="password">
                        <mat-error>
                            {{ 'pg-logged.min-8' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-label>{{ 'pg-logged.confirm-password' | translate }}</mat-label>
                        <input matInput type="password" formControlName="passwordConfirm">
                        <mat-error>
                            {{ 'pg-logged.fail-password' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!--Body-->

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <button style="background-color:#5394ff; color: white;" type="submit" mdbBtn
                    [disabled]="passwordForm.invalid" mdbWavesEffect (click)="updatePassword(frame5)">{{
                    'pg-logged.modify' | translate }}</button>
                <button mdbBtn style="background-color:#5394ff; color: white;" mdbWavesEffect (click)="frame5.hide()"
                    data-dismiss="modal">{{ 'pg-jurisdiction.cancel' | translate}}</button>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal Password-->