import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-mobile-search-ticket',
  templateUrl: './mobile-search-ticket.component.html',
  styleUrls: ['./mobile-search-ticket.component.scss']
})
export class MobileSearchTicketComponent implements OnInit {

  searchCode: number;
  public activeLang;

  constructor(private appComponent: AppComponent, private translate: TranslateService, private modalsService: ModalsService) {
      this.translate.setDefaultLang(this.appComponent.activeLang);
      this.translate.setDefaultLang(this.activeLang);
    }

  ngOnInit() {
    if (localStorage.getItem("language") == null) {
      this.activeLang = 'pt';
    } else {
      this.activeLang = localStorage.getItem("language");
    }
  }

  public changeLanguage(lang) {
    this.activeLang = lang;
    this.translate.use(lang);
    // for print window.open
    localStorage.setItem('language', lang);
  }

  public searchTicket(code?: string) {

    //let ticketCode: number = +(<HTMLInputElement>document.getElementById("code-search")).value;
    let ticketCode: string;
    if (typeof(code) == 'undefined'){
      ticketCode = (<HTMLInputElement>document.getElementById("code-search")).value;
    }
    else {
      ticketCode = code;
    }
    this.appComponent.detailCoupon(ticketCode);
  }

}
