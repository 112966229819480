<!-- <div class="container">
    <div class="row">
        <div class="col-12 mb-5">-->
<div class="card-group rule-spaces">
    <!-- Card -->
    <mdb-card class="bg-theme">
        <!-- Card image -->
        <!--<img class="card-img-top" src="../../assets/image/yellow-card.jpg" alt="Regras">-->
        <!--Card content-->
        <mdb-card-body>
            <!--Title-->
            <mdb-card-title>
                <h4>
                    <i class="fas fa-clipboard-list" style="font-size: 25px;"></i> {{ 'pg-privacy-policy.title' |
                    translate }}
                </h4>
            </mdb-card-title>

            <hr>

            <mdb-card-text>
                <p>{{ 'pg-privacy-policy.intro' | translate }}</p>

                <p>{{ 'pg-privacy-policy.intro2' | translate }}</p>

                <p>{{ 'pg-privacy-policy.intro3' | translate }}</p>

                <p class="title">{{ 'pg-privacy-policy.sub-title1' | translate }}</p>

                <p>{{ 'pg-privacy-policy.info1' | translate }}</p>

                <p class="title">{{ 'pg-privacy-policy.sub-title2' | translate }}</p>

                <p>{{ 'pg-privacy-policy.info2' | translate }}</p>

                <li>{{ 'pg-privacy-policy.list-item1' | translate }}</li>

                <li>{{ 'pg-privacy-policy.list-item2' | translate }}</li>

                <li>{{ 'pg-privacy-policy.list-item3' | translate }}</li>

                <li>{{ 'pg-privacy-policy.list-item4' | translate }}</li>

                <li>{{ 'pg-privacy-policy.list-item5' | translate }}</li>

                <li>{{ 'pg-privacy-policy.list-item6' | translate }}</li>

                <p class="title">{{ 'pg-privacy-policy.sub-title3' | translate }}</p>

                <p>{{ 'pg-privacy-policy.info3' | translate }}</p>

                <p class="title">{{ 'pg-privacy-policy.sub-title4' | translate }}</p>

                <p>{{ 'pg-privacy-policy.info4' | translate }}</p>

                <li>{{ 'pg-privacy-policy.list-item7' | translate }}</li>

                <li>{{ 'pg-privacy-policy.list-item8' | translate }}</li>

                <li>{{ 'pg-privacy-policy.list-item9' | translate }}</li>

                <li>{{ 'pg-privacy-policy.list-item10' | translate }}</li>

                <p class="title">{{ 'pg-privacy-policy.sub-title5' | translate }}</p>

                <p>{{ 'pg-privacy-policy.info5' | translate }}</p>

                <p>{{ 'pg-privacy-policy.info6' | translate }}</p>
            </mdb-card-text>
        </mdb-card-body>
    </mdb-card>
</div>
<!--</div>
                 </div>
            </div> -->
