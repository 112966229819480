<div class="bg-basic_dark">
  <ul class="list-inline list-routes">
    <li id="headerHome">
      <a routerLink="/home" class="nav-link waves-light menu-style" mdbWavesEffect
        [class.active-router]="isActiveRoute('/home')">
        <img width="27px" class="mb-1" src="../assets/image/home.png" />
        {{ "menu.ReturnHome" | translate }}
      </a>
    </li>

    <li style="display: none;" id="headerHome">
      <a class="nav-link waves-light menu-style" mdbWavesEffect>
        {{ "menu.SportBetting" | translate }}
      </a>
    </li>

    <li class="elementMobile" id="headerSlot" *ngIf="skinConfig.casino">
      <a routerLink="/slot" class="nav-link waves-light menu-style" mdbWavesEffect
        [class.active-router]="isActiveRoute('/slot')">
        <img width="27px" class="mb-1" src="../assets/image/slot-machine.png" />
        {{ ("menu.slot" | translate).toUpperCase() }}
      </a>
    </li>
    <li id="headerCasssino" *ngIf="skinConfig.liveCasino">
      <a routerLink="/cassino" class="nav-link waves-light menu-style" mdbWavesEffect
        [class.active-router]="isActiveRoute('/cassino')">
        <img width="27px" class="mb-1" src="../assets/image/cards.png" />
        {{ "menu.live-casino" | translate }}
      </a>
    </li>
    <li id="headerAviator" *ngIf="skinConfig.crashGames">
      <a routerLink="/crash" class="nav-link waves-light menu-style" mdbWavesEffect
        [class.active-router]="isActiveRoute('/crash')">
        <img width="27px" class="mb-1" src="../assets/image/aviator.png" />
        CRASH GAMES
      </a>
    </li>

    <li id="headerResult">
      <a routerLink="/event-result" class="nav-link waves-light menu-style" mdbWavesEffect
        [class.active-router]="isActiveRoute('/event-result')">
        <img width="27px" class="mb-1" src="../assets/image/trophy.png" />
        {{ "menu.result" | translate }}
      </a>
    </li>
  </ul>
</div>