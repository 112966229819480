<div class="container">
    <div class="row">
        <!-- Card -->
        <div class="card w-100 bg-theme">

            <div class="card-body bg-theme pb-1">
                <div class="row">
                    <div class="col-12 pt-3">
                        <h3><i class="fas fa-user-plus" style="font-size: 25px;"></i> {{ 'pg-jurisdiction.create-user' |
                            translate}}</h3>
                        <hr />
                    </div>
                </div>
            </div>

            <!-- Card content -->
            <div class="card-body bg-theme" *ngIf="!isLoaded" id="spinner-loading">
                <!-- Loading (desapears when is loaded) -->
                <div class="justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

            <div class="card-body bg-theme pt-1" *ngIf="isLoaded">
                <form [formGroup]="createUserForm" (ngSubmit)="createUser(createUserForm.value)">
                    <div class="row">
                        <div class="col-12">
                            <div class="row mb-2">

                                <div class="col-4 text-right">{{ 'pg-new-user.choose-net' | translate }} *</div>
                                <div class="col-3">
                                    <select style="height: 30px;" class="form-control py-0 pr-0 pl-1" #jurisdictionType
                                        jurisdictionType name="jurisdictionType"
                                        (click)="jurisdictionLevelChange($event)">
                                        <option [value]="item.id" *ngFor="let item of jurisdictions">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-4">
                                    <select style="height: 30px;" class="form-control py-0 pr-0 pl-1" matInput
                                        jurisdictionFather id="jurisdiction-father" name="jurisdictionFather">
                                        <option [value]="item.id" *ngFor="let item of allowUsersList">
                                            {{item.login}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <!-- User Data -->
                                <div class="col-4 text-right">Username *</div>
                                <div class="col-5">
                                    <input class="bg-white text-dark pl-2" type="text" matInput userName name="userName"
                                        formControlName="userName" />
                                </div>
                            </div>
                            <div class="row mb-2">
                                <!-- User Data -->
                                <div class="col-4 text-right">{{ 'pg-settings.password' | translate }} *</div>
                                <div class="col-3">
                                    <input class="bg-white text-dark pl-2" type="password" matInput password
                                        formControlName="password" name="password">
                                </div>
                            </div>
                            <div class="row mb-2">
                                <!-- User Data -->
                                <div class="col-4 text-right">{{ 'pg-jurisdiction.confirm-password' | translate }} *
                                </div>
                                <div class="col-3">
                                    <input class="bg-white text-dark pl-2" type="password" matInput confirmPassword
                                        formControlName="confirmPassword" name="confirmPassword">
                                </div>
                            </div>
                            <div class="row mb-2">
                                <!-- User Data -->
                                <div class="col-4 text-right">E-mail *</div>
                                <div class="col-7">
                                    <input class="bg-white text-dark pl-2" type="text" matInput #email
                                        formControlName="email" name="email">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-accordion>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{ 'pg-new-user.optional-fields' | translate }}
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="col-12">
                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">E-mail 2</div>
                                            <div class="col-7">
                                                <input class="bg-white text-dark pl-2" type="text" matInput email2
                                                    name="email2" formControlName="email2">
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">E-mail 3</div>
                                            <div class="col-7">
                                                <input class="bg-white text-dark pl-2" type="text" matInput email3
                                                    name="email3" formControlName="email3">
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">{{ 'pg-settings.name' | translate }} </div>
                                            <div class="col-5">
                                                <input class="bg-white text-dark pl-2" type="text" matInput name
                                                    name="name" formControlName="name">
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">{{ 'pg-settings.last-name' | translate }}
                                            </div>
                                            <div class="col-5">
                                                <input class="bg-white text-dark pl-2" type="text" matInput lastName
                                                    name="lastName" formControlName="lastName">
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">{{ 'pg-new-user.gender' | translate }} </div>
                                            <div class="col-6">
                                                <mat-radio-group formControlName="gender">
                                                    <mat-radio-button value="Male">{{ 'pg-new-user.male' | translate }}
                                                    </mat-radio-button>
                                                    <mat-radio-button value="Female" class="ml-3">{{
                                                        'pg-new-user.female' | translate }}</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">{{ 'pg-new-user.birth-date' | translate }}
                                            </div>
                                            <div class="col-6">
                                                <mat-form-field class="no-padding">
                                                    <input matInput class="birthdate-field" [matDatepicker]="birthdate"
                                                        name="birthdate" formControlName="birthdate">
                                                    <mat-datepicker-toggle matSuffix [for]="birthdate">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #birthdate></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">{{ 'pg-app-component.country' | translate }}
                                            </div>
                                            <div class="col-6">
                                                <select style="height: 30px;" class="form-control py-0 pr-0 pl-1"
                                                    country name="country" formControlName="country">
                                                    <option [value]="item.name" *ngFor="let item of countries">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">{{ 'pg-app-component.city' | translate }}
                                            </div>
                                            <div class="col-6">
                                                <input class="bg-white text-dark pl-2" type="text" matInput city
                                                    name="city" formControlName="city">
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">{{ 'pg-new-user.address' | translate }} </div>
                                            <div class="col-6">
                                                <input class="bg-white text-dark pl-2" type="text" matInput city
                                                    name="address" formControlName="address">
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">{{ 'pg-new-user.zip-code' | translate }}
                                            </div>
                                            <div class="col-4">
                                                <input class="bg-white text-dark pl-2" type="text" matInput zipcode
                                                    name="zipcode" formControlName="zipcode">
                                            </div>
                                        </div>

                                        <div class="row mb-2" *ngIf="(jurisdictionType.value < 6)">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">{{ 'pg-new-user.commission' | translate }}
                                            </div>
                                            <div class="col-2">
                                                <input class="bg-white text-dark pl-2 pr-0" type="number" matInput
                                                    commission name="commission" value="0" formControlName="commission">
                                            </div>
                                            <div class="col-1 pl-0">
                                                %
                                            </div>
                                        </div>

                                        <div class="row mb-2"
                                            *ngIf="(jurisdictionType.value < 6) && (jurisdictionCoupon == '')">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">
                                                {{ 'pg-new-user.friend-code' | translate}}
                                            </div>
                                            <div class="col-8">
                                                <span style="cursor: pointer; color: #5394ff"
                                                    (click)="generateCoupon()">
                                                    {{ 'pg-jurisdiction.generate-new' | translate}}
                                                </span>
                                            </div>
                                        </div>

                                        <div class="row mb-2"
                                            *ngIf="(jurisdictionType.value < 6) && (jurisdictionCoupon != '')">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">
                                                {{ 'pg-new-user.friend-code' | translate}}
                                            </div>
                                            <div class="col-4">
                                                <span>
                                                    {{jurisdictionCoupon}}
                                                </span>
                                            </div>
                                        </div>

                                        <div class="row mb-2"
                                            *ngIf="(jurisdictionType.value < 6) && (jurisdictionCoupon != '')">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">
                                                {{ 'pg-new-user.free-credit' | translate}}
                                            </div>
                                            <div class="col-4">
                                                {{ Currency.currencySymbol }}
                                                <input id="available-value" value="0" style="width: 100px"
                                                    type="number" /> ,00
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">{{ 'pg-new-user.phone' | translate }}
                                            </div>
                                            <div class="col-3">
                                                <input class="bg-white text-dark pl-2" type="text" matInput phone
                                                    name="phone" formControlName="phone">
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">{{ 'pg-new-user.phone' | translate }} 2
                                            </div>
                                            <div class="col-3">
                                                <input class="bg-white text-dark pl-2" type="text" matInput phone2
                                                    name="phone2" formControlName="phone2">
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">{{ 'pg-new-user.cell-phone' | translate }}
                                            </div>
                                            <div class="col-3">
                                                <input class="bg-white text-dark pl-2" type="text" matInput cellphone
                                                    name="cellphone" formControlName="cellphone">
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">Whatsapp</div>
                                            <div class="col-3">
                                                <input class="bg-white text-dark pl-2" type="text" matInput whatsapp
                                                    name="whatsapp" formControlName="whatsapp">
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">{{ 'pg-new-user.notes' | translate }}
                                            </div>
                                            <div class="col-7">
                                                <textarea name="notes" rows="3" formControlName="notes"></textarea>
                                            </div>
                                        </div>

                                        <div class="row mb-2">
                                            <!-- User Data -->
                                            <div class="col-4 text-right">{{ 'pg-new-user.active' | translate }}
                                            </div>
                                            <div class="col-6">
                                                <input type="checkbox" name="active" checked formControlName="access">
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-right">
                            <button class="p-2" style="margin-right: 0px;" type="button" routerLink='../jurisdiction'
                                mdbBtn color="danger" mdbWavesEffect>
                                {{ 'pg-jurisdiction.cancel' | translate}}</button>
                            <button class="p-2" style="margin-right: 0px;" type="submit" mdbBtn color="success"
                                mdbWavesEffect>
                                <i class="fas fa-user-plus"></i> {{ 'pg-jurisdiction.create-user' | translate}}</button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
        <!-- Card -->
    </div>
</div>