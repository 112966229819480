<div class="container">

    <!-- Card -->
    <div class="card mb-5">
      <!-- Card content -->
      <div class="card-body bg-theme">
  
        <div class="row">
          <div class="col-12 pt-3">
            <h3>{{ 'pg-payment.credit-purchase' | translate }} PIX</h3>
            <hr />
            <p>
              1- {{ 'pg-pix.iten1' | translate }}
            </p>

            <p>
                2- {{ 'pg-pix.iten2' | translate }}
            </p>

            <p class="text-center">
                <img src="../../../assets/image/qrcode-key.jpg"><br/>
            </p>

            <p class="text-center">
              {{ 'pg-pix.key' | translate }}: 141e5c44-a130-4660-8a57-3af6bee6b09e
            </p>

            <p>
                3- {{ 'pg-pix.iten3' | translate }}
            </p>
          </div>
          
        </div>

        <div class="row">
            <div class="col-12 text-right">
                <button class="p-2" style="margin-right: 0px;" type="button" mdbBtn color="success" routerLink='../../home' mdbBtn cmdbWavesEffect >
                    OK
                </button>
            </div>
        </div>
  
      </div>
    </div>
    <!-- Card -->
  </div>