import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../config.service';
import { TableChoiceFacotry } from '../../../factory/TableChoice/TableChoiceFactory';
import { ShieldHelper } from '../../../Helpers/ShieldHelper';
import { DetailCoupon } from '../../../Interfaces/DetailCoupon/DetailCoupon.interface';
import { BetService } from '../../../services/bet-service/bet.service';
import { ModalsService } from '../../../services/modals/modals.service';
import { IBet } from '../../../Interfaces/Bet.interface';

@Component({
  selector: 'app-detail-coupon',
  templateUrl: './detail-coupon.component.html',
  styleUrls: ['./detail-coupon.component.scss']
})
export class DetailCouponComponent implements OnInit {
  @Input() id: number;
  @Input() uuid: string;

  detail: DetailCoupon = {
    bet: <IBet>{},
    winner: false,
    roundResult: "",
    roundName: '',
    roundType: 0,
    roundSportId: 0,
    roundPosted: false,
    events: [],
    matches: []
  };

  details: DetailCoupon[] = [];

  multipleBets: boolean = true;

  isVisible: boolean = false;
  tableOfChoices: string = '';
  canceled: number = 0;
  numberEventsCanceled: number = 0;

  constructor(private translate: TranslateService, private betService: BetService, public datePipe: DatePipe,
    public configService: ConfigService, private modalsService: ModalsService, private tableChoiceFacotry: TableChoiceFacotry) {
    this.modalsService.registerDetailsTicketModal(this);
  }

  ngOnInit(): void {
    // this.open(0, '616de6f4');
  }

  open(id: number, uuid: string | null) {
    this.betService.getBetDetail(id, uuid == undefined ? "" : uuid)
      .subscribe(response => {
        this.details = response;
        if (response.length > 1) {
          this.multipleBets = true;
          this.isVisible = true;
        }else{
          this.detail = response[0];
          this.renderTableOfChoices();
          this.multipleBets = false;
        }
      }, error => {

      });
  }

  closeModal() {
    this.isVisible = false;
  }

  selectCoupon(detail: DetailCoupon){
    this.detail = detail;
    this.renderTableOfChoices();
    this.multipleBets = false;
  }

  checkWinner(winner: boolean) {
    return winner ? "Bet won" : "Lost Bet";
  }

  getBasicShield() {
    return ShieldHelper.getChampionshipLogos(this.detail.roundName);
  }

  getSingleMatchShield(number) {
    return ShieldHelper.getSingleMatchShield(this.detail.roundName, number);
  }

  renderTableOfChoices() {
    this.tableOfChoices = this.tableChoiceFacotry.GenerateTable(this.detail);

    this.isVisible = true;
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section2')!.innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>PoolBet365</title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
          <style>
            body {
              font-family: Roboto, "Helvetica Neue", sans-serif;
              font-size: 13px;
            }

            #print-section2{
              margin: 0;
              width: 50%;
              max-height: 100vh;
            }

            .div-information-confirmation-ticket{
              padding: .15rem;
              display: flex;
              flex: row;
              flex-wrap: nowrap;
              width: 30%;
            }

            .bg-theme-grey{}

            .event-name-Confirmation{
              width: 50%;
              padding: 0.3rem;
              font-size: 13px;
            }

            .event-Choices-Confirmation{
              display: none;
            }

            .active-Confirmation-Choice{
              display: block !important;
              width: 14%;
              border-radius: 5px;
              color: #1b1b1b;
              margin: auto auto auto auto;
              font-weight: bold;
              text-align: center;
              padding: .25rem;
              font-size: 13px;
            }

            .bg-none{
              background-color: rgba(0, 0, 0, 0) !important;
            }

            .bg_green-text_white{
              background-color: rgba($color: #000, $alpha: 0) !important;
            }

            .style-flex-resume-cupom{
              display: flex !important;
              flex-direction: row !important;
              flex-wrap: nowrap !important;
              width: 80%;
              margin-left: 10%;
            }

            .modal-detail-legend{
              font-size: 15px;
              display: flex;
              justify-content: space-between;
              width: 25%;
              padding: .15rem;
            }

            .cancel-choice-event{
              width: 17% !important;
              margin: auto auto;
            }

            /*@page {
              size: auto;
              margin: 10;
            }
            @media print {
              html, body {
                width: 210mm;
                height: 297mm;
              }*/
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
}
