import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_LOCALE, MatCheckboxModule, MatSelectModule } from '@angular/material';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from "@angular/router";
import { NgxPayPalModule } from "ngx-paypal";
//Translation
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgxQRCodeModule } from 'ngx-qrcode2';

import { MaterialModule } from './material.module';

import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { AppComponent } from './app.component';
import { ResultComponent } from './result/result.component';
import { HomeComponent } from './home/home.component';
import { MobileHomeComponent } from './mobile-home/mobile-home.component';
import { TicketComponent } from './ticket/ticket.component';
import { RuleComponent } from './rule/rule.component';
import { ContactComponent } from './contact/contact.component';
import { AdminComponent } from './admin/admin.component';
import { RoundComponent } from './round/round.component';
import { PostResultComponent } from './post-result/post-result.component';
import { JackpotComponent } from './jackpot/jackpot.component';
import { Payment } from './payment/payment.component';
import { ManageTicketComponent } from './manage-ticket/manage-ticket.component';
import { ReportComponent } from './report/report.component';
import { JurisdictionComponent } from './jurisdiction/jurisdiction.component';
import { CreditTransferComponent } from './credit-transfer/credit-transfer.component';
import { SettingsComponent } from './settings/settings.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { InteractionService } from './interaction.service';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderService } from '../services/loader.service';
import { LoaderInterceptor } from '../../src/services/loader.interceptor';

import { ConfigService } from './config.service';
import { PrintComponent } from './print/print.component';
import { MessengerComponent } from './components/shared/messenger/messenger.component';
import { MessengerService } from  '../../src/services/messenger.service';
import { DataSource } from '@oasisdigital/angular-material-search-select';

import localePtBr from '@angular/common/locales/pt';

import { registerLocaleData } from '@angular/common';
import { RoundgroupsComponent } from './components/roundgroups/roundgroups.component';
import { MobileRoundgroupsComponent } from './components/mobile-roundgroups/mobile-roundgroups.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { LoggedComponent } from './logged/logged.component';
import { BannersComponent } from './banners/banners.component';
import { ManageGroupComponent } from './manage-group/manage-group.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { SearchTicketComponent } from './components/search-ticket/search-ticket.component';
import { MobileSearchTicketComponent } from './components/mobile-search-ticket/mobile-search-ticket.component';
import { from } from 'rxjs';
import { ApiComponent } from './api/api.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { TicketPanelComponent } from './components/ticket-panel/ticket-panel.component';
import { CurrencyQuoteComponent } from './components/currency-quote/currency-quote.component';
import { MobileCurrencyQuoteComponent } from './components/mobile-currency-quote/mobile-currency-quote.component';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { CartBadgeComponent } from './components/cart-badge/cart-badge.component';
import { UploadDocComponent } from './upload-doc/upload-doc.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RecoveredJackpotDetailsComponent } from './components/recovered-jackpot-details/recovered-jackpot-details.component';
import { TicketModalComponent } from './components/shared/ticket-modal/ticket-modal.component';
import { NewUserComponent } from './jurisdiction/new-user/new-user.component';
import { EditUserComponent } from './jurisdiction/edit-user/edit-user.component';
import { PixComponent } from './payment/pix/pix.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { GlobalTermsComponent } from './global-terms/global-terms.component';
import { StreamdataComponent } from './components/streamdata/streamdata.component';
import { LiveMatchesComponent } from './live-matches/live-matches.component';

// import { BetGridComponent } from './components/bet-grid/bet-grid.component';

import { RefCodeComponent } from './ref-code/ref-code.component';
import { SearchTicketMobileComponent } from './components/search-ticket-mobile/search-ticket-mobile.component';
import { WorkTogetherComponent } from './work-together/work-together.component';
import { EventResultComponent } from './result/event-result/event-result.component';
import { PokerComponent } from '../app/poker/poker.component';
import { RiseUpComponent } from './slot/rise-up.component';
import { CassinoComponent } from './cassino/cassino.component';
import { CrashGameComponent } from './crash-game/crash-game.component';
import { BetListComponent } from './result/bet-list/bet-list.component';
import { SportbookComponent } from './sportbook/sportbook.component';
import { SideMenuComponent } from './components/side-menu/side-menu/side-menu.component';
import { NewbetgridComponent } from './components/newbetgrid/newbetgrid.component';
import { ApiLobbyComponent } from './components/api-lobby/api-lobby';
import { MessageStandardComponent } from './components/modals/message-standard/message-standard.component';
import { ModalsService } from './services/modals/modals.service';
import { DetailCouponComponent } from './components/modals/detail-coupon/detail-coupon.component';
import { FooterComponent } from './components/footer/footer.component';
import { UserActionsComponent } from './components/modals/user-actions/user-actions.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ErrorComponent } from './auth/error/error.component';
import { AuthGuard } from './auth/auth.guard';


registerLocaleData(localePtBr);

@NgModule({
  declarations: [
    AppComponent,
    ResultComponent,
    HomeComponent,
    MobileHomeComponent,
    TicketComponent,
    RuleComponent,
    ContactComponent,
    AdminComponent,
    RoundComponent,
    PostResultComponent,
    JackpotComponent,
    Payment,
    ManageTicketComponent,
    ReportComponent,
    JurisdictionComponent,
    CreditTransferComponent,
    SettingsComponent,
    LoaderComponent,
    PrintComponent,
    MessengerComponent,
    RoundgroupsComponent,
    MobileRoundgroupsComponent,
    TransactionsComponent,
    LoggedComponent,
    BannersComponent,
    ManageGroupComponent,
    SearchTicketComponent,
    MobileSearchTicketComponent,
    ApiComponent,
    CheckoutComponent,
    TicketPanelComponent,
    TicketModalComponent,
    CurrencyQuoteComponent,
    MobileCurrencyQuoteComponent,
    CartBadgeComponent,
    UploadDocComponent,
    UserDetailComponent,
    RecoveredJackpotDetailsComponent,
    NewUserComponent,
    EditUserComponent,
    PixComponent,
    PrivacyPolicyComponent,
    GlobalTermsComponent,
    StreamdataComponent,
    LiveMatchesComponent,
    RefCodeComponent,
    SearchTicketMobileComponent,
    WorkTogetherComponent,
    EventResultComponent,
    PokerComponent,
    RiseUpComponent,
    CassinoComponent,
    CrashGameComponent,
    BetListComponent,
    SportbookComponent,
    SideMenuComponent,
    NewbetgridComponent,
    ApiLobbyComponent,
    MessageStandardComponent,
    DetailCouponComponent,
    FooterComponent,
    UserActionsComponent,
    NavBarComponent,
    ErrorComponent,

  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    NgxPayPalModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      }
    }),
    MDBBootstrapModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxQRCodeModule,
    RouterModule.forRoot([
      { 
        path: "", canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: 
        [
          { path: 'home', component: HomeComponent },
          { path: 'home/:roundId', component: HomeComponent },
          // { path: 'result', component: ResultComponent },
          { path: 'result/:roundId', component: ResultComponent },
          { path: 'bet-list/:roundId', component: BetListComponent },
          { path: 'event-result', component: EventResultComponent },
          { path: 'rule', component: RuleComponent },
          { path: 'privacy-policy', component: PrivacyPolicyComponent },
          { path: 'work-together', component: WorkTogetherComponent },
          { path: 'global-terms', component: GlobalTermsComponent },
          { path: 'contact', component: ContactComponent },
          { path: 'poker', component: PokerComponent },
          { path: 'slot', component: RiseUpComponent },
          { path: 'cassino', component: CassinoComponent },
          { path: 'crash', component: CrashGameComponent },
          { path: 'admin', component: AdminComponent },
          //{ path: 'round', component: RoundComponent }, <---
          //{ path: 'post-result', component: PostResultComponent }, <---
          //{ path: 'jackpot', component: JackpotComponent }, <---
          { path: 'payment', component: Payment },
          { path: 'pix/:value', component: PixComponent },
          { path: 'upload-doc', component: UploadDocComponent },
          { path: 'manage-ticket', component: ManageTicketComponent },
          { path: 'ticket', component: TicketComponent },
          //{ path: 'report', component: ReportComponent }, <---
          { path: 'jurisdiction', component: JurisdictionComponent },
          { path: 'new-user', component: NewUserComponent },
          { path: 'edit-user/:userId', component: EditUserComponent },
          { path: 'credit-transfer', component: CreditTransferComponent },
          { path: 'settings', component: SettingsComponent },
          { path: 'live-stream/:id/:spId/:type', component: LiveMatchesComponent },
          //{ path: 'live-stream/:id/:spId', component: LiveMatchesComponent },
          { path: 'print/:betId/:operator/:roundId', component: PrintComponent },
          //{ path: 'messenger', component: MessengerComponent }, <---
          { path: 'transactions', component: TransactionsComponent },
          { path: 'banners', component: BannersComponent },
          { path: 'manage-group', component: ManageGroupComponent },
          { path: 'checkout', component: CheckoutComponent },
          { path: 'logged/:key', component: LoggedComponent },
          { path: 'home/refcode/:code', component: HomeComponent },
          //{ path: 'ticket', component: TicketComponent }, // Obs.: Aponta para a pagina de /ticket
          { path: 'api/:login/:email/:credit/:parentId/:visitorId/:key', component: ApiComponent },
          // { path: 'sportbook', component: SportbookComponent},
          { path: '', redirectTo: '/home', pathMatch: 'full' },
          { path: 'lobby/:login/:email/:credit/:parentId/:visitorId/:key', component: ApiLobbyComponent },
          { path: "error", component: ErrorComponent }
        ]
      }
    ])
  ],
  entryComponents: [UserDetailComponent],
  exports: [UserDetailComponent],
  providers: [
    ModalsService,
    NgxSpinnerService,
    ConfigService,
    BnNgIdleService,
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: "pt-BR"
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    MessengerService,
    InteractionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
