import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { Round } from '../round.interface';
import { IBet } from '../Interfaces/Bet.interface';
import { User } from '../user.interface';
import { Currency } from '../currency.interface';
import { ConfigService } from '../config.service';
import { AppComponent } from '../app.component';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { InteractionService } from 'src/app/interaction.service';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent, MatInput, MatSort } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { IGetMyBetsResponse } from '../Interfaces/ManageTicket/GetMyBetsResponse.interface';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalsService } from '../services/modals/modals.service';
import { UserCookieService } from '../services/User/UserCookie.service';
@Component({
  selector: 'app-manage-ticket',
  templateUrl: './manage-ticket.component.html',
  styleUrls: ['./manage-ticket.component.scss']
})
export class ManageTicketComponent implements OnInit, OnDestroy {
  @ViewChild('frame4', { static: true }) modalFilter: ModalDirective;

  //DESKTOP TABLE
  @ViewChild('datePikerInput', { read: MatInput }) datePikerInput: MatInput;
  @ViewChild('todatePikerInput', { read: MatInput }) todatePikerInput: MatInput;
  desktopDisplayedColumns: string[] = ['date', 'description', 'player', 'quantity', 'bet', 'gain', 'ticket'];
  @ViewChild('desktopPaginator') desktopPaginator: MatPaginator;
  @ViewChild('sortDesktop') sortDesktop: MatSort;

  //MOBILE TABLE
  @ViewChild('mobileDatePikerInput', { read: MatInput }) MobileDatePikerInput: MatInput;
  @ViewChild('mobileTodatePikerInput', { read: MatInput }) MobileTodatePikerInput: MatInput;
  @ViewChild('sortMobile') sortMobile: MatSort;
  mobileDisplayedColumns: string[] = ['date', 'description', 'bet', 'ticket']
  @ViewChild('mobilePaginator') mobilePaginator: MatPaginator;

  dataSource = new MatTableDataSource<IGetMyBetsResponse>([]);

  private subscription: Subscription;


  bets: IGetMyBetsResponse[] = [];
  isLoaded: boolean = false;
  Currency: Currency = <Currency>{};
  filterCriteria: {
    search: string;
    betradarSportName: string;
    startDate: Date;
    endDate: Date;
  } = {
      search: '',
      betradarSportName: '',
      startDate: null,
      endDate: null,
    };

  typeSelector: string[] = [];
  selectedBetradarSport: string = '';

  isMobile: boolean = false;
  constructor(private interactionService: InteractionService, private configService: ConfigService, private appComponent: AppComponent,
    private translate: TranslateService, private router: Router, private datePipe: DatePipe, private modalsService: ModalsService,
    private userCookieService: UserCookieService) { }

  ngAfterViewInit() {

  }

  ngOnInit() {
    this.interactionService.getResponsiveMainApp().subscribe(isManageTicket => {
      if (isManageTicket) {
        const mainContainer = document.getElementById("colun13");
        mainContainer?.classList.add("principal-responsivity");
      }
    });

    const userId = this.userCookieService.getUserId();
    if (window.innerWidth >= 990) {

      this.isMobile = false;
      this.dataSource.paginator = this.desktopPaginator;
      this.getMyBetsDesktop(userId);
    } else {

      this.isMobile = true;
      this.dataSource.paginator = this.mobilePaginator;
      this.getMyBetsMobile(userId);
    }
    this.translate.setDefaultLang(this.appComponent.activeLang);
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  goHome() { // back button 
    this.router.navigate(['/']);
  }

  getMyBetsDesktop(userId: number) {
    this.subscription = this.configService.getMyBets(userId)
      .subscribe(data => {
        this.bets = this.betAnalysis(data).sort((a, b) => { return a.betTime > b.betTime ? -1 : 1 });
        this.typeSelector = [...new Set(data.map(bet => bet.betradarSportName).filter(sportName => sportName))];
        this.dataSource.data = this.bets;
        this.dataSource.paginator = this.desktopPaginator;
        this.dataSource.sort = this.sortDesktop;
        this.isLoaded = true;
      })
  }

  getMyBetsMobile(userId: number) {
    this.subscription = this.configService.getMyBets(userId)
      .subscribe(data => {
        this.bets = this.betAnalysis(data).sort((a, b) => { return a.betTime > b.betTime ? -1 : 1 });
        this.typeSelector = [...new Set(data.map(bet => bet.betradarSportName).filter(sportName => sportName))];
        this.dataSource.data = this.bets;
        this.dataSource.paginator = this.mobilePaginator;
        this.dataSource.sort = this.sortMobile;
        this.isLoaded = true;
      })
  }

  betAnalysis(data: IGetMyBetsResponse[]) {
    return data.map(x => {
      switch (x.betStatus) {
        case 0:
          x.betStatusString = "credit.refundbet"
          x.betStatusColor = "#FFFFFF"
          x.prizeWinnerValue = x.betValue
          break;
        case 1:
          if (x.prizeWinnerValue > 0.0) {
            x.betStatusString = "pg-transactions.bet-win"
            x.betStatusColor = "#56F78C"
          } else {
            x.betStatusString = "pg-transactions.lose-bet"
            x.betStatusColor = "#F75656"
          }
          break;
        case 2:
          x.betStatusString = "credit.betWaiting"
          x.betStatusColor = "#FCFF70"
          break;
      }
      return x;
    });
  }

  getEmblems(roundName: string[]) {
    const objResponse = {
      home: null,
      away: null
    }
    for (let index = 0; index < roundName.length; index++) {
      const element = roundName[index];
      if (index == 0) {
        objResponse.home = 'https://poolbet365.com/assets/shields/' + roundName[index]
      } else {
        objResponse.away = 'https://poolbet365.com/assets/shields/' + roundName[index]
      }
    }

    return objResponse;
  }

  applyFilters() {
    let filteredData = this.bets;

    if (this.selectedBetradarSport) {
      filteredData = filteredData.filter(bet =>
        bet.betradarSportName === this.selectedBetradarSport
      );
    }

    if (this.filterCriteria.search) {
      filteredData = filteredData.filter(bet =>
        bet.roundName.toLowerCase().includes(this.filterCriteria.search.toLowerCase())
      );
    }

    if (this.filterCriteria.startDate) {
      filteredData = filteredData.filter(bet => {
        const betDate = new Date(bet.betTime);
        return betDate >= this.filterCriteria.startDate;
      });
    }

    if (this.filterCriteria.endDate) {
      filteredData = filteredData.filter(bet => {
        const betDate = new Date(bet.betTime);
        const endDate = new Date(this.filterCriteria.endDate);
        endDate.setDate(endDate.getDate() + 1);
        return betDate < endDate;
      });
    }

    this.dataSource.data = filteredData;
    this.isMobile ? this.dataSource.paginator = this.mobilePaginator : this.dataSource.paginator = this.desktopPaginator;
  }
  openFilterModal() { // filter modal
    this.modalFilter.show()
  }
  changeDate(event: MatDatepickerInputEvent<Date>, type: string): void {
    if (type === 'start') {
      this.filterCriteria.startDate = event.value;
    } else if (type === 'end') {
      this.filterCriteria.endDate = event.value;
    }
  }

  clearFilter() { // filter
    this.filterCriteria = {
      search: '',
      betradarSportName: '',
      startDate: null,
      endDate: null,
    };

    this.selectedBetradarSport = '';

    this.dataSource.data = this.bets;

    this.isMobile ? this.dataSource.paginator = this.mobilePaginator : this.dataSource.paginator = this.desktopPaginator;
  }

  detailCoupon(ticketCode) {
    this.modalsService.openDetailTickeModal(ticketCode, "");
  }

  AdaptedDate(date): string {
    let minutes = parseInt(localStorage.getItem('minute'), 10);
    let dateTime = new Date(date).getTime() + (minutes * 60000);
    return `${this.datePipe.transform(dateTime, 'short')}`
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    const mainContainer = document.getElementById("colun13");
    mainContainer.classList.remove("principal-responsivity");
  }
}