<div class="row mt-2 rowlajust mobile-hide" style="background-color: #3d3d3d;">
    <div class="col-12 p-0">
        <div class="side-menu">{{ 'comp-search-ticket.search-ticket' | translate }}</div>
        <div class="serch-ticket">
            <form class="form-inline" (ngSubmit)="searchTicket()">
                <div class="row">
                    <div class="col-9 mb-2">
                        <!--<input id="code-search" class="form-control mr-sm-2 code-field" type="text" required />-->
                        <input type="text" id="code-search" class="ml-2" required />
                    </div>
                    <div class="col-3 mb-2">
                        <div class="bloom" (click)="searchTicket()">
                            <mat-icon matSuffix>search</mat-icon>
                        </div>
                        <!--<button type="button" mdbBtn class="search-button mr-2" (click)="searchTicket()">
                            <mat-icon matSuffix>search</mat-icon>
                            {{ 'comp-search-ticket.search' | translate }}
                        </button>-->
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>