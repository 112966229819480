import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from '../config.service';
import { RoundGroup } from '../round-group.interface';
import { AppComponent } from '../app.component';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ModalDirective } from 'angular-bootstrap-md';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from '../services/modals/modals.service';

interface FoodNode {
  name: string;
  id: number;
  childrens?: FoodNode[];
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-manage-group',
  templateUrl: './manage-group.component.html',
  styleUrls: ['./manage-group.component.scss']
})
export class ManageGroupComponent implements OnInit {
  @ViewChild('frame', { static: true }) modalEdit: ModalDirective;
  @ViewChild('frame2', { static: true }) modalDelete: ModalDirective;

  private _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.childrens && node.childrens.length > 0,
      name: node.name,
      id: node.id,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.childrens);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  treeRoundGroups: RoundGroup[] = [];
  listRoundGroups: RoundGroup[] = [];
  newGroup: string = "";
  jurisdictionFather: number = 0;
  isLoaded: boolean = false;

  currentGroup: RoundGroup; // used to edit or delete a group
  changeName = "";

  constructor(private configService: ConfigService, private appComponent: AppComponent,
    private translate: TranslateService,
    private modalsService: ModalsService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
    //this.dataSource.data = TREE_DATA;
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  ngOnInit() {
    this.getRoundGroups();
  }

  getListRoundGroups() {
    this.configService.getListedRoundGroups()
      .subscribe(data => {
        this.listRoundGroups = data;
        this.isLoaded = true;
        document.getElementById("spinner-loading").classList.add("hidden");
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-manage-group.operation-fail"), this.translate.instant("pg-manage-group.load-fail-info"), true);
        console.log(error);
      });
  }

  createGroup() {
    this.configService.addRoundGroup(this.newGroup, this.jurisdictionFather)
      .subscribe(data => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-manage-group.successfully-done"), this.translate.instant("pg-manage-group.group-created-info"), false);
        this.ngOnInit();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-manage-group.operation-fail"), this.translate.instant("pg-manage-group.general-fail-info"), true);
      })
  }

  deleteGroup(groupId: number) {
    this.currentGroup = this.listRoundGroups.find(x => x.id == groupId);
    this.changeName = this.currentGroup.name;

    this.modalDelete.show();
  }

  confirmDelete() {
    this.modalDelete.hide();

    this.configService.deleteRoundGroup(this.currentGroup.id)
      .subscribe(data => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-manage-group.successfully-done"), this.translate.instant("pg-manage-group.delete-done"), false);
        this.ngOnInit();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-manage-group.operation-fail"), this.translate.instant("pg-manage-group.delete-fail-info"), true);
        console.log(error);
      })
  }

  editGroup(groupId: number) {

    this.currentGroup = this.listRoundGroups.find(x => x.id == groupId);
    this.changeName = this.currentGroup.name;

    this.modalEdit.show();
  }

  saveEdit() {
    this.modalEdit.hide();
    this.currentGroup.name = this.changeName;

    this.configService.editRoundGroup(this.currentGroup)
      .subscribe(data => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-manage-group.successfully-done"), this.translate.instant("pg-manage-group.edit-success-info"), false);
        this.ngOnInit();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-manage-group.operation-fail"), this.translate.instant("pg-manage-group.general-fail-info"), true);
        console.log(error);
      });
  }
  getRoundGroups() {
    this.configService.getAllRoundGroups()
      .subscribe(data => {
        this.treeRoundGroups = data;
        this.dataSource.data = this.treeRoundGroups;
        this.getListRoundGroups();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-manage-group.operation-fail"), this.translate.instant("pg-manage-group.general-fail-info"), true);
        console.log(error);
      });
  }

}
