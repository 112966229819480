<div class="container desktop-hide">
  <div id="spinner-loading2" *ngIf="isLoadingGames">
    <div class="col-12" style="position: fixed; top: 50vh;left: 50vw;">
      <!-- Loading (desapears when is loaded) -->
      <div class="justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!isLoadingGames">
    <div class="card-body bg-theme mobile-width-adjust">
      <h1 class="title">
        {{ 'pg-event-result.event-result' | translate }}
      </h1>
      <div class="container d-flex flex-column mb-2 p-2">
        <p class="datepicker-label">{{ 'pg-event-result.date' | translate }}:</p>
        <div class="d-flex">
          <input [(ngModel)]="dateStart" type="date" class="datepicker datepicker-mobile" name="dateStart">
          <input [(ngModel)]="dateEnd" type="date" class="datepicker datepicker-mobile ml-1 mr-1" name="dateEnd">
          <div style="margin-top: -8%;">
            <p class="type-label">{{ 'pg-event-result.type' | translate }}:</p>
            <select [(ngModel)]="type" type="text" name="typefilter" id="typefilter-mobile">
              <option value="all">{{ 'pg-event-result.all' | translate }}</option>
              <option value="Soccer">{{ 'Soccer' | translate }}</option>
              <option value="formula 1">{{ 'Formula 1' | translate }} </option>
              <option value="Basketball">{{ 'Basketball' | translate }}</option>
              <option value="Motorcycle Racing">{{ 'Motorcicle Racing' | translate }}</option>
            </select>
          </div>
        </div>
        <div class="d-flex flex-column mt-2">
          <p class="searchSport-label">{{ 'pg-event-result.name-event' | translate }}:</p>
          <div class="d-flex">
            <input class="mr-1" [(ngModel)]="nameEvent" type="text" name="searchSport" id="searchSport-mobile">
            <button (click)="searchDate(dateStart, dateEnd, type, nameEvent)" type="submit" class="btnSearch-mobile"
              value="Submit">{{ 'pg-app-component.search' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-theme mobile-width-adjust">
      
      <div class="resultFilter-mobile" >
        <div *ngFor="let element of suportFilter" (click)="openRound(element)" class="events">

          <div class="d-flex flex-row flex-nowrap pl-2 pb-1" style="background-color: #141414;">

            <div style="width: 24% !important; margin: auto 0 !important; height: fit-content !important;">
              <div class="typeStyle d-flex statusText justify-content-center" [ngClass]="handleSportClasses(element)">
                <i class="fa fa-circle mr-1 mt-auto mb-auto" style="font-size: 7px;"></i>
                <p class="mt-auto mb-auto" style="font-size:9px; color: white;"
                  [innerHtml]="statusMessagerMobile(element)">
                </p>
              </div>
            </div>


            <div class="">
              <span class="eventName" *ngIf="element.singleMatch != 0 || element.jj != 0 " style="font-size: 10px;">
                <img src="{{setMatchShield(element.name, 0)}}" class="mr-1"
                  onerror="this.onerror=null;this.src='https://poolbet365.com/assets/shields/default-logo.png';"
                  width="27px">
                {{element.name}}
                <img src="{{setMatchShield(element.name, 1)}}" class="ml-1"
                  onerror="this.onerror=null;this.src='https://poolbet365.com/assets/shields/default-logo.png';"
                  width="27px">
              </span>

              <span class="eventName" *ngIf="element.singleMatch == 0 && element.jj == 0" style="font-size: 10 px;">
                <img src="{{getMatchShield(element.name)}}" class="mr-1"
                  onerror="this.onerror=null;this.src='https://poolbet365.com/assets/shields/default-logo.png';"
                  width="27px">
                {{element.name}}
              </span>
            </div>

          </div>

          <div class="pt-1" style="background-color: #0a0808;">
            <div class="d-flex flex-row flex-nowrap mt-2 mb-1">

              <div class="w-50 pl-2" style="font-size: 11px;">

                <div class="d-flex mb-1">
                  <span>{{ 'pg-event-result.total-winners' | translate }}: &nbsp;</span>
                  <span *ngIf="element.posted" class="hits">{{element.qtdWinners}}</span>
                  <span *ngIf="!element.posted">--</span>
                </div>

                <div class="d-flex">
                  <span> {{ 'pg-event-result.total-hits' | translate }}: &nbsp;</span>
                  <span *ngIf="element.posted" class="hits">{{element.totalhits}}</span>
                  <span *ngIf="!element.posted">--</span>
                </div>
              </div>

              <div class="d-flex mt-auto mb-auto w-50 pl-2 pt-auto pb-auto" style="font-size: 16px;"
                *ngIf="element.posted">
                <span>{{ 'pg-event-result.paid-prize' | translate }}: &nbsp;</span>
                <span class="hits">{{element.totalprize}}</span>
              </div>

            </div>

            <div class="d-flex flex-row flex-wrap pl-2 pb-1" style="background-color: #141414;">
              <div class="mr-3">
                <div class="datastyle" style="font-size: 10px;">
                  {{element.dateStart | date:'mediumDate'}}
                </div>
              </div>

              <div class="pt-auto pb-auto">
                <!-- <span (click)="openBetList(element.id)" class="sportsBox qtdPlayers mr-2">
                  {{element.players}} {{ 'pg-event-result.participants' | translate }}
                </span> -->

                <span (click)="openBetList(element.id)" class="sportsBox qtdPlayers mr-2">
                  {{ 'pg-event-result.participants' | translate }}
                </span>

                <span class="sportsBox mr-2" *ngIf="element.singleMatch == 0 && element.jj == 0">
                  {{ 'Round' | translate}} {{element.roundNumber}}
                </span>

                <span class="sportsBox">
                  {{element.sport}}
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>

<div class="container mobile-hide">
  <div class="row">
    <div id="spinner-loading2" *ngIf="isLoadingGames">
      <div class="col-12" style="position: fixed; top: 50vh;left: 50vw;">
        <!-- Loading (desapears when is loaded) -->
        <div class="justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body bg-theme" *ngIf="!isLoadingGames" style="width: 80vw;">
      <form>
        <div class="d-flex container mb-2 p-2">
          <div class="col-5">
            <label class="p-0 m-0" for="searchSport">{{ 'pg-event-result.name-event' | translate }}:</label> <br />
            <div class="d-flex">
              <input class="mr-1 searchSportAdjust" [(ngModel)]="nameEvent" type="text" name="searchSport">
              <button (click)="searchDate(dateStart, dateEnd, type, nameEvent)" type="submit" class="btnSearch"
                value="Submit">
                <i class=material-icons>search</i>
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="w-100">
        <div *ngFor="let element of suportFilter" (click)="openRound(element)" class="events w-100 mt-2 event-overlay">
          <div class="d-flex pb-2" style="background-color: #221f1f;">
            <div class="d-flex w-75 ml-2">
              <div style="width: 23% !important;">
                <div class="typeStyle d-flex statusText justify-content-center" [ngClass]="handleSportClasses(element)">
                  <i class="fa fa-circle mr-1 mt-auto mb-auto" style="font-size: 8px"></i>
                  <div class="mt-auto mb-auto" style="color: whitesmoke;" [innerHtml]="statusMessager(element)">

                  </div>
                </div>
              </div>


              <span class="eventName mt-auto mb-auto" *ngIf="element.singleMatch != 0 || element.jj != 0 "
                style="font-size: 19px;">
                <img src="{{setMatchShield(element.name, 0)}}"
                  onerror="this.onerror=null;this.src='https://poolbet365.com/assets/shields/default-logo.png';"
                  width="27px">
                {{element.name}}
                <img src="{{setMatchShield(element.name, 1)}}"
                  onerror="this.onerror=null;this.src='https://poolbet365.com/assets/shields/default-logo.png';"
                  width="27px">

              </span>
              <span class="eventName mt-auto mb-auto" *ngIf="element.singleMatch == 0 && element.jj == 0"
                style="font-size: 19px;">
                <img src="{{getMatchShield(element.name)}}"
                  onerror="this.onerror=null;this.src='https://poolbet365.com/assets/shields/default-logo.png';"
                  width="27px">
                {{element.name}}
              </span>
            </div>
            <div class="d-flex mt-auto mb-auto" style="font-size: 19px;" *ngIf="element.posted">
              <span>{{ 'pg-event-result.paid-prize' | translate }}: &nbsp;</span>
              <span class="hits">{{element.totalprize}}</span>
            </div>
          </div>

          <div class="d-flex pt-1 pb-1" style="background-color: #181717;">
            <div class="d-flex justify-content-between date-text content-adjust ml-2 mr-2 mt-auto mb-auto"
              style="font-size: 16px;">{{element.dateStart | date:'mediumDate'}} </div>

            <!-- <span (click)="openBetList(element.id)" class="sportsBox qtdPlayers mr-2">{{element.players}} {{ 'pg-event-result.participants' | translate }}</span> -->
            <span (click)="openBetList(element.id)" class="sportsBox qtdPlayers mr-2">{{
              'pg-event-result.participants' | translate }}</span>

            <span class="mr-2 sportsBox" *ngIf="element.singleMatch == 0 && element.jj == 0">{{ 'Round' | translate}}
              {{element.roundNumber}}</span>

            <span class="mr-2 sportsBox">{{element.sport}}</span>

            <div class="d-flex mr-2 sportsBox">
              <span>{{ 'pg-event-result.total-winners' | translate }}: &nbsp;</span>
              <span *ngIf="element.posted" class="hits">{{element.qtdWinners}}</span>
              <span *ngIf="!element.posted">--</span>
            </div>

            <div class="d-flex sportsBox">
              <span> {{ 'pg-event-result.total-hits' | translate }}: &nbsp;</span>
              <span *ngIf="element.posted" class="hits">{{element.totalhits}}</span>
              <span *ngIf="!element.posted">--</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 
  PORTUGUÊS: REMOVER COMENTÁRIO PARA ABRIR O MODAL AO CLICAR ME "APOSTE AGORA" E "RESULTADO EM BREVE"
  ENGLISH: REMOVE COMMENT TO OPEN THE MODAL BY CLICKING ME "BET NOW" AND "RESULT SOON"
<div mdbModal #frame="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
  <div class="modal-dialog" role="document">
    <div class="modal-content bg-theme">
      <div class="modal-header text-center" style="background-color: #e93f3f; border-bottom: none;">
        <h4 class="modal-title w-100 font-weight-bold">{{ 'pg-event-result.no-result' | translate }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3">
          {{ 'pg-event-result.no-result' | translate }}. <br/>
          <a class="linkToBetList" (click)="openBetList(roundId.toString())">{{ 'pg-event-result.access-bet-list' | translate }}</a>
      </div>
      <div class="modal-footer d-flex justify-content-center p-0" style="border-top: 1px solid #434343;">
        <button mdbBtn class="waves-light button-bg-theme" style="background-color: #e93f3f !important;"
          (click)="frame.hide()" mdbWavesEffect>Voltar</button>
      </div>
    </div>
  </div>
</div> 
-->