export class ShieldHelper {
    public static getBasicShield(elementName: string): string {
        elementName = this.remove_accents(elementName);
        elementName = elementName.replace(" ", "%20");
        return `https://poolbet365.com/assets/shields/${elementName}.png`
    }

    public static getSingleMatchShield(elementName: String, team: number): string {
        let nameTeam: string[]
        elementName = elementName.replace(" (", " X ")
        elementName = elementName.replace(" - ", " X ")
        nameTeam = elementName.split((" X ").toUpperCase());
        let getTemLogo;
        getTemLogo = nameTeam[team];
        getTemLogo = this.remove_accents(getTemLogo)
        getTemLogo = getTemLogo.replace(" ", "%20")
        return `https://poolbet365.com/assets/shields/${getTemLogo}.png`
    }

    public static getJiuJitsuShield(elementName: String, team: number): string {
        let nameTeam: string[]
        elementName = elementName.replace(" (", " X ")
        elementName = elementName.replace(" - ", " X ")
        nameTeam = elementName.split((" X ").toUpperCase());
        let getTemLogo;
        getTemLogo = nameTeam[team];
        getTemLogo = this.remove_accents(getTemLogo);
        getTemLogo = getTemLogo.replace(" ", "%20");
        return `https://poolbet365.com/assets/image/bjj/${getTemLogo}.png`;
    }

    public static getChampionshipLogos(name: string): string {
        let searchText = this.remove_accents(name);
        for (var i = 0; i < searchText.length; i++) {
            if (searchText.charAt(i) == " ") searchText = searchText.replace(" ", "%20");
        }
        searchText = searchText.toLocaleLowerCase()
        let ret = 'https://poolbet365.com/assets/shields/' + searchText + '.png';
        if (name.toLocaleUpperCase().includes(("POKER").toLocaleUpperCase())) {
            if (name.toLocaleUpperCase().includes(("Israelita").toLocaleUpperCase())) {
                return ret = '../../../assets/image/ChampionShipFlags/estrDavy.png';
            }
            return ret = '../../../assets/image/ChampionShipFlags/poker.png';
        }
        if (name.toLocaleUpperCase().includes(("LA LIGA").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/laliga.png';
        if (name.toLocaleUpperCase().includes(("campeonato BRASILEIRO").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/brasileirao.png';
        if (name.toLocaleUpperCase().includes("MOTO GP")) return ret = '../../../assets/image/ChampionShipFlags/motogp.png';
        if (name.toLocaleUpperCase().includes("FÓRMULA 1")) return ret = '../../../assets/image/ChampionShipFlags/f1.png';
        if (name.toLocaleUpperCase().includes(("Bundesliga").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/bl.png';
        if (name.toLocaleUpperCase().includes(("campeonato italiano").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/it.png';
        if (name.toLocaleUpperCase().includes(("campeonato frances").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/fr.png';
        if (name.toLocaleUpperCase().includes(("premier league").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/pl.png';
        if (name.toLocaleUpperCase().includes(("uefa").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/uefa.png';
        if (name.toLocaleUpperCase().includes(("Internacionais").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/international.png';
        if (name.toLocaleUpperCase().includes(("UFC").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/ufc.png';
        if (name.toLocaleUpperCase().includes(("CFC").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/cfc.png';
        return ret;
    }

    public static getSelectedShield(name: string, betsport: number): string {
        let searchText = name.split(" ");
        let ret = '../../../assets/shields/default-logo.png';
        if (betsport == 40) {
            return this.getFormula1Shield(name);
        }
        if (betsport == 1000) {
            ret = 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Naipe_ouros.png/216px-Naipe_ouros.png'
        }
        return ret;
    }

    public static getFormula1Shield(driverName: string): string {
        let ret = '../../../assets/shields/default-logo.png';
        for (const team of this.formula1Shields) {
            const found = team.drivers.find(x => x.toLowerCase() == driverName.toLowerCase());
            if (found) {
                ret = 'https://poolbet365.com/assets/shields/' + team.shield;
                break;
            }
        }

        return ret;
    }

    private static remove_accents(str: string): string {
        let accented = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
        let withoutAccent = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
        let namewithoutAccent = "";
        for (let i = 0; i < str.length; i++) {
            let troca = false;
            for (let a = 0; a < accented.length; a++) {
                if (str.substr(i, 1) == accented.substr(a, 1)) {
                    namewithoutAccent += withoutAccent.substr(a, 1);
                    troca = true;
                    break;
                }
            }
            if (troca == false) {
                namewithoutAccent += str.substr(i, 1);
            }
        }
        return namewithoutAccent;
    }

    private static formula1Shields = [
        {
            team: 'Red Bull',
            shield: 'f1-red-bull.png',
            drivers: [
                'Max Verstappen',
                'M. Verstappen',
                'Sergio Perez',
                'S. Perez'
            ]
        },
        {
            team: 'Sauber',
            shield: 'f1-sauber.png',
            drivers: [
                'Valtteri Bottas',
                'V. Bottas',
                'Guanyu Zhou',
                'G. Zhou'
            ]
        },
        {
            team: 'Alpine',
            shield: 'f1-alpine.png',
            drivers: [
                'Pierre Gasly',
                'P. Gasly',
                'Esteban Ocon',
                'E. Ocon',
            ]
        },
        {
            team: 'Aston Martin',
            shield: 'f1-aston-martin.png',
            drivers: [
                'Fernando Alonso',
                'F. Alonso',
                'Lance Stroll',
                'L. Stroll'
            ]
        },
        {
            team: 'Ferrari',
            shield: 'f1-ferrari.png',
            drivers: [
                'Charles Leclerc',
                'C. Leclerc',
                'Carlos Sainz',
                'C. Sainz'
            ]
        },
        {
            team: 'Haas F1 Team',
            shield: 'f1-haas.png',
            drivers: [
                'Kevin Magnussen',
                'K. Magnussen',
                'Nico Hulkenberg',
                'N. Hulkenberg'
            ]
        },
        {
            team: 'McLaren',
            shield: 'f1-mclaren.png',
            drivers: [
                'Lando Norris',
                'L. Norris',
                'Oscar Piastri',
                'O. Piastri'
            ]
        },
        {
            team: 'Mercedes',
            shield: 'f1-mercedes.png',
            drivers: [
                'Lewis Hamilton',
                'L. Hamilton',
                'George Russell',
                'G. Russell'
            ]
        },
        {
            team: 'Racing Bulls',
            shield: 'f1-racing-bulls.png',
            drivers: [
                'Yuki Tsunoda',
                'Y. Tsunoda',
                'Liam Lawson',
                'L. Lawson',
                'Daniel Ricciardo',
                'D. Ricciardo'
            ]
        },
        {
            team: 'Williams',
            shield: 'f1-williams.png',
            drivers: [
                'Alexander Albon',
                'A. Albon',
                'Franco Colapinto',
                'F. Colapinto'
            ]
        }
    ];
}