<div class="mobile-width-adjust">
  <div class="bg-theme pb-2 px-5">
    <h3 class="d-flex justify-content-start align-items-center  pl-4 pt-3">
      <img class="mr-2" src="../../assets/image/slot-machine.png" width="60vh">
      {{ 'menu.slot' | translate }}
    </h3>
    <hr>
    <div class="providers-Box pb-2 px-5">
      <button class="btn btn-primary selectProviders-btn" type="button" (click)="toggleCollapse()">
        {{ 'pg-slot.select-providers' | translate }}
        <span [ngClass]="{'arrow-up': isCollapseOpen, 'arrow-down': !isCollapseOpen}"></span>
      </button>
      <div class="collapse pt-2 px-5" id="collapseExample" [class.show]="isCollapseOpen" data-parent=".providers-Box">
        <div class="providers-menu">
          <button class="providers-btn" data-toggle="collapse" data-target="#collapseExample"
            *ngFor="let games of providersList;" (click)="selectProvider(games)">
            <img *ngIf="checkImageExists(games)" class="providers-logo" [src]="getImageSource(games)">
            <p class="m-0 p-0 providers-name" *ngIf="!checkImageExists(games)">{{games}}</p>
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex games-bg flex-column desktop-hide" style="padding: 0 5vw;">
      <div class="w-100 pt-3 d-flex justify-content-center align-items-center desktop-hide">
        <input class="game-filter" type="text" placeholder="{{ 'pg-slot.filter-by-games' | translate }}">
      </div>
      <div class="p-3 pt-3 mobile-container desktop-hide">
        <ng-container *ngFor="let games of filteredMobileGameList;">
          <div class="videoSlot-Box_mobile w-100 mb-2">
            <div (click)="openSlotSession(games)" class="img-box">
              <img class="game_img" src="{{ games.imgUrl }}" alt="thumb">
            </div>
            <span class="text-center game_name desktop-hide"> {{ games.name }}</span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="d-flex games-bg flex-column mobile-hide" style="padding: 0 5vw;">
      <div class="w-100 pt-3 d-flex justify-content-center align-items-center mobile-hide">
        <input #gameFilter (keyup)="filterByGames(gameFilter.value)" class="game-filter" type="text" placeholder="{{ 'pg-slot.filter-by-games' | translate }}">
      </div>
      <div class="p-3 desktop-container pt-3 mobile-hide">
        <ng-container *ngFor="let games of filteredDesktopGameList;">
          <div class="videoSlot-Box w-100 mb-2">
            <div (click)="openSlotSession(games)" class="img-box">
              <img class="game_img" src="{{ games.imgUrl }}" alt="thumb">
              <span class="w-100 text-center">
                <mat-icon
                  style="font-size: 60px; text-align: center; width: 100% !important;">play_circle_outline</mat-icon>
                <br>
                {{ games.name }}
              </span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
