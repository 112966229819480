import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class CryptoService{
    private secretKey: string = 'vasco123';

    constructor(){}

    encrypt(data: string): string{
        return CryptoJS.AES.encrypt(data, this.secretKey).toString();
    }

    decrypt(data: string): string{
        try{
            const bytes = CryptoJS.AES.decrypt(data, this.secretKey);
            return bytes.toString(CryptoJS.enc.Utf8);
        }catch(e){
            console.error(e);

            return '';
        }
    }
}