<div class="row my-footer" style="margin: auto !important;">
  <div class="container" style="min-width: 100%;" *ngIf="!this.actualUrl.includes('/lobby')">
    <div class="row" style="margin: auto !important;">
      <div class="col-4 padding-10-l footer-img-div">
        <a href="?">
          <img src="../assets/image/logo-footer.png" class="desktopLogoFooterImageStyle" alt="ss logo" />
        </a>
      </div>

      <div class="col-4 d-flex flex-row footer-nav-principal-div" style="align-items: center; justify-content: space-around">
        <div class="d-flex flex-column">
          <p>{{ "Pool" | translate }}</p>
          <a routerLink="/event-result">{{ "pg-tickets.results" | translate }}</a>
          <a (click)="goTo('/payment')">{{ "pg-jurisdiction.deposit" | translate }}</a>
          <a (click)="goTo('/checkout')">{{ "pg-jurisdiction.withdraw" | translate }}</a>
        </div>

        <div class="d-flex flex-column help-container" style="margin-top: 2%">
          <p>{{ "pg-app-component.help" | translate }}</p>
          <a routerLink="/contact">{{ "pg-footer.assistance-footer" | translate }}</a>
          <a routerLink="/rule">{{ "pg-footer.rules-footer" | translate }}</a>
          <a routerLink="/work-together">{{ "pg-footer.work-together" | translate }}</a>
          <a routerLink="/global-terms">{{ "pg-footer.global-terms-footer" | translate }}</a>
        </div>
      </div>

    </div>
    <div class="col-12 padding-10-l text-center" style="margin-top: 2%">
      <p style="margin: 0px !important">
        {{ "pg-footer.text-footer" | translate }}
      </p>
      <br />
      <p style="margin: 0px !important">
        &copy; 2022 Copyright: poolbet365.com -
        <span style="color: #25c820">v.2.06</span>
      </p>
    </div>
  </div>
</div>
