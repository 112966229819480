import { Component, OnInit } from '@angular/core';
import { InteractionService } from '../../interaction.service';
import { Router } from '@angular/router';
import { ISkinConfig } from 'src/app/Interfaces/SkinConfig.interface';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  skinConfig: ISkinConfig;

  constructor(private interactionService: InteractionService,
    private router: Router) { }

  ngOnInit(): void {
    this.interactionService.skinConfig
      .subscribe((data) => {
        this.skinConfig = data;
      });
  }

  isActiveRoute(routerLink: string): boolean {
    return this.router.isActive(routerLink, true);
  }

}
