<div class="container">

    <!-- Card -->
    <div class="card mb-5" >
        <!-- Card content -->
        <div class="card-body bg-theme">

            <div *ngIf="!isLoaded" id="spinner-loading" class="justify-content-center">
                <div class="spinner-border"role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <!--Showing current round-->
            <div class="row" *ngIf="isLoaded">
                <div class="col-sm-12 col-md-7 pt-3">
                    <h3>{{ 'pg-round.rounds' | translate }}</h3>

                    <form class="competition-form">

                        <mat-form-field class="competition-full-width code-field mb-2">
                            <mat-label>{{ 'pg-round.sport' | translate }} *</mat-label>
                            <input type="text" aria-label="Sports" matInput [formControl]="sportsFormControl"
                                [matAutocomplete]="autoSport" id="sport" #custommatchSport>
                            <mat-autocomplete #autoSport="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let sport of sports | async" [value]="sport"
                                    (click)="getSportCategories(sport.id)">
                                    {{sport.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field class="competition-full-width code-field mb-2">
                            <mat-label>{{ 'pg-round.category' | translate }}</mat-label>
                            <input type="text" aria-label="Category" matInput [formControl]="categoriesFormControl"
                                [matAutocomplete]="autoCategory">
                            <mat-autocomplete #autoCategory="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let category of categories | async" [value]="category"
                                    (click)="getCategoryTournaments(category.id)">
                                    {{category.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field class="competition-full-width code-field mb-2">
                            <mat-label>{{ 'pg-round.competition' | translate }}</mat-label>
                            <input type="text" aria-label="Competição" matInput [formControl]="myControl"
                                [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let tournament of filteredOptions | async" [value]="tournament"
                                    (click)="showTournamentMatches(tournament.id)">
                                    {{tournament.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <!--<mat-form-field appearance="fill">
                            <mat-label>Competições</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let tournament of tournamentList" [value]="tournament.id"
                                (click)="tournamentMatches(tournament.id)">
                                    {{ tournament.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>-->
                        <mat-slide-toggle  (click)="toggleRankRound()" value="false">{{ 'pg-round.rank-round-type' | translate }}</mat-slide-toggle>

                        <table class="table table-striped mt-3" mdbTable mdbTableScroll scrollX="true" maxWidth="100%" bordered="true">

                            <thead>
                                <tr>
                                    <th>{{ 'pg-round.date-hour' | translate }}</th>
                                    <th>{{ 'pg-round.match' | translate }}</th>
                                    <th>{{ 'pg-round.add' | translate }}</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        <mat-form-field class="length-adjust mb-2">
                                            <input matInput [min]="minDate" [matDatepicker]="picker" id="date-picker" #customdate>
                                            <mat-datepicker-toggle matSuffix [for]="picker" placeholder="20/02/2002"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field><br/>

                                        <mat-form-field class="length-adjust center-content code-field mb-2">
                                            <mat-label>{{ 'pg-round.matchTime' | translate }}</mat-label>
                                            <input matInput #customtime id="hour-input" maxwidth="50px" placeholder="01:23">
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field class="code-field mb-2">
                                            <mat-label *ngIf="!rankRound">{{ 'pg-round.homeTeam' | translate }}</mat-label>
                                            <mat-label *ngIf="rankRound">{{ 'pg-home.player-name' | translate }}</mat-label>
                                            <input #custommatch1 id="team-home" matInput placeholder="ex: Real Madrid or America">
                                        </mat-form-field><br/>

                                        <mat-form-field class="code-field mb-2" id="team-away-matfield">
                                            <mat-label>{{ 'pg-round.awayTeam' | translate }}</mat-label>
                                            <input #custommatch2 id="team-away" matInput>
                                        </mat-form-field>
                                    </td>
                                    <td class="center-content">
                                        <button *ngIf="!rankRound" class="m-0 p-2 button-bg-theme button-theme" type="button" mdbBtn color="primary"
                                            mdbWavesEffect (click)="addCustomGame(0, custommatch1.value, customdate.value, customtime.value, custommatch2.value, custommatchSport.value)">+</button>
                                        <button *ngIf="rankRound" class="m-0 p-2 button-bg-theme button-theme" type="button" mdbBtn color="primary"
                                            mdbWavesEffect (click)="addCustomGame(0,custommatch1.value,customdate.value,customtime.value,custommatch2.value,custommatchSport.value)">+</button>
                                    </td>
                                </tr>
                                <tr *ngFor="let match of betradarMatches; let i = index">
                                    <td>
                                        {{ match.date | date: 'dd/MM/yy HH:mm' }}
                                    </td>
                                    <td>
                                        <div *ngIf="!rankRound">{{ match.homeTeam.name }} x {{ match.awayTeam.name }}</div>
                                        <div *ngIf="rankRound">{{ match.homeTeam.name }}</div>
                                    </td>
                                    <td class="center-content">
                                        <button *ngIf="!checkExistingId(match.id)" class="m-0 p-2 button-bg-theme button-theme" type="button" mdbBtn color="primary"
                                            mdbWavesEffect (click)="addNewGame(match)">+</button>

                                        <i *ngIf="checkExistingId(match.id)" style="color: green" class="fas fa-check"></i>
                                    </td>
                                </tr>
                            </tbody>

                        </table>

                    </form>
                </div>

                <div class="col-sm-12 col-md-5 pt-3 new-round-panel" style="overflow: auto;">
                    <p class="new-round-panel-title">{{ 'pg-round.scope-new-round' | translate }}</p>
                    <hr />


                    <form class="col-12 competition-form">

                        <mat-radio-group name="round-type" aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="roundType" (ngModelChange)="radioChange()">
                            <mat-radio-button class="example-radio-button" value="1" >
                                {{ 'pg-round.competition-continuation' | translate }}
                            </mat-radio-button>
                            <mat-radio-button class="example-radio-button" value="2" >
                                {{ 'pg-round.new-competition' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>

                        <mat-form-field class="competition-full-width code-field mb-2" *ngIf="(roundType == 1)">
                            <mat-label>Competição de Base</mat-label>
                            <mat-select [(value)]="roundBaseChoosen" id="roundsname">
                                <mat-option *ngFor="let rounds of roundsFinished; let i = index" [value]="rounds.id" [class.bg-red]="rounds.posted == 0" (click)="onChangeCurrentCompetition(rounds.id)" >
                                    {{rounds.name}} - {{rounds.number}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <p *ngIf="(roundType == 1)">Jackpot: {{currentJackpot}}</p>

                        <mat-form-field class="competition-full-width code-field mb-2" *ngIf="(roundType == 2)">
                            <mat-label>{{ 'pg-manage-group.choose-parent' | translate }}</mat-label>
                            <mat-select [(value)]="groupParent" id="groupParent">
                                <!--<mat-option value="0">Principal</mat-option>-->
                                <mat-option *ngFor="let groups of listRoundGroups" [value]="groups.id">
                                    {{groups.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="competition-full-width code-field mb-2" *ngIf="(roundType == 2)">
<!--                            <mat-label>Título da Rodada</mat-label>-->
                          <mat-label>Round Title PT<br>
                          </mat-label>
                            <input matInput placeholder="Ex: Campeonato Brasileiro"  class="center-content" id="round-name">
                        </mat-form-field>
                      <mat-form-field class="competition-full-width code-field mb-2" *ngIf="(roundType == 2)">
<!--                            <mat-label>Título da Rodada</mat-label>-->
                          <mat-label>
                            Round Title IT
                          </mat-label>
                            <input matInput placeholder="Ex: Campeonato Italian"   class="center-content" id="round-name-italy">
                        </mat-form-field>
                      <mat-form-field class="competition-full-width code-field mb-2" *ngIf="(roundType == 2)">
<!--                            <mat-label>Título da Rodada</mat-label>-->
                          <mat-label>Round Title ES
                          </mat-label>
                            <input matInput placeholder="Ex: Campeonato spanish"  class="center-content" id="round-name-span">
                        </mat-form-field>
                      <mat-form-field class="competition-full-width code-field mb-2" *ngIf="(roundType == 2)">
<!--                            <mat-label>Título da Rodada</mat-label>-->
                          <mat-label>Round Title EN<br>
                          </mat-label>
                            <input matInput placeholder="Ex: Campeonato english"  class="center-content" id="round-name-Eng">
                        </mat-form-field>

                        <mat-form-field class="competition-full-width" *ngIf="(roundType == 2)">
                            <mat-label>Acumulado</mat-label>
                            <span matPrefix>{{ Currency.currencySymbol }} </span>
                            <input matInput value="0" class="center-content code-field" id="new-jackpot">
                            <span matSuffix>,00</span>
                        </mat-form-field>

                        <mat-form-field class="competition-full-width">
                            <mat-label>{{ 'pg-round.round-value' | translate }}</mat-label>
                            <span matPrefix>{{ Currency.currencySymbol }} </span>
                            <input matInput value="" class="center-content code-field" id="round-value" placeholder="Round Value ex:10">
                            <span matSuffix>,00</span>
                        </mat-form-field>
                        <mat-slide-toggle *ngIf="!rankRound" [formControl]="autoRenew" (click)="toggleTwoResults()" value="false">{{ 'pg-home.All' | translate }} 2 {{ 'pg-tickets.results' | translate }}</mat-slide-toggle>
                    </form>

                    <!-- new creation area -->
                    <div class="col-12">
                        <table class="competition-full-width">
                            <tr *ngFor="let match of chosenMatches; let i = index">
                                <td *ngIf="!rankRound">
                                    {{ match.date | date: 'dd/MM/yy HH:mm' }}
                                    <mat-slide-toggle [(ngModel)]="match.two_options" value="0">2 {{ 'pg-tickets.results' | translate }}</mat-slide-toggle>
                                </td>
                                <td>
                                    {{match.game}}
                                </td>
                                <td style="text-align: center;">
                                    <a (click)="removeGame(i)"><i class="far fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            <tr *ngIf="(chosenMatches.length > 0)">
                                <td *ngIf="!rankRound">{{ 'pg-round.matches' | translate }}: {{chosenMatches.length}}</td>
                                <td *ngIf="rankRound">{{ 'pg-result.players' | translate }}: {{chosenMatches.length}}</td>
                                <td colspan='2'>
                                    <button class="side-align" mat-flat-button color="primary" (click)="onSubmit()">{{ 'pg-round.create' | translate }}</button>
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>

            </div>
            <!--Showing current round-->

        <!-- Card -->
    </div>
</div>

<!--Modal Success Creation MSG-->
<div mdbModal #successCreation="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document">
        <div class="modal-content bg-theme">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold">{{ 'pg-round.round-created' | translate }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModalConfirmation()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">
                <div class="md-form mb-5">

                    <p style="color: white">
                        {{ 'pg-round.round-created-info' | translate }}
                    </p>

                </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
                <button mdbBtn class="waves-light button-bg-theme button-font" (click)="closeModalConfirmation()" mdbWavesEffect>OK</button><br/>
            </div>

        </div>
    </div>
</div>
<!--Modal Success Creation MSG-->

<!--Modal Avoid Ativated Round -->
<div mdbModal #avoidCreation="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-danger" role="document">
        <div class="modal-content bg-theme">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold">{{ 'pg-round.fail' | translate }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeAvoidCreation()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">
                <div class="md-form mb-5">

                    <p style="color: white">
                        {{ 'pg-round.fail-avoid' | translate }}
                    </p>

                </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
                <button mdbBtn class="waves-light button-bg-theme button-font" (click)="closeAvoidCreation()" mdbWavesEffect>OK</button><br/>
            </div>

        </div>
    </div>
</div>
<!--Modal Avoid Ativated Round -->
