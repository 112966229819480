import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SportService {
    sportList = new BehaviorSubject<any>(null);
    constructor() { }

    setSportList(sports){
        if(sports){
            this.sportList.next(sports);
        }
    }
}