import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from '../../services/cookie/cookie.service';
import { AppComponent } from '../../app.component';
import { ConfigService } from '../../config.service';
import { InteractionService } from '../../interaction.service';
import { Flags, StreamGroup } from '../../round-group.interface';
import { UserCookieService } from '../../services/User/UserCookie.service';

export class DataNode {
  name: string;
  roundId: number;
  childrens?: DataNode[];
}

@Component({
  selector: 'app-mobile-roundgroups',
  templateUrl: './mobile-roundgroups.component.html',
  styleUrls: ['./mobile-roundgroups.component.scss']
})
export class MobileRoundgroupsComponent implements OnInit {

  treeControl = new NestedTreeControl<StreamGroup>(node => node.children);
  dataSource = new MatTreeNestedDataSource<StreamGroup>();
  isLogged: boolean = false;
  countryFlags: Flags;
  currentUser: any;
  public activeLang;
  matIcons: any = [
    { name: "Soccer", icon: "sports_soccer" },
    { name: "Basketball", icon: "sports_basketball" },
    { name: "Baseball", icon: "sports_baseball" },
    { name: "Ice Hockey", icon: "sports_hockey" },
    { name: "Tennis", icon: "sports_tennis" },
    { name: "Handball", icon: "sports_handball" },
    { name: "Floorball", icon: "sports_handball" },
    { name: "Boxing", icon: "sports_mma" },
    { name: "Rugby", icon: "sports_rugby" },
    { name: "Bandy", icon: "sports_football" },
    { name: "American Football", icon: "sports_football" },
    { name: "Cycling", icon: "directions_bike" },
    { name: "Table Tennis", icon: "sports_tennis" },
    { name: "Cricket", icon: "sports_cricket" },
    { name: "Darts", icon: "south_east" },
    { name: "Volleyball", icon: "sports_volleyball" },
    { name: "Curling", icon: "sports_hockey" },
    { name: "Futsal", icon: "sports_football" },
    { name: "Badminton", icon: "sports_tennis" },
  ]

  matchNode: StreamGroup;
  constructor(private interactionService: InteractionService, private configService: ConfigService,
    private appComponent: AppComponent, private router: Router,
    private cookieService: CookieService, private userCookieService: UserCookieService,
    private translate: TranslateService) {


    this.translate.setDefaultLang(this.appComponent.activeLang);
    this.configService.getFlags().subscribe(data => {
      this.countryFlags = data;
    });
  }

  ngOnInit() {
    const userId = this.userCookieService.getUserId()
    if (userId > 0) {
      this.configService.getUser(userId)
        .subscribe(data => {
          if (data.credit >= 10) {
            this.isLogged = true;
          }
        });
    }
    // this.configService.getStreamingData().subscribe(data => {
    //   this.dataSource.data = data;
    // }, error => {
    //   console.log(error);
    // });
  }

  getFlagByName(name: string) {
    name = name == "England" ? "United Kingdom" : name;
    var result = this.countryFlags.data.filter(obj => {
      return obj.name === name;
    });
    if (result.length > 0) {
      return result[0].flag;
    }
    else {
      return "/assets/svgs/flag.svg";
    }
  }
  getGameIcon(name: string) {
    var result = this.matIcons.filter(obj => {
      return obj.name === name;
    });
    if (result.length > 0)
      return result[0].icon;
  }

  public isGroup = (_: number, node: StreamGroup) => !!node.children;

  flagName(groupName: string): string {
    let indexResult: number;
    let flags: string[] = ['pt', 'it', 'en', 'ar', 'es', 'wo'];

    switch (groupName) {
      case 'Brasil': {
        indexResult = 0;
        break;
      }
      case 'Italia': {
        indexResult = 1;
        break;
      }
      case 'England': {
        indexResult = 2;
        break;
      }
      case 'Argentina': {
        indexResult = 3;
        break;
      }
      case 'Espanha': {
        indexResult = 4;
        break;
      }
      default: {
        indexResult = 5;
        break;
      }
    }

    return flags[indexResult];
  }

  customTranslate(country: string): string {
    let currentLanguage = this.appComponent.activeLang;
    let result: string = '';
    switch (country) {
      case 'Brasil': {
        if (currentLanguage == 'it') {
          result = 'Brasile';
        }
        if (currentLanguage == 'en') {
          result = 'Brazil';
        }
        if (currentLanguage == 'pt') {
          result = 'Brasil';
        }
        if (currentLanguage == 'es') {
          result = 'Brasil';
        }
        break;
      }
      case 'Italia': {
        if (currentLanguage == 'it') {
          result = 'Italia';
        }
        if (currentLanguage == 'en') {
          result = 'Italy';
        }
        if (currentLanguage == 'pt') {
          result = 'Itália';
        }
        if (currentLanguage == 'es') {
          result = 'Italia';
        }
        break;
      }
      case 'Misto': {
        if (currentLanguage == 'it') {
          result = 'Misto';
        }
        if (currentLanguage == 'en') {
          result = 'Mixed';
        }
        if (currentLanguage == 'pt') {
          result = 'Misto';
        }
        if (currentLanguage == 'es') {
          result = 'Misto';
        }
        break;
      }
      case 'Espanha': {
        if (currentLanguage == 'it') {
          result = 'Spagna';
        }
        if (currentLanguage == 'en') {
          result = 'Spain';
        }
        if (currentLanguage == 'pt') {
          result = 'Espanha';
        }
        if (currentLanguage == 'es') {
          result = 'España';
        }
        break;
      }
      case 'England': {
        if (currentLanguage == 'it') {
          result = "Inghilterra";
        }
        if (currentLanguage == 'en') {
          result = "England";
        }
        if (currentLanguage == 'pt') {
          result = "Inglaterra";
        }
        if (currentLanguage == 'es') {
          result = "Inglaterra";
        }
        break;
      }
      default: {
        result = country;
      }
    }
    return result;
  }

  showMatches(node: StreamGroup) {
    this.matchNode = node;
    this.configService.getLiveMatches(node.id, node.sportId).subscribe(data => {
      var updatedSource = this.dataSource.data;
      updatedSource.forEach(element => {
        element.children.forEach(element2 => {
          element2.children.forEach(element3 => {
            if (this.matchNode.id == element3.id) {
              element3.children = [{ "id": parseInt(data[0].code), "name": data[0].name, "sportId": data[0].sportId, "style": "", "children": [] }]
              this.matchNode = element3;
            }
          });
        });
      });
      this.dataSource.data = null;
      this.dataSource.data = updatedSource;
    }, error => {
    });

  }
  loadMatch(id: number, sportId: number, type: string) {
    this.router.navigate(['/live-stream/' + id + '/' + sportId + '/' + type]);
  }
  sportLevelFormat(sportId: number) {
    let ret: boolean = false;

    if (sportId >= 1 && sportId <= 117 || sportId == 1000) {
      ret = true;
    }

    return ret;
  }
}
