import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from '../config.service';
import { Round } from '../round.interface';
import { Game } from '../game.interface';
import { ModalDirective } from 'angular-bootstrap-md';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from '../services/modals/modals.service';

@Component({
  selector: 'app-post-result',
  templateUrl: './post-result.component.html',
  styleUrls: ['./post-result.component.scss']
})
export class PostResultComponent implements OnInit {
  @ViewChild('frame', { static: true }) modalPost: ModalDirective;
  @ViewChild('frame2', { static: true }) modalCancel: ModalDirective;

  isLoaded: boolean = false;
  isLoadingGames: boolean = false;
  roundsFinished: Round[] = [];
  info: any = [];
  roundName: string;
  games: Game[] = [];
  scoreArray: number[] = [0];
  sortIndices: number[] = [0];
  isRankRound: boolean = false;

  gameCancel = {} as Game;

  constructor(private configService: ConfigService, private appComponent: AppComponent,
    private router: Router, private translate: TranslateService, private modalsService: ModalsService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {
    this.games = [];
    this.getRoundsFinished();

  }

  getRoundsFinished() {
    this.configService.getRoundsFinished()
      .subscribe(data => {
        this.roundsFinished = data;
        for (let index = 0; index < this.roundsFinished.length; index++) {
          switch (this.appComponent.activeLang) {
            case 'pt':
              this.roundName = this.roundsFinished[index].name;
              break;
            case 'en':
              this.roundName = this.roundsFinished[index].nameEng;
              break;
            case 'it':
              this.roundName = this.roundsFinished[index].nameItaly;
              break;
            case 'es':
              this.roundName = this.roundsFinished[index].nameSpan;
              break;
            default:
              break;
          }
          this.info.push({
            id: this.roundsFinished[index].id,
            startDateTime: this.roundsFinished[index].startDateTime,
            endDateTime: this.roundsFinished[index].endDateTime,
            name: this.roundName,
            number: this.roundsFinished[index].number,
            rankRound: this.roundsFinished[index].rankRound
          });
        }
        document.getElementById("spinner-loading").classList.add("hidden");
        this.isLoaded = true;
      }, error => {
        console.log(error);
        this.modalsService.openNotificationModal(this.translate.instant("pg-post-result.failed-round"), this.translate.instant("pg-post-result.failed-round-info") +
          this.translate.instant("pg-post-result.assistence-contact"), true);
      })
  }

  loadGames(roundId: number, rankRound: number) {
    this.games = [];

    this.isLoadingGames = true;
    this.isRankRound = rankRound ? true : false;
    document.getElementById("spinner-loading2").classList.remove("hidden");

    this.configService.getGames(roundId)
      .subscribe(data => {
        this.getBetradarResults(data);
      }, error => {
        console.log(error);
        this.modalsService.openNotificationModal(this.translate.instant("pg-post-result.failed-matches"), this.translate.instant("pg-post-result.failed-matches-info") +
        this.translate.instant("pg-post-result.assistence-contact"), true);
      });
  }

  getBetradarResults(currentGames: Game[]) {
    this.configService.getBetRadarResults(currentGames)
      .subscribe(data => {
        this.games = data;
        for (let i = 0; data && i < this.games.length; i++) {
          const gameDateTime = (new Date(this.games[i].dateTime)).getTime() - (new Date()).getTimezoneOffset() * 60000;
          this.games[i].dateTime = new Date(gameDateTime);
        }
        if (this.isRankRound) {
          for (let i = 0; i < data.length; i++) {
            this.scoreArray[i] = this.games[i].homeTeamScore;
          }
          this.games[0].matchWinner = this.setRank();
        }
        this.isLoadingGames = false;
        document.getElementById("spinner-loading2").classList.add("hidden");
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-post-result.failed-post"), this.translate.instant("pg-post-result.failed-post-info") +
        this.translate.instant("pg-post-result.assistence-contact"), true);
        console.log(error);
      });
  }

  setResult(homeScore: number, awaySocore: number) {
    let ret = "0";
    if (homeScore > awaySocore) {
      ret = "1";
    } else if (homeScore < awaySocore) {
      ret = "2";
    } else if (homeScore == awaySocore) {
      ret = "X"
    }
    return ret;
  }

  setRank() {
    let toSort = [];

    for (var i = 0; i < this.games.length; i++) {
      toSort[i] = [this.scoreArray[i], i];
    }
    toSort.sort(function (left, right) {
      return left[0] > right[0] ? 1 : -1;
    });
    let ret = "";
    for (var j = 0; j < toSort.length; j++) {
      ret += this.games[toSort[j][1]].homeName + '|';
    }

    return ret.substring(0, ret.length - 1);
  }

  onChange(homeOrAway: string, indexGame: number, score: string) {
    if (this.isRankRound) {
      this.scoreArray[indexGame] = parseInt(score);
      return;
    }

    if (score == "") score = "0"
    if (homeOrAway == "a") {
      this.games[indexGame].homeTeamScore = +parseInt(score);
    } else {
      this.games[indexGame].awayTeamScore = +parseInt(score);
    }
    this.games[indexGame].matchResult = this.setResult(this.games[indexGame].homeTeamScore, this.games[indexGame].awayTeamScore);
  }

  postResult() {
    this.games[0].matchWinner = this.setRank();
    this.configService.postResult(this.games)
      .subscribe(data => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-post-result.result-posted"), this.translate.instant("pg-post-result.result-posted-info"), false);
        this.modalPost.hide();
        this.ngOnInit();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-post-result.failed-post"), this.translate.instant("pg-post-result.failed-post-info") +
          this.translate.instant("pg-post-result.assistence-contact"), true);
        console.log(error);
      });
  }

  cancelGame(matchId: number) {
    this.gameCancel = this.games.find(x => x.id == matchId);

    if (this.gameCancel.status == 1) {
      this.gameCancel.status = 0;
      this.games.find(x => x.id == matchId).status = 0;
      if (!this.isRankRound)
        this.modalsService.openNotificationModal(this.translate.instant("pg-post-result.cancel"), this.translate.instant("pg-post-result.cancel-info") +
          "<br/><strong>" + this.gameCancel.homeName + " - " + this.gameCancel.awayName + "</strong>", true);
      else
        this.modalsService.openNotificationModal(this.translate.instant("pg-post-result.cancel"), this.translate.instant("pg-post-result.cancel-info") +
          "<br/><strong>" + this.gameCancel.homeName + "</strong>", true);
    }
    else {
      this.gameCancel = null;
      this.games.find(x => x.id == matchId).status = 1;
    }
  }

}
