import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { ConfigService } from '../config.service';
import { ToastService } from '../toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-poker',
  templateUrl: './poker.component.html',
  styleUrls: ['./poker.component.scss']
})
export class PokerComponent implements OnInit {
  activeUser: any;

  constructor(private configService: ConfigService, private toastService: ToastService, private translate: TranslateService,
  private appComponent: AppComponent) { }

  ngOnInit(): void {
  }

  openPokerSession() {
    if (localStorage.getItem("currentUser") === null || !this.appComponent.userAdmin) {
      return this.toastService.showNotification({ message: this.translate.instant("pg-home.must-be-logged"), type: 'danger' });
    };

    this.activeUser = this.appComponent.userAdmin.id;
    this.configService.getUser(this.activeUser).subscribe(data => {
    this.configService.openPokerSession(data.login)
      .subscribe(data => {
        window.open(data, "Poker", "width=1280, height=720");
      });
    })
  }

  openPokerSessionOnMobile() {
    if (localStorage.getItem("currentUser") === null || !this.appComponent.userAdmin) {
      return this.toastService.showNotification({ message: this.translate.instant("pg-home.must-be-logged"), type: 'danger' });
    };
    this.activeUser = this.appComponent.userAdmin.id;
    let mobileWindow = window.open();
    this.configService.getUser(this.activeUser).subscribe(data => {
      this.configService.openPokerSession(data.login)
        .subscribe((link: any) => {
          mobileWindow.location = link;
        });
    });
  }

}
