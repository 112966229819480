import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private lastNotificationTime: number;
  private readonly notificationCooldown: number = 3000; // Set the cooldown period in milliseconds

  constructor() {
    this.lastNotificationTime = 0;
  }

  showNotification({ message, from = 'top', align = 'right', type = 'danger' }: {
    message: string;
    from?: string;
    align?: string;
    type?: string;
  }): void {
    const currentTime = new Date().getTime();
    const timeSinceLastNotification = currentTime - this.lastNotificationTime;

    if (timeSinceLastNotification >= this.notificationCooldown) {
      $.notify({
        icon: 'notifications',
        message,
      }, {
        type,
        timer: 1000,
        delay: 1000,
        placement: { from, align },
        template: '<div data-notify="container" class="notification col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon d-flex align-items-center" role="alert" style="background-color: #f55145; color: white; border: none !important;">' +
          '<button type="button" aria-hidden="true" class="close text-white" data-notify="dismiss"><i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });

      this.lastNotificationTime = currentTime;
    }
  }
}
