import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { ConfigService } from 'src/app/config.service';
import { InteractionService } from 'src/app/interaction.service';
import { Prize } from 'src/app/prize.interface';
import { AppComponent } from '../../app.component';
import { Round } from 'src/app/round.interface';
import { Currency } from '../../currency.interface';
import axios from "axios";

@Component({
  selector: 'app-recovered-jackpot-details',
  templateUrl: './recovered-jackpot-details.component.html',
  styleUrls: ['./recovered-jackpot-details.component.scss']
})
export class RecoveredJackpotDetailsComponent implements OnInit {

  @ViewChild('frame', { static: true }) modalDetails: ModalDirective;

  @Input() currentRound: number;

  bolaoName: string;
  roundsChampionship: Round[];
  prizes: Prize[];
  prizesToShow = {};

  Currency: Currency = <Currency>{};

  constructor(private interactionService: InteractionService, private configService: ConfigService,
    private appComponent: AppComponent) { }

  ngOnInit() {
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  handleButtonClick(event: Event): void {
    event.stopPropagation();
  }

  loadRecoveredTable() {
    let roundId: number;
    this.configService.getRoundById(this.currentRound)
      .subscribe(data => {
        roundId = data.id;
        this.bolaoName = data.name;
      // }, error => {
      //   console.log(error);
      // });

    this.configService.getChampionshipByRound(roundId)
      .subscribe(data => {
        this.roundsChampionship = data;
        for (let i = 0; data && i < this.roundsChampionship.length; i++) {
          let localDateTime = (new Date(this.roundsChampionship[i].startDateTime)).getTime() - (new Date()).getTimezoneOffset() * 60000;
          this.roundsChampionship[i].startDateTime = new Date(localDateTime);
          localDateTime = (new Date(this.roundsChampionship[i].endDateTime)).getTime() - (new Date()).getTimezoneOffset() * 60000;
          this.roundsChampionship[i].endDateTime = new Date(localDateTime);
        }
      }, error => {
        console.log(error);
      });
    });
  }

  setRedColor(firstJackpot, recoveredJackpot): boolean {
    //if (firstJackpot > recoveredJackpot){
    if (recoveredJackpot < 0) {
      return true;
    }
    else {
      return false;
    }
  }
}
