<div class="row rowlajust" style="background-color: #676767;border-bottom: 1px solid #fff">
    <div class="col-12 p-0">
        <div class="side-menu">{{ 'comp-search-ticket.search-ticket' | translate }}</div>
        <div class="serch-ticket">
            <form class="form-inline">
                <div class="row" style="margin:0px!important">
                    <div class="col-12" style="padding-left: 0px;padding-right:0px">
                        <mat-label style="font-size:16px">{{ 'comp-search-ticket.ticket-code' | translate }}: </mat-label>
                    </div>
                    <div class="col-5 mb-2" style="padding-left: 0px;">
                        <!--<input id="code-search" class="form-control mr-sm-2 code-field" type="text" required />-->
                        <input type="text" id="code-search-mobile" required />
                    </div>
                    <div class="col-5 mb-2" style="padding-left: 0px;">
                        <button type="button" mdbBtn class="button-bg-theme button-font search-button mr-2"
                            (click)="searchTicket()">{{ 'comp-search-ticket.search' | translate }}</button>
                    </div>
                    <div class="col-2" style="padding:0px;">
                        <div class="lang-div">
                            <div class="dropdown-language">
                                <button class="dropbtn">
                                    <img src="../assets/image/flags/{{activeLang}}.png" />
                                    <mat-icon aria-hidden="false" aria-label="Example home icon" color="black">
                                        keyboard_arrow_down</mat-icon>
                                </button>
                                <div class="dropdown-content">
                                    <a [ngClass]="{'active': activeLang == 'pt'}" (click)="changeLanguage('pt');">
                                        <img src="../assets/image/flags/pt.png" />
                                    </a>
                                    <a [ngClass]="{'active': activeLang == 'en'}" (click)="changeLanguage('en')">
                                        <img src="../assets/image/flags/en.png" />
                                    </a>
                                    <a [ngClass]="{'active': activeLang == 'es'}" (click)=" changeLanguage('es')">
                                        <img src="../assets/image/flags/es.png" />
                                    </a>
                                    <a [ngClass]="{'active': activeLang == 'it'}" (click)="changeLanguage('it')">
                                        <img src="../assets/image/flags/it.png" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
