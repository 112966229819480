import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(public loaderService: LoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('GetWithdrawPending') == -1 && req.url.indexOf('GetDocumentPending') == -1
      && req.url.indexOf('users') == -1 && req.url.indexOf('APIUserInfo') == -1 && req.url.indexOf('BetradarSports') == -1
      && req.url.indexOf('prizes') == -1 && req.url.indexOf('getcompleteactivatedrounds') == -1 && req.url.indexOf('GetActivatedTicketList') == -1) {
      this.loaderService.show();
    }
    return next.handle(req).pipe(
      finalize(() => this.loaderService.hide())
    );
  }
}
