<div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
  <div class="lobby-userInfo w-100"> <!-- this div must only appear in the lobby url -->
    <ng-container *ngIf="this.userAdmin">
      <span>Username: {{ userAdmin?.login }}</span>
      <span>{{ 'pg-app-component.credit' | translate }}:
        <span style="color: rgb(7, 255, 7) ;"> {{Currency.currencySymbol}} {{userAdmin.credit * Currency.quote |
          currency:'':''}} </span>
      </span>
    </ng-container>
    <ng-container>
      <div *ngIf="!this.userAdmin" class="spinner-border align-self-center text-center" role="status"
        style="margin: 0 auto;">
        <span class="sr-only">Loading...</span>
      </div>
    </ng-container>
  </div>

  <app-cart-badge style="top: 8%; position: fixed; z-index: 10;"></app-cart-badge>
  <!-- <app-bet-grid class="w-100"></app-bet-grid> -->
  <app-newbetgrid class="w-100"></app-newbetgrid>
</div>
<app-ticket-panel></app-ticket-panel>
