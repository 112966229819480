<div class="container mt-4 p-0 side-menu-tree" style="max-height: fit-content; overflow-x: auto;">

  <div class="col-md-12">
    <app-ticket></app-ticket>
  </div>


  <!--<div class="row side-menu-tree-title" style="margin-left: 0px;">
    {{ 'comp-roundgroups.live' | translate }}
    &nbsp;
    <div class="blink_me">
      <i class="fa fa-circle" aria-hidden="true"></i>&nbsp;
    </div>
  </div>

   <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="row side-menu-tree">

    <mat-nested-tree-node *matTreeNodeDef="let node; when: isGroup">
      <li>
        <div class="mat-tree-node side-menu-tree-node" [class.sport-level-format]="sportLevelFormat(node.id)"
          [class.match-level-format]="(node.sportId != undefined && node.sportId != 0 && node.code != null)"
          [ngClass]="getGameIcon(node.name) ? 'main-menu' : !getGameIcon(node.name) && !getFlagByName(node.name) ? 'last-menu' : 'other'">
          <button style="min-width: 192px !important; border-radius: 0px !important; text-align: left; margin-left: 3px"
            [ngStyle]="{'margin-left': (!getGameIcon(node.name) && getFlagByName(node.name) && node.id != -2 && node.code == null && node.id?.toString().length != 5 ) || node.name == 'Other Countries'? '20px' : ((node.name == 'Other Tournaments') || (node.name != 'Other Tournaments' && node.id?.toString().length == 5 && node.code == null && !getGameIcon(node.name))) ? '50px' : (node.sportId != undefined && node.sportId != 0 && node.code != null && node.id != -2) ? '15px' : '10px'}"
            mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name"
            (click)="node.sportId == undefined ? showMatches(node) : loadMatch(node.code,node.sportId,'mob')">
            <mat-icon *ngIf="getGameIcon(node.name)" class="mat-icon-rtl-mirror">
              {{getGameIcon(node.name)}}
            </mat-icon>
            <img style="margin-right: 5px;"
              *ngIf="!getGameIcon(node.name) && getFlagByName(node.name) && node.code == null && node.name != 'Other Tournaments' && node.id.toString().length < 5"
              height="18" width="18" src="{{getFlagByName(node.name)}}" alt="" />
            <mat-icon *ngIf="!getGameIcon(node.name) && !getFlagByName(node.name) && node.sportId == undefined"
              class="mat-icon-rtl-mirror">
              emoji_events
            </mat-icon>
            <span [class.sport-level-format-text]="sportLevelFormat(node.id)">{{customTranslate(node.name)}}</span>
            <mat-icon class="mat-icon-rtl-mirror" *ngIf="node.sportId == undefined">
              {{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_left'}}
            </mat-icon>
          </button>
        </div>
        <ul [class.side-menu-tree-invisible]="treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: !isGroup" matTreeNodeToggle>
      <li class="mat-tree-node side-menu-tree-leaf force-black">
        <button mat-icon-button (click)="showMatches(node);">
          <span class="text-black">{{node.name}}</span>
        </button>
      </li>
    </mat-tree-node>

  </mat-tree> -->
</div>