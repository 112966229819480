<div class="container" (window:resize)="handleResize()">
    <div class="row">
        <!-- Card -->
        <div class="card-body bg-theme" style="width: 80vw;">
            <!-- Card content -->

            <div *ngIf="!isLoaded" id="spinner-loading">
                <!-- Loading (desapears when is loaded) -->
                <div class="justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

            <div *ngIf="isLoaded">
                <h3>
                    {{ 'pg-settings.personal-data' | translate }}
                </h3>
                <hr />
            </div>

            <ng-container *ngIf="checkClub == true">
                <div class="d-flex flex-column mb-2">
                    <div class="row">
                        <div class="col">
                            <div class="font-weight-bold">{{ 'friendCode.title' | translate }}:</div>
                        </div>
                    </div>
                    <div class="container-FriendCode">
                        <div class="row adjustCenter">
                            <div class="col-sm-5">
                                <div class="grid-container">
                                    <div class="adjustContainerWidith">
                                        <div class="col qrCodeTitle">QrCode:</div>
                                        <div class="col qrCodeMain">
                                            <img class="QrCodeWidith" src="{{QrCodeLink}}" alt="">
                                        </div>
                                        <div class="col qrCodefootet">
                                            <button class="buttonStyle" (click)="downloadImage(QrCodeLink)">{{
                                                'friendCode.qrDownload' | translate }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 flexAdJust">
                                <div class="LinkCode">
                                    <div class="grid-container">
                                        <div class="adjustContainerWidith">
                                            <div class="col linkTitle">Link:</div>
                                            <div class="col linkMain">
                                                <input class="LinkCodeWidith" type="text" name="" id="LinkCodeCopy"
                                                    value="{{linkRefCode}}" disabled>
                                            </div>
                                            <div class="col linkfootet">
                                                <button class="buttonStyle" (click)="copyLinkRefCode()">{{
                                                    'friendCode.copyLink' | translate }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- <ng-template #HideFriendCode>
                <div class="d-flex flex-column mb-2" hidden>
                    <div class="row">
                        <div class="col">
                            <div class="font-weight-bold">Codigo Amigo:</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="QrCode">
                                <div>QRCODE:</div>
                                <img class="QrCodeWidith" src="https://cdn.discordapp.com/attachments/385092801425178628/1121890024699478036/frame.png" alt="">
                            </div>
                        </div>
                        <div class="col">
                            <div class="LinkCode">
                                <div>Link do Club:</div>
                                <input class="LinkCodeWidith" type="text" name="" id="LinkCodeCopy" value="{{linkRefCode}}"
                                    disabled>
                                <button (click)="copyLinkRefCode()">Copiar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template> -->

            <div class="updateDataContent">
                <div class="userData theme_greyDG">
                  <div class="mb-3">
                    <p class="mb-0">
                      {{ 'pg-settings.username' | translate }}:
                    </p>
                    <input class="inputStyle_DG specialWidth" type="text" value="{{user.login}}" disabled/>
                  </div>

                  <div class="userDataCenter">
                    <div class="userDataLeft">
                      <div class="d-flex flex-column mb-3">
                        <label class="mb-0" for="name">
                          {{ 'pg-settings.name' | translate }}:
                        </label>
                        <input class="inputStyle_DG" type="text" [(ngModel)]="user.name" id="name">
                      </div>

                      <div class="d-flex flex-column mb-3">
                        <label class="mb-0" for="email">
                          {{ 'pg-checkout.e-mail' | translate }}:
                        </label>
                        <input class="inputStyle_DG" type="email" [(ngModel)]="user.email" id="email">
                      </div>

                      <div class="d-flex flex-column mb-3">
                        <label class="mb-0" for="email2">
                          {{ 'pg-settings.email2' | translate }}:
                        </label>
                        <input class="inputStyle_DG" type="email" [(ngModel)]="user.email2" id="email2">
                      </div>

                      <div class="d-flex flex-column mb-3">
                        <label class="mb-0" for="wpp">
                          Whatsapp:
                        </label>
                        <input class="inputStyle_DG" type="number" [(ngModel)]="user.whatsapp" id="wpp">
                      </div>

                      <div class="d-flex flex-column mb-3">
                        <label class="mb-0" for="cellphone">
                          {{ 'pg-settings.phone' | translate }} / {{ 'pg-settings.cellphone' | translate }}:
                        </label>
                        <input class="inputStyle_DG" type="number" [(ngModel)]="user.cellPhone" id="cellphone">
                      </div>
                    </div>

                    <div class="userDataRight">
                      <div class="d-flex flex-column mb-3">
                        <label class="mb-0" for="lastname">
                          {{ 'pg-settings.last-name' | translate }}:
                        </label>
                        <input class="inputStyle_DG" type="text" [(ngModel)]="user.lastName" id="lastname">
                      </div>

                      <div class="d-flex flex-column mb-3">
                        <label class="mb-0" for="address">
                          {{ 'pg-settings.address' | translate }}:
                        </label>
                        <input class="inputStyle_DG" type="text" [(ngModel)]="user.address" id="address">
                      </div>

                      <div class="d-flex flex-column mb-3">
                        <label class="mb-0" for="state">
                          <!-- {{ 'pg-settings.address' | translate }}: -->
                          {{'pg-settings.state' | translate}}:
                        </label>
                        <input class="inputStyle_DG" type="text" [(ngModel)]="user.city" id="state">
                      </div>

                      <div class="d-flex flex-column mb-3">
                        <label class="mb-0">
                          {{ 'pg-settings.country' | translate }}:
                        </label>
                        <select class="inputStyle_DG" country name="country" id="country-field" [(ngModel)]="user.country" [disabled]="docs">
                              <option value="{{selectedCountry}}">
                                  {{selectedCountry}}
                              </option>
                              <option [value]="item.name" *ngFor="let item of countries">
                                  {{item.name}}
                              </option>
                          </select>
                      </div>

                      <div class="d-flex flex-column mb-3">
                        <label class="mb-0" for="phone2">
                          {{ 'pg-settings.phone' | translate }} / {{ 'pg-settings.cellphone' | translate }} 2:
                        </label>
                        <input class="inputStyle_DG" type="number" [(ngModel)]="user.phone2" id="phone2">
                      </div>
                    </div>
                  </div>


                  <button class="btnStyle_DG specialWidth" (click)="editUser()" [disabled]="docs">
                    {{'pg-settings.change-data' | translate }}
                  </button>
                </div>

                <div class="passwordData theme_greyDG">
                  <p style="font-size: 16px; font-weight: bold; margin-bottom: 5vh;">
                    {{ 'pg-settings.updatePassword' | translate }}:
                  </p>

                  <div class="mb-3">
                    <p class="mb-0">
                      {{ 'pg-settings.current-password' | translate }}:
                    </p>
                    <input class="inputStyle_DG" type="password" [(ngModel)]="currentPassword">
                  </div>

                  <div class="mb-3">
                    <p class="mb-0">
                      {{ 'pg-settings.new-password' | translate }}:
                    </p>
                    <input class="inputStyle_DG" type="password" [(ngModel)]="newPassword">
                  </div>

                  <div class="mb-3">
                    <p class="mb-0">
                      {{ 'pg-settings.confirm-new' | translate }}:
                    </p>
                    <input class="inputStyle_DG" type="password" [(ngModel)]="confirmPassword">
                  </div>

                  <p *ngIf="this.newPassword != this.confirmPassword" class="infomP">
                    {{'pg-settings.password-not-match-info' | translate}}
                  </p>

                  <button class="btnStyle_DG" [disabled]="passwordsChekeds()" (click)="changePassword()">{{
                    'pg-settings.change-password' | translate }}
                  </button>
                </div>
            </div>
        </div>
        <!-- Card -->
    </div>
</div>

<!--Modal Edit User-->
<div mdbModal #frame="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    onOpen="fullFillUserForm()" aria-labelledby="myModalLabel" aria-hidden="true"
    [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-primary" role="document">
        <!--Content-->
        <div class="modal-content bg-theme">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">{{ 'pg-settings.edit-user' | translate }}</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div class="text-center">
                    <form style="color: #757575;" #f="ngForm">

                        <!-- User Data -->
                        <mat-form-field class="w-100">
                            <mat-label>{{ 'pg-settings.name' | translate }} *</mat-label>
                            <input style="color:white" type="text" matInput [(ngModel)]="user.name" userName
                                name="userName">
                        </mat-form-field>
                        <mat-form-field class="w-100">
                            <mat-label>{{ 'pg-settings.last-name' | translate }}</mat-label>
                            <input style="color:white" type="text" matInput [(ngModel)]="user.lastName" lastName
                                name="lastName">
                        </mat-form-field>
                        <mat-form-field class="w-100">
                            <mat-label>E-mail *</mat-label>
                            <input style="color:white" type="text" matInput [(ngModel)]="user.email" userEmail ngModel
                                name="userEmail">
                        </mat-form-field>
                        <!-- <mat-form-field class="w-100">
                            <mat-label>{{ 'pg-settings.country' | translate }}</mat-label>
                            <input style="color:white" type="text" matInput [(ngModel)]="user.country" country ngModel
                                name="country">
                        </mat-form-field> -->
                        <mat-form-field class="w-100">
                            <mat-label>{{ 'pg-settings.country' | translate}}</mat-label>
                            <mat-select [(value)]="user.country" country ngModel name="country">
                                <mat-option *ngFor="let p of paises" [value]="p.name">
                                    {{p.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="w-100">
                            <mat-label>{{ 'pg-settings.city' | translate }}</mat-label>
                            <input style="color:white" type="text" matInput [(ngModel)]="user.city" city ngModel
                                name="city">
                        </mat-form-field>

                    </form>
                    <div class="row">
                        <div class="col-6">
                            <button mdbBtn class="button-bg-theme button-font" (click)="editUser()">{{
                                'pg-settings.save-changes' | translate }}</button>
                        </div>
                        <div class="col-6">
                            <button mdbBtn color="warning" outline="true" (click)="closeModalUser()">{{
                                'pg-settings.back' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal Edit User-->

<!--Modal Change Password-->
<div mdbModal #frame2="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    onOpen="fullFillUserForm()" aria-labelledby="myModalLabel" aria-hidden="true"
    [config]="{backdrop: true, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-primary" role="document">
        <!--Content-->
        <div class="modal-content bg-theme">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">{{ 'pg-settings.change-password' | translate }}</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame2.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body password-modal">
                <div class="text-center">
                    <form style="color:white" #f2="ngForm">

                        <!-- User Data -->
                        <mat-form-field class="w-100">
                            {{ 'pg-settings.current-password' | translate }}
                            <input class="pass-fields" type="password" matInput required ngModel currentPassword
                                name="currentPassword">
                        </mat-form-field>
                        <mat-form-field class="w-100">
                            {{ 'pg-settings.new-password' | translate }}
                            <input class="pass-fields" type="password" matInput required ngModel newPassword
                                name="newPassword">
                        </mat-form-field>
                        <mat-form-field class="w-100">
                            {{ 'pg-settings.confirm-new' | translate }}
                            <input class="pass-fields" type="password" matInput required ngModel newPassword
                                name="confirmPassword">
                        </mat-form-field>
                    </form>
                    <div class="row d-flex justify-content-center align-items-center">
                        <div class="col d-flex justify-content-center align-items-center">
                            <button mdbBtn class="btn-primary" [disabled]="f2.invalid" (click)="changePassword()">{{
                                'pg-settings.change-password' | translate }}</button>
                        </div>
                        <div class="col-6 d-flex justify-content-center align-items-center">
                            <button mdbBtn class="btn-danger" (click)="closeModalPassword()">{{
                                'pg-settings.back' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal Change Password-->
