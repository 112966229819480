<div class="container">
  <!-- Card -->
  <div class="card mb-5">
      <!-- Card content -->
      <div class="card-body bg-theme" *ngIf="!isLoaded" id="spinner-loading">
        <!-- Loading (desapears when is loaded) -->
        <div  class="justify-content-center">
            <div class="spinner-border"role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
      </div>

      <div class="card-body bg-theme" *ngIf="isLoaded" >
          <div class="row">
              <div class="col-12 pt-3">
                  <h3>{{ 'pg-manage-group.manage-groups' | translate }}</h3>
                  <hr/>
              </div>

              <div class="col-md-6  col-12">

                <p>{{ 'pg-manage-group.existing-groups' | translate }}</p>

                <mat-tree class="tree-area" [dataSource]="dataSource" [treeControl]="treeControl">
                  <!-- This is the tree node template for leaf nodes -->
                  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                    <!-- use a disabled button to provide padding for tree leaf -->
                    <button mat-icon-button disabled></button>
                    {{node.name}}

                    <button mat-icon-button>
                      <mat-icon class="icon-edit" (click)="editGroup(node.id)">edit</mat-icon>
                    </button>
                    <button mat-icon-button>
                      <mat-icon class="icon-delete" (click)="deleteGroup(node.id)">delete</mat-icon>
                    </button>

                  </mat-tree-node>
                  <!-- This is the tree node template for expandable nodes -->
                  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                    <button mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'toggle ' + node.name">
                      <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                      </mat-icon>
                    </button>
                    {{node.name}}

                    <button mat-icon-button>
                      <mat-icon class="icon-edit" (click)="editGroup(node.id)">edit</mat-icon>
                    </button>
                    <button mat-icon-button>
                      <mat-icon class="icon-delete" (click)="deleteGroup(node.id)">delete</mat-icon>
                    </button>

                  </mat-tree-node>
                </mat-tree>

              </div>

              <div class="col-md-6 col-12">
                <p>{{ 'pg-manage-group.add-group' | translate }}</p>

                <p>
                  <mat-form-field class="example-full-width code-field">
                    <mat-label>{{ 'pg-manage-group.new-groups-name' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'pg-manage-group.new-groups-name' | translate }}" [(ngModel)]="newGroup">
                  </mat-form-field>
                </p>

                <p>
                  <mat-form-field class="code-field">
                    <mat-label>{{ 'pg-manage-group.choose-parent' | translate }}</mat-label>
                    <mat-select [(value)]="jurisdictionFather">
                      <mat-option value="0">Principal</mat-option>
                      <mat-option *ngFor="let groups of listRoundGroups" [value]="groups.id">
                        {{groups.name}}
                      </mat-option>
                    </mat-select>
                    <mat-hint>{{ 'pg-manage-group.hint' | translate }}</mat-hint>
                  </mat-form-field>
                </p>

                <p>
                  <button mat-button class="btn-success" (click)="createGroup()">
                    <mat-icon>add</mat-icon> {{ 'pg-manage-group.add' | translate }}
                  </button>
                </p>
              </div>
          </div>
      </div>
  </div>
  <!-- Card -->
</div>

<!--Modal edit -->
<div mdbModal #frame="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog" role="document">
        <div class="modal-content bg-theme">
            <div class="modal-header text-center">
                <h4 class="modal-title font-weight-bold" style="text-align: left;">{{ 'pg-manage-group.edit-group-name' | translate }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body mx-3">
                <div>
                    <p>
                      <mat-form-field class="code-field">
                        <mat-label>{{ 'pg-manage-group.desired-name' | translate }}</mat-label>
                        <input matInput [value]="changeName" [(ngModel)]="changeName">
                      </mat-form-field>
                    </p>
                </div>
            </div>
            <div class="modal-footer d-flex justify-content-start">
                <button mdbBtn class="waves-light button-bg-theme button-font" (click)="saveEdit()" mdbWavesEffect>{{ 'pg-manage-group.save' | translate }}</button>
                <button mdbBtn color="warning" outline="true" class="waves-light" (click)="frame.hide()" mdbWavesEffect>{{ 'pg-manage-group.close' | translate }}</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal edit -->

<!--Modal delete -->
<div mdbModal #frame2="mdbModal" class="modal fade right" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-danger" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h4 class="modal-title">{{ 'pg-manage-group.delete-round-group' | translate }}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame2.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body mx-3">
                <div>
                    <p>
                      
                      {{ 'pg-manage-group.confirmation-msg-1' | translate }} {{ changeName }} {{ 'pg-manage-group.confirmation-msg-2' | translate }}

                    </p>
                </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
                <button mdbBtn color="danger" class="waves-light button-font" (click)="confirmDelete()" mdbWavesEffect>{{ 'pg-manage-group.delete' | translate }}</button>
                <button mdbBtn color="danger" outline="true" class="waves-light" (click)="frame2.hide()" mdbWavesEffect>{{ 'pg-manage-group.back' | translate }}</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal delete -->