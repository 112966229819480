import { Injectable } from '@angular/core';
import { EUserActionsModalType } from '../../Interfaces/Enumerators/EUserActionsModalType'

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  private notificationModalComponent: any;
  private detailTickerComponent: any;
  private userActionsModalComponent: any;
  constructor() { }

  registerNotificationModal(notificationModalComponent: any) {
    this.notificationModalComponent = notificationModalComponent;
  }

  openNotificationModal(title: string, message: string, isError: boolean, redirectTo: string | null = null): void {
    this.notificationModalComponent.open(title, message, isError, redirectTo);
  }
  closeNotificationModal() {
    this.notificationModalComponent.close();
  }

  registerDetailsTicketModal(detailTickerComponent: any) {
    this.detailTickerComponent = detailTickerComponent;
  }

  openDetailTickeModal(id: number, uuid: string | null) {
    this.detailTickerComponent.open(id, uuid);
  }

  closeDetailsTicketModal() {
    this.detailTickerComponent.closeModal();
  }

  registerUserActionsModal(userActionsComponent: any) {
    this.userActionsModalComponent = userActionsComponent;
  }

  openUserActionsModal(modalType: EUserActionsModalType) {
    this.userActionsModalComponent.open(modalType);
  }
}
