import { Component, OnInit } from "@angular/core";
import { ConfigService } from "../config.service";
import { ToastService } from "../toast.service";
import { TranslateService } from "@ngx-translate/core";
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-crash-game',
  templateUrl: './crash-game.component.html',
  styleUrls: ['./crash-game.component.scss']
})
export class CrashGameComponent implements OnInit {
  mobileGameList: any;
  desktopGameList: any;
  userId: any;

  constructor(private configService: ConfigService, private toastService: ToastService, private translate: TranslateService,
  private appComponent: AppComponent) {}

  ngOnInit(): void {
    this.getCrashGameList("Spribe")
  }

  getCrashGameList(provider: string) {
    this.configService.getRiseUpGameListByProvider(provider).subscribe((gameList: any) => {
      this.desktopGameList = gameList.data.filter((x: any) => x.device === "desktop" || x.device === "html5");
      this.mobileGameList = gameList.data.filter((x: any) => x.device === "mobile");
    });
  }

  openCrashGameSession(element: any) {
    if(element.provider == null) {
      element.provider = "Aviator";
    }
    const device = element.device;
    if (localStorage.getItem("currentUser") === null || !this.appComponent.userAdmin) {
      this.toastService.showNotification({
        message: this.translate.instant("pg-home.must-be-logged"),
        type: "danger",
      });
      return;
    }
    this.userId = this.appComponent.userAdmin.id;
    if (device === "desktop" || device === "html5") {
      this.configService.getRiseUpGameLink(this.userId, element.id, "", device, element.name, element.provider)
      .subscribe((data: any) => {
        let desktopWindow = window.open('', "Slot", "width=1280, height=720");
        desktopWindow.window.document.body.style.margin = '0';
        desktopWindow.window.document.body.style.padding = '0';
        desktopWindow.window.document.title = element.name;
        desktopWindow.window.document.body.innerHTML = `<iframe style='width: 100%; height: 100%; border: none;' src='${data.data}'></iframe>`;
      });
    } else if(device === "mobile") {
      let mobilewindow = window.open();
      this.configService.getRiseUpGameLink(this.userId, element.id, "", device, element.name, element.provider)
      .subscribe((data: any) => {
        const gameLink = data.data;
        mobilewindow.window.document.body.style.margin = '0';
        mobilewindow.window.document.body.style.padding = '0';
        mobilewindow.window.document.title = element.name;
        mobilewindow.window.document.body.innerHTML = `<iframe style='width: 100%; height: 100%; border: none;' src='${gameLink}'></iframe>`;
      })
    }
  }

}
