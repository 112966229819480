import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DetailCoupon } from '../../Interfaces/DetailCoupon/DetailCoupon.interface';
import { HttpHeaders } from '@angular/common/http';
import { SignalrService } from 'src/app/signalr.service';

@Injectable({
  providedIn: 'root'
})
export class BetService {

  constructor(private http: HttpClient, private signalRService: SignalrService) { }

  getBetDetail(id: number, uuid: string): Observable<DetailCoupon[]>{
    return this.http.get<DetailCoupon[]>(`${this.signalRService.apiUrl}/bets/GetDetailBet?id=${id}&uuid=${uuid}`);
  }
}
