import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TableChoicesMadeService {

  constructor() { }

  static Generateconfirmtable2Choices(name, result: string, text1: string, text2: string){
    switch(result){
      case '1':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${text1}</div>
            <div class="event-Choices-Confirmation bg-none"></div>
            <div class="event-Choices-Confirmation">${text2}</div>
          </div>
        `;

      case '2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">${text1}</div>
            <div class="event-Choices-Confirmation bg-none"></div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${text2}</div>
          </div>
        `;
      case '12':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${text1}</div>
            <div class="event-Choices-Confirmation bg-none"></div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${text2}</div>
          </div>
        `;
    }
  }

  static GenerateNoHitTable2Choices(name, result: string, text1: string, text2: string){
    switch(result){
      case '1':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${text1}</div>
            <div class="event-Choices-Confirmation bg-none"></div>
            <div class="event-Choices-Confirmation">${text2}</div>
          </div>
        `;

      case '2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">${text1}</div>
            <div class="event-Choices-Confirmation bg-none"></div>
            <div class="event-Choices-Confirmation not-hit-Choice">${text2}</div>
          </div>
        `;
      case '12':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${text1}</div>
            <div class="event-Choices-Confirmation bg-none"></div>
            <div class="event-Choices-Confirmation not-hit-Choice">${text2}</div>
          </div>
        `;
    }
  }

  static GenarateConfirmTable2ChoicesOverUnder(name: string, result: string, odd, over:string, under:string) {
    switch(result){
      case '1':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${over}</div>
            <div class="event-Choices-Confirmation bg_green-text_white"> ${odd}</div>
            <div class="event-Choices-Confirmation">${under}</div>
          </div>
        `;

      case '2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">${over}</div>
            <div class="event-Choices-Confirmation bg_green-text_white">${odd}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${under}</div>
          </div>
        `;

      case '12':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${over}</div>
            <div class="event-Choices-Confirmation bg_green-text_white">${odd}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${under}</div>
          </div>
        `;
    }
  }

  static GenarateNoHitTable2ChoicesOverUnder(name: string, result: string, odd, over:string, under:string){
    switch(result){
      case '1':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${over}</div>
            <div class="event-Choices-Confirmation bg_green-text_white"> ${odd}</div>
            <div class="event-Choices-Confirmation">${under}</div>
          </div>
        `;

      case '2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">${over}</div>
            <div class="event-Choices-Confirmation bg_green-text_white">${odd}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${under}</div>
          </div>
        `;

      case '12':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${over}</div>
            <div class="event-Choices-Confirmation bg_green-text_white">${odd}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${under}</div>
          </div>
        `;
    }
  }

  static GenarateConfirmTable3Choices(name: string, result: string){
    switch(result){
      case '1':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">1</div>
            <div class="event-Choices-Confirmation">X</div>
            <div class="event-Choices-Confirmation">2</div>
          </div>
        `;

      case '2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">1</div>
            <div class="event-Choices-Confirmation">X</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">2</div>
          </div>
        `;

      case 'x':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">1</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">X</div>
            <div class="event-Choices-Confirmation">2</div>
          </div>
        `;
      case '12':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">1</div>
            <div class="event-Choices-Confirmation">X</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">2</div>
          </div>
        `;

      case '1x':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">1</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">X</div>
            <div class="event-Choices-Confirmation">2</div>
          </div>
        `;

      case 'x2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">1</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">X</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">2</div>
          </div>
        `;

      case '1x2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">1</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">X</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">2</div>
          </div>
        `;
    }
  }

  static GenarateConfirmTable3ChoicesDynamic(name: string, result: string, textLeft: string, textCenter:string, textRight: string){
    switch(result){
      case '1':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${textLeft}</div>
            <div class="event-Choices-Confirmation">${textCenter}</div>
            <div class="event-Choices-Confirmation">${textRight}</div>
          </div>
        `;

      case '2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">${textLeft}</div>
            <div class="event-Choices-Confirmation">${textCenter}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${textRight}</div>
          </div>
        `;

      case 'x':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">${textLeft}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${textCenter}</div>
            <div class="event-Choices-Confirmation">${textRight}</div>
          </div>
        `;
      case '12':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${textLeft}</div>
            <div class="event-Choices-Confirmation">${textCenter}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${textRight}</div>
          </div>
        `;

      case '1x':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${textLeft}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${textCenter}</div>
            <div class="event-Choices-Confirmation">${textRight}</div>
          </div>
        `;

      case 'x2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">${textLeft}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${textCenter}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${textRight}</div>
          </div>
        `;

      case '1x2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${textLeft}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${textCenter}</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">${textRight}</div>
          </div>
        `;
    }
  }

  static GenarateConfirmTable3ChoicesWithImage(homename: string, awayName, shieldHome: string, shieldAway: string, result: string){
    switch(result){
      case '1':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
                ${homename}
              </div>
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
                ${awayName}
              </div>
            </div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">1</div>
            <div class="event-Choices-Confirmation">X</div>
            <div class="event-Choices-Confirmation">2</div>
          </div>
        `;

      case '2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
                ${homename}
              </div>
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
                ${awayName}
              </div>
            </div>
            <div class="event-Choices-Confirmation">1</div>
            <div class="event-Choices-Confirmation">X</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">2</div>
          </div>
        `;

      case 'x':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
                ${homename}
              </div>
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
                ${awayName}
              </div>
            </div>
            <div class="event-Choices-Confirmation">1</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">X</div>
            <div class="event-Choices-Confirmation">2</div>
          </div>
        `;
      case '12':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
                ${homename}
              </div>
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
                ${awayName}
              </div>
            </div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">1</div>
            <div class="event-Choices-Confirmation">X</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">2</div>
          </div>
        `;

      case '1x':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
                ${homename}
              </div>
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
                ${awayName}
              </div>
            </div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">1</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">X</div>
            <div class="event-Choices-Confirmation">2</div>
          </div>
        `;

      case 'x2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
                ${homename}
              </div>
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
                ${awayName}
              </div>
            </div>
            <div class="event-Choices-Confirmation">1</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">X</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">2</div>
          </div>
        `;

      case '1x2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
                ${homename}
              </div>
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
                ${awayName}
              </div>
            </div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">1</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">X</div>
            <div class="event-Choices-Confirmation active-Confirmation-Choice">2</div>
          </div>
        `;
    }
  }

  static GenarateNoHitTable3Choices(name: string, result: string){
    switch(result){
      case '1':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">1</div>
            <div class="event-Choices-Confirmation">X</div>
            <div class="event-Choices-Confirmation">2</div>
          </div>
        `;

      case '2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">1</div>
            <div class="event-Choices-Confirmation">X</div>
            <div class="event-Choices-Confirmation not-hit-Choice">2</div>
          </div>
        `;

      case 'x':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">1</div>
            <div class="event-Choices-Confirmation not-hit-Choice">X</div>
            <div class="event-Choices-Confirmation">2</div>
          </div>
        `;
      case '12':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">1</div>
            <div class="event-Choices-Confirmation">X</div>
            <div class="event-Choices-Confirmation not-hit-Choice">2</div>
          </div>
        `;

      case '1x':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">1</div>
            <div class="event-Choices-Confirmation not-hit-Choice">X</div>
            <div class="event-Choices-Confirmation">2</div>
          </div>
        `;

      case 'x2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">1</div>
            <div class="event-Choices-Confirmation not-hit-Choice">X</div>
            <div class="event-Choices-Confirmation not-hit-Choice">2</div>
          </div>
        `;

      case '1x2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">1</div>
            <div class="event-Choices-Confirmation not-hit-Choice">X</div>
            <div class="event-Choices-Confirmation not-hit-Choice">2</div>
          </div>
        `;
    }
  }

  static GenarateNoHitTable3ChoicesDynamic(name: string, result: string, textLeft: string, textCenter:string, textRight: string){
    switch(result){
      case '1':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${textLeft}</div>
            <div class="event-Choices-Confirmation">${textCenter}</div>
            <div class="event-Choices-Confirmation">${textRight}</div>
          </div>
        `;

      case '2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">${textLeft}</div>
            <div class="event-Choices-Confirmation">${textCenter}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${textRight}</div>
          </div>
        `;

      case 'x':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">${textLeft}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${textCenter}</div>
            <div class="event-Choices-Confirmation">${textRight}</div>
          </div>
        `;
      case '12':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${textLeft}</div>
            <div class="event-Choices-Confirmation">${textCenter}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${textRight}</div>
          </div>
        `;

      case '1x':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${textLeft}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${textCenter}</div>
            <div class="event-Choices-Confirmation">${textRight}</div>
          </div>
        `;

      case 'x2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation">${textLeft}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${textCenter}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${textRight}</div>
          </div>
        `;

      case '1x2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">${name}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${textLeft}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${textCenter}</div>
            <div class="event-Choices-Confirmation not-hit-Choice">${textRight}</div>
          </div>
        `;
    }
  }

  static GenarateNoHitTable3ChoicesWithImage(homename: string, awayName, shieldHome: string, shieldAway: string, result: string){
    switch(result){
      case '1':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
                ${homename}
              </div>
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
                ${awayName}
              </div>
            </div>
            <div class="event-Choices-Confirmation not-hit-Choice">1</div>
            <div class="event-Choices-Confirmation">X</div>
            <div class="event-Choices-Confirmation">2</div>
          </div>
        `;

      case '2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
                ${homename}
              </div>
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
                ${awayName}
              </div>
            </div>
            <div class="event-Choices-Confirmation">1</div>
            <div class="event-Choices-Confirmation">X</div>
            <div class="event-Choices-Confirmation not-hit-Choice">2</div>
          </div>
        `;

      case 'x':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
                ${homename}
              </div>
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
                ${awayName}
              </div>
            </div>
            <div class="event-Choices-Confirmation">1</div>
            <div class="event-Choices-Confirmation not-hit-Choice">X</div>
            <div class="event-Choices-Confirmation">2</div>
          </div>
        `;
      case '12':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
                ${homename}
              </div>
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
                ${awayName}
              </div>
            </div>
            <div class="event-Choices-Confirmation not-hit-Choice">1</div>
            <div class="event-Choices-Confirmation">X</div>
            <div class="event-Choices-Confirmation not-hit-Choice">2</div>
          </div>
        `;

      case '1x':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
                ${homename}
              </div>
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
                ${awayName}
              </div>
            </div>
            <div class="event-Choices-Confirmation not-hit-Choice">1</div>
            <div class="event-Choices-Confirmation not-hit-Choice">X</div>
            <div class="event-Choices-Confirmation">2</div>
          </div>
        `;

      case 'x2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
          <div class="event-name-Confirmation">
            <div>
              <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
              ${homename}
            </div>
            <div>
              <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
              ${awayName}
            </div>
            </div>
            <div class="event-Choices-Confirmation">1</div>
            <div class="event-Choices-Confirmation not-hit-Choice">X</div>
            <div class="event-Choices-Confirmation not-hit-Choice">2</div>
          </div>
        `;

      case '1x2':
        return `
          <div class="div-information-confirmation-ticket bg-theme-grey">
            <div class="event-name-Confirmation">
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
                ${homename}
              </div>
              <div>
                <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
                ${awayName}
              </div>
            </div>
            <div class="event-Choices-Confirmation not-hit-Choice">1</div>
            <div class="event-Choices-Confirmation not-hit-Choice">X</div>
            <div class="event-Choices-Confirmation not-hit-Choice">2</div>
          </div>
        `;
    }
  }

  static GenerateConfirmTableRankMatch(name: string, colocacao: string){
    return `
      <div class="div-information-confirmation-ticket bg-theme-grey">
        <div class="event-name-Confirmation">${name}</div>
        <div class="event-Choices-Confirmation bg-none"></div>
        <div class="event-Choices-Confirmation active-Confirmation-Choice">${colocacao}</div>
      </div>
    `;
  }

  static GenerateResultTableRankMatch(shield: string, name: string, colocacao: string){
    return `
      <div class="div-information-confirmation-ticket bg-theme-grey">
        <div class="event-name-Confirmation"> <img class='mr-3' width='30px' src='${shield}'> ${name} </div>
        <div class="event-Choices-Confirmation bg-none"></div>
        <div class="event-Choices-Confirmation active-Confirmation-Choice">${colocacao}</div>
      </div>
    `;
  }

  static GenerateNoHitTableRankMatch(shield: string, name: string, colocacao: string){
    return `
      <div class="div-information-confirmation-ticket bg-theme-grey">
        <div class="event-name-Confirmation"> <img class='mr-3' width='30px' src='${shield}'> ${name} </div>
        <div class="event-Choices-Confirmation bg-none"></div>
        <div class="event-Choices-Confirmation not-hit-Choice">${colocacao}</div>
      </div>
    `;
  }
  static GenerateResultCanceledWithShield(name:string, cancel: string, shieldName: string){
    return `
      <div class="div-information-confirmation-ticket bg-theme-grey">
        <div class="event-name-Confirmation">
            <img class="mr-3" width="30px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldName}">
             ${name}
        </div>
        <div class="cancel-choice-event">${cancel}</div>
      </div>
    `;
  }

  static GenerateResultCanceled(name:string, cancel: string){
    return `
      <div class="div-information-confirmation-ticket bg-theme-grey">
        <div>
          ${name}
        </div>
        <div class="cancel-choice-event">${cancel}</div>
      </div>
    `;
  }

  static GenerateResultCanceledWithImage(homeName: string, shieldHome: string, awayName: string, shieldAway: string, cancelText: string){
    return `<div class="div-information-confirmation-ticket bg-theme-grey">
              <div class="event-name-Confirmation">
                <div>
                  <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldHome}">
                  ${homeName}
                </div>
                <div>
                  <img class="mr-1 mb-1 mt-1" width="21px" onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src="${shieldAway}">
                  ${awayName}
                </div>
              </div>
              <div class="cancel-choice-event">${cancelText}</div>
            </div>`;
  }
}
