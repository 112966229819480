import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from '../services/cookie/cookie.service';
import { AppComponent } from '../app.component';
import { ConfigService } from '../config.service';
import { InteractionService } from '../interaction.service';
import { Login } from '../login.interface';
import { UserCookieService } from '../services/User/UserCookie.service';
import { User } from '../user.interface';

@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.scss']
})
export class ApiComponent implements OnInit {

  login: string;
  email: string;
  credit: number;
  parentId: number;
  visitorId: number;
  key: string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private configService: ConfigService, private interactionservice: InteractionService,
    private cookieService: CookieService, private appComponent: AppComponent,
    private userCookieService: UserCookieService) {
    }

  ngOnInit() {
    this.cookieService.deleteAll();
    this.router.navigate(['/lobby']);
    this.login = this.activatedRoute.snapshot.paramMap.get("login") || "";
    this.email = this.activatedRoute.snapshot.paramMap.get("email") || "";
    this.credit = +this.activatedRoute.snapshot.paramMap.get("credit")!;
    this.parentId = +this.activatedRoute.snapshot.paramMap.get("parentId")!;
    this.visitorId = +this.activatedRoute.snapshot.paramMap.get("visitorId")!;
    this.key = this.activatedRoute.snapshot.paramMap.get("key") || "";

    this.registerAPIUser();
  }

  registerAPIUser() {
    this.configService.registerAPIUser(this.login, this.email, this.credit, this.parentId, this.visitorId, this.key)
      .subscribe((data: User) => {
        let loginData: Login = {
          user: data.login,
          token: this.key,
          message: 'Login success'
        }
        this.interactionservice.setLoginData(loginData);
        this.appComponent.userAdmin = data;
        this.appComponent.userAdmin.userPermissions =
          [{ value: true }, { value: false }, { value: false }, { value: false }, { value: false }, { value: false }]
        // localStorage.setItem('currentUser', data.id.toString());
        this.userCookieService.setUserId(data.id);
        localStorage.setItem('currentUserToken', this.key);

        if (data.country == null) {
          localStorage.setItem('currentUserCountry', 'Brazil');
        } else {
          localStorage.setItem('currentUserCountry', data.currencyCode!);
        }

        this.interactionservice.setIsLogged(true);
        this.cookieService.set('user', JSON.stringify(data), 1);
        // this.appComponent.checkCredit();
        this.configService.setGlobalCurrency();

        // this.router.navigate(['/home']);
      }, error => {
        console.log(error);
      });
  }

}
