<div *ngIf="multipleBets == true">
    <div class="msg-modal modal fade right" [ngClass]="{'show': isVisible}" tabindex="-1" role="dialog">
        <div class="modal-detail" role="document">
            <div class="modal-content bg-theme scrollDeg">
                <div class="modal-header text-center notification" style="background-color: #5e5e5e;">
                    <h5 class="modal-title w-100 font-weight-bold" style="color: white">
                        {{"pg-tickets.resume-ticket" | translate}}</h5>
                    <button type="button" class="close" (click)="closeModal()">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body adjustModel scrollDeg pt-2 pb-2">
                    <table>
                        <tr class="table-header">
                            <th>
                                {{'Round' | translate}}
                            </th>
                            <th>
                                {{"pg-home.player" | translate}}
                            </th>
                        </tr>
                        <tr class="table-row-choice" *ngFor="let betDetail of details"
                            (click)="selectCoupon(betDetail)">
                            <th>
                                {{betDetail.roundName}}
                            </th>
                            <th>
                                {{betDetail.bet.playerName}}
                            </th>
                        </tr>
                    </table>
                </div>
                <div class="modal-footer d-flex justify-content-center modal-footer-size">
                    <a type="button" class="modal-deatilCoupon-butto" (click)="closeModal()">{{"pg-home.back"|translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="multipleBets == false">
    <div class="msg-modal modal fade right" [ngClass]="{'show': isVisible}" tabindex="-1" role="dialog">
        <div class="modal-detail" role="document">
            <div class="modal-content bg-theme scrollDeg">
                <div class="modal-header text-center notification" style="background-color: #5e5e5e;">
                    <h5 class="modal-title w-100 font-weight-bold" style="color: white">
                        {{"pg-tickets.resume-ticket" | translate}} {{detail.bet.uuid8.slice(0,8)}} ...</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body ml-2 adjustModel scrollDeg pt-2 pb-2" id="print-section2">
                    <div>
                        <img src="../../../assets/image/Icon-print.svg" width="18px" (click)="print()">
                        <!-- Nome da rodada -->
                        <div *ngIf="detail.roundType != 0" class="modal-detail-roundName">
                            <img src="{{getBasicShield()}}" width="30px"> {{detail.roundName}}
                        </div>

                        <div *ngIf="detail.roundType == 0" class="modal-detail-roundName">
                            <img src="{{getSingleMatchShield(0)}}" width="30px"> {{detail.roundName}} <img
                                src="{{getSingleMatchShield(1)}}" width="30px">
                        </div>

                        <!-- Valor do ticket -->
                        <div class="modal-detail-legend">
                            <div>
                                <strong>{{"pg-credit-transfer.value" | translate}}</strong>:
                            </div>
                            <div>{{configService.formatCurrencyValue(detail.bet.value)}}</div>
                        </div>

                        <!-- Player name -->
                        <div class="modal-detail-legend">
                            <div>
                                <strong>{{"pg-tickets.player" | translate}}</strong>:
                            </div>
                            <div>{{detail.bet.playerName}}</div>
                        </div>

                        <!-- Data do ticket -->
                        <div class="modal-detail-legend">
                            <div>
                                <strong>{{"pg-post-result.date" | translate}}</strong>:
                            </div>
                            <div>{{datePipe.transform(detail.bet.betDate, 'dd/MM/y HH:mm')}}</div>
                        </div>

                        <!-- Events canceleds -->
                        <div class="modal-detail-legend" *ngIf="detail.roundPosted">
                            <div>
                                <strong>{{"pg-manage-ticket.canceled" | translate}}</strong>:
                            </div>
                            <div>{{numberEventsCanceled}}</div>
                        </div>

                        <!-- Ticket Winner? -->
                        <div class="modal-detail-legend" *ngIf="detail.roundPosted">
                            <div>
                                <strong>{{"pg-manage-ticket.status" | translate}}</strong>:
                            </div>
                            <div [ngClass]="detail.winner ? 'text-success' : ''">{{checkWinner(detail.winner) |
                                translate}}</div>
                        </div>

                        <!-- TableChoice are -->
                        <div class="mt-3" id="TableOfChoices" [innerHtml]="tableOfChoices">
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex justify-content-center modal-footer-size">
                    <a type="button" class="modal-deatilCoupon-butto" (click)="closeModal()">OK</a>
                </div>
            </div>
        </div>
    </div>
</div>
