<div class="container">
    <h1 style="color: white !important">Logging in...</h1>
    <div class="rectangle-animated">

    </div>
</div>

<!--<div class="container">
    <div class="row">
        
        <div class="card w-100">

            <div class="card-body bg-theme">
                <h3>API</h3>
                <hr/>
                
                <div class="row">
                    <div class="col-12">
                        <p>Login: {{login}}</p>
                        <p>Email: {{email}}</p>
                        <p>Credit: {{credit}}</p>
                        <p>Key: {{key}}</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>-->