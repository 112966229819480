import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { ConfigService } from 'src/app/config.service';
import { Currency } from 'src/app/currency.interface';
import { InteractionService } from 'src/app/interaction.service';
import { User } from 'src/app/user.interface';
import { ModalsService } from '../../services/modals/modals.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  isLoaded: boolean = false;
  Currency: Currency = <Currency>{};

  userId: number;
  editableUser = {} as User;
  fatherName: string;
  confirmPassword: string;
  selectedGender: any;
  newBirth: Date;
  selectedCountry: string;
  selectedAccess: boolean = true;
  passwordForm: FormGroup;
  friendCode: string = '';
  availableValue: number = 0;

  editUserForm: FormGroup;

  countries: any[];

  constructor(private translate: TranslateService, public appComponent: AppComponent,
    private configService: ConfigService, private route: ActivatedRoute,
    private router: Router, private interactionService: InteractionService,
    private _formBuilder: FormBuilder,
    private modalsService: ModalsService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);

    this.passwordForm = this._formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
    });
  }

  ngOnInit() {
    this.userId = +this.route.snapshot.paramMap.get("userId");

    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });

    this.getUser(this.userId);
  }

  getUser(userId: number) {
    this.configService.getUser(userId)
      .subscribe((data: User) => {
        this.editableUser = data;
        this.confirmPassword = data.password;
        this.selectedGender = data.gender;
        this.selectedCountry = data.country;
        this.selectedAccess = !data.deleted ? true : false;
        this.newBirth = data.birthDate != null ? new Date(data.birthDate) : null;
        this.getFather();
      }, error => {
        console.log(error);
      });
  }

  getFather() {
    this.configService.getUser(this.editableUser.parentId)
      .subscribe(data => {
        this.fatherName = data.login;
        this.getFriendCode();
      }, error => {
        console.log(error);
      })
  }

  getFriendCode() {
    this.configService.getCoupon(this.editableUser.id)
      .subscribe(data => {
        if (data != null) {
          this.friendCode = data.code;
          this.availableValue = this.Currency.quote * data.availableValue;
        }
      }, error => {
        console.log(error);
      });
    this.getCountries();
  }

  getCountries() {
    this.configService.getCountries()
      .subscribe(data => {
        this.countries = data.sort((a, b) => a.name.localeCompare(b.name));
        document.getElementById("spinner-loading").classList.add("hidden");
        this.isLoaded = true;
      }, error => {
        console.log(error);
      })
  }

  generateFriendCode() {
    this.configService.generateJurisdictionCoupon()
      .subscribe(data => {
        this.friendCode = data.code;
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-new-user.fail"), this.translate.instant("pg-edit-user.fail-server"), true);
      })
  }

  changeBirth(type: string, event: MatDatepickerInputEvent<Date>) {
    this.editableUser.birthDate = new Date(event.value);
  }

  editUser() {
    this.editableUser.gender = this.selectedGender;
    this.editableUser.country = (<HTMLInputElement>document.getElementById('country-field')).value;
    this.editableUser.deleted = !this.selectedAccess ? true : false;
    this.editableUser.password = '';
    this.configService.updateUser(this.editableUser.id, this.editableUser)
      .subscribe(data => {
        if (this.friendCode != '') {
          this.configService.saveJurisdictionCoupon(this.editableUser.id, this.friendCode, this.Currency.quote * this.availableValue)
            .subscribe(data => {
              //console.log("success - " + data);
            }, error => {
              console.log(error);
            })
        }
        this.modalsService.openNotificationModal(this.translate.instant("pg-new-user.success"), this.translate.instant("pg-edit-user.success-info"), false);
        this.router.navigate(['/jurisdiction']);
      }, error => {
        console.log(error);
        this.modalsService.openNotificationModal(this.translate.instant("pg-new-user.whatch-requirements"), this.translate.instant("pg-edit-user.fail-server"), true);
      });

  }

  updatePassword(frame) {
    this.configService.updatePassword(this.editableUser.id, this.editableUser.login, true, this.passwordForm.get('password').value, this.editableUser.password)
      .subscribe(res => {
        frame.hide();
        this.router.navigate(['/jurisdiction']);
        this.modalsService.openNotificationModal(this.translate.instant("pg-new-user.success"), this.translate.instant("pg-edit-user.success-info"), false);
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-new-user.fail"), this.translate.instant("pg-edit-user.fail-server"), true);
      });
  }

}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};
