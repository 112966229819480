import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from '../config.service';
import { Prize } from '../prize.interface';
import { Jackpot } from '../jackpot.interface';
import { Currency } from '../currency.interface';
import { AppComponent } from '../app.component';
import { TranslateService } from '@ngx-translate/core';
import { Round } from '../round.interface';
import { element } from 'protractor';
import { ModalDirective } from 'angular-bootstrap-md';
import axios from "axios";
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { InteractionService } from '../interaction.service';
import { ModalsService } from '../services/modals/modals.service';

@Component({
  selector: 'app-jackpot',
  templateUrl: './jackpot.component.html',
  styleUrls: ['./jackpot.component.scss']
})

export class JackpotComponent implements OnInit {
  @ViewChild('frame2', { static: true }) modalDelete: ModalDirective;
  displayedColumns: string[] = ['championship', 'value', 'date', 'acao'];

  dataSource = new MatTableDataSource<Jackpot>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  jackpot: number;
  prizes: Prize;
  rounds: Round[] = [];
  acumulates: any[];
  total: number;
  deleteId: number;
  Currency: Currency = <Currency>{};

  constructor(private interactionService: InteractionService,
    private configService: ConfigService, private appComponent: AppComponent,
    private translate: TranslateService, private modalsService: ModalsService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {
    this.getRoundsOpened();
    this.getJackpot();

    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }


  getRoundsOpened() {
    this.configService.getRoundsOpended()
      .subscribe(data => {
        this.rounds = data;
      }, error => {
        console.log(error);
      });
  }

  getRoundJackpot(roundId: number) {
    this.configService.getRoundJackpot(roundId)
      .subscribe(data => {
        this.prizes = data;
        this.jackpot = (this.prizes.gathered);
      }, error => {
        console.log(error);
      });
  }

  changeJackpot() {

    let newValue = (<HTMLInputElement>document.getElementById("jackpot")).value;
    let newPrizes: Prize;
    //newPrizes = this.prizes;

    newPrizes = this.prizes;
    newPrizes.gathered = +newValue;
    newPrizes.type = 2;

    if (newValue != "") {
      newPrizes.gathered = Number(newValue) / this.Currency.quote;
      this.configService.updateRoundJackpot(newPrizes)
        .subscribe(data => {
          this.modalsService.openNotificationModal(this.translate.instant("pg-jackpot.jackpot-changed"), this.translate.instant("pg-jackpot.jackpot-changed-info"), false);
          (<HTMLInputElement>document.getElementById("jackpot")).value = "";
          this.jackpot = (newPrizes.gathered);
          this.ngOnInit();
        }, error => {
          this.modalsService.openNotificationModal(this.translate.instant("pg-jackpot.operation-failed"), this.translate.instant("pg-jackpot.operation-failed-info"), true);
          console.log(error);
        });
    }
    else {
      this.modalsService.openNotificationModal(this.translate.instant("pg-jackpot.empty-field"), this.translate.instant("pg-jackpot.empty-field-info"), true);
    }
  }

  getJackpot() {
    this.configService.getJackpot()
      .subscribe(jackpot => {
        this.acumulates = jackpot;
        this.dataSource.data = jackpot;
        this.dataSource.paginator = this.paginator;
        var t = 0;
        this.acumulates.forEach(function (entry) {
          t = t + entry.value;
        });
        this.total = t;
      }, error => {
        console.log(error);
      });

  }
  headElements = ['Nome do campeonato', 'Valor restante', 'Data de término', 'Açao'];


  deleteAcumulate(roundId: number) {
    this.deleteId = roundId;
    this.modalDelete.show();
  }

  confirmDelete() {
    this.modalDelete.hide();
    this.configService.deleteJackpotRemaining(this.deleteId)
      .subscribe(data => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-jackpot.jackpot-success"), this.translate.instant("pg-jackpot.jackpot-deleted"), false);
        this.ngOnInit();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-jackpot.operation-fail"), this.translate.instant("pg-jackpot.delete-fail-info"), true);
        console.log(error);
      })
  }

}
