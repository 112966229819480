<div class="container mt-2 p-0 side-menu-tree">

  <div class="row side-menu-tree-title">
    {{ 'comp-roundgroups.live' | translate }}
    &nbsp;
    <div class="blink_me">
      <i class="fa fa-circle" aria-hidden="true"></i>
    </div>
  </div>

  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="row side-menu-tree">

    <mat-nested-tree-node *matTreeNodeDef="let node; when: isGroup">
      <li>
        <div class="mat-tree-node side-menu-tree-node" [class.sport-level-format]="sportLevelFormat(node.id)"
          [class.match-level-format]="(node.sportId != undefined && node.sportId != 0 && node.code != null)"
          [ngClass]="getGameIcon(node.name) ? 'main-menu' : !getGameIcon(node.name) && !getFlagByName(node.name) ? 'last-menu' : 'other'">
          <button style="min-width: 192px !important; border-radius: 0px !important; text-align: left"
            [ngStyle]="{'margin-left': (!getGameIcon(node.name) && getFlagByName(node.name) && node.id != -2 && node.code == null && node.id?.toString().length != 5 ) || node.name == 'Other Countries'? '15px' : ((node.name == 'Other Tournaments') || (node.name != 'Other Tournaments' && node.id?.toString().length == 5 && node.code == null && !getGameIcon(node.name))) ? '50px' : (node.sportId != undefined && node.sportId != 0 && node.code != null && node.id != -2) ? '0px' : '0px'}"
            mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
            <mat-icon *ngIf="getGameIcon(node.name)" class="mat-icon-rtl-mirror">
              {{getGameIcon(node.name)}}
            </mat-icon>
            <img style="margin-right: 5px;"
              *ngIf="!getGameIcon(node.name) && getFlagByName(node.name) && node.code == null && node.name != 'Other Tournaments' && node.id.toString().length < 5"
              height="18" width="18" src="{{getFlagByName(node.name)}}" alt="" />
            <mat-icon
              *ngIf="!getGameIcon(node.name) && !getFlagByName(node.name) && (node.sportId == undefined || node.sportId == 0)"
              class="mat-icon-rtl-mirror">
              emoji_events
            </mat-icon>
            <ng-container *ngIf="(node.sportId != undefined && node.sportId != 0 && node.code != null)">
              <a (click)="loadMatch(node.code,node.sportId,'mob')" class="">
                <mat-icon style="font-size: 18px;" class="fore-green">tablet_android</mat-icon>
              </a>
              <b> & </b>
              <a (click)="loadMatch(node.code,node.sportId,'desk')" class="">
                <mat-icon style="font-size: 18px;" class="fore-green">tv</mat-icon>
              </a>
            </ng-container>
            <span [class.sport-level-format-text]="sportLevelFormat(node.id)">
              <!--{{customTranslate(node.name)}}-->
              {{ node.name | translate }}
            </span>
            <mat-icon class="mat-icon-rtl-mirror"
              *ngIf="(node.id == -2 || node.sportId == undefined || node.sportId == 0)">
              {{treeControl.isExpanded(node) ? 'arrow_left' : 'arrow_drop_down'}}
            </mat-icon>
          </button>
        </div>
        <ul [class.side-menu-tree-invisible]="treeControl.isExpanded(node)">
          <!-- <ul> -->
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: !isGroup" matTreeNodeToggle>
      <li class="mat-tree-node side-menu-tree-leaf force-black">
        <button mat-icon-button>
          <!--<button mat-icon-button (click)='loadRound(node.roundId)'>-->
          <span class="text-black">{{node.name}}</span>
        </button>
      </li>
    </mat-tree-node>
    <!-- <mat-tree-node *matTreeNodeDef="let node; when: !isGroup" matTreeNodeToggle>
      <li class="mat-tree-node side-menu-tree-leaf force-black">
        <button mat-icon-button (click)="showMatches(node);">          
          <span class="text-black">{{node.name}}</span>
        </button>
      </li>
    </mat-tree-node> -->

  </mat-tree>

</div>

<div mdbModal #frameMessageDanger="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-danger" role="document" id="msgModalStandardDanger">
    <!--<div class="modal-content  bg-theme" style="height: 378px;
    max-height: 400px;">-->
    <div class="modal-content  bg-theme">
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="titleToAdd"></h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="frameMessageDanger.hide();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-3 adjustModel scrollDeg">
        <p style="color: white" id="msg" [innerHtml]="msgToAdd"></p>
      </div>
      <div class="modal-footer d-flex justify-content-center">

        <a type="button" color="waves-light button-font" style="background-color:#ff4b4b;" class="btn" mdbWavesEffect
          (click)="frameMessageDanger.hide();" data-dismiss="modal">OK</a>
      </div>

    </div>
  </div>
</div>