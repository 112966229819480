<!--Body-->

<div id="bannerswidth">
    <div class="my-body">
        <div class="row text-center">

            <!-- Slide Carousel NEW-->
            <div class="col-12" id="bannersltm" style="z-index: 0;">
                <mdb-carousel [isControls]="false" class="carousel slide carousel-fade" [animation]="'fade'">
                    <mdb-carousel-item *ngFor="let slides of slidesList; let i = index;">
                        <div class="view w-100">
                            <img class="d-block w-100 banner-limits" src="{{slidesPathPrefix + slides.fileName}}">
                            <div class="waves-light" mdbWavesEffect></div>
                        </div>
                        <div class="carousel-caption">
                            <h3 class="h3-responsive">{{ slides.title }}</h3>
                            <p>{{slides.subtitle}}</p>
                        </div>
                    </mdb-carousel-item>
                </mdb-carousel>
            </div>
        </div>
        <!-- Slide Carousel NEW-->

    </div>
    <!-- Loading (desapears when is loaded) -->
    <div *ngIf="!isLoaded" id="spinner-loading" class="container my-body justify-content-center">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <div class="col-12 round-header" style="padding: 0px;height: 400px;overflow: auto;overflow-x: hidden;">

        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" matSort
            (matSortChange)="sortData($event)">
            <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</th>
                <td mat-cell *matCellDef="let element"
                    style="text-align: center!important;padding-left: 0px!important;">{{element[column]}}</td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" style="padding:0px;">
                    <div class="example-element-detail"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div *ngIf="(isLoaded && responseGames.length > 0)" class="my-body">

                            <!-- New Games-->
                            <div class="row justify-content-center">

                                <div class="row col-12">
                                    <!-- <div class="col-12 round-value">
                                        {{ 'pg-home.round-value' | translate }}
                                        {{ Currency.currencySymbol }}
                                        {{this.round.value * this.Currency.quote |currency:'':''}}
                                    </div>
                                    <div class="col-12 total-prize">
                                        {{ 'pg-home.total-prize' | translate }}: {{ Currency.currencySymbol }} {{fullPrize * Currency.quote | currency:'':'' }}
                                    </div>
                                    <div class="col-6 others-prizes">
                                        {{ 'pg-home.jackpot' | translate }}
                                        {{ Currency.currencySymbol }} {{jackpot * Currency.quote |currency:'':''}}
                                    </div>
                                    <div class="col-6 others-prizes">
                                        {{ 'pg-home.prize' | translate }}
                                        {{ Currency.currencySymbol }} {{prizeAmount * this.Currency.quote |currency:'':''}}
                                    </div>

                                    <div class="col-2 top-round">
                                        {{ 'pg-home.date-hour' | translate }}
                                    </div>
                                    <div class="col-10 top-round">
                                        {{this.round.name}}
                                        {{ dateBegin | date: 'dd/MM'}} {{ 'pg-home.to' | translate }}
                                        {{ dateEnd | date: 'dd/MM' }}
                                    </div> -->

                                    <!-- DESKTOP GAMES LINES-->
                                    <div class="mobile-hide game-line"
                                        *ngFor="let game of responseGames; let i = index">
                                        <div class="col-2 game-line-left game-line-border">
                                            {{game.dateBegin | date: 'dd/MM'}} {{game.dateBegin | date: 'hh:mm'}}
                                        </div>
                                        <div *ngIf="game.two_options" class="col-6 game-line-left game-line-border">
                                            {{game.teamHome}} - {{game.teamVisit}}
                                        </div>
                                        <div *ngIf="!game.two_options" class="col-4 game-line-left game-line-border">
                                            {{game.teamHome}} - {{game.teamVisit}}
                                        </div>
                                        <div class="col-2 game-line-border">
                                            <button (click)="setBet(i, '1')" id="{{i}}a"
                                                [class.bg-yellow]="formatingCheck(i, '1')" class="small-spaces"
                                                type="button" mdbBtn color="light" mdbWavesEffect>
                                                1
                                            </button>
                                        </div>
                                        <div *ngIf="!game.two_options" class="col-2 game-line-border">
                                            <button id="{{i}}b" (click)="setBet(i, 'X');"
                                                [class.bg-yellow]="formatingCheck(i, 'X')" class="small-spaces "
                                                type="button" mdbBtn color="light" mdbWavesEffect>
                                                x
                                            </button>
                                        </div>
                                        <div class="col-2 game-line-border">
                                            <button id="{{i}}c" (click)="setBet(i, '2')"
                                                [class.bg-yellow]="formatingCheck(i, '2')" class="small-spaces"
                                                type="button" mdbBtn color="light" mdbWavesEffect>
                                                2
                                            </button>
                                        </div>
                                    </div>
                                    <!-- DESKTOP GAMES LINES-->

                                    <!-- MOBILE GAMES LINES-->
                                    <div class="desktop-hide w-100" *ngFor="let game of responseGames; let i = index">
                                        <div class="col-12 game-group">
                                            <div class="teams-date">
                                                <div class="col-8 teams">
                                                    {{game.teamHome}} - {{game.teamVisit}}
                                                </div>
                                                <div class="col-4 date">
                                                    {{game.dateBegin | date: 'dd/MM'}} {{game.dateBegin | date:
                                                    'hh:mm'}}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div *ngIf="!game.two_options" class="col-4 bt-mobile">
                                                    <button (click)="setBet(i, '1'); setXY($event)" id="{{i}}a"
                                                        [class.bg-yellow]="formatingCheck(i, '1')" class="medium-spaces"
                                                        type="button" mdbBtn>
                                                        1
                                                    </button>
                                                </div>
                                                <div *ngIf="game.two_options" class="col-6 bt-mobile">
                                                    <button (click)="setBet(i, '1'); setXY($event)" id="{{i}}a"
                                                        [class.bg-yellow]="formatingCheck(i, '1')" class="medium-spaces"
                                                        type="button" mdbBtn>
                                                        1
                                                    </button>
                                                </div>
                                                <div *ngIf="!game.two_options" class="col-4 bt-mobile">
                                                    <button id="{{i}}b" (click)="setBet(i, 'X'); setXY($event)"
                                                        [class.bg-yellow]="formatingCheck(i, 'X')"
                                                        class="medium-spaces " type="button" mdbBtn>
                                                        X
                                                    </button>
                                                </div>
                                                <div *ngIf="game.two_options" class="col-6 bt-mobile">
                                                    <button id="{{i}}c" (click)="setBet(i, '2'); setXY($event)"
                                                        [class.bg-yellow]="formatingCheck(i, '2')" class="medium-spaces"
                                                        type="button" mdbBtn>
                                                        2
                                                    </button>
                                                </div>
                                                <div *ngIf="!game.two_options" class="col-4 bt-mobile">
                                                    <button id="{{i}}c" (click)="setBet(i, '2'); setXY($event)"
                                                        [class.bg-yellow]="formatingCheck(i, '2')" class="medium-spaces"
                                                        type="button" mdbBtn>
                                                        2
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- MOBILE GAMES LINES-->
                                </div>

                            </div>
                            <!--Games-->
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay;sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>

    </div>
</div>

<!-- Body -->