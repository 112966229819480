<div class="mobile-width-adjust">
  <div class="bg-theme pb-2">
    <h3 class="d-flex justify-content-start align-items-center  pl-4 pt-3">
      <img class="mr-2" src="../../assets/image/cards.png" width="60vh">
      {{ 'menu.live-casino' | translate}}
    </h3>
    <hr>
    <!-- <div class="providers-Box">
      <button class="btn btn-primary selectProviders-btn" type="button" data-toggle="collapse"
        data-target="#collapseExample" [attr.aria-expanded]="isCollapseOpen ? 'true' : 'false'"
        aria-controls="collapseExample" (click)="toggleCollapse()">
        Selecione entre nossos fornecedores
        <span [ngClass]="{'arrow-up': isCollapseOpen, 'arrow-down': !isCollapseOpen}"></span>
      </button>
      <div class="collapse pt-2" id="collapseExample" [class.show]="isCollapseOpen" data-parent=".providers-Box">
        <div class="providers-menu">
          <button class="providers-btn" data-toggle="collapse" data-target="#collapseExample"
            *ngFor="let provider of providerList;" (click)="selectProvider(slot)">
            <img *ngIf="checkImageExists(slot)" class="providers-logo" [src]="getImageSource(slot)">
            <p class="m-0 p-0 providers-name" *ngIf="!checkImageExists(slot)">{{slot}}</p>
          </button>
        </div>
      </div>
    </div> -->
    <div class="bg-theme p-3 pt-3 mobile-container desktop-hide">
      <ng-container *ngFor="let games of mobileGameList;">
        <div class="videoSlot-Box_mobile w-100 mb-2">
          <div (click)="openCassinoSession(games)" class="img-box">
            <img class="game_img" src="{{ games.imgUrl }}" alt="thumb">
          </div>
          <span class="text-center game_name desktop-hide"> {{ games.name }}</span>
        </div>
      </ng-container>
    </div>
    <div class="bg-theme p-3 desktop-container pt-3 mobile-hide">
      <ng-container *ngFor="let games of desktopGameList;">
        <div class="videoSlot-Box w-100 mb-2">
          <div (click)="openCassinoSession(games)" class="img-box">
            <img class="game_img" src="{{ games.imgUrl }}" alt="thumb">
            <span class="w-100 text-center">
              <mat-icon
                style="font-size: 60px; text-align: center; width: 100% !important;">play_circle_outline</mat-icon> <br>
              {{ games.name }}
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
