import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import {
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatOptionModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    MatMenuModule,
    MatPaginatorModule,
    MatListModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatTabsModule,
    } from '@angular/material';

const MATERIAL_MODULES = [
    MatButtonModule,
    MatIconModule,
    MatTreeModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatOptionModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    MatMenuModule,
    MatPaginatorModule,
    MatListModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatTabsModule,
];

@NgModule({
    imports: [
        MATERIAL_MODULES
    ],
    exports: [
        MATERIAL_MODULES
    ]
})

export class MaterialModule {}