<div class="container">
    <div class="row">
        <!-- Card -->
        <div class="card mb-5">

            <!-- Card content -->
            <div class="card-body bg-theme">

                <div class="row">
                    <!-- Title -->
                    <div class="col-12 pt-3 page-title">
                        <h3><i class="fas fa-comment-alt" style="font-size: 25px;"></i> {{ 'pg-contact.assistence' |
                            translate }}</h3>
                        <hr />
                    </div>

                    <!-- Text -->
                    <div class="col-12">
                        <!--<p>
                            {{ 'pg-contact.paragraph1' | translate }}
                        </p>-->
                        <p>
                            <!--<i class="fab fa-whatsapp"></i>Whatsapp: <a href="https://wa.me/5521979657332"
                                target="_blank">+55 21 97965-7332</a><br />-->
                            <!--<i class="far fa-envelope"></i> -->E-mail: <a
                                href="mailto:contact@brasilbolao.com">contact@brasilbolao.com</a><br />
                        </p>
                        <p>
                            <a href="javascript:void(Tawk_API.toggle())">
                                <button mdbBtn
                                    style="background: linear-gradient(to bottom, #44b548 0%,#44b548 41%,#09a320 56%,#09a320 100%); padding: 1px; margin: 0; height: 2em; color: white; width: 130px; font-weight: bold;"
                                    class="waves-light button-font" mdbWavesEffect>
                                    <i class="fas fa-comment-alt"></i>
                                    Chat
                                </button>
                            </a>
                        </p>
                        <!--<p>
                            {{ 'pg-contact.paragraph2' | translate }}
                        </p>-->
                    </div>
                    <!-- New Form Area-->
                    <div class="col-12 d-flex justify-content-center">
                        <div class="col-11 form-area">
                            <form [formGroup]="contactForm">
                                <div class="row form-line">
                                    <div class="col-md-3 col-sm-12 text-right">
                                        {{ 'pg-contact.name' | translate }}: <span class="asteristic">*</span>
                                    </div>
                                    <div class="col-md-7 col-sm-9">
                                        <input class="form-fields-pattern" type="text" mdbInput formControlName="name"
                                            required>
                                    </div>
                                </div>

                                <div class="row form-line">
                                    <div class="col-md-3 col-sm-12 text-right">
                                        E-mail: <span class="asteristic">*</span>
                                    </div>
                                    <div class="col-md-7 col-sm-9">
                                        <input class="form-fields-pattern" type="text" mdbInput formControlName="email"
                                            required>
                                    </div>
                                </div>

                                <div class="row form-line">
                                    <div class="col-md-3 col-sm-12 text-right">
                                        Whatsapp:
                                    </div>
                                    <div class="col-md-7 col-sm-9">
                                        <input class="form-fields-pattern" type="text" mdbInput
                                            formControlName="whatsapp">
                                    </div>
                                </div>

                                <div class="row form-line">
                                    <div class="col-md-3 col-sm-12 text-right">
                                        {{ 'pg-contact.subject' | translate }}: <span class="asteristic">*</span>
                                    </div>
                                    <div class="col-md-6 col-sm-7">
                                        <select class="form-fields-pattern" mdbInput formControlName="subject" required>
                                            
                                            <option value="{{ 'pg-contact.subject2' | translate }}">
                                                {{ 'pg-contact.subject2' | translate }}
                                            </option>
                                            <option value="{{ 'pg-contact.subject3' | translate }}">
                                                {{ 'pg-contact.subject3' | translate }}
                                            </option>
                                            <option value="{{ 'pg-contact.subject4' | translate }}">
                                                {{ 'pg-contact.subject4' | translate }}
                                            </option>
                                            <option value="{{ 'pg-contact.subject5' | translate }}">
                                                {{ 'pg-contact.subject5' | translate }}
                                            </option>
                                            <option value="{{ 'pg-contact.subject6' | translate }}">
                                                {{ 'pg-contact.subject6' | translate }}
                                            </option>
                                            <option value="{{ 'pg-contact.subject7' | translate }}">
                                                {{ 'pg-contact.subject7' | translate }}
                                            </option>
                                            <option value="{{ 'pg-contact.subject8' | translate }}">
                                                {{ 'pg-contact.subject8' | translate }}
                                            </option>
                                            <option value="{{ 'pg-contact.subject9' | translate }}">
                                                {{ 'pg-contact.subject9' | translate }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row form-line">
                                    <div class="col-md-5 col-sm-12 text-right">
                                        {{ 'pg-contact.device' | translate }}: <span class="asteristic">*</span>
                                    </div>
                                    <div class="col-md-4 col-sm-7">
                                        <select class="form-fields-pattern" mdbInput formControlName="device" required>
                                            <option value="{{ 'pg-contact.device2' | translate }}">
                                                {{ 'pg-contact.device2' | translate }}
                                            </option>
                                            <option value="{{ 'pg-contact.device3' | translate }}">
                                                {{ 'pg-contact.device3' | translate }}
                                            </option>
                                            <option value="{{ 'pg-contact.device4' | translate }}">
                                                {{ 'pg-contact.device4' | translate }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row form-line">
                                    <div class="col-md-3 col-sm-12 text-right">
                                        {{ 'pg-contact.message' | translate }}: <span class="asteristic">*</span><br />
                                    </div>
                                    <div class="col-md-7 col-sm-9">
                                        <textarea class="form-fields-pattern" rows="5" type="text" mdbInput
                                            formControlName="message" required></textarea>
                                        <br /><span class="msg-warning">({{'pg-contact.message-required' | translate
                                            }})</span>
                                    </div>
                                </div>

                                <div class="row form-line">
                                    <div class="col-12 d-flex justify-content-center">
                                        <button mdbBtn [disabled]="!contactForm.valid" type="submit" color="success"
                                            mdbWavesEffect (click)="sendMessage()">
                                            {{ 'pg-contact.send' | translate}}
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                    <!-- New Form Area-->
                </div>

            </div>

        </div>
        <!-- Card -->
    </div>

</div>