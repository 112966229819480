import { HttpClientJsonpModule } from '@angular/common/http';
import { Component, DebugElement, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../app.component';
import { Bet } from '../../bet.interface';
import { ConfigService } from '../../config.service';
import { InteractionService } from '../../interaction.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { ShieldHelper } from 'src/app/Helpers/ShieldHelper';
import { ModalsService } from 'src/app/services/modals/modals.service';


@Component({
  selector: 'app-bet-list',
  templateUrl: './bet-list.component.html',
  styleUrls: ['./bet-list.component.scss']
})

export class BetListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  roundId : number = +localStorage.getItem("roundGet");
  isRankRound: boolean = false;
  isNewRound: boolean = false;
  bets: Bet[] = [];
  ELEMENT_DATA: any[] = [];
  dynamicColumnNames: any[] = [];
  dataSource: MatTableDataSource<Bet>;
  countryFlags;
  posted: number;
  rankResult: string[];
  roundName: string;
  roundDate: Date;
  isRoundPosted: number;
  roundEvents: any[] = [];
  shieldHome: string;
  shieldAway: string;

  underOverDictionary = new Map([
    ['1', 'Over'],
    ['2', 'Under']
  ]);

  yesNoDictionary = new Map([
    ['1', 'Yes'],
    ['2', 'No']
  ]);

  evenOddDictionary = new Map([
    ['1', 'Even'],
    ['2', 'Odd']
  ]);

  homeAwayDictionary = {
    '1' : 'Home',
    '2' : 'Away'
  };
  homeDrawAwayDictionary = new Map([
    ['1', 'Home'],
    ['2', 'Away'],
    ['x', 'Draw']
  ]);


  constructor(private configService: ConfigService, private appComponent: AppComponent, private modalsService: ModalsService,
    private translate: TranslateService, private interactionService: InteractionService, private activatedRoute: ActivatedRoute, public router: Router, private datePipe: DatePipe) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
    this.translate.get(['pg-print.ticket', 'pg-print.player', 'pg-settings.country']).subscribe(translations => {
      this.dynamicColumnNames = [
        translations['pg-print.ticket'],
        translations['pg-print.player'],
        translations['pg-settings.country']
      ]
    });
  }

  ngOnInit(): void {
    this.getBetById(this.roundId);
    this.getRoundById(this.roundId);
    this.configService.getFlags()
    .subscribe(data => {
      this.countryFlags = data;
    });
  }

  getBetById(roundId: number) {
    this.configService.getBets(roundId)
    .subscribe(bets => {
      this.dataSource = new MatTableDataSource(bets);
      this.dataSource.paginator = this.paginator;
      this.configTableHeaders(bets);
      this.configBets(bets);
    }, error => {
      console.log(error);
     });
  }

  configBets(bets) {
    if(this.isNewRound) {
      for(let i = 0; i < bets.length; i++) {
        let splitBet = bets[i].results.split("|");
        for(let j = 0; j < splitBet.length; j++) {
          switch(this.roundEvents[j].type){
            case 2:
              splitBet[j] = this.translate.instant(this.underOverDictionary.get(splitBet[j]));
              break;

            case 3:
              splitBet[j] = this.translate.instant(this.yesNoDictionary.get(splitBet[j]));
              break;

            case 4:
              splitBet[j] = this.translate.instant(this.evenOddDictionary.get(splitBet[j]));
              break;

            case 5:
            case 6:
              splitBet[j] = this.translate.instant(this.homeDrawAwayDictionary.get(splitBet[j]));
              break;
          }
        };
        bets[i].results = splitBet.join("|");
      };
    }
  }

  getRoundById(roundId: number) {
    this.configService.getRoundById(roundId)
    .subscribe(round => {
      this.isRankRound = !round.singleMatchRound && !round.jiuJitsuRound && round.rankRound ? true : false;
      this.isNewRound = round.singleMatchRound && round.jiuJitsuRound ? true : false;
      this.roundDate =  round.startDateTime;
      this.isRoundPosted = round.posted;
      this.roundEvents = round.events;
      this.roundName = round.name;
      // let lang = localStorage.getItem('language');
      // if(lang == 'pt') {
      //   this.roundName = round.name;
      // } else if(lang == 'en') {
      //   this.roundName = round.nameEng;
      // } else if(lang == 'it') {
      //   this.roundName = round.nameItaly;
      // } else if(lang == 'es') {
      //   this.roundName = round.nameSpan;
      // } else {
      //   this.roundName = round.nameEng;
      // }
      if(this.isNewRound){
        this.shieldHome = this.getSingleMatchShield(round.name, 0);
        this.shieldAway = this.getSingleMatchShield(round.name, 1);
      }else{
        this.shieldHome = this.getChampionshipLogos(round.name);
      }
    });
  }

  configTableHeaders(bet: any) {
    const dynamicColumns = bet[0].results.split('|').map((_, index) => (index + 1).toString());
    this.dynamicColumnNames.push(...dynamicColumns);
  }

  getStaticValue(column: string, element: any): string {
    switch (column) {
      case this.translate.instant('pg-print.ticket'):
        return element.uuid8;
      case this.translate.instant('pg-print.player'):
        return element.playerName;
      case this.translate.instant('pg-settings.country'):
        return this.getFlagImage('brazil');
    }
  }

  getFlagByName(name: string) {
    name = name == "England" ? "United Kingdom" : name == "" ? "Brazil" : name;
    if (this.countryFlags.data.length > 0) {
      var result = this.countryFlags.data.filter(obj => {
        return obj.name === name;
      });
      if (result.length > 0) {
        return result[0].flag;
      }
      else {
        return "/assets/image/flags/pt.png";
      }
    }
  }

  getFlagImage(country: string): string {
    const flagUrl = this.getFlagByName(country);
    return flagUrl;
  }

  configTableHeader(column: string) {
    if(column == this.translate.instant('pg-print.ticket')) {
      return 'cupom-header';
    }

    if(column == this.translate.instant('pg-print.player')) {
      return 'player-header';
    }

    if(column == this.translate.instant('pg-settings.country')) {
      return 'country-header';
    }

    if(column != this.translate.instant('pg-print.player') && column != this.translate.instant('pg-print.ticket') && column != this.translate.instant('pg-settings.country')) {
      return 'bets-header';
    }
  }

  openTicket(element) {
    this.modalsService.openDetailTickeModal(0,element.slice(0,8));
  }

  print2(): void {
    let printContents, popupWin;
    let formattedDate = this.datePipe.transform(this.roundDate, 'mediumDate');
    printContents = document.getElementById('table-box').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
          <style>
            body {
              font-family: Roboto, "Helvetica Neue", sans-serif;
              font-size: 13px;
            }
            table {
              border-width: thin;
              border: 1px solid black;
            }

            table > thead > tr > th {
              border: 1px solid black;
              text-align: center;
            }

            table > tbody > tr > td {
              border: 1px solid black !important;
              text-align: center !important;
            }

            b {
                font-weight: 600;
            }
            .print-betid {
                margin: 0;
                float: right;
            }
            .bet-qrcode {
                display: flex;
                justify-content: center;
            }
            .bet-qrcode img{
                max-width: 150px;
            }
            .roundName {
              font-size: 20px;
            }
            .roundDate {
              font-size: 17px;
            }
            /*@page {
              size: auto;
              margin: 10;
            }
            @media print {
              html, body {
                width: 210mm;
                height: 297mm;
              }*/
          </style>
        </head>
        <body onload="window.print();window.close()">
        <div class='ml-2 mt-2 mb-2'>
        <h1 class='roundName m-0 p-0 pl-1'> ${this.roundName}</h1>
        <h2 class='roundDate m-0 p-0 pl-1'>${formattedDate}</h1>
        </div>
        ${printContents}
        </body>
      </html>`
    );
    popupWin.document.close();
  }

  getSingleMatchShield(elementName: String, team: number) {
    return ShieldHelper.getSingleMatchShield(elementName, team);
  }

  getChampionshipLogos(name: string) {
    return ShieldHelper.getChampionshipLogos(name);
  }

  openRound(roundId: string) {
    this.router.navigate(['/result/:' + roundId]);
  }

}
