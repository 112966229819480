<div class="row desktop-hide w-100" style="background-color: #303030ec ;">
    <form class="form-inline" (ngSubmit)="searchTicket()" style="width:100% ;">
        <div class="row" style="width:100% ;">
            <div class="col-7 mb-2">
                <!--<input id="code-search" class="form-control mr-sm-2 code-field" type="text" required />-->
                <input type="text" id="code-search2" class="ml-2"
                    placeholder="{{ 'comp-search-ticket.ticket-code' | translate }}" required />
            </div>
            <div class="col-5 mb-2">
                <div class="bloom" (click)="searchTicket()">
                    <mat-icon matSuffix>search</mat-icon>
                </div>
            </div>
        </div>
    </form>

</div>