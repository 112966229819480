import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'angular-bootstrap-md';

import { ConfigService } from '../config.service';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { Currency } from '../currency.interface';
import { Match } from '../match.model';
import { BetradarData } from '../betradar-data.interface';
import { BetradarTeam } from '../betradar-team.interface';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Round } from '../round.interface';
import { Game } from '../game.interface';
import { TranslateService } from '@ngx-translate/core';
import { RoundGroup } from '../round-group.interface';
import { BetradarTournament } from '../betradar-tournament.interface';
import axios from 'axios';
import { BetradarMatch } from '../betradar-match.interface';
import moment from 'moment';
import { BetradarSport } from '../betradar-sport.interface';
import { BetradarCategory } from '../betradar-category.interface';
import { InteractionService } from '../interaction.service';
import { ModalsService } from '../services/modals/modals.service';

interface Tournament {
  id: number;
  name: string;
}

@Component({
  selector: 'app-round',
  templateUrl: './round.component.html',
  styleUrls: ['./round.component.scss']
})
export class RoundComponent implements OnInit {
  @ViewChild('frame', { static: true }) modalCreate: ModalDirective;
  @ViewChild('successCreation', { static: true }) modalCreationSuccess: ModalDirective;
  @ViewChild('avoidCreation', { static: true }) modalAvoidCreatioon: ModalDirective;

  match = new Match();
  chosenMatches = [];

  sports: Observable<BetradarSport[]>;
  sportId: number;
  categories: Observable<BetradarCategory[]>;
  filteredOptions: Observable<BetradarTournament[]>;

  minDate: Date;

  Currency: Currency = {} as Currency;
  formBetradar: FormGroup;
  betradarMatches: BetradarMatch[];
  tempGameId = 0;
  lastRound: Round;
  listRoundGroups: RoundGroup[];
  groupParent = 0;
  roundType = 0; // 0 = nothing choosen, 1 = existing competition, 2 = new competition
  rankRound = 0;
  roundsFinished: Round[];
  // roundBaseChoosen = {} as Round;
  roundBaseChoosen: number;
  currentJackpot = 0;
  roundGroupRound = {} as any;

  feedOfMatches: any;
  allTournaments: any;
  availableMatches: any = [];
  tournamentList: Tournament[] = [];

  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());

  isLoaded = false;
  autoRenew = new FormControl();
  constructor(private interactionService: InteractionService,
    private configService: ConfigService, private appComponent: AppComponent,
    private router: Router, private translate: TranslateService,
    private modalsService: ModalsService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });

    this.formBetradar = new FormGroup({
      myControl: new FormControl('', Validators.required),
      sportsFormControl: new FormControl('', Validators.required),
      categoriesFormControl: new FormControl('', Validators.required),
    });

    this.getLastRound();
    this.listBetradarSports();
    this.listFeedTournaments();

    this.getRoundGroupRounds();

    const currentDate = moment();
    this.minDate = moment().toDate();

  }

  get myControl() {
    return this.formBetradar.get('myControl');
  }

  get sportsFormControl() {
    return this.formBetradar.get('sportsFormControl');
  }

  get categoriesFormControl() {
    return this.formBetradar.get('categoriesFormControl');
  }

  getLastRound() {
    this.configService.getLastRound()
      .subscribe(data => {
        this.lastRound = data;
        this.getListRoundGroups();
      }, error => {
        console.log(error);
      });
  }

  getListRoundGroups() {
    this.configService.getListedRoundGroups()
      .subscribe((data: RoundGroup[]) => {
        this.listRoundGroups = data;
        this.getRoundsPosted();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant('pg-manage-group.operation-fail'), this.translate.instant('pg-manage-group.load-fail-info'), true);
        console.log(error);
      });
  }

  getRoundGroupRounds() {
    this.configService.getRoundGroupRound()
      .subscribe(data => {
        this.roundGroupRound = data;
      });
  }

  getRoundsPosted() {
    this.configService.getChampionshipRounds()
      .subscribe(data => {
        this.roundsFinished = data;
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant('pg-manage-group.operation-fail'), this.translate.instant('pg-manage-group.load-fail-info'), true);
        console.log(error);
      });
  }

  displayFn(competition?: any): string | undefined {
    return competition ? competition.name : undefined;
  }

  private _filter(competition: any[], name: string): any[] {
    const filterValue = name.toLowerCase();

    return competition.filter(comp => comp.name.toLowerCase().indexOf(filterValue) === 0);
  }

  // --- FEED MATCHES IMPEMENTATION --- //

  listFeedTournaments() {
    this.configService.getFeedMatches()
      .subscribe(data => {
        this.allTournaments = data;
        this.compList();
      }, error => {
        console.log(error);
      });
  }

  compList() {
    this.allTournaments.forEach(element => {
      this.tournamentList.push({
        id: element.Tournament.BetradarTournamentID,
        name: element.Tournament.SuperTournament.Name
      });
    });

    // this.tournamentMatches(this.tournamentList[0].id);
  }

  tournamentMatches(tournId: number) {
    this.allTournaments.forEach(element => {
      if (element.Tournament.BetradarTournamentID == tournId) {
      }
    });
  }

  // --- FEED MATCHES IMPEMENTATION --- //

  // --- BETRADAR IMPLEMANTATION --- //
  listBetradarSports() {
    this.configService.getBetradarSports()
      .subscribe((betradarSports: BetradarSport[]) => {

        this.sports = this.sportsFormControl.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(betradarSports, name) : betradarSports.slice())
          );

        document.getElementById('spinner-loading').classList.add('hidden');
        this.isLoaded = true;

      }, error => {
        console.log(error);
      });
  }

  getSportCategories(sportId: number) {
    this.sportId = sportId;
    this.configService.getBetradarCategoriesBySportId(sportId)
      .subscribe((betradarCategories: BetradarCategory[]) => {

        this.categories = this.categoriesFormControl.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(betradarCategories, name) : betradarCategories.slice())
          );

      }, error => {
        console.log(error);
      });
  }

  getCategoryTournaments(categoryId: number) {
    this.configService.getBetradarTournamentsByCategoryId(categoryId)
      .subscribe((betradarTournaments: BetradarTournament[]) => {

        this.filteredOptions = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this._filter(betradarTournaments, name) : betradarTournaments.slice())
          );

      }, error => {
        console.log(error);
      });
  }

  showTournamentMatches(tournamentId: number) {
    this.configService.getBetradarDataByTournamentId(tournamentId)
      .subscribe((betradarData: BetradarData[]) => {
        this.configService.getBetradarTeams(betradarData)
          .subscribe((betradarTeams: BetradarTeam[]) => {
            this.betradarMatches = betradarData.map(match => {
              return {
                id: match.matchId,
                homeTeam: betradarTeams.find(team => team.id == match.teamHomeId),
                awayTeam: betradarTeams.find(team => team.id == match.teamAwayId),
                date: match.matchDate,
                // sportName: betradarTeams.find(team => team.id == match.sportId),
              };
            });
          }, error => {
            console.log(error);
          });
      }, error => {
        console.log(error);
      });
  }

  checkExistingId(matchId: number) {
    return this.chosenMatches.some(match => (match.id == matchId));
  }

  checkCustomMatch() {
    let msg = '';
    let testCustomMatch = true;

    const dt = (document.getElementById('date-picker') as HTMLInputElement).value;
    const hr = (document.getElementById('hour-input') as HTMLInputElement).value;
    // let gm = (<HTMLInputElement>document.getElementById("match-name")).value;
    const tm1 = (document.getElementById('team-home') as HTMLInputElement).value;
    const sport = (document.getElementById('sport') as HTMLInputElement).value;
    if (dt === '') {
      testCustomMatch = false;
      msg = this.translate.instant('pg-round.date-empty');
    } else if (hr === '') {
      testCustomMatch = false;
      msg = this.translate.instant('pg-round.hour-empty');
      // } else if (gm == "") {
    } else if (tm1 === '') {
      testCustomMatch = false;
      msg = this.translate.instant('pg-round.match-empty');
    } else if (sport === '') {
      testCustomMatch = false;
      msg = this.translate.instant('pg-round.sport-empty');
    }

    if (!testCustomMatch) {
      this.modalsService.openNotificationModal('Campo Obrigatório Vazio', msg, true);
    }

    return testCustomMatch;

  }

  addCustomGame(id: number, teamHome: string, date: string, hour: string, teamAway: string, sportName: string) {
    if ((id === 0 && this.checkCustomMatch())) {
      this.match = new Match();
      this.match.id = id;

      if (!teamAway || teamAway === '') {
        this.match.game = teamHome.replace('-', '');
      } else {
        this.match.game = teamHome.replace('-', ' ') + ' - ' + teamAway.replace('-', ' ');
      }

      this.match.date = new Date(Date.parse(date));
      const day = date.toString().substring(0, 2);
      const month = date.toString().substring(3, 5);
      let year;

      // fixing date for custom game
      if (id === 0) {
        year = date.toString().substring(11, 6);
      } else {
        year = date.toString().substring(11, 6) + '20';
      }
      this.match.date = new Date(year + '-' + month + '-' + day + ' ' + hour);

      this.chosenMatches.push(this.match);


      // clear custom match
      if (id === 0) {
        (document.getElementById('team-home') as HTMLInputElement).value = '';
        (document.getElementById('team-away') as HTMLInputElement).value = '';
      }
      if (id === 0 && this.rankRound === 0) {
        (document.getElementById('hour-input') as HTMLInputElement).value = '';
      }
    }

  }

  addNewGame(selectedMatch: BetradarMatch) {
    if ((selectedMatch.id !== 0 && !this.checkExistingId(selectedMatch.id))) {

      const match = new Match();
      match.id = selectedMatch.id;

      if (!selectedMatch.awayTeam || selectedMatch.awayTeam.name === '') {
        match.game = selectedMatch.homeTeam.name.replace('-', ' ') + ' - ' + selectedMatch.awayTeam.name.replace('-', ' ');
      } else {
        match.game = selectedMatch.homeTeam.name.replace('-', ' ');
      }

      const matchDate = moment(selectedMatch.date, 'YYYY-MM-DD hh:mm:ss');

      match.date = matchDate.toDate();

      this.chosenMatches.push(match);

      if (this.chosenMatches.length === 10) {
        this.modalsService.openNotificationModal(this.translate.instant('pg-round.recommended-limit'), this.translate.instant('pg-round.arrived-10'), false)
      }
    }

  }

  removeGame(indexData: number) {
    if (indexData > -1) {
      this.chosenMatches.splice(indexData, 1);
    }
  }

  onChangeCurrentCompetition(roundId: number) {
    const rd = this.roundsFinished.find(x => x.id == roundId);
    if (rd.posted === 0) {
      this.modalAvoidCreatioon.show();
    }
    this.configService.getRoundJackpot(roundId)
      .subscribe(data => {
        if (data != null) {
          this.currentJackpot = data.gathered;
        } else {
          this.currentJackpot = 0;
        }
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant('pg-round.fail'), this.translate.instant('pg-round.fail-info'), true);
        console.log(error);
      });

    try {
      this.groupParent = this.roundGroupRound.find(x => x.roundId == roundId).roundGroupId;
    } catch {
      this.groupParent = 0;
    }
  }

  closeAvoidCreation() {
    this.modalAvoidCreatioon.hide();
    //location.reload();
  }

  toggleTwoResults() {

    if (this.autoRenew.value == null) {
      this.autoRenew.setValue(true);
    }
    this.chosenMatches.forEach(element => {
      element.two_options = this.autoRenew.value ? 0 : 1;
    });
  }
  toggleRankRound() {
    if (this.rankRound) {
      this.rankRound = 0;
      (document.getElementById('team-away-matfield') as HTMLInputElement).style.display = 'block';
    } else if (!this.rankRound) {
      this.rankRound = 1;
      (document.getElementById('team-away-matfield') as HTMLInputElement).style.display = 'none';
    }
  }
  radioChange() {
  }
  onSubmit() {
    if (this.roundType == 0) {
      this.modalsService.openNotificationModal(this.translate.instant('pg-round.fail'), this.translate.instant('pg-round.type-field'), true)
      return;
    }
    if (this.roundType == 1 && typeof (this.roundBaseChoosen) == 'undefined') {
      this.modalsService.openNotificationModal(this.translate.instant('pg-round.fail'), this.translate.instant('pg-round.competition-base-field'), true)
      return;
    }
    // extracting teams names and preparing object Games
    const newGames: Game[] = [];
    this.chosenMatches.forEach((element, index) => {
      const obj: any = { homeName: element.game, awayName: element.game };
      newGames.push(obj);
      const pos = element.game.indexOf('-');
      if (this.rankRound) {
        newGames[index].homeName = element.game;
        newGames[index].awayName = '';
      } else {
        newGames[index].homeName = element.game.substring(0, pos - 1);
        newGames[index].awayName = element.game.substring(pos + 2);
      }
      newGames[index].betradarMatchId = element.id;
      newGames[index].status = 1;
      // newGames[index].awayTeamId = 100;
      newGames[index].dateTime = element.date;
      newGames[index].homeTeamScore = null;
      newGames[index].awayTeamScore = null;
      newGames[index].two_options = typeof (element.two_options) === 'undefined' ? 0 : 1;
    });
    let rjackpot: number;
    let rn: string;
    let rnItaly: string;
    let rnEng: string;
    let rnSpan: string;
    let two_options: number;
    const vl: number = +(document.getElementById('round-value') as HTMLInputElement).value / this.Currency.quote;
    if (this.autoRenew.value == null || this.autoRenew.value === false) {
      two_options = 0;
    } else {
      two_options = 1;
    }

    let pid: number;
    // tslint:disable-next-line:radix
    if (parseInt(String(this.roundType)) === 2) { // when it's a new competition
      rjackpot = +(document.getElementById('new-jackpot') as HTMLInputElement).value / this.Currency.quote;
      rn = (document.getElementById('round-name') as HTMLInputElement).value;
      rnItaly = (document.getElementById('round-name-italy') as HTMLInputElement).value;
      rnEng = (document.getElementById('round-name-Eng') as HTMLInputElement).value;
      rnSpan = (document.getElementById('round-name-span') as HTMLInputElement).value;
      pid = 0;

    } else { // when it's a competition continuation
      rjackpot = this.currentJackpot / this.Currency.quote;
      rn = this.roundsFinished.find(x => x.id === this.roundBaseChoosen).name;
      pid = this.roundsFinished.find(x => x.id === this.roundBaseChoosen).id;
      if (vl === 0) {
        this.modalsService.openNotificationModal(this.translate.instant('pg-round.fail'), this.translate.instant('pg-round.rjackpot-field'), true);
      }
    }
    if (rn === '' || rnItaly === '' || rnEng === '' || rnSpan === '') {
      this.modalsService.openNotificationModal(this.translate.instant('pg-round.fail'), this.translate.instant('pg-round.rn-required'), true);
    } else if (rjackpot === 0 || vl === 0) {
      this.modalsService.openNotificationModal(this.translate.instant('pg-round.fail'), this.translate.instant('pg-round.rjackpot-field'), true);
    } else {
      // tslint:disable-next-line:max-line-length
      this.configService.createRound(this.groupParent, rn, rnEng, rnItaly, rnSpan, rjackpot, vl, pid, this.sportId, this.rankRound, newGames/*,two_options*/)
        .subscribe((data: string) => {
          this.modalCreationSuccess.show();
        }, error => {
          this.modalsService.openNotificationModal(this.translate.instant('pg-round.fail'), this.translate.instant('pg-round.fail-info'), true);
          console.log(error);
        });
    }

  }

  closeModalConfirmation() {
    this.modalCreationSuccess.hide();
    // window.location.href = "/home";
    this.router.navigate(['/home']);
  }

}
