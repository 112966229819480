<div class="container">
    <div class="row">
        <!-- Card -->
        <div class="card mb-5">

            <!-- Card content -->
            <div class="card-body bg-theme">

                <div class="row">
                    <!-- Title -->
                    <div class="col-12 pt-3 page-title">
                        <h3>
                            <i class="fas fa-briefcase"></i>
                            {{ 'pg-work-together.title' | translate }}
                        </h3>
                        <hr />
                        <p>
                            {{ 'pg-work-together.description' | translate }}
                        </p>
                    </div>

                    <!-- New Form Area-->
                    <div class="col-12 d-flex justify-content-center">
                        <div class="col-11 form-area">
                            <form [formGroup]="contactForm">
                                <p class="sub-title">{{ 'pg-work-together.personal-info' | translate }}</p>
                                <div class="row form-line">
                                    <div class="col-md-4 col-sm-12 text-right">
                                        {{ 'pg-work-together.complete-name' | translate }}: <span
                                            class="asteristic">*</span>
                                    </div>
                                    <div class="col-md-7 col-sm-9">
                                        <input class="form-fields-pattern" type="text" mdbInput formControlName="name"
                                            required>
                                    </div>
                                </div>

                                <div class="row form-line">
                                    <div class="col-md-4 col-sm-12 text-right">
                                        {{ 'pg-work-together.birth-date' | translate }}:
                                    </div>
                                    <div class="col-md-7 col-sm-9">
                                        <mat-form-field>
                                            <input class="black-border" formControlName="birthdate" matInput
                                                [matDatepicker]="birthdate" [value]="birthdate">
                                            <mat-datepicker-toggle matSuffix [for]="birthdate"></mat-datepicker-toggle>
                                            <mat-datepicker #birthdate></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <p class="sub-title">{{ 'pg-work-together.contact-info' | translate }}</p>
                                <div class="row form-line">
                                    <div class="col-md-4 col-sm-12 text-right">
                                        E-mail: <span class="asteristic">*</span>
                                    </div>
                                    <div class="col-md-7 col-sm-9">
                                        <input class="form-fields-pattern" type="text" mdbInput formControlName="email"
                                            required>
                                    </div>
                                </div>

                                <div class="row form-line">
                                    <div class="col-md-4 col-sm-12 text-right">
                                        Whatsapp:
                                    </div>
                                    <div class="col-md-7 col-sm-9">
                                        <input class="form-fields-pattern" type="text" mdbInput
                                            formControlName="whatsapp">
                                    </div>
                                </div>

                                <p class="sub-title mt-4">{{ 'pg-work-together.location-info' | translate }}</p>
                                <div class="row form-line">
                                    <div class="col-md-4 col-sm-6 text-right">
                                        {{ 'pg-work-together.country' | translate }}
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <select class="form-fields-pattern" mdbInput formControlName="country">
                                            <option *ngFor="let items of countries" [value]="items.name">
                                                {{items.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row form-line">
                                    <div class="col-md-4 col-sm-6 text-right">
                                        {{ 'pg-work-together.city' | translate }}
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <input class="form-fields-pattern" type="text" mdbInput formControlName="city">
                                    </div>
                                </div>

                                <p class="sub-title mt-4">{{ 'pg-work-together.role-info' | translate }}</p>
                                <div class="row form-line">
                                    <div class="col-md-4 col-sm-6 text-right">
                                        {{ 'pg-work-together.role' | translate }}: <span class="asteristic">*</span>
                                    </div>
                                    <div class="col-md-6 col-sm-7">
                                        <select class="form-fields-pattern" mdbInput formControlName="role" required>
                                            <option *ngFor="let items of roleList" [value]="items">
                                                {{ 'pg-work-together.' + items | translate }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row form-line">
                                    <div class="col-md-4 col-sm-12 text-right">
                                        {{ 'pg-contact.message' | translate }}: <span class="asteristic">*</span><br />
                                    </div>
                                    <div class="col-md-7 col-sm-9">
                                        <textarea class="form-fields-pattern" rows="5" type="text" mdbInput
                                            formControlName="message" required></textarea>
                                        <br /><span class="msg-warning">({{'pg-contact.message-required' | translate
                                            }})</span>
                                    </div>
                                </div>

                                <div class="row form-line">
                                    <div class="col-12 d-flex justify-content-center">
                                        <button mdbBtn [disabled]="!contactForm.valid" type="submit" color="success"
                                            mdbWavesEffect (click)="sendMessage()">
                                            {{ 'pg-work-together.send' | translate}}
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                    <!-- New Form Area-->
                </div>

            </div>

        </div>
        <!-- Card -->
    </div>

</div>