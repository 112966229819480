<div class="container">
  <div class="row">
    <div class="card-body bg-theme">
      <div class="mb-3 d-flex align-items-end">
        <div class="col-6 d-flex">
          <h3 class="m-0">{{ 'pg-bet-list.plays-list' | translate }}</h3>
        </div>
        <div class="col-6 d-flex justify-content-end p-0">
          <button routerLink="/event-result" class="tertiary-button backBtn">{{ 'pg-home.back' | translate }}</button>
        </div>
      </div>
      <hr>
      <img src="../../../assets/image/Icon-print.svg" width="18px" (click)="print2()">
      <div class="d-flex flex-column justify-content-center align-items-center pb-0 mb-2" *ngIf="isNewRound">
        <div>
          <img class="mr-1" src="{{shieldHome}}" width="35px" onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">
          {{ this.roundName }}
          <img class="ml-1" src="{{shieldAway}}" width="35px" onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">
        </div>
         <div>{{this.roundDate | date:'mediumDate'}}</div>
         <a class="resultBtn" *ngIf="isRoundPosted" (click)="this.router.navigate(['/result/:' + this.roundId.toString()])">ver resultado</a>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center pb-0 mb-2" *ngIf="!isNewRound">
        <div>
          <img class="mr-1" src="{{shieldHome}}" width="20px" onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">
          {{ this.roundName }}
        </div>
         <div>{{this.roundDate | date:'mediumDate'}}</div>
         <a class="resultBtn" *ngIf="isRoundPosted" (click)="this.router.navigate(['/result/:' + this.roundId.toString()])">ver resultado</a>
      </div>
      <div id="table-box" class="table-box">
        <table class="w-100" mat-table [dataSource]="dataSource">
          <ng-container *ngFor="let column of dynamicColumnNames; let i = index" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef class="table-header-cell" [ngClass]="configTableHeader(column)">{{ column }}</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'even-column': i % 2 === 0, 'odd-column': i % 2 !== 0}">
              <a *ngIf="i === 0" (click)="openTicket(element.uuid8)">
                {{ getStaticValue(column, element) }}
              </a>
              <ng-container *ngIf="i === 2;">
                <img src="{{getFlagImage(element.country)}}" alt="" width="19px">
              </ng-container>
              <ng-container *ngIf="i < 3 && i != 0 && i != 2; else dynamicCell">
                {{ getStaticValue(column, element) }}
              </ng-container>
              <ng-template #dynamicCell>
                {{element['results'].split('|')[column - 1]}}
              </ng-template>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="dynamicColumnNames"></tr>
          <tr mat-row *matRowDef="let row; columns: dynamicColumnNames;"></tr>
        </table>
      </div>
      <mat-paginator [pageSizeOptions]="[10, 30, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>

