<!--Top page-->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
  <p style="color: white"></p>
</ngx-spinner>
<!-- <div style="line-height:20%;">
    <br>
</div> -->
<!-- <span *ngIf="!isLogged" class="mobile-hide"
    style="font-size: 10px; position: absolute;padding-left: 70%; color: #ccc">{{
    'pg-app-component.text' | translate }}</span>


<span class="desktop-hide" style="font-size: 9px; position: absolute;padding-left: 50%; color: #ccc">{{
    'pg-app-component.text' |
    translate }}</span> -->
<ng-container *ngIf="!blockedCountry">
  <div [ngStyle]="{ 'background-color': backgroundColor }">
    <div class="desktop-hide" id="mobile-menu" *ngIf="!this.actualUrl.includes('/lobby')">
      <nav class=" navbar navbar-dark bg-dark adjustNavBarButton">
        <!-- <button class="navbar-toggler languageButtonStyle" data-toggle="collapse" data-target="#LanguageMobileCollapse"
              aria-controls="LanguageMobileCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <img src="../assets/image/flags/{{activeLang}}.png" />
          </button> -->
        <img src="./../assets/image/bars-solid.svg" width="18px" (click)="sportMenu.show()" />

        <a href="/">
          <img src="../assets/image/{{mainLogo}}" class="img-fluid imgLogoMobileStyle" alt="PoolBet365 logo" />
        </a>
        <button class="LoggedButtonStyle" type="button" style="box-shadow: 2px 1px -1px 0px #00000052" *ngIf="isLogged"
          routerLink="/payment">
          {{ 'menu.credit' | translate }}
        </button>
        <ng-container *ngIf="isLogged; else notLogged">
          <button *ngIf="!isMenuOpen" class="navbar-toggler ButtonStyleSimple" type="button" data-toggle="collapse"
            data-target="#PlayerMobileProfile" aria-controls="PlayerMobileProfile" (click)="toggleMenu()"
            aria-expanded="false" aria-label="Toggle navigation" style="padding: 10px !important">
            <img *ngIf="!isMenuOpen" src="../assets/image/NavBarMobile/PerfilLogged.png" style="width: 115%" />
          </button>
          <button *ngIf="isMenuOpen" class="navbar-toggler ButtonStyleSimple" type="button" data-toggle="collapse"
            data-target="#PlayerMobileProfile" aria-controls="PlayerMobileProfile" (click)="toggleMenu()"
            aria-expanded="false" aria-label="Toggle navigation" style="padding: 10px !important">
            <img src="../assets/image/NavBarMobile/remover.png" style="width: 150%" />
          </button>
        </ng-container>
        <ng-template #notLogged>
          <button class="button-bg-theme LoggedButton" (click)="openLoginModal()">
            {{ "menu.signin" | translate }}
          </button>
          <button class="button-bg-theme LoggedButton" (click)="openRegisterModal()">
            {{ "menu.signup" | translate }}
          </button>
        </ng-template>
      </nav>
      <div *ngIf="isLogged" class="collapse" id="PlayerMobileProfile">
        <div class="bg-dark p-4 PlayerMobileProfileStyle">
          <div class="container-fluid" style="
            color: #ebe5e4;
            margin-top: -30px;
            padding-right: 0px !important;
            padding-left: 0px !important;
          ">
            <div class="row" style="width: 100%">
              <div class="col-12" style="
                border-bottom: solid #38ad38;
                display: flex;
                justify-content: space-evenly;
              ">
                {{ userAdmin.login }}
              </div>
              <div class="row" style="padding-bottom: 5px; display: contents">
                <div class="col-6 mobileBetsStyle" style="border-right: 1px solid #222222">
                  <div class="col-12">
                    <img src="../assets/image/NavBarMobile/saldo.png" style="max-width: 60px" />
                  </div>
                  <div class="col-12">
                    <b style="color: rgb(7, 255, 7)">{{ Currency.currencySymbol }}
                      {{
                      userAdmin.credit * Currency.quote | currency : "" : ""
                      }}</b>
                    <br />
                    <span class="fi12">{{
                      adjustLetter("pg-transactions.balance" | translate)
                      }}</span>
                  </div>
                </div>
                <div class="col-6 mobileBetsStyle" style="align-items: center !important">
                  <div class="col-12">
                    <a *ngIf="userAdmin.userPermissions[0].value" data-toggle="collapse" (click)="toggleMenu()"
                      data-target="#PlayerMobileProfile" routerLink="/manage-ticket" href="#" mdbWavesEffect><img
                        src="../assets/image/NavBarMobile/apostas.png" style="max-width: 60px" /></a>
                  </div>
                  <div class="col-12" style="color: #ebe5e4; margin-bottom: -10px">
                    {{ adjustLetter("pg-manage-ticket.manage-ticket" | translate) }}
                  </div>
                </div>
              </div>
              <div class="col-12 styleAdjustMobile">
                <span _ngcontent-rfx-c342="" style="background-color: #38ad38; color: transparent">.</span>
                {{ 'withdraw.account' | translate }}
              </div>
              <div class="row" style="gap: 1px; background-color: #4c4c4c; width: 100%">
                <div class="col-12 menuContentStyle">
                  <div class="col-1" style="
                    padding-left: 0px !important;
                    margin-left: -3px !important;
                  ">
                    <img src="../assets/image/NavBarMobile/perfil.png" class="navbarIconStyle" />
                  </div>
                  <div class="col-11" style="padding-left: 5px !important">
                    <a routerLink="/settings" data-toggle="collapse" data-target="#PlayerMobileProfile" href="#"
                      mdbWavesEffect (click)="toggleMenu()" style="color: #ebe5e4">{{ adjustLetter("menu.settings" |
                      translate) }}</a>
                  </div>
                </div>
                <div class="col-12 menuContentStyle">
                  <div class="col-1" style="
                    padding-left: 0px !important;
                    margin-left: -3px !important;
                  ">
                    <img src="../assets/image/NavBarMobile/cofrinho.png" class="navbarIconStyle" />
                  </div>
                  <div class="col-11" style="padding-left: 5px !important">
                    <a routerLink="/checkout" href="#" data-toggle="collapse" (click)="toggleMenu()"
                      data-target="#PlayerMobileProfile" mdbWavesEffect style="color: #ebe5e4">{{
                      adjustLetter("menu.checktout" | translate) }}</a>
                  </div>
                </div>
                <div class="col-12 menuContentStyle">
                  <div class="col-1" style="
                    padding-left: 0px !important;
                    margin-left: -3px !important;
                  ">
                    <img src="../assets/image/NavBarMobile/documento.png" class="navbarIconStyle" />
                  </div>
                  <div class="col-11" style="padding-left: 5px !important">
                    <a routerLink="/upload-doc" href="#" data-toggle="collapse" (click)="toggleMenu()"
                      data-target="#PlayerMobileProfile" mdbWavesEffect style="color: #ebe5e4">{{
                      adjustLetter('menu.upload-doc' | translate) }}</a>
                  </div>
                </div>
              </div>
              <div class="col-12 styleAdjustMobile">
                <span _ngcontent-rfx-c342="" style="background-color: #38ad38; color: transparent">.</span>
                {{ 'menu.reports' | translate }}
              </div>
              <div class="row" style="gap: 1px; background-color: #4c4c4c; width: 100%">
                <!-- <div class="col-12 menuContentStyle">
                              <div class="col-1" style="padding-left: 0px !important;margin-left: -3px !important;">
                                  <img src="../assets/image/NavBarMobile/relatorio de apostas.png"
                                      class="navbarIconStyle">
                              </div>
                              <div class="col-11" style="padding-left: 5px !important"> RELATORIO DE APOSTAS
                              </div>
                          </div> -->
                <div class="col-12 menuContentStyle">
                  <div class="col-1" style="
                    padding-left: 0px !important;
                    margin-left: -3px !important;
                  ">
                    <img src="../assets/image/NavBarMobile/transacao.png" class="navbarIconStyle" />
                  </div>
                  <div class="col-11" style="padding-left: 5px !important">
                    <a routerLink="/transactions" href="#" data-toggle="collapse" data-target="#PlayerMobileProfile"
                      mdbWavesEffect (click)="toggleMenu()" style="color: #ebe5e4">{{ adjustLetter("menu.transactions" |
                      translate) }}</a>
                  </div>
                </div>
              </div>
              <div class="col-12 styleAdjustMobile">
                <span _ngcontent-rfx-c342="" style="background-color: #38ad38; color: transparent">.</span>
                {{ "pg-app-component.help" | translate }}
              </div>
              <div class="row" style="gap: 1px; background-color: #4c4c4c; width: 100%">
                <div class="col-12 menuContentStyle">
                  <div class="col-1" style="
                    padding-left: 0px !important;
                    margin-left: -3px !important;
                  ">
                    <img src="../assets/image/NavBarMobile/regras.png" class="navbarIconStyle" />
                  </div>
                  <div class="col-11" style="padding-left: 5px !important">
                    <a routerLink="/rule" href="#" data-toggle="collapse" (click)="toggleMenu()"
                      data-target="#PlayerMobileProfile" mdbWavesEffect style="color: #ebe5e4">{{
                      adjustLetter("pg-footer.rules-footer" | translate) }}</a>
                  </div>
                </div>
                <div class="col-12 menuContentStyle">
                  <div class="col-1" style="
                    padding-left: 0px !important;
                    margin-left: -3px !important;
                  ">
                    <img src="../assets/image/NavBarMobile/termos.png" class="navbarIconStyle" />
                  </div>
                  <div class="col-11" style="padding-left: 5px !important">
                    <a routerLink="/global-terms" data-toggle="collapse" data-target="#PlayerMobileProfile" href="#"
                      mdbWavesEffect (click)="toggleMenu()" style="color: #ebe5e4">{{
                      adjustLetter("pg-footer.global-terms-footer" | translate)
                      }}</a>
                  </div>
                </div>
                <div class="col-12 menuContentStyle">
                  <div class="col-1" style="
                    padding-left: 0px !important;
                    margin-left: -3px !important;
                  ">
                    <img src="../assets/image/NavBarMobile/contato.png" class="navbarIconStyle" />
                  </div>
                  <div class="col-11" style="padding-left: 5px !important">
                    <a routerLink="/contact" data-toggle="collapse" data-target="#PlayerMobileProfile" href="#"
                      mdbWavesEffect (click)="toggleMenu()" style="color: #ebe5e4">{{
                      adjustLetter("pg-footer.assistance-footer" | translate)
                      }}</a>
                  </div>
                </div>
              </div>
              <div *ngIf="isLogged" style="width: 100%">
                <div *ngIf="userAdmin.jurisdictionId < 7" class="col-12 styleAdjustMobile">
                  <span _ngcontent-rfx-c342="" style="background-color: #38ad38; color: transparent">.</span>
                  {{ "menu.management" | translate }}
                </div>
                <div *ngIf="userAdmin.jurisdictionId < 7" class="col-12 menuContentStyle"
                  style="gap: 1px; background-color: #4c4c4c; width: 100%">
                  <a routerLink="/jurisdiction" data-toggle="collapse" (click)="toggleMenu()"
                    data-target="#PlayerMobileProfile" href="#" mdbWavesEffect style="color: #ebe5e4">
                    <img src="../assets/image/NavBarMobile/usuarios.png" class="navbarIconStyle mr-1" />
                    {{ adjustLetter("pg-jurisdiction.users" | translate) }}
                  </a>
                </div>
              </div>
            </div>
            <div class="row logoutGrid">
              <div>
                <button class="btnLogOutStyle" type="button" (click)="logout(); cancelbgyellow(); toggleMenu()"
                  routerLink="/home">
                  {{ adjustLetter("menu.logout" | translate) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <!-- <div class="collapse" id="LanguageMobileCollapse"> -->
      <div id="LanguageMobileCollapse" class="d-flex flex-row justify-content-between grandient">
        <div class="lang-div align-self-end ml-2 text-white" style="font-size: 15px; font-weight: bold">
          <div class="dropdown-language">
            <button class="dropbtn">
              {{ "pg-report.currency" | translate }}: {{activeCurrency}}
              <mat-icon aria-hidden="false" aria-label="Example home icon" color="black"
                style="color: #00ca44 !important">
                keyboard_arrow_down
              </mat-icon>
            </button>
            <div class="dropdown-content ml-3 drowpdown-currency bg-the-grey text-white">
              <a [ngClass]="{ 'active-choice': activeCurrency == 'EUR' }" class="text-white"
                (click)="setCurrency('Italy', 'EUR')">
                EUR
              </a>

              <a [ngClass]="{ 'active-choice': activeCurrency == 'USD' }" class="text-white"
                (click)="setCurrency('United States of America', 'USD')">
                USD
              </a>

              <a [ngClass]="{ 'active-choice': activeCurrency == 'BRL' }" class="text-white"
                (click)="setCurrency('Brazil', 'BRL')">
                BRL
              </a>

              <a [ngClass]="{ 'active-choice': activeCurrency == 'GBP' }" class="text-white"
                (click)="setCurrency('United Kingdom', 'GBP')">
                GBP
              </a>

              <a [ngClass]="{ 'active-choice': activeCurrency == 'MAD' }" class="text-white"
                (click)="setCurrency('Morocco', 'MAD')">
                MAD
              </a>

              <a [ngClass]="{ 'active-choice': activeCurrency == 'RON' }" class="text-white"
                (click)="setCurrency('Romania', 'RON')">
                RON
              </a>

              <a [ngClass]="{ 'active-choice': activeCurrency == 'TRY' }" class="text-white"
                (click)="setCurrency('Turkey', 'TRY')">
                TRY
              </a>

              <a [ngClass]="{ 'active-choice': activeCurrency == 'INR' }" class="text-white"
                (click)="setCurrency('India', 'INR')">
                INR
              </a>
            </div>
          </div>
        </div>
        <div class="lang-div align-self-end text-white" style="font-size: 15 px; font-weight: Bold">
          <!-- {{ 'language' | translate }}: -->
          <div class="dropdown-language ml-1">
            <button class="dropbtn">
              <img width="20px" src="../assets/image/flags/{{ activeLangCountry }}.png" />
              <mat-icon aria-hidden="false" aria-label="Example home icon" color="black">
                keyboard_arrow_down</mat-icon>
            </button>
            <div class="dropdown-content bg-the-grey">
              <a [ngClass]="{ 'active-choice': activeLangCountry == 'es' }" (click)="changeLanguage('es', 'es')">
                <img width="20px" src="../assets/image/flags/es.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'fr' }" (click)="changeLanguage('fr', 'fr')">
                <img width="20px" src="../assets/image/flags/fr.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'it' }" (click)="changeLanguage('it', 'it')">
                <img width="20px" src="../assets/image/flags/it.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'en' }" (click)="changeLanguage('en', 'en')">
                <img width="20px" src="../assets/image/flags/en.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'ar' }" (click)="changeLanguage('ar', 'ar')">
                <img width="20px" src="../assets/image/flags/ar.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'pt' }" (click)="changeLanguage('pt', 'pt')">
                <img width="20px" src="../assets/image/flags/pt.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'chi' }" (click)="changeLanguage('chi', 'chi')">
                <img width="20px" src="../assets/image/flags/chi.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'col' }" (click)="changeLanguage('col', 'col')">
                <img width="20px" src="../assets/image/flags/col.png" width="16px" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'hi' }" (click)="changeLanguage('hi', 'hi')">
                <img width="20px" src="../assets/image/flags/hi.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'ger' }" (click)="changeLanguage('ger', 'ger')">
                <img width="20px" src="../assets/image/flags/ger.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'gre' }" (click)="changeLanguage('gre', 'gre')">
                <img width="20px" src="../assets/image/flags/gre.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'ron' }" (click)="changeLanguage('ron', 'ron')">
                <img width="20px" src="../assets/image/flags/ron.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'rus' }" (click)="changeLanguage('rus', 'rus')">
                <img width="20px" src="../assets/image/flags/rus.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'tur' }" (click)="changeLanguage('tur', 'tur')">
                <img width="20px" src="../assets/image/flags/tur.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="desktop-hide">
      <!-- <div *ngIf="!isLogged" style="padding-left: 20%;" class="row justify-space-between"> -->
      <div *ngIf="!isLogged" class="row justify-space-between">
        <div class="col-9" class="desktop-hide mobile-hide">
          <div style="line-height: 80%">
            <br />
          </div>
          <a href="/">
            <img src="../assets/image/logo-mobile.png" width="800px" class="img-fluid" alt="PoolBet365 logo" />
          </a>
        </div>

        <app-cart-badge *ngIf="!this.router.url.includes('/lobby')"></app-cart-badge>
      </div>
      <!-- <div *ngIf="isLogged" style="padding-left: 20%; padding-top: 15px" class="row justify-space-between"> -->
      <div *ngIf="isLogged" class="row justify-space-between">
        <div class="col-9" class="desktop-hide mobile-hide">
          <div style="line-height: 80%">
            <br />
          </div>
          <a href="/">
            <img src="../assets/image/logo-mobile.png" width="800px" class="img-fluid" alt="PoolBet365 logo" />
          </a>
        </div>

        <app-cart-badge *ngIf="!this.router.url.includes('/lobby')"></app-cart-badge>
      </div>
    </div>



    <div class="mobile-hide formLogin" id="formlogin" *ngIf="!this.router.url.includes('/lobby')">
      <div class="col-4 text-center">
        <!-- <a href="http://www.poolbet365.com"> -->
        <a [href]="myUrl">
          <img [ngStyle]="{ 'width': mainLogoWidth }" src="../assets/image/{{mainLogo}}"
            class="img-fluid desktopPrimaryLogoStyle" alt="logo" />
        </a>
        <!-- </a> -->
      </div>

      <div class="col-6 d-flex flex-nowrap justify-content-end" *ngIf="!isLogged">
        <form id="loginform" class="form-inline mobile-hide">
          <div class="">
            <ng-container *ngIf="processingClick; else disabledLogin">
              <button mdbBtn color="default" class="base-btn-login" (click)="openLoginModal()" id="btnOpenLogin">
                {{ "menu.signin" | translate }}
              </button>
            </ng-container>
            <ng-template #disabledLogin>
              <button mdbBtn color="default" class="base-btn-login base-btn-login_disabled" disabled>
                {{ "menu.signin" | translate }}
              </button>
            </ng-template>

            <button mdbBtn color="default" class="waves-light button-bg-theme button-font base-btn-singnup"
              id="btnSigunp" *ngIf="!isLogged" (click)="openRegisterModal()" mdbWavesEffect>
              {{ "menu.signup" | translate }}
            </button>
          </div>
        </form>
      </div>

      <div class="col-6 mt-1 d-flex justify-content-end flex-nowrap" *ngIf="isLogged">
        <app-cart-badge *ngIf="!this.router.url.includes('/lobby')"></app-cart-badge>
        <span class="mobile-hide" style="font-size: 10px; color: #ccc">
          {{ "pg-app-component.text" | translate }}
        </span>
        <div class="mobile-hide form-inline d-flex flex-row flex-nowrap">
          <a class="nav-link waves-light admin-style user-logged" mdbWaverEffect *ngIf="isLogged" data-toggle="tooltip"
            data-placement="bottom">
            <button (click)="CredidLoad()" mat-button [matMenuTriggerFor]="menu" #mattrigger="matMenuTrigger"
              class="mt-2">
              <!-- <mat-icon> monetization_on</mat-icon> -->
              <mat-icon>menu</mat-icon>
              <span style="font-size: 18px; color: white">
                {{ userAdmin.login }}
              </span>
            </button>
            <mat-menu #menu="matMenu">
              <div style="
                color: white;
                font-size: 17px;
                padding-right: 10px;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
              ">
                <div style="
                  color: white;
                  font-size: 17px;
                  text-align: left;
                  padding-left: 10px;
                ">
                  <span style="cursor: pointer" class="dropdown-item waves-light text-right pr-1" mdbWavesEffect
                    (click)="openChangePasswordModal()">{{ "pg-jurisdiction.change-password" | translate }}</span>
                  <span>
                    {{ "pg-app-component.credit" | translate }}:
                    <b style="color: rgb(7, 255, 7)">{{ Currency.currencySymbol }}
                      {{
                      userAdmin.credit * Currency.quote | currency : "" : ""
                      }}</b></span>
                </div>
              </div>
              <hr style="margin: 0" />
              <div style="padding-left: 10px; padding-top: 8px">
                <a *ngIf="userAdmin.userPermissions[0].value" class="dropdown-item waves-light"
                  routerLink="/manage-ticket" href="#" mdbWavesEffect>{{ "menu.manage-tickets" | translate }}</a>
                <a *ngIf="userAdmin.userPermissions[0].value" class="dropdown-item waves-light"
                  routerLink="/transactions" href="#" mdbWavesEffect>{{ "menu.transactions" | translate }}</a>
                <a *ngIf="userAdmin.userPermissions[0].value" class="dropdown-item waves-light" routerLink="/payment"
                  href="#" mdbWavesEffect>{{ "menu.credit" | translate }}</a>
                <a *ngIf="userAdmin.userPermissions[0].value" class="dropdown-item waves-light" routerLink="/checkout"
                  href="#" mdbWavesEffect>{{ "menu.checktout" | translate }}</a>
                <div *ngIf="userAdmin.jurisdictionId < 7" class="divider dropdown-divider"></div>
                <a *ngIf="userAdmin.jurisdictionId == 7" class="dropdown-item waves-light" routerLink="/upload-doc"
                  href="#" mdbWavesEffect>{{ "menu.upload-doc" | translate }}</a>
                <a *ngIf="userAdmin.userPermissions[0].value" class="dropdown-item waves-light" routerLink="/settings"
                  href="#" mdbWavesEffect>{{ "menu.settings" | translate }}</a>
                <a *ngIf="userAdmin.jurisdictionId < 7" class="dropdown-item waves-light" routerLink="/jurisdiction"
                  href="#" mdbWavesEffect>{{ "menu.users" | translate }}</a>
              </div>
            </mat-menu>
          </a>
          <div class="Loged_Buttons">
            <button mdbBtn style="
              white-space: nowrap;
              padding: 1px 6px;
              height: 2em;
              z-index: 555;
              color: black;
              font-weight: bold;
            " class="waves-light button-bg-theme button-font" mdbWavesEffect *ngIf="isLogged"
              (click)="logout(); cancelbgyellow()" routerLink="/home" mdbWavesEffect>
              {{ "menu.logout" | translate }}
            </button>

            <button mdbBtn style="
              white-space: nowrap;
              padding: 1px 6px;
              height: 2em;
              z-index: 555;
              color: black;
              font-weight: bold;
            " class="waves-light button-bg-theme button-font" mdbWavesEffect *ngIf="isLogged" routerLink="/payment"
              mdbWavesEffect>
              <span *ngIf="activeLang == 'pt'" class="pr-2 pl-2">
                <img src="../assets/image/icon-pix-small.png" />
                PIX
              </span>
              <span *ngIf="activeLang != 'pt'">
                <i class="fas fa-dollar-sign" style="font-size: 18px"></i>
                {{ "menu.credit" | translate }}
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="col-2 justify-content-end currency_language_div text-white"
        style="font-size: 0.8rem; font-weight: bold;">
        <div class="lang-div align-self-end">
          {{ "pg-report.currency" | translate }}:
          <div class="dropdown-language ml-1">
            <div class="currencybtn d-flex align-items-center pl-1">
              <div class="mt01">
                {{ activeCurrency }}
              </div>
              <mat-icon aria-hidden="false" aria-label="Example home icon" color="black" style="transform: scale(0.7)">
                keyboard_arrow_down</mat-icon>
            </div>

            <div class="dropdown-content bg-the-grey">
              <a [ngClass]="{ 'active-choice': activeCurrency == 'EUR' }" class="text-white"
                (click)="setCurrency('Italy', 'EUR')">
                EUR
              </a>

              <a [ngClass]="{ 'active-choice': activeCurrency == 'USD' }" class="text-white"
                (click)="setCurrency('United States of America', 'USD')">
                USD
              </a>

              <a [ngClass]="{ 'active-choice': activeCurrency == 'BRL' }" class="text-white"
                (click)="setCurrency('Brazil', 'BRL')">
                BRL
              </a>

              <a [ngClass]="{ 'active-choice': activeCurrency == 'GBP' }" class="text-white"
                (click)="setCurrency('United Kingdom', 'GBP')">
                GBP
              </a>

              <a [ngClass]="{ 'active-choice': activeCurrency == 'MAD' }" class="text-white"
                (click)="setCurrency('Morocco', 'MAD')">
                MAD
              </a>

              <a [ngClass]="{ 'active-choice': activeCurrency == 'RON' }" class="text-white"
                (click)="setCurrency('Romania', 'RON')">
                RON
              </a>

              <a [ngClass]="{ 'active-choice': activeCurrency == 'TRY' }" class="text-white"
                (click)="setCurrency('Turkey', 'TRY')">
                TRY
              </a>

              <a [ngClass]="{ 'active-choice': activeCurrency == 'INR' }" class="text-white"
                (click)="setCurrency('India', 'INR')">
                INR
              </a>
            </div>
          </div>
        </div>

        <div class="lang-div ml-1 align-self-end">
          <!-- {{ 'language' | translate }}: -->
          <div class="dropdown-language ml-1">
            <button class="dropbtn">
              <img src="../assets/image/flags/{{ activeLangCountry }}.png" />
              <mat-icon aria-hidden="false" aria-label="Example home icon" color="black">
                keyboard_arrow_down</mat-icon>
            </button>
            <div class="dropdown-content bg-the-grey">
              <a [ngClass]="{ 'active-choice': activeLangCountry == 'es' }" (click)="changeLanguage('es', 'es')">
                <img src="../assets/image/flags/es.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'fr' }" (click)="changeLanguage('fr', 'fr')">
                <img src="../assets/image/flags/fr.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'it' }" (click)="changeLanguage('it', 'it')">
                <img src="../assets/image/flags/it.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'en' }" (click)="changeLanguage('en', 'en')">
                <img src="../assets/image/flags/en.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'ar' }" (click)="changeLanguage('es', 'ar')">
                <img src="../assets/image/flags/ar.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'pt' }" (click)="changeLanguage('pt', 'pt')">
                <img src="../assets/image/flags/pt.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'chi' }" (click)="changeLanguage('chi', 'chi')">
                <img src="../assets/image/flags/chi.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'col' }" (click)="changeLanguage('es', 'col')">
                <img src="../assets/image/flags/col.png" width="16px" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'hi' }" (click)="changeLanguage('hi', 'hi')">
                <img src="../assets/image/flags/hi.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'ger' }" (click)="changeLanguage('ger', 'ger')">
                <img src="../assets/image/flags/ger.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'gre' }" (click)="changeLanguage('gre', 'gre')">
                <img src="../assets/image/flags/gre.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'ron' }" (click)="changeLanguage('ron', 'ron')">
                <img src="../assets/image/flags/ron.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'rus' }" (click)="changeLanguage('rus', 'rus')">
                <img src="../assets/image/flags/rus.png" />
              </a>

              <a [ngClass]="{ 'active-choice': activeLangCountry == 'tur' }" (click)="changeLanguage('tur', 'tur')">
                <img src="../assets/image/flags/tur.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Top page-->
    <div class="mobile-hide" style="line-height: 50%" *ngIf="!this.actualUrl.includes('/lobby')">
      <br />
    </div>
    <app-nav-bar></app-nav-bar>

    <!--Content Pages-->
    <div class="container mobile-container" style="min-height: 850px !important;">
      <div class="row p-2 mobile-padding">
        <div class="lateral-provisory-adjust" *ngIf="!isHomePage">

        </div>
        <div class="col-md-2 col-12 mobile-rounds-group" *ngIf="isHomePage">
          <div class="row mobile-hide">
            <div class="col-12">
              <app-search-ticket></app-search-ticket>
            </div>
          </div>

          <div class="row mobile-hide mt-3" *ngIf="isHomePage">
            <app-side-menu class="w-100"></app-side-menu>
          </div>

          <div class="row mt-3 mobile-hide" *ngFor="let slide of leftSlideList">
            <a href="{{ slide.url }}" target="_self">
              <img src="{{ slidesPathPrefix + slide.fileName }}" class="img-fluid" />
            </a>
          </div>
        </div>

        <div [ngClass]="isHomePage ? 'col-md-8 col-12 mt-2 pl-1 pr-1 mobile-container1' : 'margin-auto min-height-main'"
          id="colun13">
          <router-outlet></router-outlet>
        </div>

        <div *ngIf="isHomePage" class="col-md-2 col-12 mobile-hide" style="min-width: 23%">
          <app-ticket></app-ticket> <!-- TICKET LIST LATERAL DIREITA-->
          <app-ticket-panel></app-ticket-panel>
          <!-- <app-streamdata></app-streamdata> -->
        </div>
      </div>
    </div>
    <!--Content Pages-->

    <app-loader></app-loader>

    <app-footer [actualUrl]="this.actualUrl"></app-footer>
  </div>

  <app-user-actions></app-user-actions>


  <app-message-standard></app-message-standard> <!-- NOTIFICATION MODAL -->


  <div mdbModal #videosTutorialModal="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{ backdrop: true, ignoreBackdropClick: false }">
    <div class="modal-dialog modal-notify modal-success modal-dialog-centered justify-content-center" role="document"
      id="msgModalStandardBetting">
      <div class="modal-content align-items-center modalTutorialsList">
        <ul class="tutorial-list">
          <li class="p-2 pt-3" (click)="frameSiteIntroduction.show(); videosTutorialModal.hide()">
            {{ "menu.site-introduction" | translate }}
          </li>
          <li class="p-2" (click)="frameHowToRegister.show(); videosTutorialModal.hide()">
            {{ "menu.how-to-register" | translate }}
          </li>
          <li class="p-2" (click)="frameHowToDeposit.show(); videosTutorialModal.hide()">
            {{ "menu.how-to-deposit" | translate }}
          </li>
          <li class="p-2" (click)="frameHowToBet.show(); videosTutorialModal.hide()">
            {{ "menu.how-to-bet" | translate }}
          </li>
          <li class="p-2" (click)="frameSendDocs.show(); videosTutorialModal.hide()">
            {{ "menu.how-to-send-docs" | translate }}
          </li>
          <li class="p-2" (click)="frameCheckout.show(); videosTutorialModal.hide()">
            {{ "menu.how-to-checkout" | translate }}
          </li>
          <li class="p-2" (click)="frameChangePassword.show(); videosTutorialModal.hide()">
            {{ "menu.how-to-change-password" | translate }}
          </li>
          <li class="p-2 pb-3" (click)="frameTransactions.show(); videosTutorialModal.hide()">
            {{ "menu.transactions-tutorial" | translate }}
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div mdbModal #frameSiteIntroduction="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-primary modalVideoTutorial" role="document">
      <div class="modal-content bg-theme modalVideoTutorial__content">
        <div class=""></div>
        <iframe *ngIf="!isLoading && this.introductionVideoUrl" class="w-100" width="560" height="315"
          [src]="introductionVideoUrl" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
        <!-- <div *ngIf="isLoading" class="spinner-border m-5 align-self-center" role="status">
                          <span class="sr-only">Loading...</span>
                      </div> -->
      </div>
    </div>
  </div>

  <div mdbModal #frameHowToRegister="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-primary modalVideoTutorial" role="document">
      <div class="modal-content bg-theme modalVideoTutorial__content">
        <div class=""></div>
        <iframe *ngIf="!isLoading && this.registerVideoUrl" class="w-100" width="560" height="315"
          [src]="registerVideoUrl" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
        <div *ngIf="isLoading" class="spinner-border m-5 align-self-center" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div mdbModal #frameHowToDeposit="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-primary modalVideoTutorial" role="document">
      <div class="modal-content bg-theme modalVideoTutorial__content">
        <div class=""></div>
        <iframe *ngIf="!isLoading && this.depositVideoUrl" class="w-100" width="560" height="315"
          [src]="depositVideoUrl" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
        <div *ngIf="isLoading" class="spinner-border m-5 align-self-center" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div mdbModal #frameHowToBet="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-primary modalVideoTutorial" role="document">
      <div class="modal-content bg-theme modalVideoTutorial__content">
        <div class=""></div>
        <iframe *ngIf="!isLoading && this.betVideoUrl" class="w-100" width="560" height="315" [src]="betVideoUrl"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
        <div *ngIf="isLoading" class="spinner-border m-5 align-self-center" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div mdbModal #frameSendDocs="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-primary modalVideoTutorial" role="document">
      <div class="modal-content bg-theme modalVideoTutorial__content">
        <div class=""></div>
        <iframe *ngIf="!isLoading && this.sendDocsVideoUrl" class="w-100" width="560" height="315"
          [src]="sendDocsVideoUrl" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
        <div *ngIf="isLoading" class="spinner-border m-5 align-self-center" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div mdbModal #frameCheckout="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-primary modalVideoTutorial" role="document">
      <div class="modal-content bg-theme modalVideoTutorial__content">
        <div class=""></div>
        <iframe *ngIf="!isLoading && this.checkoutVideoUrl" class="w-100" width="560" height="315"
          [src]="checkoutVideoUrl" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
        <div *ngIf="isLoading" class="spinner-border m-5 align-self-center" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div mdbModal #frameChangePassword="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-primary modalVideoTutorial" role="document">
      <div class="modal-content bg-theme modalVideoTutorial__content">
        <div class=""></div>
        <iframe *ngIf="!isLoading && this.changePasswordVideoUrl" class="w-100" width="560" height="315"
          [src]="changePasswordVideoUrl" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
        <div *ngIf="isLoading" class="spinner-border m-5 align-self-center" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div mdbModal #frameTransactions="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-primary modalVideoTutorial" role="document">
      <div class="modal-content bg-theme modalVideoTutorial__content">
        <div class=""></div>
        <iframe *ngIf="!isLoading && this.transactionVideoUrl" class="w-100" width="560" height="315"
          [src]="transactionVideoUrl" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
        <div *ngIf="isLoading" class="spinner-border m-5 align-self-center" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div mdbModal #sportMenu="mdbModal" class="modal fade sideMenu" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-primary" role="document" style="
            display: block;
            margin-top: -95vh !important;
            margin-left: -0.05rem;
          ">
      <div class="modal-content" style="background: none !important">
        <div class="mb-4">
          <button type="button" class="close text-light" data-dismiss="modal" aria-label="Close"
            (click)="sportMenu.hide()">
            <span>&times;</span>
          </button>
        </div>
        <div style="width: 65% !important">
          <app-side-menu (close)="sportMenu.hide()"></app-side-menu>
        </div>
      </div>
    </div>
  </div>
  <!-- </div>
    </div>
  </div> -->

  <!-- Modal Choose Ticket -->

  <div mdbModal #frameChooseTicket="mdbModal" class="msg-modal modal fade right" id="frameModalTop" tabindex="-1"
    role="dialog" aria-labelledby="myModalLabel" aria-hidden="true"
    [config]="{ backdrop: false, ignoreBackdropClick: true }">
    <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandard">
      <div class="modal-content bg-theme">

        <div class="modal-header text-center" style="background-color: #00ca44 !important">
          <h5 class="modal-title w-100 font-weight-bold" style="color: white">
            {{ "pg-event-result.date" | translate }} {{ "pg-result.ticket" | translate }}
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="frameChooseTicket.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body2">
          <div class="col-12">
            <p>{{ "pg-jurisdiction.choose" | translate }}:</p>
          </div>
          <div class="col-12 text-center" *ngFor="let ticket of dateTicketsList">
            <a type="button" class="btn bg-theme-green btn-success" style="width: 80% !important;"
              (click)="detailCoupon(ticket.id); frameChooseTicket.hide()">
              {{ ticket.datetime }}
            </a>
          </div>
        </div>

        <div class="modal-footer d-flex justify-content-center">
          <a type="button" style="padding: 0.54rem 2.14rem" class="btn bg-theme-green" id="msgBtnsucces" mdbWavesEffect
            (click)="frameChooseTicket.hide(); isResumeTicket = false" data-dismiss="modal">
            {{ "pg-home.back" | translate }}
          </a>
        </div>

      </div>
    </div>
  </div>
  <!-- Modal Choose Ticket -->

  <app-detail-coupon></app-detail-coupon>
</ng-container>

<app-error *ngIf="blockedCountry"></app-error>