<div class="container">

  <div class="upload_content">
    <div>
      <h3> {{ 'upload-doc.documents' | translate }}</h3>
      <hr />
    </div>
    <div class="dFlex">
      <div class="standards fSize-15 mb-1">
        <p class="bg-theme-greyDG mb-1">
          {{ 'upload-doc.content1' | translate }}
        </p>
        <p class="bg-theme-greyDG mb-1">
          {{ 'upload-doc.content2' | translate }}
        </p>
        <div class="bg-theme-greyDG">

          <p class="mb-2">
            {{ 'upload-doc.content3' | translate }}
          </p>

          <p class="mb-2">
            {{ 'upload-doc.content4' | translate }}
          </p>

          <p class="mb-1">
            {{ 'upload-doc.content5' | translate }}
          </p>
        </div>
      </div>

      <div class="uploadsDocs bg-theme-greyDG fSize-15">
        <p>{{'upload-doc.header' | translate}}:</p>

        <p class="fSize-13" style="margin-bottom: 0;">{{'upload-doc.doc_type_1' | translate}}:</p>
        <div class="btn-choose-file fSize-13 mb-3">
          <span id="FileName1">{{ FilesNames[1] | translate}}</span>
          <input type="file" #file placeholder="Choose file" (change)="onFileSelected($event, 1)" style="display: none;">
          <button type="button" class="bg-theme-greyDGBt bt_ThemeDG" (click)="file.click()" id="btnFile1">
            {{ 'pg-banners.choose-image' | translate }}
          </button>

          <button type="button" class="bg-theme-greyDGBt bt_ThemeDG ml-2" *ngIf="FileStatus[1] == 2" (click)="file.click()">
            {{ 'pg-banners.choose-image' | translate }}
          </button>
        </div>

        <p class="fSize-13" style="margin-bottom: 0;">{{'upload-doc.doc_type_6' | translate}}:</p>
        <div class="btn-choose-file fSize-13 mb-3">
          <span id="FileName5">{{FilesNames[5] | translate}}</span>
          <input type="file" #file2 placeholder="Choose file" (change)="onFileSelected($event, 5)" style="display: none;">
          <button type="button" class="bg-theme-greyDGBt bt_ThemeDG" (click)="file2.click()" id="btnFile5">
            {{ 'pg-banners.choose-image' | translate }}
          </button>

          <button type="button" class="bg-theme-greyDGBt bt_ThemeDG ml-2" *ngIf="FileStatus[5] == 2" (click)="file2.click()">
            {{ 'pg-banners.choose-image' | translate }}
          </button>
        </div>

        <p class="fSize-13" style="margin-bottom: 0;">{{'upload-doc.doc_type_2' | translate}}:</p>
        <div class="btn-choose-file fSize-13 mb-3">
          <span id="FileName2">{{FilesNames[2] | translate}}</span>
          <input type="file" #file3 placeholder="Choose file" (change)="onFileSelected($event, 2)" style="display: none;">
          <button type="button" class="bg-theme-greyDGBt bt_ThemeDG" (click)="file3.click()" id="btnFile2">
            {{ 'pg-banners.choose-image' | translate }}
          </button>

          <button type="button" class="bg-theme-greyDGBt bt_ThemeDG ml-2" *ngIf="FileStatus[2] == 2" (click)="file3.click()">
            {{ 'pg-banners.choose-image' | translate }}
          </button>
        </div>
        
        <p class="fSize-13">
          {{ 'upload-doc.content8' | translate }}
        </p>

        <button type="button" class="btn_docs fSize-15" (click)="uploadFile()">
          {{ 'pg-home.send' | translate }}
        </button>
      </div>
    </div>

    <!-- <div style="background-color: #fff; width: 100%;">
      <div class="row row-space">
        <div class="row row-space" style="justify-content: center;">
          <div class="col-md-6 col-sm-12 sub-content my-auto" style="justify-content: right;display: flex;">
            {{ 'upload-doc.doc-type' | translate }}
          </div>
          <div class="col-md-6 col-sm-12 sub-content my-auto">
            <select class="form-fields-pattern align-middle" id="select_type" [(value)]="type_val" mdbInput>
              <option *ngFor="let doc of docsTypes" [value]="doc.type">
                {{ doc.name | translate }}
              </option>
            </select>
          </div>
          <div class="col-12 sub-content text-center" id="btn-choose-file">
            <input type="file" #file placeholder="Choose file" (change)="onFileSelected($event)" style="display: none;">
            <button type="button" class="choose-fiel-btn" (click)="file.click()">
              {{ 'pg-banners.choose-image' | translate }}
            </button>
            <span>{{fileName}}</span>
          </div>

          <button type="button" class="btn btn-success" (click)="uploadFile(file.files)">
            {{ 'pg-banners.publish' | translate }}</button><br /> 
        </div>
      </div>
    </div> -->
  </div>

</div>