import { EEMailLanguage } from '../Interfaces/Enumerators/EEMailLanguage'

export class RecoveryPasswordRequestModel {
  Email: string;
  Language: EEMailLanguage;

  constructor(email: string, language: EEMailLanguage) {
    this.Email = email;
    this.Language = language
  }
}
