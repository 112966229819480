import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DetailCoupon } from "../../Interfaces/DetailCoupon/DetailCoupon.interface";
import { RoundEvent } from "../../Interfaces/RoundEvent/Event.interface";
import { RoundMatch } from "../../Interfaces/Match/RoundMatch.interface";
import { ShieldHelper } from "../../Helpers/ShieldHelper";
@Injectable({
    providedIn: 'root'
})

export class TableChoiceFacotry {
    private texts = [
        [
            '1',
            'x',
            '2'
        ],
        [
            '1',
            ' ',
            '2'
        ],
        [
            'Over',
            '',
            'Under'
        ],
        [
            'Yes',
            ' ',
            'No'
        ],
        [
            'Even',
            ' ',
            'Odd'
        ],
        [
            'Home',
            ' ',
            'Away'
        ],
        [
            'Home',
            'Draw',
            'Away'
        ]
    ];

    private baseClasses: string[] = [
        'not-hit-Choice',
        'active-Confirmation-Choice'
    ];
    constructor(private translate: TranslateService) {
    }

    private options = {
        '1': 0,
        'x': 1,
        '2': 2
    }

    GenerateTable(detail: DetailCoupon): string {
        let table = '';


        let choices = detail.bet.results.split("|");
        let results = detail.roundPosted ? detail.roundResult.split("|") : choices;

        switch (detail.roundType) {
            case 0:
                table = this.GenerateGenericTable(choices, detail.events, detail.roundPosted, results);
                break;
            case 1:
                table = this.GenerateChampionshipTable(choices, detail.matches, detail.roundPosted, results);
                break;
            case 2:
                table = this.GenerateRankRoundTable(choices, detail.roundSportId, detail.roundPosted, results);
                break;
        }

        return table;
    }

    private GenerateGenericTable(choices: string[], events: RoundEvent[], isPosted: boolean, results: string[] = []): string {
        let table = '';

        if (!isPosted) {
            events.forEach(
                (event, i) => {
                    table += this.GenerateGenericRow(event.name, event.type, choices[i], true, event.odd);
                }
            );
        }

        if (isPosted) {
            events.forEach(
                (event, i) => {
                    if (choices[i].includes('¢')) {
                        table += this.GenerateCanceledRow(event.name.substring(1), 0, 0);
                    }
                    else {
                        const hit = choices[i].toLowerCase().includes(results[i].toLowerCase()) ? true : false;

                        table += this.GenerateGenericRow(event.name, event.type, choices[i], hit, event.odd);
                    }
                }
            )
        }

        return table;
    }

    private GenerateChampionshipTable(choices: string[], matchs: RoundMatch[], isPosted: boolean, results: string[] = []): string {
        let table = '';

        if (!isPosted) {
            matchs.forEach(
                (match, i) => {
                    let matchName = match.homeName + " X " + match.awayName;
                    table += this.GenerateGenericRow(matchName, match.two_options, choices[i], true);
                }
            )
        }

        if (isPosted) {
            matchs.forEach(
                (match, i) => {
                    let matchName = match.homeName + " X " + match.awayName;

                    if (choices[i].includes("¢")) {
                        table += this.GenerateCanceledRow(matchName, 1, 0);
                    } else {
                        let hit = choices[i].toLowerCase().includes(results[i].toLowerCase()) ? true : false;

                        table += this.GenerateGenericRow(matchName, match.two_options, choices[i], hit);
                    }
                }
            )
        }

        return table;
    }

    private GenerateGenericRow(name: string, type: number, choice: string, areHits: boolean, odd: string = '') {
        let text: string[] = this.texts[type];
        let classes: string[] = [
            '',
            type == 0 || type == 6 ? '' : 'bg-none',
            ''
        ];
        let baseClass: string = areHits ? this.baseClasses[1] : this.baseClasses[0];

        choice = choice.toLowerCase();
        const options = this.options;

        for (let i = 0; i < choice.length; i++) {
            const option = options[choice[i]];

            if (option !== undefined)
                classes[option] = baseClass;
        }

        if (type == 2) {
            text[1] = odd;
        }

        return `<div class="div-information-confirmation-ticket bg-theme-grey">` +
            `<div class="event-name-Confirmation">${this.translate.instant(name)}</div>` +
            `<div class="event-Choices-Confirmation ${classes[0]}">${this.translate.instant(text[0])}</div>` +
            `<div class="event-Choices-Confirmation ${classes[1]}">${this.translate.instant(text[1])}</div>` +
            `<div class="event-Choices-Confirmation ${classes[2]}">${this.translate.instant(text[2])}</div>` +
            `</div>`;
    }

    private GenerateRankRoundTable(choices: string[], sportId: number, isPosted: boolean, results: string[]): string {
        let table = '';
        debugger;

        if (!isPosted) {
            choices.forEach(
                (choice, i) => {
                    table += this.GenerateRankRoundRow(choice, i + 1, true, sportId);
                }
            )
        }

        if (isPosted) {
            choices.forEach(
                (choice, i) => {
                    if (choice.includes('¢')) {
                        table += this.GenerateCanceledRow(choice.substring(1), 2, sportId);
                    } else {
                        let hit = choice.toLowerCase().includes(results[i].toLowerCase()) ? true : false;
                        table += this.GenerateRankRoundRow(choice, i + 1, true, sportId);
                    }
                }
            )
        }

        return table;
    }

    private GenerateRankRoundRow(name: string, rank: number, areHit: boolean, sportId: number = 0) {
        let shieldURL: string = ShieldHelper.getSelectedShield(name, sportId);
        let tagImg = '';
        let baseCLass = areHit ? this.baseClasses[1] : this.baseClasses[0];

        if (sportId > 0)
            tagImg = `<img width='30px' onerror="this.onerror=null; this.src='https://poolbet365.com/assets/shields/default-logo.png'" src='${shieldURL}'>`

        return `<div class="div-information-confirmation-ticket bg-theme-grey">` +
            `<div class="event-name-Confirmation"> ${tagImg}  ${name} </div>` +
            `<div class="event-Choices-Confirmation bg-none"></div>` +
            `<div class="event-Choices-Confirmation ${baseCLass}">${rank}°</div>` +
            `</div>`;
    }

    private GenerateCanceledRow(name: string, roundType: number, sportId: number) {
        let eventCaceled: string = '';

        switch (roundType) {
            case 0:
                eventCaceled = name;
                break;
            case 1:
                // let names = name.split(' X ');
                // let homeUrlShield = ShieldHelper.getBasicShield(names[0]);
                // let awayUrlShield = ShieldHelper.getBasicShield(names[1]);

                // eventCaceled = `<img src="${homeUrlShield}" width="20px">` +
                //     `${name}` +
                //     `<img src="${awayUrlShield}" width="20px">`;

                eventCaceled = name;
                break;
            case 2:
                eventCaceled = name;
                const shield = ShieldHelper.getSelectedShield(name, sportId)

                eventCaceled = `<img src="${shield}" width="20px">` +
                    `${eventCaceled}`;
                break;
        }

        return `<div class="div-information-confirmation-ticket bg-theme-grey">` +
            `<div class="event-name-Confirmation">` +
            `${eventCaceled}` +
            `</div>` +
            `<div class="cancel-choice-event">${this.translate.instant('Canceled')}</div>` +
            `</div>`;
    }
}