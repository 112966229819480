<div class="mobile-width-adjust">
  <div class="bg-theme pb-2">
    <h3 class="d-flex justify-content-start align-items-center  pl-4 pt-3">
      <img class="mr-2" src="../../assets/image/aviator.png" width="60vh">
      CRASH GAMES
    </h3>
    <hr>
    <div class="bg-theme p-3 pt-3 mobile-container desktop-hide">
      <ng-container *ngFor="let games of mobileGameList;">
        <div class="videoSlot-Box_mobile w-100 mb-2">
          <div (click)="openCrashGameSession(games)" class="img-box">
            <img class="game_img" src="{{ games.imgUrl }}" alt="thumb">
          </div>
          <span class="text-center game_name desktop-hide"> {{ games.name }}</span>
        </div>
      </ng-container>
    </div>
    <div class="bg-theme p-3 desktop-container pt-3 mobile-hide">
      <ng-container *ngFor="let games of desktopGameList;">
        <div class="videoSlot-Box w-100 mb-2">
          <div (click)="openCrashGameSession(games)" class="img-box">
            <img class="game_img" src="{{ games.imgUrl }}" alt="thumb">
            <span class="w-100 text-center">
              <mat-icon
                style="font-size: 60px; text-align: center; width: 100% !important;">play_circle_outline</mat-icon> <br>
              {{ games.name }}
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
