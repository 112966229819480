<!--Body-->

<div id="bannerswidth">
  <div class="my-body">
    <div class="row text-center bannerAdjust">

      <mdb-carousel [isControls]="true" class="carousel slide carousel-fade" [animation]="'fade'">
        <mdb-carousel-item *ngFor="let slides of topSlideList; let i = index;">
          <div class="view w-100">
            <!-- <img class="d-block w-100 banner-limits" src="{{ slidesPathPrefix + slides.fileName}}"> -->
            <img class="d-block banner-limits" src="{{ slidesPathPrefix + slides.fileName}}" aria-disabled="true">
            <div class="waves-light" mdbWavesEffect></div>
          </div>
          <div class="carousel-caption">
            <h3 class="h3-responsive">{{ slides.title }}</h3>
            <p>{{slides.subtitle}}</p>
          </div>
        </mdb-carousel-item>
      </mdb-carousel>

      <!-- <button mdbBtn color="default" *ngIf="!isLogin"
        style="white-space: nowrap; padding: 1px 5px;z-index: 1; color: black;background-image: linear-gradient(#fff,#888686) !important;position: absolute;bottom: 1%;"
        class="waves-light button-bg-theme button-font desktop-hide" (click)="showStreamingMsg()" mdbWavesEffect>
        <span class="button-bg-theme" style="color: red">
          <i class="blink_me fa fa-circle blk-style" aria-hidden="true"></i>
          {{ 'comp-roundgroups.live' | translate }}</span>
      </button> -->


      <!-- <button mdbBtn color="default" *ngIf="!isLogin"
        style="white-space: nowrap; padding: 1px 5px;z-index: 1; color: black;background-image: linear-gradient(#fff,#888686) !important;position: absolute;bottom: 1%;"
        class="waves-light button-bg-theme button-font desktop-hide" (click)="showTicketList()" mdbWavesEffect>
        <span class="button-bg-theme">
          {{ 'pg-tickets.tickets-list' | translate }}</span>
      </button> -->




      <!-- <button mdbBtn color="default"
        style="white-space: nowrap; padding: 1px 5px;z-index: 1; color: black;background-image: linear-gradient(#fff,#888686) !important;position: absolute;bottom: 1%;"
        class="waves-light button-bg-theme button-font desktop-hide" (click)="openRoundMenu()" mdbWavesEffect>
        <span class="button-bg-theme" style="color: red">
          <i class="blink_me fa fa-circle blk-style" aria-hidden="true"></i>
          {{ 'comp-roundgroups.live' | translate }}

        </span>
    </button>
   <div style="width:25%;padding-left: 3px; padding-top: 29px; position: absolute;" class="mobile-menu">
        <div [class.example-is-mobile]="mobileQuery.matches">
            <mat-sidenav-container #roundmenu>
                <mat-sidenav
                    style="width: 85%;height: fit-content;background: #676767;left:0px;box-shadow: 0px 0px 10px 10px rgb(0 0 0 / 66%);"
                    #snav1 [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
                    fixedTopGap="0">

                    <button mat-icon-button type="button" *ngIf="showCloseRoundBtn" class="desktop-hide"
                        style="font-size:20px;position: absolute;right: 0%;z-index: 99;" (click)="closeRoundMenu()">X
                    </button>
                    <mat-nav-list style="padding: 12px 30px;">
                        <app-mobile-roundgroups></app-mobile-roundgroups>
                    </mat-nav-list>
                </mat-sidenav>
            </mat-sidenav-container>
        </div>
    </div>  -->

    </div>




    <!-- Slide Carousel NEW-->

  </div>
  <!-- Loading (desapears when is loaded) -->
  <div *ngIf="!isLoaded" id="spinner-loading" class="container my-body justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <!-- Code added for bet grid by rehmat  -->
  <!-- <app-bet-grid id="BetGrid"></app-bet-grid> -->
  <app-newbetgrid id="BetGrid"></app-newbetgrid>

  <!--Modal Create Account -->
  <div mdbModal #frame3="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog" role="document">
      <div class="modal-content bg-theme">
        <div class="modal-header text-center">
          <h4 class="modal-title w-100 font-weight-bold" style="color:  rgb(71, 67, 67) !important;">{{
            'pg-app-component.register'
            | translate }}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame3.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <!-- Loading (appears when is loading register) -->
        <div class="modal-body mx-3" *ngIf="registerLoading" id="spinner-loading">{{ 'pg-app-component.registering' |
          translate }}...
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div class="modal-body mx-3" *ngIf="!registerLoading">
          <div class="md-form mb-4">
            <i class="fas fa-user prefix grey-text"></i>
            <input type="text" id="orangeForm-login-home" name="orangeForm-login" [formControl]="userFormLogin"
              class="form-control code-field" mdbInput mdbValidate>
            <label for="orangeForm-login">{{ 'pg-app-component.username' | translate }} *</label>
          </div>
          <div class="md-form mb-4 " hidden>
            <i class="fas fa-user prefix grey-text"></i>
            <input type="text" id="orangeForm-name-home" name="orangeForm-name" class="form-control code-field"
              [formControl]="userFormName" mdbInput mdbValidate>
            <label data-error="wrong" data-success="right" for="orangeForm-name-home">{{ 'pg-app-component.name' |
              translate }}
              *</label>
          </div>
          <div class="md-form mb-4 " hidden>
            <i class="fas fa-user prefix grey-text"></i>
            <input type="text" id="orangeForm-lastname-home" name="orangeForm-lastname"
              class="form-control validate code-field" [formControl]="userFormLastName" mdbInput mdbValidate>
            <label data-error="wrong" data-success="right" for="orangeForm-lastname-home">{{
              'pg-app-component.last-name'
              | translate }}</label>
          </div>
          <div class="md-form mb-4">
            <i class="fas fa-envelope prefix grey-text"></i>
            <input type="email" id="orangeForm-email-home" name="orangeForm-email" class="form-control code-field"
              [formControl]="userFormEmail" mdbInput mdbValidate>
            <label data-error="wrong" data-success="right" for="orangeForm-email-home">E-mail *</label>
          </div>
          <div class="md-form mb-4">
            <i class="fas fa-envelope prefix grey-text"></i>
            <input type="email" id="orangeForm-email2-home" name="orangeForm-email2" class="form-control code-field"
              [formControl]="userFormConfirmEmail" mdbInput mdbValidate>
            <label data-error="wrong" data-success="right" for="orangeForm-email2-home">{{ 'pg-app-component.confirm' |
              translate }} e-mail *</label>
          </div>
          <div class="md-form mb-4">
            <mdb-icon fas icon="ticket-alt" class="prefix grey-text"></mdb-icon>
            <input type="email" id="jurisdiction-coupon-home" name="jurisdiction-coupon" class="form-control code-field"
              [formControl]="userJurisdictionCoupon" mdbInput mdbValidate>
            <label data-error="wrong" data-success="right" for="orangeForm-email2-home">{{
              'pg-app-component.register-coupon' | translate }}</label>
          </div>
          <div class="md-form mb-4 " hidden>
            <i class="fas fa-location-arrow prefix grey-text"></i>
            <input type="text" id="orangeForm-country-home" name="orangeForm-country" class="form-control code-field"
              [formControl]="userFormCountry" mdbInput mdbValidate value="Brasil">
            <label data-error="wrong" data-success="right" for="orangeForm-country-home">{{ 'pg-app-component.country' |
              translate }}</label>
          </div>
          <div class="md-form mb-4 " hidden>
            <i class="fas fa-location-arrow prefix grey-text"></i>
            <input type="text" id="orangeForm-city-home" name="orangeForm-city" class="form-control code-field"
              [formControl]="userFormCity" mdbInput mdbValidate>
            <label data-error="wrong" data-success="right" for="orangeForm-city-home">{{ 'pg-app-component.city' |
              translate }}</label>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button mdbBtn class="waves-light button-bg-theme button-font" mdbWavesEffect (click)="register()">{{
            'pg-app-component.register' | translate }}</button>
          <!--<button mdbBtn color="default" class="waves-light" (click)="frame3.hide()" mdbWavesEffect>Cancelar</button>-->
        </div>
      </div>
    </div>
  </div>
