<a (click)="loadRecoveredTable(); frame.show(); handleButtonClick($event)">
    INFO
    <i class="fas fa-info-circle"></i>
</a>

<!--Modal Recovered Details-->
<div style="cursor: default !important;" mdbModal #frame="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-warning modal-dialog-scrollable" role="document">
        <!--Content-->
        <div class="modal-content bg-theme">
            <!--Header-->
            <div class="modal-header">
                <!--<p class="heading lead">{{ 'recovered-jackpot.championship' | translate }}: {{bolaoName}}</p>-->
                <p class="heading lead text-left">{{ 'recovered-jackpot.jackpot-report' | translate }}</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <p style="color: yellow; text-align: left;">
                    {{ "recovered-jackpot.description" | translate }}
                </p>
                <div class="row text-center">
                    <div class="col-2 table-header d-flex align-items-center justify-content-center">{{
                        'pg-post-result.date' | translate }}</div>
                    <div class="col-2 table-header d-flex align-items-center justify-content-center">{{
                        'pg-tickets.round' | translate }}</div>
                    <div class="col-3 table-header d-flex align-items-center justify-content-center">{{
                        'recovered-jackpot.jackpot' | translate }}</div>
                    <div class="col-2 table-header d-flex align-items-center justify-content-center">{{
                        'recovered-jackpot.recovered-jackpot' | translate }}</div>
                    <div class="col-3 table-header d-flex align-items-center justify-content-center">{{
                        'recovered-jackpot.to-be-recovered' | translate }}</div>
                </div>
                <div class="row text-center" *ngFor="let element of roundsChampionship; let i = index">
                    <div class="col-2 table-cell d-flex align-items-center justify-content-center">
                        {{element.startDateTime | date: 'dd/MM'}}</div>
                    <div class="col-2 table-cell d-flex align-items-center justify-content-center">{{element.number}}
                    </div>
                    <div class="col-3 table-cell d-flex align-items-center justify-content-center">{{
                        Currency.currencySymbol }} {{element.firstJackpot * Currency.quote | currency:'':''}}</div>
                    <!--<div class="col-2 table-cell d-flex align-items-center justify-content-center" [class.red-color]="setRedColor(element.recoveredJackpot, element.firstJackpot)">{{ Currency.currencySymbol }} {{(element.firstJackpot + element.recoveredJackpot) * Currency.quote | currency:'':''}}</div>-->
                    <div class="col-2 table-cell d-flex align-items-center justify-content-center">{{
                        Currency.currencySymbol }} {{(element.firstJackpot + element.recoveredJackpot) * Currency.quote
                        | currency:'':''}}</div>
                    <div class="col-3 table-cell d-flex align-items-center justify-content-center"
                        [class.red-color]="setRedColor(element.firstJackpot, element.recoveredJackpot)">{{
                        Currency.currencySymbol }} {{element.recoveredJackpot * Currency.quote | currency:'':''}}</div>
                </div>

            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">

                <a type="button" mdbBtn style="background-color: #ff8e38;" class="btn" mdbWavesEffect
                    (click)="frame.hide()" data-dismiss="modal">OK</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal Recoreved Details-->
