<div class="container mt-3 p-0 side-menu-tree">

  <div class="row side-menu-tree-title">{{ 'comp-roundgroups.live' | translate }}</div>

  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="row side-menu-tree">

    <mat-nested-tree-node *matTreeNodeDef="let node; when: isGroup">
      <li>
        <div class="mat-tree-node side-menu-tree-node">
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
            <img src="../../../assets/image/flags/{{flagName(node.name)}}.png" />
            <!--{{node.name}}-->
            {{customTranslate(node.name)}}
          </button>
        </div>
        <ul [class.side-menu-tree-invisible]="treeControl.isExpanded(node)">
          <!-- <ul> -->
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: !isGroup" matTreeNodeToggle>
      <li class="mat-tree-node side-menu-tree-leaf force-black">
        <button mat-icon-button>
          <!--<button mat-icon-button (click)='loadRound(node.roundId)'>-->
          <span class="text-black">{{node.name}}</span>
        </button>
      </li>
    </mat-tree-node>

  </mat-tree>
</div>