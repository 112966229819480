import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../app.component';
import { InteractionService } from '../interaction.service';

@Component({
  selector: 'app-global-terms',
  templateUrl: './global-terms.component.html',
  styleUrls: ['./global-terms.component.scss']
})
export class GlobalTermsComponent implements OnInit {
  activeLanguage: string = '';

  constructor(private appComponent: AppComponent, private translate: TranslateService, private interactionService: InteractionService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {
  }

}
