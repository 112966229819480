import { Component, OnInit } from '@angular/core';

import { ConfigService } from '../config.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from '../services/modals/modals.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  userAdmin: any;

  contactForm = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    whatsapp: [''],
    subject: ['', Validators.required],
    device: ['', Validators.required],
    message: ['', [Validators.required, Validators.minLength(20)]]
  });

  constructor(private configService: ConfigService, private fb: FormBuilder,
    private appComponent: AppComponent, private translate: TranslateService,
    private modalsService: ModalsService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {

  }

  get name() { return this.contactForm.get('name'); }
  get email() { return this.contactForm.get('email'); }
  get whatsapp() { return this.contactForm.get('whatsapp'); }
  get subject() { return this.contactForm.get('subject'); }
  get device() { return this.contactForm.get('device'); }
  get message() { return this.contactForm.get('message'); }

  sendMessage() {
    let contact = {
      name: this.name.value,
      email: this.email.value,
      whatsapp: this.whatsapp.value,
      subject: this.subject.value,
      device: this.device.value,
      message: this.message.value,
    }

    this.configService.sendContactMessage(contact)
      .subscribe(data => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-contact.message-sent"), this.translate.instant("pg-contact.message-sent-info"), false);
        this.contactForm.reset();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-contact.message-not-sent"), this.translate.instant("pg-contact.message-not-sent-info"), true);
        console.log(error);
      });
  }

}
