import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { AppComponent } from '../app.component';
import { ConfigService } from '../config.service';
//import { Team } from '../team.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ElementRef } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import axios from "axios";
import { Banner } from '../banner.interface';
import { Currency } from '../currency.interface';
import { Game } from '../game.interface';
import { InteractionService } from '../interaction.service';
import { Prize } from '../prize.interface';
import { ResponseGame } from '../response-game.class';
import { Round } from '../round.interface';
import { ModalsService } from '../services/modals/modals.service';
import { Slides } from '../slide.interface';
import { Ticket } from '../ticket.interface';
import { User } from '../user.interface';
import { UserCookieService } from '../services/User/UserCookie.service';

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export interface PeriodicElement {
  id: string;
  datetime: string;
  sport: string;
  bolao: string;
  value: string;
  prize: string;
  jackpot: string;
}

var dataSource: PeriodicElement[] = [
  { id: "fe16", datetime: 'Hydrogen', sport: "fdjsa", bolao: 'H', value: 'R$ 20.0', prize: 'R$ 2055.0', jackpot: 'R$ 520' },
  { id: "ihui", datetime: 'Helium', sport: "vjodsa", bolao: 'He', value: 'R$ 456.0', prize: 'R$ 2055.0', jackpot: 'R$ 620' },
  { id: "fde5", datetime: 'Lithium', sport: "joieowjvsd", bolao: 'Li', value: 'R$ 3.0', prize: 'R$ 254', jackpot: 'R$ 280' },
  { id: "iji9", datetime: 'Beryllium', sport: "idosaf", bolao: 'Be', value: 'R$ 432.0', prize: 'R$ 354320', jackpot: 'R$ 820' },
  { id: "ev45", datetime: 'Boron', sport: "jioewf", bolao: 'B', value: 'R$ 156.0', prize: 'R$ 28870', jackpot: 'R$ 8620' },
  { id: "op90", datetime: 'Carbon', sport: "viojw", bolao: 'C', value: 'R$ 485.0', prize: 'R$ 42380', jackpot: 'R$ 280' },
  { id: "vdsa", datetime: 'Nitrogen', sport: "movw", bolao: 'N', value: 'R$ 156.0', prize: 'R$ 7820', jackpot: 'R$ 68720' },
  { id: "fef5", datetime: 'Oxygen', sport: "wefmo", bolao: 'O', value: 'R$ 1231.0', prize: 'R$ 5720', jackpot: 'R$ 6720' },
  { id: "fdjs", datetime: 'Fluorine', sport: "vioj", bolao: 'F', value: 'R$ 5461.0', prize: 'R$ 83720', jackpot: 'R$ 6720' },
  { id: "fewf5", datetime: 'Neon', sport: "jivwe", bolao: 'Ne', value: 'R$ 432.0', prize: 'R$ 53520', jackpot: 'R$ 26870' },
  { id: "jfie", datetime: 'Neon', sport: "iwvwe", bolao: 'Ne', value: 'R$ 79.0', prize: 'R$ 8680', jackpot: 'R$ 88720' },
  { id: "jie8", datetime: 'Neon', sport: "niuwev", bolao: 'Ne', value: 'R$ 20.0', prize: 'R$ 87620', jackpot: 'R$ 20' },
];


@Component({
  selector: 'app-mobile-home',
  templateUrl: './mobile-home.component.html',
  styleUrls: ['./mobile-home.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class MobileHomeComponent implements OnInit {
  @ViewChild('frame2', { static: true }) modalCreate: ModalDirective;
  @ViewChild('frame3', { static: true }) modalFinishBet: ModalDirective;
  @ViewChild('frame4', { static: true }) modalPrint: ModalDirective;
  @ViewChild('frame5', { static: true }) modalWhatsapp: ModalDirective;
  betForm: FormGroup;
  isLogged: boolean = false;


  slidesPathPrefix = "../../assets/banners/";
  slidesList: Slides[] = [];
  // datatable
  dataSource: PeriodicElement[];
  columnsToDisplay = ['id', 'datetime', 'sport', 'bolao', 'value', 'prize', 'jackpot'];
  expandedElement: PeriodicElement | null;

  htmlToAdd: any;
  whatsappHead: string;
  whatsappBody: string;
  round: Round;
  rounds: Round[];
  games: Game[];
  allGames: Game[];

  bannersList: Banner[] = [];
  user: User = <User>{};
  isLoaded: boolean = false;
  isChecked: boolean = false;
  idRound: number;
  dateBegin: string;
  dateEnd: string;
  responseGames = new Array<ResponseGame>();
  periodsDate: string;
  prizes: Prize[] = [];
  jackpot: number;
  //prizeAmount: string;
  prizeAmount: number;
  fullPrize: number;
  roundValue: number;
  todaydate;
  componentproperty;
  newcomponentproperty;
  newcomponent = "Entered in newcomponent";
  ticket = {
    id: 0, date: new Date(Date.now()), hour: new Date(Date.now()), userName: "", playerName: "", status: "inactivated", roundId: 0, roundNum: 0,
    results: ["", "", "", "", "", "", "", "", "", ""], resultToPass: "", userId: 1, value: 0

  };
  currentTicket = {} as Ticket;
  Currency: Currency = <Currency>{};

  message: string;
  hubId: number;
  constructor(private interactionService: InteractionService, private configService: ConfigService,
    private appComponent: AppComponent, private router: Router,
    private translate: TranslateService, private elRef: ElementRef,
    private modalsService: ModalsService, private userCookieService: UserCookieService) {
      this.interactionService.isLogged.subscribe(logged => {
        this.isLogged = logged;
      })

    this.translate.setDefaultLang(this.appComponent.activeLang);
    this.hubId = this.configService.creatorId;

    this.message = this.translate.instant("pg-home.required-results");
    this.user = this.appComponent.userAdmin;
  }

  ngOnInit() {
    this.configService.getRounds()
      .subscribe(data => {
        this.rounds = data;
        this.getAllGames();
        this.getRoundPrize();
      }, error => {
        console.log(error);
      });

    this.getMasterParent();
    this.interactionService.homeVisibleRound.subscribe(round => {
      if (round != null) { this.setRound(round); }
    });
    // this.getLastActivatedRound();

    this.betForm = new FormGroup({
      user: new FormControl('', { validators: [Validators.required] })
    });

    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });

    this.interactionService.ticketPanelState
      .subscribe(data => {
        if (data != null) {
          this.currentTicket = data;
        }
      });
  }

  getRoundId(length) {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id': return compare(a.id, b.id, isAsc);
        case 'datetime': return compare(a.datetime, b.datetime, isAsc);
        case 'sport': return compare(a.sport, b.sport, isAsc);
        case 'bolao': return compare(a.bolao, b.bolao, isAsc);
        case 'value': return compare(a.value, b.value, isAsc);
        case 'prize': return compare(a.prize, b.prize, isAsc);
        case 'jackpot': return compare(a.jackpot, b.jackpot, isAsc);

        default: return 0;
      }
    });
  }

  getPrize() {
    this.configService.getPrize()
      .subscribe((data: Prize[]) => {
        this.prizes = data;
        this.setPrizes();
      }, error => {
        console.log(error);
      });
  }

  setPrizes() {
    this.jackpot = (this.prizes.find(x => x.roundId == this.round.id && x.type == 2)!.gathered);
    //this.prizeAmount = (this.prizes.find(x => x.roundId == this.round.id && x.type == 1).gathered).toString()
    this.prizeAmount = (this.prizes.find(x => x.roundId == this.round.id && x.type == 1)!.gathered);

    this.fullPrize = (+(this.jackpot) + +(this.prizeAmount));

    this.roundValue = this.round.value;
  }

  getRoundPrize() {
    this.configService.getPrize()
      .subscribe((data: Prize[]) => {
        this.prizes = data;

        let stringJson2 = JSON.stringify(this.rounds);
        let rounds = JSON.parse(stringJson2);
        var infos: any[] = [];
        for (let index = 0; index < rounds.length; index++) {
          var random_roundid = this.getRoundId(4);
          var date = new Date(rounds[index].startDateTime);
          // console.log(rounds[index])
          // rounds[index].startDateTime = date.toDateString();
          var round_infos = this.setRoundPrizes(rounds[index]);
          infos.push(
            {
              id: random_roundid,
              datetime: rounds[index].startDateTime.toString(),
              //sport: rounds[index].sportsId,
              sport: 'Football',
              bolao: rounds[index].name,
              value: this.configService.formatCurrencyValue(rounds[index].value),
              prize: this.configService.formatCurrencyValue(round_infos[1]),
              jackpot: this.configService.formatCurrencyValue(round_infos[0]),
            }
          );
        }
        this.dataSource = infos;
      }, error => {
        console.log(error);
      });
  }

  setRoundPrizes(round) {
    var jackpot = (this.prizes.find(x => x.roundId == round.id && x.type == 2)!.gathered);

    var prizeAmount = (this.prizes.find(x => x.roundId == round.id && x.type == 1)!.gathered);

    var fullPrize = (+(jackpot) + +(prizeAmount));

    var roundValue = round.value;

    return [jackpot, prizeAmount, fullPrize, roundValue];
  }

  // getLastActivatedRound() {
  //this.configService.getLastActivatedRound()
  // this.configService.getNextActivatedRound()
  //   .subscribe(data => {
  //     if (data != null) {
  //       this.getPrize();
  //       this.interactionService.setHomeVisibleRound(data);
  //       this.setRound(data);
  //     }
  //     else {
  //       let spinner = document.getElementById("spinner-loading");
  //       if (spinner != null) { spinner.classList.add("hidden"); }
  //       this.isLoaded = true;
  //     }

  //   }, error => {
  //     console.log(error);
  //   });
  // }

  setRound(round: Round) {
    this.round = round;
    this.getGames();
    //this.getOwnBanners();
    //this.getMasterParent();
    //this.getPrize();
  }

  getMasterParent() {
    let userId = 1;
    if (!this.appComponent.userAdmin) {
      userId = 1;
    }
    else {
      userId = this.appComponent.userAdmin.id;
    }

    /* this.configService.getMasterParent(userId)
      .subscribe(data => {
        this.getOwnSlides(data.id);
      }, error => {
        this.appComponent.msgStandard(this.translate.instant("pg-banners.operation-fail"), this.translate.instant("pg-banners.load-fail-info"), 4);
        console.log(error);
      }); */

    let curUserId = this.userCookieService.getUserId();
    if (curUserId <= 0) {
      curUserId = 1;
    }
    this.getOwnSlides(this.hubId);
  }

  getOwnBanners(masterParentId: number) {
    this.configService.getOwnBanners(masterParentId)
      .subscribe(data => {
        this.bannersList = data;
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-banners.operation-fail"), this.translate.instant("pg-banners.load-fail-info"), true);
        console.log(error);
      });
  }

  getOwnSlides(masterParentId: number) {
    this.configService.getOwnSlides(this.hubId, 1, "top")
      .subscribe(data => {
        this.slidesList = data;
        /*  console.log(masterParentId);
        console.log(data); */
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant('pg-banners.operation-fail'), this.translate.instant('pg-banners.load-fail-info'), true);
        console.log(error);
      });
  }

  getAllGames() {
    this.configService.getAllGames()
      .subscribe(data => {
        this.allGames = data;
        this.initRound();
        let spinner = document.getElementById("spinner-loading");
        if (spinner != null) { spinner.classList.add("hidden"); }
        this.isLoaded = true;
      }, error => {
        console.log(error);
      });
  }

  getGames() {
    this.configService.getGames(this.round.id)
      .subscribe(data => {
        this.games = data;
        this.initRound();
        let spinner = document.getElementById("spinner-loading");
        if (spinner != null) { spinner.classList.add("hidden"); }
        this.isLoaded = true;
      }, error => {
        console.log(error);
      });
  }

  getUser() {
    this.configService.getUser(this.appComponent.userAdmin.id)
      .subscribe(data => {
        this.user = data;
      }, error => {
        console.log(error);
      });
  }

  initRound() {

    this.idRound = this.round.number;
    this.dateBegin = this.round.startDateTime.toString();
    this.dateEnd = this.round.endDateTime.toString();
    //console.log(this.round.startDateTime);
    //console.log(this.round.id);
    //console.log(this.dateBegin) return date start;
    //console.log(this.round.name);
    //console.log(this.dateEnd) return date end;
    /* reportForm = this.fb.group({
    jurisdictionId: [0],
    dateStart: [''],
    dateEnd: [''],
    roundId: [0]
  });
  // function exported above
  nothing that serves
  */
    this.generateGames();
  }

  generateGames() {

    this.responseGames = new Array<ResponseGame>();

    for (let i = 0; i < this.games.length; i++) {
      this.responseGames[i] = new ResponseGame();
      this.responseGames[i].idGame = this.games[i].id;
      this.responseGames[i].teamHome = this.games[i].homeName;
      this.responseGames[i].teamVisit = this.games[i].awayName;
      this.responseGames[i].homeTeamScore = this.games[i].homeTeamScore;
      this.responseGames[i].awayTeamScore = this.games[i].awayTeamScore;
      this.responseGames[i].two_options = this.games[i].two_options;
      this.responseGames[i].dateBegin = this.games[i].dateTime;
      this.responseGames[i].hourBegin = this.games[i].dateTime;
      this.responseGames[i].dateEnd = this.games[i].dateTime;
      this.responseGames[i].hourEnd = this.games[i].dateTime;
    }
  }

  // --- TICKET CREATION --- //
  chooseGameResult(resultIndex: number, resultChoosen: string) {
    // register inside the object
    if (this.ticket.results[resultIndex] == resultChoosen) {
      this.ticket.results[resultIndex] = "";
    }
    else {
      // when is the same, uncheck that result
      this.ticket.results[resultIndex] = resultChoosen;
    }

    // update format of every games

    //this.makeFormatting();

  }

  /* makeFormatting() {
     // when any result is selected, runs for all result options updanting their colors
     for (let i = 0; i < this.games.length; i++) {
       switch (this.ticket.results[i]) {
         case '1':
           document.getElementById(i + 'a').classList.add("bg-yellow");
           document.getElementById(i + 'b').classList.remove("bg-yellow");
           document.getElementById(i + 'c').classList.remove("bg-yellow");
           break;
         case 'X':
           document.getElementById(i + 'a').classList.remove("bg-yellow");
           document.getElementById(i + 'b').classList.add("bg-yellow");
           document.getElementById(i + 'c').classList.remove("bg-yellow");
           break;
         case '2':
           document.getElementById(i + 'a').classList.remove("bg-yellow");
           document.getElementById(i + 'b').classList.remove("bg-yellow");
           document.getElementById(i + 'c').classList.add("bg-yellow");
           break;
         default:
           document.getElementById(i + 'a').classList.remove("bg-yellow");
           document.getElementById(i + 'b').classList.remove("bg-yellow");
           document.getElementById(i + 'c').classList.remove("bg-yellow");
           break;
       }
     }
   }
   */

  // bg-yellow uncheck and check



  // mark and unmark bet
  onButtonGroupClick($event) {

    // rounds



    let clickedElement = $event.target || $event.srcElement;
    if (clickedElement.nodeName === "BUTTON") {
      let isCertainButtonAlreadyActive = clickedElement.parentElement.querySelector(".bg-yellow");

      //unmark
      function rmvy() {

        let ncelt = document.querySelectorAll('#cupomhtml button.bg-yellow');
        for (var i = 0; i < ncelt.length; i++) {
          ncelt[i].addEventListener('click', function (event) {
            document.getElementById(this.id)!.classList.remove("bg-yellow");
            //console.log(this.id);
            var idt = this.id;
            function rmvX() {
              if (document.querySelector('#trc' + idt.substring(0, 1))) {
                var mtk = document.querySelectorAll('.trcclass' + idt.substring(0, 1) + ' .bg-yellow').length;
                if (mtk == 1) {
                  document.querySelector('#trc' + idt.substring(0, 1))!.remove();
                }
                else {
                  document.querySelector(".trcclass" + idt.substring(0, 1) + " button[id='" + idt + "']")!.remove();
                }
                if (document.querySelectorAll(".bg-yellow").length == 0) {
                  document.getElementById("cupomhtml")!.innerHTML = " <div id='txtcupomhtml'>" + this.translate.instant("pg-home.bet-selected") + "</div> ";
                }
              }
            }
            rmvX();
          });
        }
      }

      if (isCertainButtonAlreadyActive) {
        isCertainButtonAlreadyActive.classList.remove("bg-yellow");
        function rmvCp() {

          if (document.querySelector('#trc' + clickedElement.id.substring(0, 1))) {
            var mtk = document.querySelectorAll('.trcclass' + clickedElement.id.substring(0, 1) + ' .bg-yellow').length;
            //console.log(mtk);
            if (mtk == 1) {
              document.querySelector('#trc' + clickedElement.id.substring(0, 1))!.remove();
            } else {
              document.querySelector(".trcclass" + clickedElement.id.substring(0, 1) + " button[id='" + clickedElement.id + "']")!.remove();
            }
            //console.log("TRC" + clickedElement.id.substring(0, 1) + "" + x);
            //console.log( ".trcclass" + clickedElement.id.substring(0, 1) + " button[id='" + clickedElement.id + "']");
            if (document.querySelectorAll(".bg-yellow").length == 0) {
              document.getElementById("cupomhtml")!.innerHTML = " <div id='txtcupomhtml'>" + this.translate.instant("pg-home.bet-selected") + "</div> ";
            }
          }
        }
        rmvCp();

        rmvy();
        //correcting single and multiple errors


        let mselect = document.querySelectorAll('table tr').length - 2;
        let contmselect = 0;
        for (var i = 0; i < mselect; i++) {
          if (document.getElementById(i + "a")!.classList.contains("bg-yellow") == true) {
            contmselect++;
          }
          if (document.getElementById(i + "b")!.classList.contains("bg-yellow") == true) {
            contmselect++;
          }
          if (document.getElementById(i + "c")!.classList.contains("bg-yellow") == true) {
            contmselect++;
          }
          //console.log("NA LINHA" + i + " \n tem: " + contmselect);
          if (contmselect == 0 || contmselect == 1) {
            //console.log(contmselect);
            document.getElementById("cfm")!.innerText = "Única";

          } else {
            //console.log(contmselect);
            document.getElementById("cfm")!.innerText = "Múltiplas";
            break;
          }



          contmselect = 0;

        }



      } else {

        //mark
        function addCp() {


          let dcp = document.getElementById(clickedElement.id)!;
          let dcp2 = clickedElement.id.substring(0, 1);
          let trcupom = dcp.closest('tr')!.innerHTML;
          if (document.querySelectorAll("#txtcupomhtml").length > 0) {
            var txtcupomrmv = document.getElementById("txtcupomhtml");
            txtcupomrmv!.remove()
          }
          document.getElementById("cupomhtml")!.innerHTML += "<style type='text/css'>#cupomhtml .waves-ripple {display: none !important;} #cupomfooter, #cupomfooter  h5 { display: block  !important} #cupomhtml { font-size: 11px;color: white; padding: 10px; overflow: hidden; }#cupomhtml div button.bg-yellow {  pointer-events: none;background: #B0B0B0 !important;position: relative; color: black; font-size: 11px !important;font-weight: bold !important; display: block !important; width: 108% !important; margin-left: -7%; font-size: 13px; line-height: 1; padding-right: 14%; margin-top: 3%; position: relative; } #cupomhtml button.bg-yellow:before { pointer-events: all;background: url(https://cdn.solutionwebltd.net/alphacode/skin/jogobrasil/assets/images/couponIcons.png) no-repeat 0 0; content: '1x2' !important;left: -35% !important; font-size: 11px !important; position: relative !important;padding-left: 2em; } #cupomhtml div button { background: transparent !important; border: none !important; display: none !important; }@media only screen and (max-width: 990px) { .trcclass { padding-left: 3em !important; } }.trcclass {font-weight: bold;background-color: rgb(189, 126, 126); width: 112%; left: -6%; position: relative; height: auto; color: #000; background: #E0E0E0; line-height: 3;padding-left: 1em}</style>";
          if (document.querySelectorAll("div#trc" + dcp2).length >= 1) {
            document.querySelector("div#trc" + dcp2)!.innerHTML = trcupom;

          } else {
            document.getElementById("cupomhtml")!.innerHTML += "<div class='trcclass'>" + "<div class='trcclass" + dcp2 + "' id='trc" + dcp2 + "'>" + trcupom + "</div></div></div>";
          }

          rmvy();
        }
        clickedElement.className += " bg-yellow";
        addCp();

        //correcting single and multiple errors
        let mselect = document.querySelectorAll('table tr').length - 2;
        let contmselect = 0;
        for (var i = 0; i < mselect; i++) {
          if (document.getElementById(i + "a")!.classList.contains("bg-yellow") == true) {
            contmselect++;
          }
          if (document.getElementById(i + "b")!.classList.contains("bg-yellow") == true) {
            contmselect++;
          }
          if (document.getElementById(i + "c")!.classList.contains("bg-yellow") == true) {
            contmselect++;
          }
          // console.log("NA LINHA" + i + " \n tem: " + contmselect);
          if (contmselect == 0 || contmselect == 1) {
            //console.log(contmselect);
            document.getElementById("cfm")!.innerText = "Única";

          } else {
            // console.log(contmselect);
            document.getElementById("cfm")!.innerText = "Múltiplas";
            break;
          }



          contmselect = 0;

        }


      }
    }
  }



  onOpen(form: NgForm) {
    this.isChecked = false;
    this.modalCreate.show();

    if (this.isLogged) {
      this.user = this.appComponent.userAdmin;
      var finaluserValue1;
      if (this.user.jurisdictionId < 7) {
        finaluserValue1 = (<HTMLInputElement>document.getElementById("finaluser")).value;

      } else {
        finaluserValue1 = this.user.name;

      }
      if (this.checkTicket(finaluserValue1)) {
        let msgResult = "";
        let finaluserValue = finaluserValue1;
        this.whatsappBody = "";
        this.ticket.resultToPass = "";
        let result = "";
        //let conttr = document.querySelectorAll('tr[class="ng-star-inserted"]').length;
        let conttr = document.querySelectorAll('.game-line').length;
        for (let i = 0; i < conttr; i++) {

          //console.log(result[i]);
          // games scored
          let contigsbets3 = "";
          // checking if they are marked
          let at = document.getElementById('' + i + 'a')!.getAttribute("class")!;
          let at2 = document.getElementById('' + i + 'b')!.getAttribute("class")!;
          let at3 = document.getElementById('' + i + 'c')!.getAttribute("class")!;
          // catching text
          let a = document.getElementById('' + i + 'a')!.innerText;
          let b = document.getElementById('' + i + 'b')!.innerText;
          let c = document.getElementById('' + i + 'c')!.innerText;

          // result 2
          let countingbettingresult = "";
          let result2;
          // adding
          if (at.indexOf("bg-yellow") != -1) {
            contigsbets3 += a;
            // change
            countingbettingresult += a;
          }
          if (at2.indexOf("bg-yellow") != -1) {
            if (contigsbets3.indexOf("1") != -1) {
              contigsbets3 += "-";
            }
            contigsbets3 += b;
            // change
            countingbettingresult += b;
          }
          if (at3.indexOf("bg-yellow") != -1) {
            if (contigsbets3.indexOf("X") != -1) {
              contigsbets3 += "-";
            }
            //change
            if (contigsbets3.indexOf("1") != -1) {
              contigsbets3 += "-";
            }

            contigsbets3 += c;
            // change
            countingbettingresult += c;

          }
          result = contigsbets3;
          result2 = countingbettingresult;
          this.ticket.resultToPass += result2;
          // change (addition of if / else, kept the original line this.ticket.resultTopass + "|")
          if (i == conttr - 1) { } else {
            this.ticket.resultToPass += "|";
          }
          // remove |

          msgResult += '<div class="row"><div class="col-9">' +
            this.responseGames[i].teamHome + ' - ' + this.responseGames[i].teamVisit +
            ' : </div><div class="col-3">' + result + '</div></div>';
          this.whatsappBody += this.responseGames[i].teamHome + ' - ' + this.responseGames[i].teamVisit + result + "%0A";
          let showDateHour = new Date(this.ticket.date);

          this.htmlToAdd =
            //'id bilhete: '+ this.ticket.id + '
            this.translate.instant("pg-home.round") + ': ' + this.ticket.roundNum + '<p/>' +
            ' ' + this.translate.instant("pg-home.creation") + ': ' + this.checkZero(showDateHour.getDate() + "") + '/' + this.checkZero((showDateHour.getMonth() + 1) + "") + ' - ' + this.checkZero(showDateHour.getHours() + "") + ':' + this.checkZero(showDateHour.getMinutes() + "") + ' - ' + this.translate.instant("pg-home.value") + ': ' + this.configService.formatCurrencyValue(this.roundValue) + '<br/>' +
            this.translate.instant("pg-home.operator") + ': ' + this.appComponent.userAdmin.login + ' - ' + this.translate.instant("pg-home.player") + ': ' + (this.ticket.playerName == "" ? this.translate.instant("pg-home.anonymous") : this.ticket.playerName) + '<br/><br/>' +
            msgResult;
          this.whatsappHead =
            this.translate.instant("pg-home.round-value") + ":" + this.configService.formatCurrencyValue(this.round.value) + " - " +
            this.translate.instant("pg-home.creation") + ': ' + this.checkZero(showDateHour.getDate() + "") + '/' + this.checkZero((showDateHour.getMonth() + 1) + "") + ' - ' + this.checkZero(showDateHour.getHours() + "") + ':' + this.checkZero(showDateHour.getMinutes() + "") + '%0A' +
            //this.translate.instant("pg-home.operator") + ': ' + this.appComponent.userAdmin.login + ' - ' + this.translate.instant("pg-home.player") + ': ' + this.ticket.playerName + " - " + this.translate.instant("pg-home.value") + ": " + this.roundValue + '%0A%0A';
            this.translate.instant("pg-home.operator") + ': ' + this.appComponent.userAdmin.login + ' - ' + this.translate.instant("pg-home.player") + ': ' + finaluserValue + " - " + this.translate.instant("pg-home.value") + ": " + this.configService.formatCurrencyValue(this.roundValue) + '%0A%0A';

          result = "";
          this.ticket.resultToPass.slice(0, -1);
        }
      }
      else {
        this.htmlToAdd = this.translate.instant("pg-home.required-results");
      }
    }

  }
  checkZero(data) {
    if (data.length == 1) {
      data = "0" + data;
    }
    return data;
  }

  cvaluenew() {

    //let mselect = document.querySelectorAll('tr[class="ng-star-inserted"]');
    let mselect = document.querySelectorAll('.game-line');
    let nsimpl = 0;
    let nduple = 0;
    let ntriple = 0;
    let cont = 0;
    for (var i = 0; i < mselect.length; i++) {
      if (document.getElementById(i + "a")!.classList.contains("bg-yellow") == true) {
        cont += 1;
      }
      if (document.getElementById(i + "b")!.classList.contains("bg-yellow") == true) {
        cont += 1;
      }
      if (document.getElementById(i + "c")!.classList.contains("bg-yellow") == true) {
        cont += 1;
      }
      if (cont == 3) {
        ntriple += 1;
      } else if (cont == 2) {
        nduple += 1
      } else {
        nsimpl += 1;
      }
      cont = 0;
    }
    //possibilities
    //console.log("Possibilities: " + (2 ** nduple) * (3 ** ntriple), "result value round: " + ((2 ** nduple) * (3 ** ntriple) * this.round.value) )
    this.roundValue = ((2 ** nduple) * (3 ** ntriple)) * this.round.value;

    //dynamically update value
    // this.round.value = this.roundValue;

    // Updating value for side coupon
    let valuemultiply = document.getElementById("txtAmount")!.innerText;
    let valuerounda = this.roundValue;
    if (Number(valuemultiply) == 0) {
      valuemultiply += 1;
    }
    let totalvalue = Number(valuemultiply) * valuerounda;
    document.getElementById("rstvaluem")!.innerText = totalvalue.toString();
    this.roundValue = totalvalue;
    //console.log(totalvalue);
    this.roundValue.toString();

  }


  checkTicket(playerName: string) {
    let test: boolean = true;

    // check whether all results was choosen
    for (let i = 0; i < this.games.length; i++) {
      if (this.ticket.results[i] == "") {
        test = false;
      }
    }

    // check if is there the player name; if so, fullfill the ticket object to go ahead
    //if (test && playerName != undefined) {
    if (test) {
      this.ticket.id = 10;
      this.ticket.roundId = this.round.id;
      this.ticket.roundNum = this.round.number;
      this.ticket.playerName = playerName;
      this.ticket.date = new Date(Date.now());
      this.ticket.hour = new Date(Date.now());
      this.ticket.status = "activated";
      this.ticket.userId = this.appComponent.userAdmin.id;
      //this.ticket.value = this.round.value;
      this.ticket.value = this.roundValue;


    }

    this.isChecked = test;

    return test;
  }

  postTicket() {
    let valuemultiply = document.getElementById("txtAmount")!.innerText;
    let n1 = 1;
    while (n1 <= Number(valuemultiply)) {
      //console.log("Aposta número >" + n1 + ": Sucess.");
      n1 = n1 + 1;

      this.modalCreate.hide();
      this.isLoaded = false;

      let userId = this.appComponent.userAdmin.id;

      this.configService.getUser(this.appComponent.userAdmin.id)
        .subscribe(data => {

          let newUserCredit = data.credit - this.roundValue;
          //alert(newUserCredit);
          if (newUserCredit >= 0) {

            this.configService.postBet(this.ticket)
              .subscribe(data => {
                this.appComponent.betIdPrint = data.id;
                this.appComponent.userAdmin.credit = newUserCredit;
                this.updateUserCredit(userId, newUserCredit);
              }, error => {
                this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.operation-failed-info"), true);
                console.log(error);
                this.ngOnInit();
              });
          }
          else {
            this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.no-enough-credit"), true);
            this.ngOnInit();
          }
        }, error => {
          this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.operation-failed-info"), true);
          console.log(error);
        });

    }
  }

  setBet(gameIndex, optionChoosen) {
    // creating new ticket whether is the case
    this.interactionService.ticketPanelState
      .subscribe(data => {
        if (data == null || this.currentTicket.round == null || data.round.id != this.interactionService.homeVisibleRound.value.id) {
          this.currentTicket.round = this.round;
          this.games.forEach(element => {
            element.matchResult = "";
          });
          this.currentTicket.matches = this.games;
          this.currentTicket.quantity = 1;
          this.currentTicket.finalValue = this.round.value;
          this.currentTicket.date = new Date(Date.now());
        }
      });

    // fulfill the array as needed
    if (this.currentTicket.matches[gameIndex].matchResult.includes(optionChoosen)) {
      this.currentTicket.matches[gameIndex].matchResult = this.currentTicket.matches[gameIndex].matchResult.replace(optionChoosen, '');
    }
    else {
      this.currentTicket.matches[gameIndex].matchResult += optionChoosen;
      this.interactionService.setPlayBallAnimation(true);
    }

    // formating and ordering items
    let one, x, two: boolean = false;
    if (this.currentTicket.matches[gameIndex].matchResult.includes("1")) {
      one = true;
      //document.getElementById(gameIndex + 'a').classList.add("bg-yellow");
    }
    else {
      //document.getElementById(gameIndex + 'a').classList.remove("bg-yellow");
    }
    if (this.currentTicket.matches[gameIndex].matchResult.includes("X")) {
      x = true;
      //document.getElementById(gameIndex + 'b').classList.add("bg-yellow");
    }
    else {
      //document.getElementById(gameIndex + 'b').classList.remove("bg-yellow");
    }
    if (this.currentTicket.matches[gameIndex].matchResult.includes("2")) {
      two = true;
      //document.getElementById(gameIndex + 'c').classList.add("bg-yellow");
    }
    else {
      //document.getElementById(gameIndex + 'c').classList.remove("bg-yellow");
    }
    this.currentTicket.matches[gameIndex].matchResult = "";
    if (one)
      this.currentTicket.matches[gameIndex].matchResult = "1";
    if (x)
      this.currentTicket.matches[gameIndex].matchResult += "X";
    if (two)
      this.currentTicket.matches[gameIndex].matchResult += "2";

    // check final ticket's value
    let doubleBets = 0;
    let tripleBets = 0;
    this.currentTicket.singleBet = true;
    this.currentTicket.matches.forEach(element => {
      if (element.matchResult.length == 2) {
        doubleBets += 1;
        this.currentTicket.singleBet = false;
      }
      else if (element.matchResult.length == 3) {
        tripleBets += 1;
        this.currentTicket.singleBet = false;
      }
    });
    this.currentTicket.finalValue = (2 ** doubleBets) * (3 ** tripleBets) * (this.currentTicket.round.value * this.currentTicket.quantity);
    this.currentTicket.currencySymbol = this.Currency.currencySymbol;
    this.currentTicket.currencyQuote = this.Currency.quote;


    // update the global ticket's service
    this.interactionService.setTicketPanelState(this.currentTicket);
  }

  setXY(event) {
    this.interactionService.setClickX(event.clientX);
    this.interactionService.setClickY(event.clientY);
  }

  formatingCheck(index: number, option: string) {
    if (this.currentTicket == null || this.currentTicket.matches == null || this.currentTicket.round.id != this.interactionService.homeVisibleRound.value.id) {
      //console.log("deu null");
    }
    else {
      if (this.currentTicket.matches.length >= (index + 1)) {
        if (this.currentTicket.matches[index].matchResult.includes(option)) {
          return true;
        }
      }
    }
    return false;
  }

  updateUserCredit(userId: number, newUserCredit: number) {
    let transactionType = 3;
    //this.configService.updateUserCredit(userId, this.round.value, transactionType)
    this.configService.updateUserCredit(userId, this.roundValue, transactionType)
      .subscribe(data => {
        this.updateJackpot();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.operation-failed-info"), true);
        this.ngOnInit();
      });
  }

  updateJackpot() {
    //let newValeu = this.prizes[0].gathered + 2;
    //let newValeu = this.prizes[0].gathered + (this.round.value * 0.2);
    //let newValeu = +(this.jackpot) + (this.round.value * 0.2);
    let newValeu = +(this.jackpot) + (this.roundValue * 0.2);
    let newPrizes: Prize;
    //newPrizes = this.prizes[0];
    newPrizes = this.prizes.find(x => x.roundId == this.round.id && x.type == 2)!;
    //newPrizes.gathered = +newValeu;
    newPrizes.type = 2;

    this.configService.updateJackpot(newPrizes, this.roundValue)
      .subscribe(data => {
        this.updateApportionment();
      }, error => {
        console.log(error);
        //alert("Houve algum erro de conexão! Cód: 100");
        this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.operation-failed-info"), true);
        this.ngOnInit();
      });
  }

  updateApportionment() {
    //let newValeu = this.prizes[1].gathered + 7;
    //let newValeu = this.prizes[1].gathered + (this.round.value * 0.6);
    //let newValeu = +(this.prizeAmount) + (this.round.value * 0.6);
    let newValeu = +(this.prizeAmount) + (this.roundValue * 0.6);
    let newPrizes: Prize;
    //newPrizes = this.prizes[1];
    newPrizes = this.prizes.find(x => x.roundId == this.round.id && x.type == 1)!;
    //newPrizes.gathered = +newValeu;
    newPrizes.type = 1;

    this.configService.updateApportionment(newPrizes, this.roundValue)
      .subscribe(data => {
        this.modalFinishBet.show();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.operation-failed-info"), true);
        this.ngOnInit();
      });
  }

  closeBetModal() {
    //this.modalFinishBet.hide();
    this.router.navigate(['/ticket']);
    //window.location.href = "/ticket";
  }

  printTicket() {
    //window.open('http://www.jogobrasil.com.br/print', "", "width=360,height=700");
    window.open('/print', "", "width=360,height=700");
    this.router.navigate(['/ticket']);
    //window.location.href = "/ticket";
  }

  showModalWhatsapp() {
    this.modalFinishBet.hide();
    this.modalWhatsapp.show();
  }

  closeWhatsappModal() {
    this.modalWhatsapp.hide();
    this.router.navigate(['/ticket']);
    //window.location.href = "/ticket";
  }

  whatsappTicket(cel: string) {
    cel = cel.trim();

    window.open('https://api.whatsapp.com/send?phone=' + cel + '&text=' + this.whatsappHead + this.whatsappBody);
    this.router.navigate(['/ticket']);
    //window.location.href = "/ticket";
  }
  getIPAddress() {

    axios.get('http://api.ipify.org/?format=json')
      .then((response) => {

        axios.get('https://api.ipfind.com?ip=' + response.data['ip'] + '&auth=7e2a21d0-0e27-44b6-aa06-8a0d015e266c')
          .then((response) => {

            localStorage.setItem('currentUserCountry', response.data['currencyCode']);
          }, error => {

            localStorage.setItem('currentUserCountry', 'Brazil');
          })

      }, error => {
        localStorage.setItem('currentUserCountry', 'Brazil');
      })
  }
}


