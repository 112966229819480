import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { CookieService } from '../services/cookie/cookie.service';
import { AppComponent } from '../app.component';
import { ConfigService } from '../config.service';
import { InteractionService } from '../interaction.service';
import { ISkinConfig } from '../Interfaces/SkinConfig.interface';
import { Login } from '../login.interface';
import { ModalsService } from '../services/modals/modals.service';
import { LoginHistory } from '../transaction.interface';
import { User } from '../user.interface';
import { UserCookieService } from '../services/User/UserCookie.service';

@Component({
  selector: 'app-ref-code',
  templateUrl: './ref-code.component.html',
  styleUrls: ['./ref-code.component.scss']
})

export class RefCodeComponent implements OnInit {

  @ViewChild('frame', { static: true }) modalLogin: ModalDirective;
  @ViewChild('frame2', { static: true }) modalForgot: ModalDirective;
  @ViewChild('frame3', { static: true }) modalRegister: ModalDirective;
  //@ViewChild('frame4', { static: true }) modalWarning: ModalDirective;
  @ViewChild('frame5', { static: true }) modalPassword: ModalDirective;
  code: string = '';
  skinConfig: ISkinConfig = <ISkinConfig>{};

  constructor(private route: ActivatedRoute, private configService: ConfigService,
    private interactionservice: InteractionService,
    private cookieService: CookieService, private appComponent: AppComponent,
    private translate: TranslateService, private router: Router,
    private modalsService: ModalsService, private userCookieService: UserCookieService) {
    this.translate.setDefaultLang(this.appComponent.activeLang)
    interactionservice.skinConfig
      .subscribe(data => {
        this.skinConfig = data;
      });
  }

  validatingForm: FormGroup;
  userValidatingForm: FormGroup;
  forgotValidatingForm: FormGroup;
  registerLoading: boolean = false;
  titleToAdd: string = '';
  msgToAdd: string = '';
  msgType: string[] = ['', 'modal-primary', 'modal-warning', 'modal-success', 'modal-danger'];
  btnType: string[] = ['', 'btn-primary', 'btn-warning', 'btn-success', 'btn-danger'];
  loginData: any = {};
  userAdmin: any;
  public isLogged: boolean = false;

  ngOnInit() {
    this.code = this.route.snapshot.paramMap.get("code");
    localStorage.setItem('refCode', this.code)

    /*if (this.appComponent.isLogged) {
      this.router.navigate(['/home']);
    }*/



    this.validatingForm = new FormGroup({
      loginFormModalName: new FormControl('', Validators.required),
      loginFormModalPassword: new FormControl('', Validators.required)
    });

    this.userValidatingForm = new FormGroup({
      userFormLogin: new FormControl('', Validators.required),
      userFormName: new FormControl('', Validators.required),
      userFormLastName: new FormControl(''),
      userFormEmail: new FormControl('', [Validators.required, Validators.email]),
      userFormConfirmEmail: new FormControl(''),
      userFormPassword: new FormControl('', Validators.required),
      userFormConfirmPassword: new FormControl('', Validators.required),
      userJurisdictionCoupon: new FormControl(''),
      userFormCountry: new FormControl(''),
      userFormCity: new FormControl('')
    });

    this.forgotValidatingForm = new FormGroup({
      forgotFormEmail: new FormControl('', [Validators.required, Validators.email])
    });

  }

  // register() {
  //   let newLogin = (<HTMLInputElement>document.getElementById('username')).value;
  //   let newEmail = (<HTMLInputElement>document.getElementById('email')).value;
  //   let newConfirmEmail = (<HTMLInputElement>document.getElementById('confirm-email')).value;
  //   let newJurisdictionCoupon = this.code;

  //   let newPassword = this.generateRandomPassword();

  //   if (newLogin == '' || newEmail == '' || newConfirmEmail == '') {
  //     this.appComponent.msgStandardDanger(this.translate.instant('pg-app-component.field-required'), this.translate.instant('pg-app-component.field-required-info'), 4);
  //   } else if (newLogin.includes(' ')) {
  //     this.appComponent.msgStandardDanger(this.translate.instant('pg-app-component.field-required'), this.translate.instant('pg-app-component.invalid-character-info'), 4);
  //   } else if (newEmail != newConfirmEmail) {
  //     this.appComponent.msgStandardDanger(this.translate.instant('pg-app-component.email-not-match'), this.translate.instant('pg-app-component.email-not-match-info'), 4);
  //   } else {
  //     //this.registerLoading = true;
  //     let newUser: User = {
  //       login: newLogin,
  //       name: null,
  //       lastName: null,
  //       email: newEmail,
  //       jurisdictionId: 7,
  //       parentId: 8,
  //       password: newPassword,
  //       country: null,
  //       city: null,
  //       deleted: false,
  //       commission: 0,
  //       jurisdiction: null,
  //       userPermission: null,
  //       credit: 0,
  //       id: 0,
  //       children: null,
  //       bank: '',
  //       agency: '',
  //       account: '',
  //       registrationDate: new Date()
  //     };
  //     this.configService.createUser(newUser, newJurisdictionCoupon)
  //       .subscribe(data => {
  //         this.configService.sendPasswordToEmail(newUser.name, newUser.login, newUser.email, newUser.password)
  //           .subscribe(data => {
  //             if (data) {
  //               this.appComponent.msgStandard(this.translate.instant('pg-app-component.successfull-register'), this.translate.instant('pg-app-component.successfull-register-info'), 3);
  //               this.router.navigate(['/home']);
  //               //this.registerLoading = false;
  //             }
  //           }, error => {
  //             this.appComponent.msgStandardDanger(this.translate.instant('pg-app-component.erro-on-register'), this.translate.instant('pg-app-component.erro-on-register-info'), 4);
  //             //this.registerLoading = false;
  //             console.log(error);
  //           });
  //       }, error => {
  //         //this.msgStandardDanger(this.translate.instant('pg-app-component.erro-on-register'), this.translate.instant('pg-app-component.erro-on-register-info'), 4);
  //         this.appComponent.msgStandardDanger(this.translate.instant('pg-app-component.erro-on-register'), this.translate.instant('pg-new-user.' + error.error), 4);
  //         //this.registerLoading = false;
  //       });
  //   }
  // }

  // generateRandomPassword() {
  //   let length = 8,
  //     charset = 'abcde0123456789',
  //     newPassword = '';

  //   for (var i = 0, n = charset.length; i < length; ++i) {
  //     newPassword += charset.charAt(Math.floor(Math.random() * n));
  //   }

  //   return newPassword;
  // }

  // msgStandard(title: string, msg: string, type?: number) {
  //   this.titleToAdd = title;
  //   this.msgToAdd = msg;

  //   for (let i = 1; i < this.msgType.length; i++) {
  //     document.getElementById('msgModalStandard').classList.remove(this.msgType[i]);
  //     document.getElementById('msgBtnsucces').classList.remove(this.btnType[i]);
  //   }
  //   if (type != 0) {
  //     document.getElementById('msgModalStandard').classList.add(this.msgType[type]);
  //     document.getElementById('msgBtnsucces').classList.add(this.btnType[type]);
  //   }

  //   this.modalMessages.show();
  // }

  // --- REGISTER COMPONENTS --//
  get userFormLogin() {
    return this.userValidatingForm.get('userFormLogin');
  }

  get userFormName() {
    return this.userValidatingForm.get('userFormName');
  }

  get userFormLastName() {
    return this.userValidatingForm.get('userFormLastName');
  }

  get userFormEmail() {
    return this.userValidatingForm.get('userFormEmail');
  }

  get userFormConfirmEmail() {
    return this.userValidatingForm.get('userFormConfirmEmail');
  }

  get userFormPassword() {
    return this.userValidatingForm.get('userFormPassword');
  }

  get userFormConfirmPassword() {
    return this.userValidatingForm.get('userFormConfirmPassword');
  }

  get userJurisdictionCoupon() {
    return this.userValidatingForm.get('userJurisdictionCoupon');
  }

  /*get userFormPassword() {
    return this.validatingForm.get('userFormPassword');
  }*/

  get userFormCountry() {
    return this.userValidatingForm.get('userFormCountry');
  }

  get userFormCity() {
    return this.userValidatingForm.get('userFormCity');
  }

  get forgotFormEmail() {
    return this.forgotValidatingForm.get('forgotFormEmail');
  }

  generateRandomPassword() {
    let length = 8,
      charset = 'abcde0123456789',
      newPassword = '';

    for (var i = 0, n = charset.length; i < length; ++i) {
      newPassword += charset.charAt(Math.floor(Math.random() * n));
    }

    return newPassword;
  }

  getLogindesktop(log?: string, pass?: string, frame?: any) {
    if (log == null) {
      log = (<HTMLInputElement>document.getElementById('defaultForm-name-desktop')).value;
      pass = (<HTMLInputElement>document.getElementById('defaultForm-pass-desktop')).value;
    }

    this.configService.getLogin(log, pass)
      .subscribe((data: Login) => {
        this.loginData = data;
        this.interactionservice.setLoginData(data);
        this.userAdmin = data.user;
        this.isLogged = true;
        /*if (this.loginData.user.firstLogin) {
          frame.show();
          if (localStorage.getItem('currentUserIp') == null || localStorage.getItem('currentUserCountry') == null) {
            this.configService.getIPAddress().then((response) => {
              localStorage.setItem('currentUserIp', response.data['ip']);
              this.configService.getCountry(response.data['ip'])
                .then((response) => {
                  localStorage.setItem('currentUserCountry', response.data['currencyCode']);
                  var loginHistory = {
                    status: 'success',
                    userId: data.user['id'],
                    ipAddress: localStorage.getItem('currentUserIp')
                  }
                  this.configService.postLoginHistory(loginHistory, log)
                    .subscribe((data: LoginHistory) => {
                      //location.replace("/home");
                    }, error => {
                      console.log(error);

                    });
                }, error => console.log(error))
            }, error => console.log(error))
          } else {
            var loginHistory = {
              status: 'success',
              userId: data.user['id'],
              ipAddress: localStorage.getItem('currentUserIp')
            }
            this.configService.postLoginHistory(loginHistory, log)
              .subscribe((data: LoginHistory) => {

                //location.replace("/home");
              }, error => {
                console.log(error);

              });
          }

        } else {*/
        this.interactionservice.setLoginData(data);
        this.userAdmin = data.user;
        // localStorage.setItem('currentUser', this.userAdmin.id);
        this.userCookieService.setUserId(this.userAdmin.id);
        localStorage.setItem('currentUserToken', data.token);
        if (localStorage.getItem('currentUserIp') == null || localStorage.getItem('currentUserCountry') == null) {
          this.configService.getIPAddress().then((response) => {
            localStorage.setItem('currentUserIp', response.data['ip']);
            this.configService.getCountry(response.data['ip'])
              .then((response) => {
                localStorage.setItem('currentUserCountry', response.data['currencyCode']);
                var loginHistory = {
                  status: 'success',
                  userId: data.user['id'],
                  ipAddress: localStorage.getItem('currentUserIp')
                }
                this.configService.postLoginHistory(loginHistory, log)
                  .subscribe((data: LoginHistory) => {
                    //location.replace("/home");
                  }, error => {
                    console.log(error);

                  });
              }, error => console.log(error))
          }, error => console.log(error))
        } else {
          var loginHistory = {
            status: 'success',
            userId: data.user['id'],
            ipAddress: localStorage.getItem('currentUserIp')
          }
          this.configService.postLoginHistory(loginHistory, log)
            .subscribe((data: LoginHistory) => {

              //location.replace("/home");
            }, error => {
              console.log(error);

            });
        }

        if (this.userAdmin.country == null) {
          localStorage.setItem('currentUserCountry', 'Brazil');
        } else {
          localStorage.setItem('currentUserCountry', this.userAdmin.currencyCode);
        }
        //this.isLogged = true;
        this.cookieService.set('user', JSON.stringify(data.user), 1);
        //this.checkCredit();
        this.configService.setGlobalCurrency();
        // (<HTMLInputElement>document.getElementById("defaultForm-name-desktop")).value = "";
        // (<HTMLInputElement>document.getElementById("defaultForm-pass-desktop")).value = "";
        // location.replace('/home');

        this.configService.getLoginEncryption(log)
          .subscribe(data => {
            this.router.navigate(['/logged/' + data.toString()]);
            //location.reload();
          }, error => {
            this.router.navigate(['/home']);
            //location.reload();
          })
        //}
      }, error => {
        console.log(error);
        this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.error-login'), this.translate.instant('pg-app-component.wrong-user-password'), true);
      });


  }

  register() {
    let newLogin = (<HTMLInputElement>document.getElementById('orangeForm-login')).value;
    let newName = (<HTMLInputElement>document.getElementById('orangeForm-name')).value;
    let newLastName = (<HTMLInputElement>document.getElementById('orangeForm-lastname')).value;
    let newEmail = (<HTMLInputElement>document.getElementById('orangeForm-email')).value;
    let newConfirmEmail = (<HTMLInputElement>document.getElementById('orangeForm-email2')).value;
    let newPassword = (<HTMLInputElement>document.getElementById('orangeForm-password')).value;
    let newConfirmPassword = (<HTMLInputElement>document.getElementById('orangeForm-confirm-password')).value;
    // let newJurisdictionCoupon = (<HTMLInputElement>document.getElementById('jurisdiction-coupon')).value;
    let newJurisdictionCoupon = this.code;
    let newCountry = (<HTMLInputElement>document.getElementById('orangeForm-country')).value;
    let newCity = (<HTMLInputElement>document.getElementById('orangeForm-city')).value;

    //let newPassword = this.generateRandomPassword();

    if (newLogin == '' || newEmail == '' || newPassword == '' || newConfirmPassword == '') {
      this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.field-required'), this.translate.instant('pg-app-component.field-required-info'), true);
    } else if (newPassword.length < 8) {
      this.modalsService.openNotificationModal(this.translate.instant('pg-settings.password-not-match'), this.translate.instant('pg-edit-user.fail-password'), true);
    } else if (newLogin.includes(' ')) {
      this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.field-required'), this.translate.instant('pg-app-component.invalid-character-info'), true);
    } else if (!newEmail.includes('@') || !newEmail.includes('.') || newEmail.length < 5) {
      this.modalsService.openNotificationModal(this.translate.instant('pg-app-component.field-required'), this.translate.instant('pg-app-component.invalid-email'), true);
    } else if (newPassword != newConfirmPassword) {
      this.modalsService.openNotificationModal(this.translate.instant('pg-settings.password-not-match'), this.translate.instant('pg-settings.password-not-match-info'), true);
    } else {
      this.registerLoading = true;
      let newUser: User = {
        login: newLogin,
        name: newName,
        lastName: newLastName,
        email: newEmail,
        jurisdictionId: 7,
        parentId: 8,
        password: newPassword,
        country: newCountry,
        city: newCity,
        deleted: false,
        commission: 0,
        jurisdiction: null,
        userPermission: null,
        credit: 0,
        id: 0,
        children: null,
        bank: '',
        agency: '',
        account: '',
        registrationDate: new Date(),
        currencyCode: "BRL"
      };

      this.configService.createUser(newUser, newJurisdictionCoupon)
        .subscribe(data => {
          this.modalsService.openNotificationModal(
            this.translate.instant('pg-app-component.successfull-register'),
            this.translate.instant('pg-app-component.successfull-register-info'),
            false
          );
          // this.modalRegister.hide();
          this.registerLoading = false;

          this.getLogindesktop(data.login, newPassword);
          this.router.navigate(['/home'])
          /*this.configService.sendPasswordToEmail(newUser.name, newUser.login, newUser.email, newUser.password)
            .subscribe(data => {
              if (data) {
                this.msgStandard(this.translate.instant('pg-app-component.successfull-register'), this.translate.instant('pg-app-component.successfull-register-info'), 3);
                this.modalRegister.hide();
                this.registerLoading = false;
              }
            }, error => {
              this.msgStandardDanger(this.translate.instant('pg-app-component.erro-on-register'), this.translate.instant('pg-app-component.erro-on-register-info'), 4);
              this.modalRegister.hide();
              this.registerLoading = false;
              console.log(error);
            });*/
        }, error => {
          this.modalsService.openNotificationModal(
            this.translate.instant('pg-app-component.erro-on-register'),
            this.translate.instant('pg-app-component.erro-on-register-info') + this.skinConfig.pageTitle,
            true
          );
          this.registerLoading = false;
        });
    }
  }
}
