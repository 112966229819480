<div class="container">

    <!-- Card -->
    <div class="card mb-5">
        <!-- Card content -->
        <div class="card-body bg-theme">

            <div class="row">
                <div class="col-12 pt-3">
                    <h3><i class="fal fa-money-check-edit-alt" style="font-size: 25px;"></i> {{
                        'pg-jackpot.edit-jackpot' | translate }}</h3>
                    <hr />
                </div>
                <div class="col-12  pb-3 table-responsive">

                    <mat-form-field>
                        <mat-label>{{ 'pg-tickets.filter' | translate}}</mat-label>
                        <input matInput (keyup)="applyFilter($event)">
                    </mat-form-field>

                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource">

                            <!-- Position Column -->
                            <ng-container matColumnDef="championship">
                                <th mat-header-cell *matHeaderCellDef> {{ 'pg-jackpot.championship-name' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.championship}} </td>
                            </ng-container>

                            <ng-container matColumnDef="value">
                                <th mat-header-cell *matHeaderCellDef> {{ 'pg-jackpot.remaining-value' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element"> {{Currency.currencySymbol}} {{element.value *
                                    Currency.quote | currency:'':'' }} </td>
                            </ng-container>

                            <ng-container matColumnDef="date">
                                <th mat-header-cell *matHeaderCellDef> {{ 'pg-jackpot.finish-data' | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.date | date: 'dd/MM/yyyy hh:mm'}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="acao">
                                <th mat-header-cell *matHeaderCellDef> {{ 'pg-jackpot.action' | translate }} </th>
                                <td mat-cell *matCellDef="let element"> <button mat-icon-button>
                                        <mat-icon (click)="deleteAcumulate(element.roundId)">delete</mat-icon>
                                    </button> </td>
                            </ng-container>



                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        </table>

                        <mat-paginator class="bg-theme" [pageSize]="50" [pageSizeOptions]="[50, 100, 500]"
                            showFirstLastButtons></mat-paginator>
                        <div class="col-12  pb-3 table-responsive">
                            <table mdbTable>
                                <thead>

                                </thead>
                                <tbody>

                                    <tr>

                                        <td>{{ 'pg-jackpot.jackpot-total' | translate }}:</td>
                                        <td colspan="3">{{Currency.currencySymbol}} {{total * Currency.quote |
                                            currency:'':'' }} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <mat-form-field class="code-field">
                        <mat-label>{{ 'pg-tickets.choose-round' | translate }}</mat-label>
                        <mat-select>
                            <mat-option #idRound *ngFor="let round of rounds" [value]="round.id"
                                (click)="getRoundJackpot(round.id)">
                                <!--{{ 'pg-tickets.round' | translate }}: ID {{round.id}}-->
                                {{round.name}} - {{round.number}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <div *ngIf="jackpot" class="col-12  pb-3">
                    <p>{{ 'pg-jackpot.current-jackpot' | translate }}: {{Currency.currencySymbol}} <span
                            style="font-weight: bold; color: red">{{ jackpot * Currency.quote | currency:'':'' }}</span>
                    </p>
                    <!--<input type="text" class="form-control" placeholder="{{ 'pg-jackpot.new-jackpot' | translate }}" id="jackpot">-->

                    <mat-form-field>
                        <mat-label>{{ 'pg-jackpot.new-jackpot' | translate }}</mat-label>
                        <span matPrefix class="pr-3">{{Currency.currencySymbol}} </span>
                        <input matInput class="form-control code-field" id="jackpot">
                        <span matSuffix class="pl-3">,00</span>
                    </mat-form-field>

                    <div class="row">
                        <div class="col-12 pt-3">
                            <button class="m-0 p-2 button-bg-theme button-font" type="button" mdbBtn
                                (click)="changeJackpot()" mdbWavesEffect>
                                {{ 'pg-jackpot.change-jackpot' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
                <!--<div class="col-12  pb-3">
                    <button class="m-0 p-2 button-bg-theme button-font" type="button"
                    mdbBtn (click)="changeJackpot()" mdbWavesEffect>
                        {{ 'pg-jackpot.change-jackpot' | translate }}</button>
                </div>-->

            </div>

        </div>
    </div>
    <!-- Card -->
</div>

<!--Modal delete -->
<div mdbModal #frame2="mdbModal" class="modal fade right" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-danger" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h4 class="modal-title">Confirmar Exclusão</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame2.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body mx-3">
                <div>
                    <p>
                        {{ 'pg-jackpot.warning-delete' | translate }}
                    </p>
                </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
                <button mdbBtn color="danger" class="waves-light button-font" (click)="confirmDelete()" mdbWavesEffect>
                    {{ 'pg-jackpot.warning-delete-buttom' | translate }}</button>
                <button mdbBtn color="danger" outline="true" class="waves-light" (click)="frame2.hide()"
                    mdbWavesEffect>{{ 'pg-jackpot.warning-cancelar-buttom' | translate }}</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal delete -->