<div class="row rowlajust" style="background-color: #676767;">
    <div class="col-12 p-0">
        <div class="side-menu">{{ 'comp-currency-quote.quote-day' | translate }}</div>
        <!--<div class="currency-quote">
            <marquee direction="left" width="100%">
                <span [innerHtml]="quotesToShow"></span>
            </marquee>
        </div>-->
        <div class="marquee">
            <p [innerHtml]="quotesToShow" style="font-size: 16px;"></p>
        </div>
        <!-- <div class="currency-info">
            * {{ 'comp-currency-quote.quote-info' | translate }}
        </div> -->
    </div>
</div>