import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { AppComponent } from '../../app.component';
import { Bet } from '../../bet.interface';
import { BetradarSport } from '../../betradar-sport.interface';
import { ConfigService } from '../../config.service';
import { Currency } from '../../currency.interface';
import { filterResult } from '../../filterResult.interface';
import { Game } from '../../game.interface';
import { InteractionService } from '../../interaction.service';
import { Round } from '../../round.interface';
import { ToastService } from '../../toast.service';
import { User } from '../../user.interface';
import { TableWinners } from '../result.component';
import { ShieldHelper } from 'src/app/Helpers/ShieldHelper';

@Component({
  selector: 'app-event-result',
  templateUrl: './event-result.component.html',
  styleUrls: ['./event-result.component.scss']
})
export class EventResultComponent implements OnInit {
  @ViewChild('frame', { static: true }) modalUser: ModalDirective;

  isLoaded: boolean = false;
  roundsPosted: Round[] = [];
  currentRound: Round;
  games: Game[] = [];
  isLoadingGames: boolean = true;
  winners: any[] = [];
  bets: Bet[] = [];
  totalPrize: number;
  totalWinners: any;
  eachValue: number;
  eachHits: number;
  users: User[] = [];
  Currency: Currency = <Currency>{};
  rankResult: string[] = [];
  roundName: string;
  info: any = [];
  dateStart: any = new Date();
  dateEnd: any = new Date();
  period: string = "lastWeek";
  type: string = "all";
  lastDay: any = new Date().getDate();
  lastMonth: any = new Date().getMonth() + 1;
  lastYear: any = new Date().getFullYear();
  betradarSports: BetradarSport[];
  listWinners: TableWinners;
  countWinners: any;
  sum: number = 0;
  betsCount: number = 0
  lodingGamersWinner: any;
  nameEvent: string = "";
  searchText: string = '';
  rodadasSelecionadas: any;
  filter: Round[] = [];
  filterResult: filterResult[] = [];
  suportFilter: any[] = [];
  ELEMENT_DATA: TableWinners[] = [];
  dataSource = new MatTableDataSource();
  selected = 0;
  allbets: number;
  roundId: number;

  isListLoaded: boolean = false;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(private configService: ConfigService, private appComponent: AppComponent, private toastService: ToastService,
    private translate: TranslateService, private interactionService: InteractionService, private router: Router,
    private modalsService: ModalsService, private modalsServiec: ModalsService
  ) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {
    this.setDates();
    this.getBetRadarSports();
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      })
  }

  getBetRadarSports() {
    this.configService.getBetradarSports()
      .subscribe(data => {
        this.betradarSports = data;
        this.StartList();
      });
  }

  getRoundsFinished() {
    this.configService.getRounds()
      .subscribe(data => {
        this.roundsPosted = data.sort((a, b) => (a.endDateTime < b.endDateTime) ? 1 : -1);
        for (let index = 0; index < this.roundsPosted.length; index++) {
          switch (this.appComponent.activeLang) {
            case 'pt':
              this.roundName = this.roundsPosted[index].name;
              break;
            case 'en':
              this.roundName = this.roundsPosted[index].nameEng;
              break;
            case 'it':
              this.roundName = this.roundsPosted[index].nameItaly;
              break;
            case 'es':
              this.roundName = this.roundsPosted[index].nameSpan;
              break;
            default:
              break;
          }
        }
        this.StartList();
        this.isLoaded = true;
      }, error => {
        //console.log(error);
        this.modalsService.openNotificationModal(this.translate.instant("pg-result.failed-load"), this.translate.instant("pg-result.failed-load-info") +
        this.translate.instant("pg-result.assistence-contact"), true);
      })
  }

  getBets(roundId: number) {
    this.configService.getAllBets(roundId)
      .subscribe(data => {
        this.bets = data;
        this.getUsers();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-result.failed-load"), this.translate.instant("pg-result.failed-load-info") +
        this.translate.instant("pg-result.assistence-contact"), true);
      });
  }

  getUsers() {
    this.configService.listCommonUsers()
      .subscribe(data => {
        this.users = data;
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-result.failed-load"), this.translate.instant("pg-result.failed-load-info") +
        this.translate.instant("pg-result.assistence-contact"), true);
      })
  }

  result(homeScore: number, awaySocore: number) {
    let ret = "X";
    if (homeScore > awaySocore) {
      ret = "1";
    } else if (homeScore < awaySocore) {
      ret = "2";
    }
    return ret;
  }
  resultForSingleMatch(homeScore: any, awaySocore: any) {
    let ret = "X";
    if (Number(homeScore) > Number(awaySocore)) {
      ret = "1";
    } else if (Number(homeScore) < Number(awaySocore)) {
      ret = "2";
    }
    return ret;
  }
  resultOverUnder(homeScore: any) {
    let ret = this.translate.instant("Over");
    if (homeScore == '2') {
      ret = this.translate.instant("Under");
    }
    return ret;
  }

  resultGG(homeScore: any, awaySocore: any) {
    let ret = this.translate.instant("No");
    if (homeScore > 0 && awaySocore > 0) {
      ret = this.translate.instant("Yes");
    }
    return ret;
  }


  resultYesNo(homeScore: any, awaySocore: any) {
    let ret = this.translate.instant("Yes");
    if (Number(homeScore) > Number(awaySocore)) {
      ret = this.translate.instant("Yes");
    } else {
      ret = this.translate.instant("No");
    }
    return ret;
  }

  detailCoupon(ticketCode) {
    this.modalsService.openDetailTickeModal(0, ticketCode);
  }

  openRound(round: filterResult) {
    const today = new Date().getTime();
    let roundId = round.id;
    if (round.posted && round.active) {
      localStorage.setItem("roundGet", roundId.toString());
      this.router.navigate(['/result/' + roundId.toString()]);
    } else if (new Date(round.dateStartMiliseconds).getTime() > today) {
      this.router.navigate(['/home/' + roundId])
    }
  }

  openBetList(roundId: string) {
    localStorage.setItem("roundGet", roundId);
    this.router.navigate(['/bet-list/' + roundId]);
  }

  searchDate(dateStart, dateEnd, type, nameEvent) {
    const datepipe: DatePipe = new DatePipe('pt-BR');
    let millisecondsPerDay = 1000 * 60 * 60 * 24;

    dateStart = datepipe.transform(dateStart, 'yyyy-MM-ddT00:00:00', 'pt-br');
    dateEnd = datepipe.transform(dateEnd, 'yyyy-MM-ddT23:59:59', 'pt-br');

    let newdateStart = new Date(dateStart);
    let newdateEnd = new Date(dateEnd);

    let minDateEnd = new Date(newdateEnd.getTime() - (91 * millisecondsPerDay));

    if (minDateEnd > newdateStart || dateEnd < dateStart) {
      return this.toastService.showNotification({ message: this.translate.instant("invalid date"), type: 'danger' });
    }

    this.suportFilter = this.filterResult.filter(element => {
      let round_dateStart = new Date(element.dateStart);
      return round_dateStart >= newdateStart && round_dateStart <= newdateEnd
    });

    this.listOrganize(type, nameEvent);

    this.suportFilter.sort((a, b) => {
      if (a.dateEnd > b.dateEnd) return -1;
      if (b.dateEnd > a.dateEnd) return 1;
      return 0;
    });
  }

  setDates() {
    const datepipe: DatePipe = new DatePipe('pt-BR');

    let dateStart = new Date();
    dateStart.setDate(dateStart.getDate() - 7);
    this.dateStart = datepipe.transform(dateStart, 'yyyy-MM-dd', null, 'pt-BR');

    let dateEnd = new Date();
    this.dateEnd = datepipe.transform(dateEnd, 'yyyy-MM-dd', null, 'pt-BR');
  }

  listOrganize(type: string, nameEvent: string) {
    nameEvent = this.normalizeString(nameEvent);
    let idBetSport: BetradarSport;

    if (type != "all") {
      idBetSport = this.betradarSports.find(data => data.name.toUpperCase() == type.toUpperCase());
      // The nameEvent is set
      if (nameEvent != "") {
        this.suportFilter = this.suportFilter.filter(element => this.normalizeString(element.name).toUpperCase().includes(nameEvent.toUpperCase()) && element.idBetRadar == idBetSport.id);
      }
      // The nameEvent is not set
      this.suportFilter = this.suportFilter.filter(element => element.idBetRadar == idBetSport.id);
    }
    //The type is not set
    else {
      //The nameEvent is set
      if (nameEvent != "") {
        this.suportFilter = this.suportFilter.filter(element => this.normalizeString(element.name).toUpperCase().includes(nameEvent.toUpperCase()));
      }
      // The nameEvent is not set
      else return;
    }
  }

  normalizeString(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  takeLimitedDate() {
    const dateToday: Date = new Date();
    this.dateEnd = new Date();
    dateToday.setDate(this.dateEnd.getDate() - 365);
    const datepipe: DatePipe = new DatePipe('pt-BR')
    let lastWeek = datepipe.transform(dateToday, 'yyyy-MM-dd', null, 'pt-BR');
    return lastWeek;
  }

  StartList() {

    let idBetSport: BetradarSport;
    const helper: filterResult[] = [];
    const winnersList: any = [];
    try {
      this.configService.getRoundWinnersAndActivateRounds().subscribe(async element => {
        element.map(data => {
          let dateStart = new Date(data.round.startDateTime).getTime() - (new Date()).getTimezoneOffset() * 60000;
          let dateEnd = new Date(data.round.endDateTime).getTime() - (new Date()).getTimezoneOffset() * 60000;
          let points = 0;

          //this.betsCount = data.quantBets;
          idBetSport = this.betradarSports?.find(betElement => betElement.id == data.round.betradarSportId);
          if (idBetSport != null) {
            winnersList.push(data.winner);
            this.totalWinners = data.winner.length;
            data.winner.forEach(item => {
              this.sum += +(item.value);
              points = item.points;
            })
            if (data.round.jiuJitsuRound != 1) data.round.jiuJitsuRound = 0;
            this.filterResult.push({
              id: data.round.id,
              name: data.round.name,
              dateStart: this.transformDate(dateStart),
              dateStartMiliseconds: new Date(data.round.startDateTime).getTime(),
              dateEnd: this.transformDate(dateStart),
              dateEndMiliseconds: new Date(data.round.endDateTime).getTime(),
              players: 0,//this.betsCount,
              qtdWinners: data.winner.length,
              winners: 0,
              totalhits: points,
              totalprize: this.configService.formatCurrencyValue(this.sum),
              idBetRadar: data.round.betradarSportId,
              sport: this.translate.instant('pg-home.' + idBetSport.name),
              posted: data.round.posted,
              active: data.round.active,
              singleMatch: data.round.singleMatchRound,
              jj: data.round.jiuJitsuRound,
              roundNumber: data.round.number
            });

            let formattedDateStart = new Date(this.dateStart);
            formattedDateStart = new Date(formattedDateStart.setDate(formattedDateStart.getDate() + 1));
            formattedDateStart.setHours(0, 0, 0, 0);

            let formattedDateEnd = new Date(this.dateEnd);
            formattedDateEnd = new Date(formattedDateEnd.setDate(formattedDateEnd.getDate() + 1));
            formattedDateEnd.setHours(23, 59, 59, 59);

            let round_startDateTime = new Date(dateStart);

            if (round_startDateTime >= formattedDateStart && round_startDateTime <= formattedDateEnd) {
              helper.push({
                id: data.round.id,
                name: data.round.name,
                dateStart: this.transformDate(dateStart),
                dateStartMiliseconds: new Date(data.round.startDateTime).getTime(),
                dateEnd: this.transformDate(dateStart),
                dateEndMiliseconds: new Date(data.round.endDateTime).getTime(),
                players: 0,//this.betsCount,
                qtdWinners: data.winner.length,
                winners: 0,
                totalhits: points,
                totalprize: this.configService.formatCurrencyValue(this.sum),
                idBetRadar: data.round.betradarSportId,
                sport: this.translate.instant('pg-home.' + idBetSport.name),
                posted: data.round.posted,
                active: data.round.active,
                singleMatch: data.round.singleMatchRound,
                jj: data.round.jiuJitsuRound,
                roundNumber: data.round.number
              });
            };
            this.sum = 0;
            this.betsCount = 0;
          };
        });
        this.suportFilter = this.orderList(helper);
        this.isLoadingGames = false;
      });
    } catch (error) {
      console.error('Error fetching rounds winners:', error);
    }
  }

  handleSportClasses(round: filterResult) {
    const today = new Date().getTime();
    if (round.posted == 1) {
      return 'roundPosted';
    }
    if (round.posted == 0 && round.active && round.dateStartMiliseconds < today && round.dateEndMiliseconds < today) {
      return 'resultsoon';
    }

    if (round.posted == 0 && round.active && round.dateStartMiliseconds < today && round.dateEndMiliseconds > today) {
      return 'inProgress';
    }

    if (round.posted == 0 && round.active && round.dateStartMiliseconds > today) {
      return 'activeRound';
    }
  }

  statusMessager(round: filterResult) {
    const today = new Date().getTime();
    if (round.posted == 1) {
      return `<p class='mt-auto mb-auto'> ${this.translate.instant('posted')} </p>`;
    }
    if (round.posted == 0 && round.active && round.dateStartMiliseconds < today && round.dateEndMiliseconds < today) {
      return `<p class='m-auto'> ${this.translate.instant('resultsoon')}</p>`;
    }

    if (round.posted == 0 && round.active && round.dateStartMiliseconds < today && round.dateEndMiliseconds > today) {
      return `${this.translate.instant('inProgress')}`;
    }

    if (round.posted == 0 && round.active && round.dateStartMiliseconds > today) {
      return ` ${this.translate.instant('Betnow')}`;
    }
  }

  statusMessagerMobile(round: filterResult) {
    const today = new Date().getTime();
    if (round.posted == 1) {
      return `${this.translate.instant('posted')}`;
    }
    if (round.posted == 0 && round.active && round.dateStartMiliseconds < today && round.dateEndMiliseconds < today) {
      return this.translate.instant('resultsoon');
    }

    if (round.posted == 0 && round.active && round.dateStartMiliseconds < today && round.dateEndMiliseconds > today) {
      return this.translate.instant('inProgress');
    }

    if (round.posted == 0 && round.active && round.dateStartMiliseconds > today) {
      return this.translate.instant('Betnow');
    }
  }

  orderList(elements: filterResult[]) {
    const statusPriority = {
      'activeRound': 1,
      'inProgress': 2,
      'resultsoon': 3,
      'roundPosted': 4
    };

    const statusValueMap = new Map();

    elements.forEach(element => {
      const status = this.handleSportClasses(element);
      statusValueMap.set(element, statusPriority[status]);
    });

    const compareDates = (date1, date2) => {
      const d1 = new Date(date1).getTime();
      const d2 = new Date(date2).getTime();
      return d2 - d1;
    };

    elements.sort((a, b) => {
      const statusCompare = statusValueMap.get(a) - statusValueMap.get(b);
      if (statusCompare !== 0) return statusCompare;

      return compareDates(a.dateEnd, b.dateEnd);
    });

    return elements;
  }




  orderList2(element: filterResult[]) {
    element.sort((a, b) => {

      const order = ['BetNow', 'inProgress', 'resultsoon'];

      const indexA = order.indexOf(this.statusMessager(a).trim());
      const indexB = order.indexOf(this.statusMessager(b).trim());

      if (indexA > indexB) return 1;
      if (indexA < indexB) return -1;

      if (a.dateEnd > b.dateEnd) return -1;
      if (b.dateEnd > a.dateEnd) return 1;

      return 0;
    });

    return element;
  }


  getToday() {
    const dateToday: Date = new Date();
    this.dateEnd = new Date();
    dateToday.setDate(this.dateEnd.getDate() + 1);
    const datepipe: DatePipe = new DatePipe('pt-BR')
    let today = datepipe.transform(dateToday, 'yyyy-MM-dd', null, 'pt-BR');
    return today;
  }
  getlastDay() {
    const dateToday: Date = new Date();
    this.dateEnd = new Date();
    dateToday.setDate(this.dateEnd.getDate() - 2);
    const datepipe: DatePipe = new DatePipe('pt-BR')
    let lastDay = datepipe.transform(dateToday, 'yyyy-MM-dd', null, 'pt-BR');
    return lastDay;
  }
  getlastWeek() {
    const dateToday: Date = new Date();
    this.dateEnd = new Date();
    dateToday.setDate(this.dateEnd.getDate() - 8);
    const datepipe: DatePipe = new DatePipe('pt-BR')
    let lastWeek = datepipe.transform(dateToday, 'yyyy-MM-dd hh:mm', null, 'pt-BR');
    return lastWeek;
  }
  getlastMonth() {
    const dateToday: Date = new Date();
    this.dateEnd = new Date();
    dateToday.setDate(this.dateEnd.getDate() - 30);
    const datepipe: DatePipe = new DatePipe('pt-BR')
    let lastMonth = datepipe.transform(dateToday, 'yyyy-MM-dd', null, 'pt-BR');
    return lastMonth;
  }
  getThreeMonth() {
    const dateToday: Date = new Date();
    this.dateEnd = new Date();
    dateToday.setDate(this.dateEnd.getDate() - 90);
    const datepipe: DatePipe = new DatePipe('pt-BR')
    let lastMonth = datepipe.transform(dateToday, 'yyyy-MM-dd', null, 'pt-BR');
    return lastMonth;
  }

  transformDate(element: any) {
    const datepipe: DatePipe = new DatePipe('pt-BR')
    let date = datepipe.transform(element, 'yyyy-MM-ddThh:mm:ss', null, 'pt-BR');
    return date;
  }

  observeSelect(dateStart, dateEnd, period): void {
  }

  setMatchShield(name: string, num: number) {
    let names = name.split(' X ');
    return this.getMatchShield(names[num]);
  }

  getMatchShield(name: string) {
    return ShieldHelper.getChampionshipLogos(name);
    // let searchText = this.remove_accents(name);
    // for (var i = 0; i < searchText.length; i++) {
    //   if (searchText.charAt(i) == " ") searchText = searchText.replace(" ", "%20")
    // }
    // searchText = searchText.toLocaleLowerCase()
    // let ret = 'https://poolbet365.com/assets/shields/' + searchText + '.png';
    // if (name.toLocaleUpperCase().includes(("POKER").toLocaleUpperCase())) {
    //   if (name.toLocaleUpperCase().includes(("Israelita").toLocaleUpperCase())) {
    //     return ret = '../../../assets/image/ChampionShipFlags/estrDavy.png'
    //   }
    //   return ret = '../../../assets/image/ChampionShipFlags/poker.png'
    // }
    // if (name.toLocaleUpperCase().includes(("LA LIGA").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/laliga.png'
    // if (name.toLocaleUpperCase().includes(("campeonato BRASILEIRO").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/brasileirao.png'
    // if (name.toLocaleUpperCase().includes("MOTO GP")) return ret = '../../../assets/image/ChampionShipFlags/motogp.png'
    // if (name.toLocaleUpperCase().includes("FÓRMULA 1")) return ret = '../../../assets/image/ChampionShipFlags/f1.png'
    // if (name.toLocaleUpperCase().includes(("Bundesliga").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/bl.png'
    // if (name.toLocaleUpperCase().includes(("campeonato italiano").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/it.png'
    // //if(name.toLocaleUpperCase().includes(("copa do mundo").toLocaleUpperCase()))return ret = '../../../assets/image/ChampionShipFlags/cp.png'
    // if (name.toLocaleUpperCase().includes(("campeonato frances").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/fr.png'
    // if (name.toLocaleUpperCase().includes(("premier league").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/pl.png'
    // if (name.toLocaleUpperCase().includes(("uefa").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/uefa.png'
    // if (name.toLocaleUpperCase().includes(("Internacionais").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/international.png'
    // //  searchText.forEach(element => {
    // //    let found = ChampionshipLogos.find(x => x.name.includes(element.toLowerCase()));
    // //    if (found != null) {
    // //      ret = '../../../assets/shields/' + found.shield;
    // //    }
    // //  });
    // return ret;
  }

  remove_accents(str) {
    let accented = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    let withoutAccent = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    let namewithoutAccent = "";
    let j = str.length;
    for (let i = 0; i < j; i++) {
      let troca = false;
      for (let a = 0; a < accented.length; a++) {
        if (str.substr(i, 1) == accented.substr(a, 1)) {
          namewithoutAccent += withoutAccent.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca == false) {
        namewithoutAccent += str.substr(i, 1);
      }
    }
    return namewithoutAccent;
  }

  // listBets(roundId: number): any {
  //   let bets: Bet[];
  //   let n: number;
  //   this.configService.getAllBets(roundId).
  //     subscribe(data => {
  //       bets = data;
  //       return n;
  //     })
  //   return bets;
  // }
}
