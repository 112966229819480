<div class="editDlg-header">
  <p class="text-white">{{data.name}}</p>
</div>
<div mat-dialog-content class="upload_content">
  <mat-form-field class="w-75">
    <div class="row">
      <div class="col col-8">
        <div class="mb-2 row">
          <mat-label class="col col-4">{{ 'withdraw.bank' | translate }}</mat-label>
          <input matInput class="center-content code-field col col-8" disabled [ngModel]="customerInfo.bank">
        </div>
        <div class="mb-2 row">
          <mat-label class="col col-4">{{ 'withdraw.agency' | translate }}</mat-label>
          <input matInput class="center-content code-field col col-8" disabled [ngModel]="customerInfo.agency">
        </div>
        <div class="mb-2 row">
          <mat-label class="col col-4">{{ 'withdraw.account' | translate }}</mat-label>
          <input matInput class="center-content code-field col col-8" disabled [ngModel]="customerInfo.account">
        </div>
      </div>
      <div class="col col-4"></div>
    </div>
    <input matInput class="empty-input">
    <div class="row">
      <p class="px-4 py-0">{{ 'upload-doc.table_heaader' | translate }}</p>
      <table class="table text-white" style="font-size: small">
        <thead>
          <th scope="col">{{ 'upload-doc.Id' | translate }}</th>
          <th scope="col">{{ 'upload-doc.CreatedAt' | translate }}</th>
          <th scope="col">{{ 'upload-doc.Type' | translate }}</th>
          <th scope="col">{{ 'upload-doc.Status' | translate }}</th>
          <th scope="col">{{ 'upload-doc.ResponseAt' | translate }}</th>
        </thead>
        <tbody>
        <tr *ngFor="let item of docList">
          <td (click)="onImageOpen(item.fileName)">{{item.id}}</td>
          <td (click)="onImageOpen(item.fileName)">{{item.createdDate.toString().substring(0,10)}}</td>
          <td (click)="onImageOpen(item.fileName)">{{ descList[item.type] | translate }}</td>
          <td (click)="onImageOpen(item.fileName)">{{ statusList[item.status] | translate }}</td>
          <td *ngIf="item.status == 0">
            <a (click)="onAgreeClicked(item.id)">{{ 'upload-doc.Agree' | translate }}</a>
            <span>/</span>
            <a (click)="onDeclineClicked(item.id)">{{ 'upload-doc.Decline' | translate }}</a>
          </td>
          <td *ngIf="item.status != 0" (click)="onImageOpen(item.fileName)">
            {{item.responseDate.toString().substring(0,10)}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row" style="margin-top: 20px;">
      <p class="px-4 py-0">{{ 'upload-doc.withdraw_heaader' | translate }}</p>
      <table class="table text-white" style="font-size: small">
        <thead>
          <th scope="col">{{ 'upload-doc.Id' | translate }}</th>
          <th scope="col">{{ 'upload-doc.CreatedAt' | translate }}</th>
          <th scope="col">{{ 'upload-doc.Status' | translate }}</th>
          <th scope="col">{{ 'withdraw.amount' | translate }}</th>
          <th scope="col">{{ 'upload-doc.ResponseAt' | translate }}</th>
        </thead>
        <tbody>
        <tr *ngFor="let item of withdrawList">
          <td>{{item.id}}</td>
          <td>{{item.createdDate.toString().substring(0,10)}}</td>
          <td>{{ statusList[item.status] | translate }}</td>
          <td>
            {{item.amount}}
<!--            <a (click)="onAgreeClicked(item.id)">{{ 'upload-doc.Agree' | translate }}</a>-->
<!--            <span>/</span>-->
<!--            <a (click)="onDeclineClicked(item.id)">{{ 'upload-doc.Decline' | translate }}</a>-->
          </td>
          <td>
            {{item.status != 0 ? item.responseDate.toString().substring(0,10) : '' }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </mat-form-field>
</div>
<div class="editDlg-footer">
  <button mdbBtn class="waves-light button-font text-white" style="background-color:#01d36b;"  mdbWavesEffect
          (click)="onNoClick()">{{ 'pg-jurisdiction.cancel' | translate}}</button>
<!--  <button class="close-btn" mat-button (click)="onNoClick()">{{ 'pg-jurisdiction.cancel' | translate}}</button>-->
</div>

<div id="myModal" class="modal" style="display: block" *ngIf="imageShowFlag">
  <span class="close" (click)="onImageClose()">&times;</span>
  <img class="modal-content" id="img01" [src]="imageSrc">
  <div id="caption"></div>
</div>
