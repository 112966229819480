<div *ngIf="isLoading | async" class="overlay">
    <!-- <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value"><div style="position:relative; top: -60px; left: 30px;">Bolão...</div></mat-progress-spinner>     -->

      <button class="spinner" mdbBtn color="white" rounded="true" outline="true">
          <!-- <span style="font-size: 4em">Bolão</span> -->
          <!-- <span class="spinner-grow" style="animation-delay: 0.2s;" role="status" aria-hidden="true"></span>
          <span class="spinner-grow" style="animation-delay: 0.1s;" role="status" aria-hidden="true"></span>
          <span class="spinner-grow" role="status" aria-hidden="true"></span> -->
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
      </button>
</div>
