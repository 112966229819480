import { Injectable } from '@angular/core';
import { CookieService } from '../cookie/cookie.service';
import { CryptoService } from '../Crypto/Crypto.service'

@Injectable({
    providedIn: 'root'
})
export class UserCookieService {
    constructor(private cookieService: CookieService,
                private cryptoService: CryptoService
    ) { }

    private userId: number = 0;

    setUserId(userId: number){
        this.userId = userId;
        const cryptedId = this.cryptoService.encrypt(`${userId}`);

        this.cookieService.set('baseId', cryptedId, 1);
    }

    getUserId(){
        if(this.userId <= 0){
            const encryptedId= this.cookieService.get('baseId'); 
            const decryptedId = this.cryptoService.decrypt(encryptedId);

            this.userId = Number(decryptedId);
        }

        return this.userId;
    }

    clearUserId(){
        this.cookieService.delete('baseId');
    }
}