import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import { CookieService } from '../services/cookie/cookie.service';
import { ConfigService } from '../config.service';
import { AppComponent } from '../app.component';
import { Login } from '../login.interface';
import { User } from '../user.interface';
import { ModalDirective } from 'angular-bootstrap-md';
import { InteractionService } from '../interaction.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from '../services/modals/modals.service';

@Component({
  selector: 'app-logged',
  templateUrl: './logged.component.html',
  styleUrls: ['./logged.component.scss']
})
export class LoggedComponent implements OnInit {
  @ViewChild('frame2', { static: true }) modalPassword: ModalDirective;

  key: string = "";
  public cookie: string;

  newPassword: string;
  confirmPassword: string;

  constructor(private configService: ConfigService, private interactionservice: InteractionService,
    private cookieService: CookieService, private route: ActivatedRoute,
    private router: Router, private appComponent: AppComponent, private translate: TranslateService,
    private modalsService: ModalsService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {
    //console.log(this.interactionservice.loginData);
    if (this.interactionservice.loginData != null) {
      this.key = this.route.snapshot.paramMap.get("key");
      //console.log("got key: " + this.key)
      this.checkLogged();
    }
    else {
      this.router.navigate(['/home']);
    }

  }

  checkLogged() {
    this.configService.checkLogged(this.key)
      .subscribe((data: User) => {
        // this.configService.getLogin(data.login, data.password)
        //   .subscribe((data: Login) => {
        //     this.interactionservice.setLoginData(data);
        //     this.appComponent.userAdmin = data.user;
        //     this.appComponent.isLogged = true;
        //     this.cookieService.set('user', JSON.stringify(data.user));
        //     this.modalPassword.show();
        this.router.navigate(['/home']);
      }, error => {
        this.router.navigate(['/home']);
      });
    // }, error => {
    //   this.appComponent.msgStandard(this.translate.instant('pg-logged.operation-failed'), this.translate.instant('pg-logged.fail-logon'), 4);
    //   this.router.navigate(['/home']);
    //   console.log(error);
    // });
  }

  changePassword() {
    if (this.newPassword == this.confirmPassword || this.newPassword == "") {
      this.modalPassword.hide();
      this.appComponent.userAdmin.password = this.newPassword;
      this.configService.updateUser(this.appComponent.userAdmin.id, this.appComponent.userAdmin)
        .subscribe(data => {
          this.modalsService.openNotificationModal(this.translate.instant('pg-logged.operation-success'), this.translate.instant('pg-logged.operation-success-info'), false);
        }, error => {
          this.modalsService.openNotificationModal(this.translate.instant('pg-logged.operation-failed'), this.translate.instant('pg-logged.fail-settings'), true);
        });
      this.router.navigate(['/home']);
      // this.appComponent.checkCredit();
    }
    else {
      this.modalsService.openNotificationModal(this.translate.instant('pg-logged.operation-failed'), this.translate.instant('pg-logged.fail-password'), true);
    }

  }

}
